import { useEffect, useState, useContext, useMemo, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import uuid from "react-uuid";
import styled from "styled-components";
import { chatContext } from "utils/providers/ChatContextProvider";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider.js";
import { signAppContext } from "utils/providers/SignContextProvider";
import { existMemberYn, signInToEmail, signInToSocial } from "api/memberApi";
import botApi from "api/botApi.js";
import LoadingComponent from "utils/LoadingComponent";
import SignUpPop from "components/templates/login/SignUpPop";
import { AlertPop2 } from "components/templates/popup/AlertPop";
import LoginPop from "components/templates/login/LoginPop";
import ComplexView from "./ComplexView";
import ComplexViewMobile from "./mobile/ComplexViewMobile";

export default function ExternalChatHome() {
  const { isMobile } = useContext(isMobileContext);
  const { isLoggedIn, handleSignInApp, handleSignOutAppFromChat } =
    useContext(signAppContext);
  const { chatBotData, setChatBotData, chatRoomId, setChatRoomId } =
    useContext(chatContext);
  const location = useLocation();
  const shortUrl = useMemo(() => {
    const tmpO = new URLSearchParams(location.search);
    return tmpO.get("bot");
  }, [location.search]);
  const { botUid } = useParams();

  // 최초 화면 띄우기 위한 정보 로딩 대기용 state
  const [isInit, setIsInit] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [displayMsg, setDisplayMsg] = useState("");

  const [loginPopState, setLoginPopState] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [tabState, setTabState] = useState(true);
  const [popState2, setPopState2] = useState(false);
  const [popMessage, setPopMessage] = useState("");
  const naver = window.naver;
  const kakao = window.Kakao;
  // 봇 정보 로딩 부분 처리.
  const getChatBotData = async () => {
    try {
      if (!shortUrl) {
        const { status, data } = await botApi.getBotInfoByBotUid(botUid, "Y");
        if (status === 200) {
          const resultData = JSON.parse(data.botInfo);
          setChatBotData(resultData);
          setIsInit(true);
        } else if (status === 204) {
          setInitLoading(false);
          setDisplayMsg(`접근이 허용 된  IP가 아닙니다.`);
          return;
        }
      } else {
        const { status, data } = await botApi.getBotInfoByShortUrl(shortUrl);
        if (status === 200) {
          const resultData = JSON.parse(data.botInfo);
          setChatBotData(resultData);
        }
      }
    } catch (error) {
      setInitLoading(false);
      setDisplayMsg(
        `유효하지 않은 주소로 작성하셨습니다.\n올바른 주소를 작성해 주세요`
      );
    }
  };

  useEffect(() => {
    if (!botUid && !shortUrl) {
      setDisplayMsg(`정상적인 접근이 아닙니다.\n올바른 주소를 작성해 주세요`);
      return;
    }
    setChatRoomId(uuid());
    // 새로고침 시 반드시 챗봇 정보를 불러온다. IP 체크도 하기 위함.
    getChatBotData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openLoginPop = () => setLoginPopState((prev) => !prev);
  //로그인 관련 모음 시작
  //E-mail 로그인
  const signIn = async (email, password) => {
    try {
      if (!email || (email?.trim() ?? "").length === 0) {
        setPopState2((prev) => !prev);
        setPopMessage("이메일 정보를 입력 해주세요.");
        return;
        // return alert("이메일 정보를 입력 해주세요.");
      }

      if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
        setPopState2((prev) => !prev);
        setPopMessage("이메일 형식으로 작성해 주세요.");
        return;
        // return alert("이메일 형식으로 작성해 주세요.");
      }

      const { headers } = await signInToEmail(email, password);

      handleSignInApp(
        headers.authorization,
        headers.refresh_token,
        headers.member_email,
        headers.member_uid,
        headers.social_yn,
        "email"
      );

      openSignUp && setOpenSignUp(false);
      loginPopState && setLoginPopState(false);
    } catch (error) {
      console.log(">>>! error : ", error);
    }
  };
  //Naver 로그인
  const handleNaverLogin = () => {
    const naverLoginButton = document.querySelector("#naverIdLogin a");
    naverLoginButton.click();
  };
  const socialSignIn = useCallback(
    async (id, email, name, socialType, agreeItems) => {
      try {
        const { headers, status } = await signInToSocial(
          id,
          email,
          name,
          socialType,
          agreeItems
        );

        if (status === 200) {
          handleSignInApp(
            headers.authorization,
            headers.refresh_token,
            headers.member_email,
            headers.member_uid,
            headers.social_yn,
            socialType
          );
        } else {
          setPopState2((prev) => !prev);
          setPopMessage("잠시 후 다시 시도 해 주세요.");
        }
      } catch (error) {
        console.log(">>> error : ", error);
      } finally {
        setLoginPopState(false);
      }
    },
    [handleSignInApp]
  );

  const naverInit = useCallback(() => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: process.env.REACT_APP_NAVER_CLIENT_KEY,
      callbackUrl: `${process.env.REACT_APP_HOST}/naverLoginSuccess?chatUid=${botUid}`,
      isPopup: false,
      loginButton: { color: "green", type: 1, height: 30 },
    });

    naverLogin.init();
    naver.successCallback = socialSignIn;
  }, [naver, botUid, socialSignIn]);

  const kakaoInit = useCallback(() => {
    !kakao.isInitialized() && kakao.init(process.env.REACT_APP_KAKAO_APP_KEY);
  }, [kakao]);

  const getGoogleUserInfo = async (userInfo) => {
    const id = userInfo.data.sub;
    const email = userInfo.data.email;

    const { data } = await existMemberYn(id, "google");

    if (data.existMemberYn === "N") {
      // setInfoStruct({
      //   id: id,
      //   email: email,
      //   socialType: "google",
      // });
    } else {
      socialSignIn(id, email, "google");
    }
  };
  //로그인 관련 모음 종료

  // 최초에 필요로 하는 정보들을 모두 가져오는 처리를 여기서 해야 함.
  useEffect(() => {
    naverInit();
    kakaoInit();
  }, [naverInit, kakaoInit]);

  if (!isInit) {
    return (
      <IntiCt>
        {initLoading && <LoadingComponent />}
        <p className="text">
          {displayMsg
            ? displayMsg
            : `정보를 로딩 중입니다..\n잠시만 기다려주세요.`}
        </p>
      </IntiCt>
    );
  }

  return (
    <>
      {isMobile ? (
        <ComplexViewMobile
          props={{
            isLoggedIn,
            chatBotData,
            chatRoomId,
            setChatRoomId,
            openLoginPop,
            handleSignOutAppFromChat,
            setPopState2,
            setPopMessage,
          }}
        />
      ) : (
        <ComplexView
          props={{
            isLoggedIn,
            chatBotData,
            chatRoomId,
            setChatRoomId,
            openLoginPop,
            handleSignOutAppFromChat,
          }}
        />
      )}
      {popState2 && (
        <AlertPop2
          popState={popState2}
          setPopState={setPopState2}
          text={popMessage}
        />
      )}
      {loginPopState && (
        <LoginPop
          setLoginPopState={setLoginPopState}
          tabState={tabState}
          setTabState={setTabState}
          getGoogleUserInfo={getGoogleUserInfo}
          kakao={kakao}
          handleNaverLogin={handleNaverLogin}
          setOpenSignUp={setOpenSignUp}
          signIn={signIn}
          popClass={"complexView"}
          retParam={encodeURIComponent(`chat/${botUid}`)}
        />
      )}
      {openSignUp && (
        <SignUpPop setOpenSignUp={setOpenSignUp} signIn={signIn} />
      )}
    </>
  );
}

const IntiCt = styled.div`
  /* position: absolute; */

  width: 100vw;
  height: 100vh;

  margin: 0;
  /* padding: 10vw 10vw; */

  background-color: #081120;
  color: #ffffff;

  font-size: 24px;
  & p.text {
    position: fixed;
    width: 100%;
    text-align: center;
    line-height: normal;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: pre-wrap;
  }
`;
