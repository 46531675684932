import { useContext, useEffect, useMemo } from "react";
import { chatContext } from "../providers/ChatContextProvider.js";
import ObjectUtil from "../objectUtil.js";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import botApi from "../../api/botApi.js";

export default function useAddKnowledgeChatBotSelect(props) {
  const { chatBotData } = useContext(chatContext);
  const { strToArray } = ObjectUtil;
  

  const queryClient = useQueryClient();
  const { data: selectedOption } = useQuery({
    queryKey: ["addKnowledgeChatBotSelectOption"],
    initialData: null,
    staleTime: Infinity,
  });

  const updateSelectedOption = (updateValue) => {
    queryClient.setQueryData(
      ["addKnowledgeChatBotSelectOption"],
      () => updateValue
    );
  };
  const createSelectedItem = (item) => {
    return {
      ...item,
      value: item.botUid,
      label: item.botName,
    };
  };
  const submitSelectedOption = (item) => {
    if (!item) return;

    updateSelectedOption(!!item?.value ? item : createSelectedItem(item));
  };

  const {
    data: loadAddKnowBotList,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["loadAddKnowBotList", chatBotData?.botUid],
    queryFn: () =>
      botApi.loadAddKnowBotList(chatBotData.botUid).then((res) => {
        console.log("res.data", res.data);
        const list = strToArray(res.data.userBotList);
        submitSelectedOption(list[0]);
        return list;
      }),
    staleTime: 1000 * 60 * 3,
    cacheTime: 1000 * 60 * 3,
    enabled: !!chatBotData?.botUid,
  });

  const refineOptions = useMemo(
    () =>
      loadAddKnowBotList?.map((item) => {
        item.value = item.botUid;
        item.label = item.botName;

        return item;
      }),
    [loadAddKnowBotList]
  );

  useEffect(() => {
    console.log('selectedOption', selectedOption)
    console.log('refineOptions', refineOptions)
    if (!selectedOption && !!refineOptions && refineOptions.length > 0) updateSelectedOption(refineOptions[0]);
    
    return () => {
      // 컴포넌트 unmount되면 캐시에서 제거.
      queryClient.removeQueries(["addKnowledgeChatBotSelectOption"]);
    };
  }, []);

  return {
    loadAddKnowBotList: refineOptions,
    isLoading,
    selectedOption,
    updateSelectedOption,
    submitSelectedOption,
    refetch,
  };
}
