import icon_close from "assets/images/icon_close.svg";
import styled from "styled-components";
import { FlexBox } from "../../pages/dashboard/dashboardStyle.js";
import { useState, useContext } from "react";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider.js";
import { List, ListItem, ListItemButton, ListSubheader } from "@mui/material";

export default function AlertPop({ popState, setPopState, popMessage }) {
  return (
    <PopWrapper>
      <CloseBtn
        icon={icon_close}
        onClick={(e) => {
          setPopState((prev) => !prev);
        }}
      ></CloseBtn>

      <TextWrapper id="tab_log">
        <PopDesc>
          MESwiser 엔진의 무료 인공지능 챗봇 생성 용량을 초과 하였습니다. 무료로
          생성 된 챗봇을 사용해보시고 서비스 모델에 가입하시면 추가로 챗봇
          생성이 가능합니다.
        </PopDesc>
      </TextWrapper>
      <LoginBtn
        onClick={(e) => {
          setPopState((prev) => !prev);
        }}
      >
        확인
      </LoginBtn>
    </PopWrapper>
  );
}

export function AlertListPop(props) {
  const { msg, title, checkItemList, setPopState, type, close } = props ?? {};

  return (
    <PopWrapper
      className={type ? type + " pop2" : "pop2"}
      style={{ zIndex: 999999999999 }}
    >
      <CloseBtn
        icon={icon_close}
        onClick={(e) => {
          if (close) close();
          else setPopState((prev) => !prev);
        }}
      ></CloseBtn>

      <TextWrapper className={type ? type + " list" : "list"} id="tab_log">
        <PopDesc className={type && type}>{msg}</PopDesc>
        {checkItemList.length > 0 && (
          <>
            <PopDescTitle className={type && type}>{title}</PopDescTitle>
            {checkItemList.map((data, idx) => (
              <PopDescKnow className={type && type} key={idx}>
                {data.name}
              </PopDescKnow>
            ))}
          </>
        )}
      </TextWrapper>
      <AlertPopBtnGroup>
        <AlertPopBtn
          className="confirm"
          onClick={(e) => {
            if (close) close();
            else setPopState((prev) => !prev);
          }}
        >
          확인
        </AlertPopBtn>
      </AlertPopBtnGroup>
    </PopWrapper>
  );
}

export function AlertPop2(props) {
  const {
    text,
    callback,
    popState,
    setPopState,
    close,
    checkItemList,
    itemList,
    ...otherProps
  } = props ?? {};

  return (
    <PopWrapper
      className="pop2"
      {...otherProps}
      style={{ ...(otherProps?.style ?? {}), zIndex: 999999999999 }}
    >
      <CloseBtn
        icon={icon_close}
        onClick={(e) => {
          close ? close() : setPopState((prev) => !prev);
        }}
      ></CloseBtn>

      <TextWrapper className={itemList && "list"} id="tab_log">
        {!itemList ? (
          <PopDesc>{text}</PopDesc>
        ) : Object.values(checkItemList).reduce(
            (acc, data) => acc + data.fileList.length,
            0,
          ) === 0 ? (
          <PopDesc>{"선택 된 지식이 없습니다."}</PopDesc>
        ) : (
          <>
            <PopDescTitle>선택된 지식 목록</PopDescTitle>
            <List
              variant="outlined"
              size={"md"}
              sx={{
                borderRadius: "sm",
              }}
            >
              {Object.values(checkItemList)?.map((item, idx) => (
                <MyListItem
                  sx={{ alignItems: "flex-start" }}
                  key={item.dataUid + idx}
                >
                  <KnowListHeader>
                    {item.dataName}({item.fileList.length}개)
                  </KnowListHeader>
                  <KnowListItemList>
                    {item.fileList.map((file) => (
                      <KnowListItem key={file.dataUid}>
                        <KnowListItemTitle>{file.dataName}</KnowListItemTitle>
                      </KnowListItem>
                    ))}
                  </KnowListItemList>
                </MyListItem>
              ))}
            </List>
            {/*{checkItemList.map((data, idx) => (*/}
            {/*  <PopDescKnow key={idx}>{data.name}</PopDescKnow>*/}
            {/*))}*/}
          </>
        )}
      </TextWrapper>
      <AlertPopBtnGroup>
        <AlertPopBtn
          className="confirm"
          onClick={(e) => {
            close ? close() : setPopState((prev) => !prev);
            callback && callback();
          }}
        >
          확인
        </AlertPopBtn>
      </AlertPopBtnGroup>
    </PopWrapper>
  );
}

// expire 팝업
export function AlertPop3(props) {
  const {
    text,
    callback,
    popState,
    setPopStateExpire,
    setPopState,
    close,
    ...otherProps
  } = props ?? {};
  return (
    <PopWrapper
      className="alert3"
      {...otherProps}
      style={{ ...(otherProps?.style ?? {}), zIndex: 999999999999 }}
    >
      <CloseBtn
        icon={icon_close}
        onClick={(e) => {
          if (close) close();
          else {
            setPopState((prev) => !prev);
            setPopStateExpire((prev) => !prev);
          }
        }}
      ></CloseBtn>

      <TextWrapper className={"alert3"} id="tab_log">
        <PopDescDiv>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </PopDescDiv>
      </TextWrapper>
      <AlertPopBtnGroup className="alert3">
        <AlertPopBtn
          className="confirm"
          onClick={(e) => {
            if (close) close();
            else {
              setPopState((prev) => !prev);
              setPopStateExpire((prev) => !prev);
            }
            callback && callback();
          }}
        >
          연장 신청하기
        </AlertPopBtn>
      </AlertPopBtnGroup>
    </PopWrapper>
  );
}

export function AlertPopWithRadio(props) {
  const [radioState, setRaidoState] = useState(5);
  const { isMobile } = useContext(isMobileContext);

  const { text, callback, popState, setPopState, ...otherProps } = props ?? {};
  return (
    <PopWrapper
      className="pop2"
      {...otherProps}
      style={{ ...(otherProps?.style ?? {}), zIndex: 999999999999 }}
    >
      <CloseBtn
        icon={icon_close}
        onClick={(e) => {
          setPopState((prev) => !prev);
        }}
      ></CloseBtn>

      <TextWrapper>
        <PopDescTitle>URL 수집 방법</PopDescTitle>
        <PopDescCt className="flexCol">
          <div className="inputCt">
            <div className="textCt">
              <p className="ex black">
                최상위 도메인을 포함하는 모든 페이지가 대상일 경우 수집 시간이
                {!isMobile && <br />}
                오래 걸릴 수 있습니다. 수집이 필요한 특정 경로 하위 페이지 또는
                {!isMobile && <br />}
                특정 페이지만의 수집을 추천합니다.
              </p>
            </div>
          </div>

          <div className="inputCt">
            <input
              onClick={() => {
                setRaidoState(5);
              }}
              name="urlInput"
              id="5"
              type="radio"
              defaultChecked="checked"
            />
            <div className="textCt">
              <label htmlFor="5">입력된 동적 페이지 모두 (default)</label>
              <p className="ex">{`ex) https://meswiser.ai/{path}/**`}</p>
              <p className="ex">{`{path}경로아래만 분석합니다. `}</p>
            </div>
          </div>
          {/* <div className="inputCt">
            <input
              onClick={() => {
                setRaidoState(2);
              }}
              name="urlInput"
              id="2"
              type="radio"
            />
            <label htmlFor="2">최상위 도메인 및 호스트 경로 모두</label>
          </div> */}
          {/* <div className="inputCt">
            <input
              onClick={() => {
                setRaidoState(3);
              }}
              name="urlInput"
              id="3"
              type="radio"
            />
            <div className="textCt">
              <label htmlFor="3">주어진 경로 모두</label>
              <p className="ex">ex) htt</p>
            </div>
          </div> */}
          <div className="inputCt">
            <input
              onClick={() => {
                setRaidoState(4);
              }}
              name="urlInput"
              id="4"
              type="radio"
            />
            <div className="textCt">
              <label htmlFor="4">입력된 페이지만</label>
              <p className="ex">ex) https://meswiser.ai/index</p>
              <p className="ex">index 페이지만 분석합니다.</p>
            </div>
          </div>
          <div className="inputCt">
            <input
              onClick={() => {
                setRaidoState(1);
              }}
              name="urlInput"
              id="1"
              type="radio"
            />
            <div className="textCt">
              <label htmlFor="1">최상위 도메인 경로 모두</label>
              <p className="ex">ex) https://meswiser.ai/*/**</p>
              <p className="ex red">전체경로를 모두 수집 및 학습합니다.</p>
              <p className="ex red small">
                홈페이지 용량에 따라 오랜 시간이 소요될 수 있습니다.
              </p>
            </div>
          </div>
        </PopDescCt>
      </TextWrapper>

      <AlertPopBtnGroup>
        <AlertPopBtn
          className="cancel"
          onClick={(e) => {
            setPopState((prev) => !prev);
          }}
        >
          취소
        </AlertPopBtn>
        <AlertPopBtn
          className="confirm"
          onClick={(e) => {
            setPopState((prev) => !prev);
            callback && callback(radioState);
          }}
        >
          확인
        </AlertPopBtn>
      </AlertPopBtnGroup>
    </PopWrapper>
  );
}

const PopWrapper = styled.div`
  position: absolute;
  top: 10%;
  left: 45%;
  background-color: #fafafa;

  /* width: 550px; */
  max-width: 550px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  padding: 65px 0 15px;

  border-radius: 20px;

  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.18);

  &.pop2,
  &.alert3 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    position: fixed;

    @media (max-width: 480px) {
      width: 85%;
    }
  }
`;

const TextWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex-wrap: wrap;
  max-width: 440px;
  margin-bottom: 35px;

  &.alert3 {
    text-align: center;
    width: 90%;
    margin-bottom: 20px;
  }

  &.list {
    padding: 5px 30px;
    max-height: 500px;
    overflow-y: scroll;
    /* 세로 스크롤바를 작게 만들기 */
    ::-webkit-scrollbar {
      width: 0px;
    }
  }
  &.except {
    overflow-y: auto;
    max-width: 100%;
    ::-webkit-scrollbar {
      width: 5px;
    }
  }
`;

const PopDesc = styled.p`
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;

  margin-top: 10px;
  width: 100%;
  padding: 0 20px;
  word-break: keep-all;

  &.except {
    text-align: center;
  }
`;

const PopDescDiv = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;

  margin-top: 10px;
  width: 100%;
  padding: 0 20px;
  word-break: keep-all;

  &.except {
    text-align: center;
  }
`;

const PopDescCt = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;

  margin-top: 10px;
  width: 100%;
  padding: 0 20px;
  word-break: keep-all;
  &.flexCol {
    display: flex;
    flex-direction: column;
  }
  & div.inputCt {
    margin: 2.5px 0;
    display: flex;
    align-items: start;
    & .textCt {
      margin-bottom: 5px;
    }
    & p.ex {
      font-size: 13px;
      color: #444;
      font-weight: 400;
      margin-left: 5px;
      /* margin-bottom: 5px; */
      word-break: break-all;
      &.red {
        color: tomato;
      }
      &.small {
        font-size: 12px;
      }
      &.black {
        color: black;
        line-height: 18px;
        margin-bottom: 5px;
        word-break: keep-all;
      }
    }
    & label {
      font-size: 14px;
      font-weight: 500;
      margin-left: 5px;
    }
  }
`;

const PopDescTitle = styled(PopDesc)`
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
`;
const PopDescKnow = styled(PopDesc)`
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  word-break: break-all;
  width: 300px;
  font-weight: 400;

  &.except {
    width: auto;
    text-align: left;
  }
`;

const LoginBtn = styled.button`
  text-align: center;
  padding: 14px;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #fff;
  width: 440px;
  height: 51px;

  border-radius: 4px;
  background-color: #ff892a;
  color: #fff;

  border-radius: 4px;
  margin-bottom: 21px;
`;

const AlertPopBtn = styled(LoginBtn)`
  width: auto;
  height: auto;
  flex: 0 0 auto;
  height: 45px;
  padding: 10px 14px;

  &.cancel {
    background-color: #fff !important;
    color: #ff6347;
    border: 1px solid #ff6347;

    &:hover {
      border: 1px solid #ff8b8b;
      color: #ff8b8b;
    }
  }
`;

const AlertPopBtnGroup = styled(FlexBox)`
  width: 100%;
  justify-content: center;
  margin-top: 25px;

  &.alert3 {
    margin-top: 15px;
  }

  button {
    &:hover {
      opacity: 0.7;
    }
    margin: 0 5px;
  }
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;

  background: url(${({ icon }) => icon}) no-repeat;
  background-size: cover;
  background-position: center;
`;

const KnowListHeader = styled.div`
  flex: 0 0 80px;
  width: 100px;
  word-break: keep-all;
  font-size: 14px;
  color: #777;
  padding: 5px;
  margin-top: 5px;
`;

const KnowListItemList = styled.div`
  max-width: 200px;
`;

const KnowListItem = styled.div`
  word-break: keep-all;
  font-size: 14px;
`;

const KnowListItemTitle = styled.div`
  margin: 10px 3px;
`;

const MyListItem = styled(ListItem)`
  border: 1px solid #dcdcdc;
  border-radius: 2px;
  margin: 5px 0;
`;
