/* eslint-disable */
import camera_icon from "assets/images/icons/icon_camera.svg";
import user_icon from "assets/images/icons/user_icon.svg";
import InputBox from "components/atomic/InputBox";
import NormalButton from "components/atomic/NormalButton2";
import TextArea from "components/atomic/TextArea";
import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";
import ProfilePop from "../popup/ProfilePop";

import iconClose from "../../../assets/images/icon_close.svg";
import { PriceUtil as priceUtil } from "../../../utils/priceUtil.js";
import { popupContext } from "../../../utils/providers/PopupProvider.js";

export default function BotInfoTable({
  botData,
  userPlan,
  modifyFunc,
  deleteFunc,
  addUseUserFunc,
  delUseUserFunc,
  useUserList,
  setPopMessage,
  setPopState,
  setPopStateExpire,
}) {
  const [useUser, setUseUser] = useState("");
  const [botName, setBotName] = useState("");
  const [excludeUrl, setExcludeUrl] = useState("");
  const [greetingMessage, setGreetingMessage] = useState("");
  const [botRole, setBotRole] = useState("");
  const [peerIP, setPeerIP] = useState("");
  const [botImagePath, setBotImagePath] = useState("");

  const [expireDateState, setExpireDateState] = useState(false);
  const [toolTip, setTooltip] = useState(false);

  const { isMobile } = useContext(isMobileContext);
  const { showTextPop } = useContext(popupContext);

  const remaining = () => {
    // let num = botData.totalCount - botData.useCount;
    let num = botData.useCount;

    if (isNaN(num)) return 0;

    if (num.toString().length > 3) {
      num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return num < 0 ? 0 : num;
  };

  const usesSizeInfo = () => {
    if (!botData.totalUsageValue) return `새로고침 필요`;
    var totalUsageString = botData.totalUsageValue.toString();
    var strSizeInfo = `${totalUsageString.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ",",
    )} byte`;

    // Byte to KB
    if (botData.totalUsageValue >= 1024) {
      const totalUsageKB = (botData.totalUsageValue / 1024).toFixed(2);
      strSizeInfo = `${totalUsageKB.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} KB`;
    }

    // Byte to MB
    if (botData.totalUsageValue >= 1024 * 1024) {
      const totalUsageMB = (botData.totalUsageValue / (1024 * 1024)).toFixed(2);
      strSizeInfo = `${totalUsageMB.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} MB`;
    }

    if (botData.serviceSize) {
      const maxSize = botData.serviceSize * 1024 * 1024;
      const currSize = Number(botData.totalUsageValue);
      const avg = (currSize / maxSize) * 100;

      if (!isMobile) {
        strSizeInfo = strSizeInfo + `<br/> (${avg.toFixed(2)}%)`;
      } else {
        strSizeInfo = strSizeInfo + ` (${avg.toFixed(2)}%)`;
      }
    }
    return strSizeInfo;
  };

  const addUserList = () => {
    if (userPlan.isFreeUser) return showTextPop("무료버전은 사용자를 추가 할 수 없습니다.");

    if (!useUser) return showTextPop("초대 할 이메일을 작성해주세요.");
  
    if (userPlan.isBasicUser && useUserList.length >= 3) {
      return showTextPop("최대 3명의 유저만 추가 가능합니다.");
    }
    
    if (userPlan.isPremiumUser && useUserList.length >= 15) {
      return showTextPop("최대 15명의 유저만 추가 가능합니다.");
    }

    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(useUser)) {
      return showTextPop("이메일 형식으로 작성해 주세요.");
    }
    const lEmail = sessionStorage.getItem("email");
    if (lEmail === useUser) {
      return showTextPop("현재 사용자의 이메일은 불가합니다.");
    }
    // 전체 리스트를 확인하여 동일한 이메일을 제어 한다.
    if (useUserList.some((data) => data.email === useUser)) {
      return showTextPop("이미 추가 된 이메일입니다.");
    }

    addUseUserFunc && addUseUserFunc(useUser);
    setUseUser("");
  };

  const botServiceModel = () => {
    return priceUtil.botServiceModel(botData.serviceModel)
  };

  const beforeDelUseUserFunc = (dataUid) => {
    if (confirm("사용자를 제외 하시겠습니까?"))
      delUseUserFunc && delUseUserFunc(dataUid);
  };

  const beforeDeleteFunc = () => {
    if (confirm("삭제 하시겠습니까?")) deleteFunc && deleteFunc();
  };

  const checkBotName = (type) => {
    if (type === 0) return !((botName?.trim()?.length ?? 0) === 0);
    else return !((botName?.trim()?.length ?? 0) > 200);
  };
  const checkGreetingMessage = (type) => {
    if (type === 0) return !((greetingMessage?.trim()?.length ?? 0) === 0);
    else return !((greetingMessage?.trim()?.length ?? 0) > 200);
  };

  const beforeModifyFunc = () => {
    if (!checkBotName(0)) {
      return showTextPop("Assistant 이름을 입력해 주세요.");
    }

    if (!checkBotName(1)) {
      return showTextPop("Assistant이름은 200자까지 입력 가능합니다.");
    }

    if (!checkGreetingMessage(0)) {
      return showTextPop("인사 메세지를 작성해주세요.");
    }

    if (!checkGreetingMessage(1)) {
      return showTextPop("인사 메세지는 최대 200자 입니다.");
    }

    // 작성이 되어있다면 IP형태인지 확인.
    if (peerIP && peerIP.length > 0) {
      const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
      // 입력값이 ,로 구분된 IP 형태인지 체크
      const isValidInput = peerIP
        .split(",")
        .every((ip) => ipRegex.test(ip.trim()));
      if (!isValidInput) {
        return showTextPop("허용IP - IP형식이 맞지 않습니다.");
      }
    }

    if (confirm("수정 하시겠습니까?")) {
      let botInfoParams = {
        botUid: botData.botUid,
        botName,
        excludeUrl: excludeUrl ? excludeUrl.trim().replace(/\n/gi, ",") : "",
        greetingMessage,
        botRole,
        botImagePath,
        peerIP,
      };

      modifyFunc && modifyFunc(botInfoParams);
    }
  };

  const handleExpireBtn = () => {
    setPopState((prev) => !prev);
    setPopStateExpire((prev) => !prev);
    setPopMessage(
      !isMobile
        ? `요금제 만료일 이후에 유료 모델 사용을 유지하시려면<br/> 아래의 연장하기 버튼을 눌러주시기 바랍니다.<br/> 연장 신청 후 담당자가 연장 관련 안내를 드릴 예정입니다.<br/> 나의 연장 문의는 [문의하기]로 자동 등록돼<br/> 좌측의 문의하기 메뉴에서도 확인하실 수 있습니다.`
        : `요금제 만료일 이후에 유료 모델 사용을 유지하시려면 아래의 연장하기 버튼을 눌러주시기 바랍니다. 연장 신청 후 담당자가 연장 관련 안내를 드릴 예정입니다. 나의 연장 문의는 [문의하기]로 자동 등록돼 좌측의 문의하기 메뉴에서도 확인하실 수 있습니다.`,
    );
  };

  useEffect(() => {
    setBotName(botData.botName);
    setExcludeUrl(botData.excludeUrl);
    setGreetingMessage(botData.greetingMessage);
    setBotRole(botData.botRole);
    setBotImagePath(botData.botImagePath);
    setPeerIP(botData.peerIP);
    if (botData.expireDate) {
      // botData.expireDate : 23-11-08 그러니 20을 일단 더해준다.
      const strTmp = "20" + botData.expireDate; // ex) 2023-11-08
      // 7일 이내의 경우 연장 버튼을 활성화 시켜준다.
      const strDate = new Date(strTmp);
      const today = new Date();
      const timeDiff = strDate - today;
      const daysRemain = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      console.log(">>>> daysRemain:", daysRemain);
      if (daysRemain <= 7) {
        setExpireDateState(true);
      } else {
        setExpireDateState(false);
      }
    } else {
      setExpireDateState(false);
    }
  }, [botData]);

  return (
    <TableCt>
      <div style={{ textAlign: "center" }}>
        {/* 데이터 컨테이너 */}
        <BotDataCt>
          <BotDataTag>
            <p className="title">사용 포인트</p>
            <p className="number">
              {remaining()} /{" "}
              {String(botData.totalCount).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </p>
          </BotDataTag>
          <BotDataTag className="Volume">
            <p className="title">지식 사용량</p>
            <div className="number usesSize">
              {/* {usesSizeInfo()} */}
              <div dangerouslySetInnerHTML={{ __html: usesSizeInfo() }}></div>
            </div>
          </BotDataTag>
          <BotDataTag>
            <p className="title">봇 생성일</p>
            <p className="number "> {botData.createAt}</p>
          </BotDataTag>
          <BotDataTag>
            <p className="title">멤버십</p>
            <p className="number text"> {botServiceModel()}</p>
          </BotDataTag>
          <BotDataTag className="expire">
            <p className="title">
              요금제 만료일
              {expireDateState && (
                <i
                  className={
                    toolTip
                      ? "ri-error-warning-line icon active"
                      : "ri-error-warning-line icon "
                  }
                  onMouseOver={() => {
                    setTooltip((prev) => !prev);
                  }}
                  onMouseOut={() => {
                    setTooltip((prev) => !prev);
                  }}
                >
                  {!isMobile && (
                    <span className="expireTooltip">
                      요금제 만료일 경과 시, <br /> 유료 모델의 기능 사용이
                      제한됩니다.
                    </span>
                  )}
                </i>
              )}
            </p>
            <div className="expireCt">
              <p className="number text">
                {botData.serviceModel === "0"
                  ? "유료버전 미사용"
                  : botData.expireDate
                    ? botData.expireDate
                    : "무기한"}
              </p>
              {expireDateState && (
                <button
                  className="expireBtn"
                  onClick={() => {
                    handleExpireBtn();
                  }}
                >
                  연장
                </button>
              )}
            </div>
          </BotDataTag>
        </BotDataCt>

        {/* 챗봇 설정 컨테이너 */}
      </div>
      <BtnCt>
        {/* 화면설계상 아직 비개발 영역 [Assistant 사용 환경] */}
        <NormalButton
          style={{ marginRight: "10px" }}
          buttonClass={isMobile ? "small cDel round" : "mid cDel round"}
          callBackFunc={beforeDeleteFunc}
          buttonTitle={"삭제"}
        />
        <NormalButton
          buttonId="loginBtn"
          buttonTitle={
            <>
              <span /> 설정 저장
            </>
          }
          buttonClass={isMobile ? "small cGreen round" : "mid cGreen round"}
          callBackFunc={beforeModifyFunc}
        />
      </BtnCt>

      <BotModifyCt>
        <BotinfoCt>
          <BotInfoTitle>
            Assistant 이름<span>*</span>
          </BotInfoTitle>
          <BotInfoRow>
            <InputForm
              target="img"
              value={botImagePath}
              setValue={setBotImagePath}
            />
            <InputForm
              inputclass={"modify"}
              target="name"
              value={botName}
              setValue={setBotName}
            />
          </BotInfoRow>
          <BotInfoDesc>
            이미지 형식: jpg, png{isMobile && <br />} 최대 1MB, 가로 최대 500px,
            세로 최대 500px
          </BotInfoDesc>
        </BotinfoCt>

        <BotinfoCt>
          <BotInfoTitle>
            Assistant 인사말<span>*</span>
          </BotInfoTitle>
          <BotInfoRow>
            <InputForm
              inputclass={"modify"}
              target="greet"
              value={greetingMessage}
              setValue={setGreetingMessage}
            />
          </BotInfoRow>
        </BotinfoCt>

        <BotinfoCt>
          <BotInfoRow className="responseStyle">
            <BotInfoTitle>
              응답스타일<span>*</span>
            </BotInfoTitle>
            <InputForm target="role" value={botRole} setValue={setBotRole} />
          </BotInfoRow>
        </BotinfoCt>

        <BotinfoCt>
          <BotInfoTitle>대화창 허용IP</BotInfoTitle>
          <BotInfoRow>
            <InputForm
              inputclass={"modify"}
              target="peerIP"
              value={peerIP}
              setValue={setPeerIP}
            />
          </BotInfoRow>
        </BotinfoCt>

        <BotinfoCt>
          <BotInfoTitle>사용자 초대</BotInfoTitle>
          <div>
            <BotInfoRow>
              <InputForm
                inputclass={"modify"}
                target="addUser"
                value={useUser}
                setValue={setUseUser}
              />
              <NormalButton
                style={{ margin: "0 10px" }}
                buttonClass={"small cGreen "}
                buttonTitle={"초대"}
                callBackFunc={addUserList}
              />
            </BotInfoRow>
            <BotInfoDesc className="user">사용자 목록</BotInfoDesc>
            <BotInfoRow>
              {useUserList.length === 0 ? (
                <BotInfoDesc>
                  초대된 사용자가 없습니다. <br /> 새로운 사용자를 초대해보세요
                </BotInfoDesc>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {useUserList.map((item, index) => (
                    <BotInfoRow className="user" key={index}>
                      <p className="userName">{item.email}</p>
                      <NormalButton
                        buttonClass="cDel small"
                        icon={iconClose}
                        buttonTitle={"삭제"}
                        callBackFunc={() => beforeDelUseUserFunc(item.dataUid)}
                      ></NormalButton>
                    </BotInfoRow>
                  ))}
                  {/* {useUserList[0].email} */}
                </div>
              )}
            </BotInfoRow>
          </div>
        </BotinfoCt>
      </BotModifyCt>
    </TableCt>
  );
}

/**
 * inputForm target별 처리 .
 */
export function InputForm({ target, value, setValue }) {
  if (target === "img") {
    const [showProfilePop, setShowProfilePop] = useState(false);
    const [previewImage, setPreviewImage] = useState("");

    useEffect(() => {
      setShowProfilePop(false);
    }, [value]);

    const IMAGE_PATH = process.env.REACT_APP_RESOURCE_HOST + "/images/bot/";
    return (
      <Profile>
        <Thumb>
          <img
            src={
              previewImage
                ? previewImage
                : value
                  ? IMAGE_PATH + value
                  : user_icon
            }
            alt=""
          />
        </Thumb>
        {showProfilePop && (
          <ProfilePop
            setShowProfilePop={setShowProfilePop}
            setPreviewImage={setPreviewImage}
            setBotImagePath={setValue}
          />
        )}
        <Modify
          imrSrc={camera_icon}
          onClick={(e) => setShowProfilePop(!showProfilePop)}
        ></Modify>
      </Profile>
    );
  } else if (target === "name") {
    return (
      <InputBox
        inputId={`manage_${target}`}
        value={value}
        setValue={setValue}
        placeholderText={"최대20자까지 입력 가능합니다."}
        style={{ width: "100%" }}
        inputclass={"botTable"}
      />
    );
  } else if (target === "greet") {
    return (
      <TextArea
        textId={`manage_${target}`}
        value={value}
        setValue={setValue}
        style={{ width: "80%", borderRadius: "10px" }}
        disabled={false}
        placeholderText={"채팅방 시작 시 환영 문구 입니다. (최대 200자)"}
        textClass={"manage"}
      />
    );
  } else if (target === "peerIP") {
    return (
      <InputBox
        inputId={`manage_${target}`}
        value={value}
        setValue={setValue}
        placeholderText={"127.0.0.1,127.0.*.* ,구분자로 다중 설정됩니다."}
        style={{ width: "100%" }}
        inputclass={"botTable"}
      />
    );
  } else if (target === "role") {
    const select = {
      label:
        value === 0
          ? "친절"
          : value === 1
            ? "귀여움"
            : value === 2
              ? "유머러스"
              : "친절",
      value: value ? value : 0,
    };

    const selectList = [
      { label: "친절", value: 0 },
      { label: "귀여움", value: 1 },
      { label: "유머러스", value: 2 },
    ];

    const handleChange = (event) => {
      setValue(event.value);
    };

    return (
      <div style={{ width: "100%", maxWidth: "488px" }}>
        <Select
          value={select}
          // defaultInputValue={select}
          defaultValue={select}
          onChange={handleChange}
          options={selectList}
        />
      </div>
    );
  } else if (target === "addUser") {
    let holdText = "이메일 주소를 입력해주세요.";
    return (
      <InputBox
        inputId={`manage_${target}`}
        value={value}
        setValue={setValue}
        placeholderText={holdText}
        style={{ width: "35%", flexGrow: "0" }}
        inputclass={"botTable addUser"}
      />
    );
  }
}

const BotModifyCt = styled.div`
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  background: #fff;
  padding: 22.5px;
  padding-top: 33px;
  text-align: center;
  margin-top: 15px;

  button {
    &.change {
      & span {
        display: inline-block;
        width: 28px;
        @media (max-width: 480px) {
          width: 0px;
        }
      }
    }
  }
`;

const BotinfoCt = styled.div`
  margin-bottom: 36px;

  .css-1nmdiq5-menu {
    z-index: 11;
  }
`;

const BotInfoTitle = styled.p`
  color: #363636;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 28.5px;
  text-align: left;

  & span {
    color: #ff6161;
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    margin-bottom: 14px;
    & span {
      font-size: 14px;
    }
  }
`;
const BotInfoDesc = styled.p`
  color: #a6a6a6;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.14px;
  text-align: left;

  &.user {
    margin: 20px 0 15px;
    font-size: 16px;
    color: #222;
  }

  @media (max-width: 480px) {
    color: #a6a6a6;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
  }
`;

const BotInfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &.user {
    margin: 5px;
    border: 1px solid #dcdcdc;
    padding: 15px;

    & button {
      background: url(${({ icon }) => icon}) no-repeat transparent;
      background-position: center;
      background-size: cover;
      border: 1px solid #dcdcdc;
      color: #333;
      border-radius: 50%;
      flex: 0 0 auto;
      width: 35px;
      height: 35px;
      padding: 0;
      font-size: 12px;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  & p.userName {
    margin: 0 10px;
    font-size: 15px;
    word-break: break-all;
    text-align: left;
    width: 200px;
    @media (max-width: 480px) {
      width: 100%;
      font-size: 13px;
    }
  }
  & p.title {
    color: #ff892a;
    font-weight: 500;
    font-size: 18px;
    margin-top: -18px;
    @media (max-width: 480px) {
      margin-top: -5px;
      font-size: 16px;
    }
  }
  &.responseStyle {
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    @media (max-width: 480px) {
      flex-direction: column;
      align-items: start;
      margin-bottom: 50px;
    }

    & p {
      margin: 0;
      margin-bottom: 28.5px;
      @media (max-width: 480px) {
        margin-bottom: 14px;
      }
    }
  }

  & input {
    border-radius: 100px;

    &::placeholder {
      color: #a6a6a6;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;
      @media (max-width: 480px) {
        font-size: 13px;
      }
    }
  }

  & input,
  textarea {
    background: #f3f3f3;

    &::placeholder {
      color: #a6a6a6;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;

      @media (max-width: 480px) {
        font-size: 13px;
      }
    }
  }
`;

const TableCt = styled.div`
  @media (max-width: 700px) {
    width: 100%;
  }
  display: block;
  width: 70%;

  & table {
    width: 80%;
    min-height: 350px;
    height: auto;
    width: 100%;
    margin-top: 64px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.22);

    & tbody.modify {
      & td {
        padding-right: 20px !important;
      }
    }

    & td {
      height: 80px;
      padding-right: 5px !important;
      word-break: keep-all;
      letter-spacing: -1px;

      &.crawlTd {
        & button.crawlUrlBtn {
          margin: 0 !important;
          width: auto !important;
          font-size: 14px;
          padding: 5px 10px !important;
        }
      }

      .chargeBtnCt {
        display: flex;
        align-items: center;
      }

      .chargeConsultBtn {
        border-radius: 15px;
        margin: 0;
        width: auto;
        font-size: 14px;
        padding: 5px 5px;
        color: #fff;
        background: #ffca36;
        margin-left: 20px;
        margin-right: 10px;
        font-weight: 500;

        &:hover {
          background: #ffe618;
          color: #6a6a6a;
        }
      }
    }

    @media (max-width: 480px) {
      min-height: auto;

      td {
        padding: 30px 15px;

        &.number {
          font-weight: 600;
        }
      }
    }

    & tbody.modify {
      tr {
      }

      .textTd {
        color: #363636;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        padding-top: 50px;
        padding-bottom: 20px;
      }

      .inputTd.avata {
        display: flex;
        align-items: center;

        .desc {
          margin-left: 55px;

          p {
            color: #a6a6a6;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.14px;
          }
        }
      }

      .inputTd.avata.mobileType {
        .desc {
          margin-top: 15px;
          margin-left: 0;
          padding-right: 15px;
          letter-spacing: -1px;
          word-break: keep-all;
        }
      }
    }

    & tr {
      height: auto;

      & td {
        padding: 0 20px;
        vertical-align: middle;

        :last-child {
          &input {
            margin-left: 20px;
          }
        }
      }
    }
  }
`;

const BotDataCt = styled.div`
  display: flex;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.18);
  margin-bottom: 20px;

  flex-wrap: wrap;

  @media (max-width: 480px) {
    border-radius: 10px;
  }
`;

const BotDataTag = styled.div`
  flex: 1 0 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: 158px;
  padding: 15px 1vw;

  &.expire {
    position: relative;
    p.title {
      position: relative;
    }
  }

  &:not(:last-child) {
    border-right: 1px solid #dcdcdc;
  }

  p {
    text-align: center;
    &.title {
      font-weight: 500;
      line-height: 15px;
      letter-spacing: -0.5px;
      font-size: 13px;
      margin: auto 0;
      word-break: keep-all;
      & .icon {
        position: absolute;
        bottom: 0;
        color: tomato;
        font-size: 16px;
        cursor: pointer;
        & .expireTooltip {
          display: none;
        }
        &.active {
          display: inline-block;
          & .expireTooltip {
            position: absolute;
            /* background: linear-gradient(135deg, #ff892a, #ffc51e); */
            /* background: tomato; */
            background: rgba(25, 195, 125, 0.9);
            border-radius: 5px;
            top: 0%;
            right: 130%;
            display: block;
            width: 220px;
            font-size: 13px;
            letter-spacing: -1px;
            line-height: 15px;
            word-break: keep-all;
            padding: 10px;
            color: #000;
            /* color: tomato; */
            font-weight: 600;
            &:after {
              content: "";
              width: 10px;
              height: 10px;
              position: absolute;
              top: 0;
              right: 0;
              /* border: 1px solid tomato; */
              /* background: tomato; */
              background: #19c37d;
              transform: rotate(45deg) translateX(50%);
              /* border-left: 1px solid #777; */
              /* border-right: 1px solid #777; */
              /* border-bottom: 1px solid #777; */
            }
          }
        }
      }
    }
  }

  div.number,
  p.number {
    color: #232323;
    font-size: 14px;
    letter-spacing: -0.5px;
    font-weight: 700;
    height: 50px;
    max-width: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 480px) {
      word-break: keep-all;
      padding: 0 15px;
    }
    &.usesSize {
      max-width: 100%;
    }
    &.text {
      color: #ff892a;
    }
    &.wide {
      color: #ff892a;
      max-width: 145px;
    }
  }

  .expireCt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .text {
      word-break: keep-all;
    }
  }

  .expireBtn {
    width: 40px;
    height: 20px;
    background: tomato;
    color: #fff;
    font-weight: 700;
    border-radius: 30px;
    font-size: 12px;
    letter-spacing: -1px;
    margin-left: 5px;
    line-height: 10px;
    /* padding: 5px 10px;
    bottom: 5%;
    left: 45%;
    transform: translateX(-45%); */
    &:hover {
      background: #19c37d;
      color: #fff;
    }

    @media (max-width: 480px) {
      position: unset;
      margin-left: 10px;
    }
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* order: 2; */
    flex: 1 0 55%;
    padding: 7.5px 10px;

    p.title {
      width: 40%;
    }
    div.number,
    p.number {
      flex: 0 0 auto;
      height: 15px;
      font-size: 13px !important;
      max-width: unset;
    }
    &:not(:last-child) {
      border-right: none;
    }
    &.Volume {
      /* order: 1; */
      div.number,
      p.number {
      }
    }
  }
`;

const BtnCt = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  /* width: 400px; */
  margin: 0 auto;

  & button {
    font-size: 14px;
    min-width: 120px;
    margin: 0 5px;
    @media (max-width: 480px) {
      flex: 0 1 auto;
      min-width: 95px;
    }
  }
  @media (max-width: 700px) {
    flex: 1 1 100%;
    margin: 15px 10px 15px !important;
    height: auto !important;
    word-break: keep-all;
  }
  @media (max-width: 480px) {
    width: auto;
  }
`;

const Profile = styled.div`
  display: inline-block;
  width: 85px;
  height: 85px;
  position: relative;
  margin-right: 15px;

  @media (max-width: 480px) {
    width: 65px;
    height: 65px;
  }
`;

const Thumb = styled.span`
  display: inline-block;
  width: 81px;
  height: 81px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
  }
`;

const Modify = styled.button`
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 2px;
  right: 2px;
  z-index: 0;
  border-radius: 50%;
  border: none;
  background: url(${({ imrSrc }) => imrSrc}) no-repeat;
  background-size: 15px 12px;
  background-position: 50%;
  background-color: #e9e9e9;
`;
