import NormalButton from "components/atomic/NormalButton2";
import Advice from "components/pages/consult/Advice";
import { AlertPop2 } from "components/templates/popup/AlertPop";
import { saveAs } from "file-saver";
import { useContext, useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";
import BotImg1 from "../../assets/images/botIllustration.svg";
import BotImg2 from "../../assets/images/botIllustration2.svg";
import BotImg3 from "../../assets/images/botIllustration3.svg";
import BotImg4 from "../../assets/images/botIllustration4.svg";
import ContentImg from "../../assets/images/content_01.svg";
import IconChat from "../../assets/images/icon_Chat.svg";
import IconPaperPen from "../../assets/images/icon_Document.svg";
import IconKnowledge from "../../assets/images/icon_Knowledge.svg";
import iconCheckOrange from "../../assets/images/icon_checkOrange.svg";
import iconCheckWhite from "../../assets/images/icon_checkWhite.svg";
import iconUser01 from "../../assets/images/icon_user01.svg";
import iconUser02 from "../../assets/images/icon_user02.svg";
import iconUser03 from "../../assets/images/icon_user03.svg";
import logoDefault from "../../assets/images/meswiser_LogoDefault.svg";
import logoAssistant from "../../assets/images/LOGO_Assistant.svg";
import logoChat from "../../assets/images/LOGO_Chat.svg";
import icondotArrowRight from "../../assets/images/icon_dotArrowRight.svg";
import icondotArrowLeft from "../../assets/images/icon_dotArrowLeft.svg";
import icondotArrowDown from "../../assets/images/icon_dotArrowDown.svg";
import Content03 from "../../assets/images/content03.png";
import Content04 from "../../assets/images/content04.svg";
import Content05 from "../../assets/images/content05.svg";
import Content06 from "../../assets/images/content06.svg";
import MainContentPrice from "./MainContentPrice";
import MainContentPriceMobile from "./MainContentPriceMobile";
import { useMediaQuery } from "react-responsive";

export default function MainContent({ contentRef }) {
  const [infoState, setInfoState] = useState("01");
  const [adviceToggle, setAdviceToggle] = useState(null);
  const { isMobile } = useContext(isMobileContext);

  const [popState, setPopState] = useState(false);
  const [popMessage, setPopMessage] = useState("");

  const webWidthCheck = useMediaQuery({ query: "(min-width: 1024px)" });

  const handleAdivce = () => {
    setAdviceToggle((prev) => !prev);
  };
  const downloadIntroFile = () => {
    const fileUrl = "/introfile.pdf";
    const fileName = "MESwiser_Assistant소개서.pdf";

    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, fileName);
      })
      .catch((error) => {
        console.log("Error While donwloading the file:", error);
      });
  };

  const [contentCtState, setContentCtState] = useState("01");
  const [contentCtState02, setContentCtState02] = useState("01");

  // 콘텐츠 에니메이션 계산
  const componentRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0); // 스크롤 위치 state

  const [aniState, setAniState] = useState(false);

  // 스크롤 이벤트 함수
  const handleScrollAni = () => {
    // 현재 스크롤 위치
    const currentScrollPosition = window.scrollY;
    setScrollPosition(currentScrollPosition);

    // ref로 잡은 컴포넌트의 위치 정보
    const componentPosition = componentRef.current.getBoundingClientRect();
    // console.log("컴포넌트의 위쪽에서의 스크롤 위치:", currentScrollPosition);
    // console.log("컴포넌트의 위쪽에서의 절대적 위치:", componentPosition.top);

    componentPosition.top <= 100 && setAniState(true);
    componentPosition.top > 100 && setAniState(false);
  };

  // 처음에만 스크롤 이벤트 부여
  useEffect(() => {
    window.addEventListener("scroll", handleScrollAni);
    return () => {
      window.removeEventListener("scroll", handleScrollAni);
    };
  }, []);

  return (
    <>
      {/* 콘텐츠 1 */}
      <SectionCt className="type01" ref={contentRef}>
        <Section className="type01">
          <img src={Content03} alt="content03" />

          <FelxColumnCt className="type01">
            <Title className="type01 title">
              <span className="orange">MESwiser Assistant</span>는<br /> 업무
              생산성 향상을 위한
              <br />
              당신의 업무 지원 파트너입니다
            </Title>
            <Title className="type01">
              <span className="desc">
                chatGPT 기반의 Assistant
                <br />
                높은 업무 생산성을 지금 바로 경험해 보세요
              </span>
            </Title>
            <BtnCt className="" style={{ display: "flex" }}>
              <NormalButton
                buttonClass={"big round cOrange"}
                // 함수를 콜백으로 넘겨줘야 함
                callBackFunc={handleAdivce}
                buttonTitle={"상담 신청"}
              />
              {/* <NormalButton
                callBackFunc={(e) => {
                  alert("여긴 어떤 기능을 넣을곳이 아닌데요?");
                }}
                buttonTitle={"무료로 시작하기"}
              /> */}
            </BtnCt>
          </FelxColumnCt>
        </Section>
      </SectionCt>

      {/* 추가 콘텐츠 */}
      <SectionCt className="type02 white">
        <Section className="type02">
          <FelxColumnCt className="type02">
            <Title
              style={{ marginBottom: "30px" }}
              className={isMobile ? "Title type02 " : "Title center"}
            >
              유기적인 협업이 필요하신가요?
              <br />
              <span className="orange">
                MESwiser 서비스 하나로{isMobile && <br />} 업무 통합 환경을
                구축해 보세요
              </span>
            </Title>
            <Title className={"hasImg"} style={{ textAlign: "center" }}>
              <img className="" src={logoDefault} alt={"logo"}></img>
            </Title>
          </FelxColumnCt>

          {isMobile ? (
            <DataTextSquareCt>
              <DataTextSquare className="added">
                <img src={logoAssistant} alt={"아이콘1"} />
                <p className="title">
                  <span className="strong">내부 업무</span>에 적합한 <br /> 업무
                  생산성 향상 지원 도구
                </p>
              </DataTextSquare>
              <img className="middleImg" src={Content06} alt={"아이콘1"} />
              <DataTextSquare className="added">
                <img src={logoChat} alt={"아이콘1"} />
                <p className="title">
                  <span className="strong">대외 서비스</span>에 적합한 <br />{" "}
                  GPT 기반의 인공지능 챗봇
                </p>
              </DataTextSquare>
            </DataTextSquareCt>
          ) : (
            <img className="contentImg" src={Content04} alt="콘텐츠 이미지" />
          )}

          <Title className="Added type02 Desc">
            MESwiser는 Chatbot과 Assistant 간<br />
            <span className="">지식 불러오기 기능</span>을 지원합니다
          </Title>

          {isMobile ? (
            <>
              <ChartWrap>
                <ChartDivedCt>
                  <ChartDivedTitle>Assistant</ChartDivedTitle>
                  <ChartLeftPart>
                    <p className="item">업무 생산성 향상</p>
                    <p className="item">업무 협업</p>
                    <div className="orangePoint hasArrow">
                      <img src={icondotArrowRight} alt="arrowImg" />
                      <p className="item">지식 관리</p>
                      <p className="item">지식 검증</p>
                    </div>
                  </ChartLeftPart>
                </ChartDivedCt>

                <ChartDivedCt>
                  <ChartDivedTitle>Chatbot</ChartDivedTitle>
                  <ChartRightPart>
                    <div className="orangePoint">
                      <p className="item">질의 응답 서비스</p>
                      <p className="item">민원 응대 서비스</p>
                      <p className="item">상담 서비스</p>
                      <p className="item">시나리오 기반 서비스</p>
                    </div>
                  </ChartRightPart>
                </ChartDivedCt>
                <ChartDivedCt
                  icon={icondotArrowDown}
                  className="onlyImg"
                ></ChartDivedCt>
              </ChartWrap>
              <Title className="type02 Added orange">
                Assistant에서 검증된 지식으로 <br />
                Chatbot에 바로 적용 및 관리 - 불러오기
              </Title>
              <Title className="type02 Added Desc">
                *할루시네이션(환각현상)제거
              </Title>
              <ChartWrap className="noneBtmMargin">
                <ChartDivedCt>
                  <ChartLeftPart>
                    <div className="orangePoint">
                      <p className="item color">신규 지식 반영</p>
                      <p className="item color">분석 지식 반영</p>
                    </div>
                  </ChartLeftPart>
                </ChartDivedCt>
                <ChartDivedCt>
                  <ChartRightPart>
                    <div className="orangePoint hasArrow">
                      <img src={icondotArrowLeft} alt="arrowRight" />
                      <p className="item color">질의 및 응답 로그</p>
                      <p className="item color">사용자 분석</p>
                    </div>
                  </ChartRightPart>
                </ChartDivedCt>
                <ChartDivedCt icon={icondotArrowDown} className="onlyImg" />
              </ChartWrap>
              <Title className="type02 Added orange">
                Chatbot 운영으로 생산된 지식을
                <br />
                Assistant 신규 지식, 분석 지식으로 활용
              </Title>
            </>
          ) : (
            <img className="contentImg" src={Content05} alt="콘텐츠 이미지" />
          )}

          <NormalButton
            buttonTitle={
              <>
                MESwiser Chatbot 바로가기{" "}
                <i className="ri-external-link-line"></i>
              </>
            }
            buttonClass={"mid round cOrange"}
            callBackFunc={() => {
              window.open("https://chatbot.meswiser.ai", "_blank");
            }}
          ></NormalButton>
        </Section>
      </SectionCt>

      {/* 컨텐츠 2번 */}
      <SectionCt className="type02">
        <Section className="type02">
          <FelxColumnCt className="type02">
            <Title className={isMobile ? "Title type02" : "Title center"}>
              <span className="orange">왜 MESwiser Assistant일까요?</span>
              <br />
              직접 경험해 보면 압니다!
            </Title>
          </FelxColumnCt>

          <DataTextSquareCt>
            <DataTextSquare
              className={contentCtState === "01" ? "active" : ""}
              onClick={() => {
                setContentCtState("01");
              }}
            >
              <img src={IconPaperPen} alt={"아이콘1"} />
              <p className="title">조직의 업무 생산성 향상</p>
              <p className="desc">
                PC, 모바일, 태블릿에서 손쉽게 <br /> 사용 가능해 업무 생산성이
                향상됩니다. 사무실이든 이동 중이든 언제 어디서나 편리하게 이용해
                보세요
              </p>
            </DataTextSquare>
            <DataTextSquare
              className={contentCtState === "02" ? "active" : ""}
              onClick={() => {
                setContentCtState("02");
              }}
            >
              <img src={IconChat} alt={"아이콘2"} />
              <p className="title">초개인화 맞춤 결과 제공</p>
              <p className="desc">
                키워드에 따른 검색 결과부터 <br /> GPT 기반의 결과까지 폭넓게!
                <br />
                목적에 따라 조건을 달리해
                <br /> 원하는 결과값을 확인해보세요.
              </p>
            </DataTextSquare>
            <DataTextSquare
              className={contentCtState === "03" ? "active" : ""}
              onClick={() => {
                setContentCtState("03");
              }}
            >
              <img src={IconKnowledge} alt={"아이콘3"} />
              <p className="title">다양한 포맷의 지식 문서 지원</p>
              <p className="desc">
                업무 환경에 최적화된 지식관리 기능
                <br /> 문서, URL을 추가해 지식 연동
                <br />
                다른 Assistant에 있는 지식까지 연동
              </p>
            </DataTextSquare>
          </DataTextSquareCt>
          {/* <img src={contentWeb} alt="MESwiser"></img> */}
        </Section>
      </SectionCt>

      {/* 컨텐츠 3번 */}
      <SectionCt className={"type03 white"}>
        <Section className="type03">
          <FelxColumnCt className="type03">
            <Title className="title center type03">
              <span className="orange">MESwiser Assistant는 다릅니다!</span>
              <br />
              맞춤형 인지검색으로 업무 환경을 더욱 편리하게!
            </Title>

            <img className="content onlyWeb" src={ContentImg} alt="content" />

            <DataTextSquareCt className={"onlyMobile"}>
              <DataTextSquare
                className={
                  contentCtState02 === "01" ? "active type03" : " type03"
                }
                onClick={() => {
                  setContentCtState02("01");
                }}
              >
                {/* <img src={IconPaperPen} alt={"아이콘1"} /> */}
                <p className="title">시간 효율성</p>
                <p className="desc ">
                  명료한 검색어만으로도 빠르게 원하는 문서를 탐색합니다 새로운
                  문서도 즉시 업데이트해 chatGPT로 학습시킬 수 있습니다
                </p>
              </DataTextSquare>
              <DataTextSquare
                className={
                  contentCtState02 === "02" ? "active type03" : "type03"
                }
                onClick={() => {
                  setContentCtState02("02");
                }}
              >
                <p className="title">시맨틱 검색 결과 제공</p>
                <p className="desc ">
                  사용자의 검색의도를 파악하고 문맥을 분석해 검색합니다 인공지능
                  기능이 동의어, 유의어, 연관어를 한 번에 파악해 최적의 결과를
                  제공합니다
                </p>
              </DataTextSquare>
              <DataTextSquare
                className={
                  contentCtState02 === "03" ? "active type03" : " type03"
                }
                onClick={() => {
                  setContentCtState02("03");
                }}
              >
                <p className="title">멀티 디바이스</p>
                <p className="desc ">
                  하나의 계정으로 언제 어디서든 다양한 디바이스에서 동기화
                  작업을 할 수 있습니다
                </p>
              </DataTextSquare>
              <DataTextSquare
                className={
                  contentCtState02 === "04" ? "active type03" : "type03"
                }
                onClick={() => {
                  setContentCtState02("04");
                }}
              >
                <p className="title">높은 보안 수준</p>
                <p className="desc ">
                  데이터 수집 및 학습하는 과정에서 민감한 정보의 유출을 막습니다
                  조직 내부 전용 문서 공유의 협업툴로 안전하게 활용할 수
                  있습니다
                </p>
              </DataTextSquare>
            </DataTextSquareCt>

            <Type2DataCt className="web"></Type2DataCt>
          </FelxColumnCt>
        </Section>
      </SectionCt>

      {/* 컨텐츠 4번 */}
      <SectionCt className="type04">
        <Section ref={componentRef} className="type04">
          <Title className="center type04">
            불필요한 야근과 비효율적인 시간관리가 고민이신가요?
            <br />
            <span className="orange">MESwiser Assistant를 도입해 보세요!</span>
          </Title>
          <DataTextRowCt className="type04">
            <MemberReviewCt
              onClick={(e) => {
                setInfoState("01");
              }}
              className={infoState === "01" ? "infoCt active" : "infoCt"}
            >
              <p className="title">
                생성형 AI는 무조건 비싼줄 알았는데 합리적인 비용으로 사용할 수
                있어 비즈니스 하기에 더욱 좋아졌어요
              </p>
              <img src={iconUser01} alt={"userIcon01"} />
              <p className="desc">서비스 회사 대표 / 김**</p>
            </MemberReviewCt>
            <MemberReviewCt
              onClick={(e) => {
                setInfoState("02");
              }}
              className={infoState === "02" ? "infoCt active" : "infoCt"}
            >
              <p className="title">
                하나의 단어만 검색해도 관련 문서를 한 눈에 볼 수 있어 문서
                찾느라 허비했던 시간이 엄청나게 줄어들었어요
              </p>
              <img src={iconUser02} alt={"userIcon01"} />
              <p className="desc">마케터 / 이**</p>
            </MemberReviewCt>
            <MemberReviewCt
              onClick={(e) => {
                setInfoState("03");
              }}
              className={infoState === "03" ? "infoCt active" : "infoCt"}
            >
              <p className="title">
                사용자를 초대할 수 있어 팀 작업이나 타 부서와의 협업 시에
                유용하게 활용할 수 있게 됐어요
              </p>
              <img src={iconUser03} alt={"userIcon01"} />
              <p className="desc">프로젝트PM / 오**</p>
            </MemberReviewCt>
          </DataTextRowCt>

          <DataTextRowCt className="type05">
            <FelxColumnCt
              className={aniState ? "scActive Ani01 type04" : "type04"}
            >
              <img src={BotImg1} alt="content" />
              <p className="title">80%</p>
              <p className="desc">고객사용 만족도</p>
            </FelxColumnCt>

            <FelxColumnCt
              className={aniState ? "scActive Ani02 type04" : "type04"}
            >
              <img src={BotImg2} alt="content" />
              <p className="title">60%</p>
              <p className="desc">업무생산성</p>
            </FelxColumnCt>

            <FelxColumnCt
              className={aniState ? "scActive Ani03 type04" : "type04"}
            >
              <img src={BotImg3} alt="content" />
              <p className="title">50%</p>
              <p className="desc">업무시간단축</p>
            </FelxColumnCt>

            <FelxColumnCt
              className={aniState ? "scActive Ani04 type04" : "type04"}
            >
              <img src={BotImg4} alt="content" />
              <p className="title">90%</p>
              <p className="desc">문서분석 정확도</p>
            </FelxColumnCt>
          </DataTextRowCt>
        </Section>
      </SectionCt>

      {/* 가격 정책 콘텐츠 */}
      {webWidthCheck ? <MainContentPrice /> : <MainContentPriceMobile />}

      {/* 컨텐츠 6번 */}
      {/* <SectionCt className="type06 white">
        <Section className="type06">
          <p className="title">
            지금 필요한 플랜을 비교해 보고
            <br />
            <span className="strong">MESwiser Assistant를 경험해 보세요</span>
          </p>

          <div className="tabBtnCt">
            <button
              className={tabState === "01" ? "active" : ""}
              onClick={(e) => {
                setTabState("01");
              }}
            >
              무료 버전
            </button>
            <button
              className={tabState === "02" ? "active" : ""}
              onClick={(e) => {
                setTabState("02");
              }}
            >
              유료 버전
            </button>
          </div>
          <div className="tabContent">
            <div
              className={
                tabState === "01" ? "free contentCt active" : "free contentCt"
              }
            >
              <p className="header ">무료 버전</p>
              <p className="unit">[계정 단위]</p>
              <p className="desc">최대 2개의 어시스턴트 생성 가능</p>
              <p className="unit">[어시스턴트 단위]</p>
              <p className="desc">지식관리 기능을 통한 다수의 학습 지식 관리</p>
              <p className="desc">
                학습지식 용량 제한 <span>(1M)</span>
              </p>
              <p className="desc last">
                하루 질의 사용 횟수 제한 <span>(50개 이내)</span>
              </p>
              <p className="descSpecial">
                ※ 상담하기를 통해 유료버전 체험을 문의해 주세요.
              </p>
            </div>
            <div
              className={
                tabState === "02"
                  ? "charge contentCt active"
                  : "charge contentCt"
              }
            >
              <p className="header ">유료 버전</p>
              <p className="unit">[계정 단위]</p>
              <p className="desc">유료 버전 이외의 무료 버전 2개 생성 가능</p>
              <p className="unit">[어시스턴트 단위]</p>
              <p className="desc">지식관리 기능을 통한 다수의 학습 지식 관리</p>
              <p className="desc ">
                학습지식 용량 확대 <span> (30M 이상 - 비용에 따라 변동) </span>
              </p>
              <p className={isMobile ? "desc hasSpan" : "desc"}>
                하루 질의 사용 횟수 확대{" "}
                <span>(1만개 이상 - 비용에 따라 변동)</span>
              </p>
              <p className="desc hasSpan">
                초대하기 기능을 통한 협업 지원
                <span>(회원가입 사용자 5명 기준 - 비용에 따라 변동)</span>
              </p>
              <p className="desc">학습지식 관리 및 정제 지원</p>
              <p className="desc">운영 지원</p>
              <p className="desc">로그 관리 및 분석 지원</p>
            </div>
          </div>
        </Section>
      </SectionCt> */}

      {/* 컨텐츠 7번 */}
      <SectionCt className="type07">
        <Section className="type07">
          <div className="infoCt first">
            <p className="text">
              우리 회사에 딱 맞는
              <br />
              <strong>업무 생산성 도구가 필요하다면?</strong>
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                setAdviceToggle((prev) => !prev);
              }}
            >
              문의하기
            </button>
          </div>

          <div className="infoCt last">
            <p className="text">
              MESwiser Assistant
              <br />
              서비스에 대해 <br />
              <strong>더 궁금하다면?</strong>
            </p>
            <button
              onClick={(e) => {
                e.preventDefault();
                downloadIntroFile();
                // alert("소개서를 열심히 준비중입니다.");
              }}
            >
              소개서 받기
            </button>
          </div>
        </Section>
      </SectionCt>
      {adviceToggle && (
        <Advice
          setAdviceToggle={setAdviceToggle}
          setPopState={setPopState}
          setPopMessage={setPopMessage}
        />
      )}
      {popState && (
        <AlertPop2
          text={popMessage}
          popState={popState}
          setPopState={setPopState}
        />
      )}
      {/* <Route path={`${match.path}Advice`} component={Advice} /> */}
    </>
  );
}

const SectionCt = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1000px) {
    padding: 0 30px;
  }
  &.type01 {
    height: 680px;
    background-color: #f7f9ff;
    &.white {
      background: #fff;
    }

    @meida (max-width:480px) {
      /* height: auto; */
      padding-top: 0px;
    }
  }

  &.type02 {
    background: #f7f9ff;
    &.white {
      background: #fff;
    }

    & img.contentImg {
      width: 80%;
      margin-bottom: 50px;
    }
  }
  &.type03 {
    background: #f7f9ff;

    &.white {
      background: #fff;
    }
    @media (max-width: 700px) {
      &.white {
        background: #fff;
      }
    }
  }
  &.type04 {
    background: #f7f9ff;
    &.white {
      background: #fff;
    }
  }
  &.type05 {
    background: #f7f9ff;
    &.white {
      background: #fff;
    }
  }
  &.type06 {
    padding-top: 90px;
    background: #f7f9ff;
    &.white {
      background: #fff;
    }
  }
  &.type07 {
    background: linear-gradient(0deg, #ff892a 0%, #ff892a 100%), #fff;

    @media (max-width: 480px) {
      padding: 0 20px;
    }
  }
`;

const Section = styled.section`
  max-width: 1200px;
  min-height: 742px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d0d0d;

  @media (max-width: 1000px) {
    flex: 0 1 auto;
    margin-top: 100px;
  }

  &.type01 {
    flex-wrap: wrap;
    min-height: auto;

    & img {
      flex: 1 0 auto;
      width: auto;
      max-width: 450px;
      max-height: 350px;
      margin-right: 30px;
      @media (max-width: 900px) {
        width: 300px;
        flex: 0 0 auto;
      }
    }
    .Title {
      font-size: 40px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-bottom: 36px;
    }
    .Title2 {
      font-size: 25px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-bottom: 16px;
      word-break: keep-all;
    }
    .Desc {
      color: #333;
      font-size: 20px;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: -0.5px;
    }

    @media (max-width: 480px) {
      min-height: 580px;
      & img {
        width: 300px;
      }
      .Title {
        color: #1a1a1a;
        font-size: 26px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
        text-align: center;
      }
      .Title2 {
        color: #1a1a1a;
        font-size: 18px;
        line-height: normal;
        letter-spacing: -0.28px;
        margin-bottom: 20px;
        text-align: center;
      }
      .Desc {
        color: #1a1a1a;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.16px;
        text-align: center;
      }
    }
  }

  &.type02 {
    flex-direction: column;
    margin: 80px 0 120px;
    min-height: 600px;
    & p.titleTop {
      color: #333;
      text-align: center;
      font-size: 50px;
      font-weight: 700;
      line-height: 65px;
      margin-bottom: 74px;

      @media (max-width: 480px) {
        font-size: 36px;
        margin-bottom: 20px;
      }
    }
    & p.descTop {
      color: #333;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      line-height: 23.5px;
      letter-spacing: -0.18px;
      margin-bottom: 20px;
    }

    @media (max-width: 480px) {
      margin: 80px 0;
    }
  }

  @media (max-width: 480px) {
    img.alvisImg {
      margin: 0 0 30px 0;
    }
    .title {
      color: #1a1a1a;
      font-size: 26px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -1px;
      margin-bottom: 50px;
    }
  }

  &.type03 {
    flex-direction: column;
    margin: 80px 0 120px;
    min-height: 600px;
    & p.titleTop {
      color: #333;
      text-align: center;
      font-size: 50px;
      font-weight: 700;
      line-height: 65px;
      margin-bottom: 74px;

      @media (max-width: 480px) {
        font-size: 36px;
        margin-bottom: 20px;
      }
    }
    & p.descTop {
      color: #333;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      line-height: 23.5px;
      letter-spacing: -0.18px;
      margin-bottom: 20px;
    }
  }

  &.type04 {
    flex-wrap: wrap;
    padding: 100px 0;
    @media (max-width: 1000px) {
      height: auto;
    }
    @media (max-width: 480px) {
      padding: 10px 0 100px;
      flex-wrap: unset;
      flex-direction: column;
    }
  }
  &.type05 {
    height: 600px;
    min-height: unset;
    padding-top: 60px;
    flex-direction: column;
    flex-wrap: wrap;

    @media (max-width: 480px) {
      padding-top: 10px;
      margin: 80px 0;
    }

    & p.line {
      width: 533px;
      height: 1px;
      background: #000;
      margin-bottom: 38px;
    }
    & p.title {
      color: #1a1a1a;
      text-align: center;
      font-size: 40px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-bottom: 18px;
    }
    & p.desc {
      color: #969595;
      text-align: center;
      font-size: 30px;
      font-weight: 350;
      line-height: normal;
      letter-spacing: 0.75px;
      margin-bottom: 120px;
    }

    @media (max-width: 1000px) {
      height: auto;
      & p.line {
        width: 80%;
      }

      & p.title,
      & p.desc {
        word-break: keep-all;
      }
      & p.title {
        font-size: 36px;
        width: 70%;
      }

      & p.desc {
        font-size: 24px;
        width: 55%;
      }
    }
    @media (max-width: 480px) {
      & p.title {
        width: 100%;
        color: #232323;
        font-size: 28px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.28px;
      }
      & p.desc {
        width: 100%;
        font-size: 20px;
        font-weight: 350;
        line-height: normal;
        letter-spacing: 0.5px;
        margin-bottom: 40px;
      }
    }
  }
  &.type06 {
    flex-direction: column;
    margin-bottom: 125px;
    flex-wrap: wrap;

    @media (max-width: 1000px) {
      margin-top: 0;
      min-height: unset;
    }

    .title {
      word-break: keep-all;
      color: #1a1a1a;
      text-align: center;
      font-size: 40px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.4px;
      margin-bottom: 41px;
      .strong {
        font-weight: bold;
      }
      @media (max-width: 1000px) {
        width: 85vw;
        font-size: 34px;
      }
      @media (max-width: 480px) {
        font-size: 26px;
        letter-spacing: -2px;
        .strong {
        }
      }
    }
    .tabBtnCt {
      margin-bottom: 61px;
      /* width: 539px; */
      border-radius: 87px;
      background: #e7ebf9;
      display: flex;
      justify-content: space-between;
      @media (max-width: 1000px) {
        font-size: 32px;
        /* width: 40%; */
      }
      @media (max-width: 480px) {
        width: 335px;
        height: 79px;
        margin-bottom: 40px;
      }
      button {
        transition: 0.2s;
        font-size: 30px;

        line-height: normal;
        letter-spacing: -0.34px;
        margin: 9px 13px 10px;

        color: rgba(0, 0, 0, 0.4);
        padding: 8px 57px;
        border-radius: 54px;
        @media (max-width: 1000px) {
          padding: 8px 20px;
        }
        @media (max-width: 480px) {
          font-size: 26px;
          flex: 0 0 auto;
          margin: 9px;
          width: 149px;
          height: 61px;
        }
      }
      button.active {
        padding: 8px 57px;
        color: #1a1a1a;
        border-radius: 54px;
        background: #fff;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
        font-weight: 700;
        @media (max-width: 1000px) {
          padding: 8px 20px;
        }
        @media (max-width: 480px) {
        }
      }
    }
    .tabContent {
      display: flex;
      .contentCt {
        transition: 0.2s;
        width: 463px;
        /* height: 343px; */
        margin: 0 15.5px;

        position: relative;

        border-radius: 20px;
        background: #e7ebf9;

        .header {
          border-bottom: 1px solid #fff;
        }

        &.active {
          border-radius: 20px;
          border: 1px solid #6d96ff;
          background: #fff;
          box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.26);
          .header {
            border-bottom: 1px solid #6d96ff;
            position: relative;
            &::after {
              position: absolute;
              content: "";
              width: 24px;
              height: 24px;
              top: 50%;
              left: 32%;
              transform: translate(0, -50%);
              border-radius: 100%;
              background: url(${iconCheckWhite}) no-repeat;
              background-position: center;
              background-size: 12px;
              background-color: #ff892a;
            }
          }
        }

        .header {
          height: 80px;
          color: #1a1a1a;
          font-size: 25px;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .unit {
          margin: 20px 0px 0px 40px;
          color: #1a1a1a;
          font-size: 18px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -1px;
          height: 20px;
          display: flex;
          align-items: center;
        }
        .desc.hasSpan {
          flex-direction: column;
          align-items: start;
          @media (max-width: 480px) {
            position: relative;
          }
        }
        .desc {
          margin: 6px 45px 6px 70px;
          color: #1a1a1a;
          font-size: 18px;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -1px;
          height: 40px;
          display: flex;
          align-items: center;

          position: relative;

          &.letterSpacing {
            letter-spacing: -0.5px;
          }

          @media (max-width: 480px) {
            margin-left: 45px;
            margin-right: 15px;
          }

          & span {
            font-size: 13px;
          }
          &::after {
            position: absolute;
            background: url(${iconCheckOrange}) no-repeat;
            background-position: center;
            background-size: contain;
            content: "";
            width: 24px;
            height: 24px;
            top: 50%;
            left: -44px;
            transform: translate(50%, -50%);
          }

          &.lineTwo {
            line-height: inherit;
          }
          &.last {
            margin-bottom: 35px;
          }
        }
        .descSpecial {
          position: absolute;
          bottom: 15px;
          font-size: 16px;
          color: #ff893a;
          width: 100%;
          text-align: center;
        }

        @media (max-width: 1000px) {
          display: none;
          &.active {
            display: block;
          }
        }
      }
      @media (max-width: 480px) {
        .contentCt {
          width: 335px;

          &.free {
            & p.desc.last {
              margin-bottom: 35px;
            }
            & .descSpecial {
              bottom: 10px;
              font-size: 14px;
            }
          }
        }
        .header {
          font-size: 24px !important;
          padding-left: 32px;
        }
        .desc {
          font-size: 15px !important;
          word-break: keep-all;
          letter-spacing: -1px !important;
        }
      }
    }
  }
  &.type07 {
    min-height: 245px;
    text-align: center;
    background: transparent;
    display: flex;

    @media (max-width: 1000px) {
      width: 100%;
      height: auto;
      min-height: 512px;
      margin-top: 0;
      flex-direction: column;
      justify-content: space-around;
    }

    @media (max-width: 480px) {
      justify-content: space-around;
    }

    @media (max-width: 1100px) and (min-width: 1000px) {
      flex-wrap: wrap;
    }

    & .infoCt {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 0 !important;

      height: 150px;
      width: 540px;

      @media (max-width: 1090px) {
        &.first {
          padding-right: 0 !important;
        }
      }
      @media (min-width: 481px) and (max-width: 1000px) {
        &.first {
          padding-right: 20px !important;
        }
      }

      @media (max-width: 1080px) and (min-width: 1000px) {
        &.first {
          border-bottom: 1px solid #fff;
          border-right: none !important;
          padding-bottom: 25px;
          margin-bottom: 30px;
          position: relative;
        }

        &.last {
          padding-left: 0px !important;
          margin-bottom: 30px;
        }
      }
      @media (max-width: 1000px) {
        width: 100%;
        height: 200px;
        flex-direction: column;
        padding: 0 20px;
        & .text {
          margin-bottom: 32px;
          margin-left: 20px;
          width: 100%;
        }
        & button {
          align-self: end;
        }
      }

      @media (max-width: 480px) {
        padding: 0;
      }

      & .text {
        color: #fff;
        font-size: 22px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -1px;
        text-align: left;
        margin-right: 10px;
        strong {
          font-weight: bold;
        }

        @media (max-width: 480px) {
          font-size: 22px;
        }
      }
      &.first {
        border-right: 1px solid #fff;
        padding-right: 25px;
        margin-bottom: 30px;
        position: relative;

        @media (max-width: 1000px) {
          border-right: none;
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background: #fff;
            bottom: -30px;
            left: 50%;
            transform: translate(-50%, 0);
          }
        }
      }
      &.last {
        padding-left: 25px;
        margin-bottom: 30px;
      }
      &.first,
      &.last {
        @media (max-width: 1000px) {
          justify-content: center;
        }

        @media (max-width: 480px) {
          padding: 0;

          button {
            width: 220px;
            height: 60px;
            font-size: 22px;
          }
        }
      }
      button {
        transition: 0.2s;
        color: #1a1a1a;
        text-align: center;
        font-size: 25px;
        font-weight: 700;
        line-height: normal;
        border-radius: 52px;
        background: #fff;
        letter-spacing: -0.22px;
        width: 200px;
        height: 60px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        &:hover {
          background: #ffca36;
          color: #ffffff;
        }
      }
    }
  }
`;

const Up = keyframes`
    0% {
      transform: translateY(-10%) scale(1.2);
 
    }
    100% {
      transform: translateY(0%) scale(1);
      opacity: 1;
    }

`;

const FelxColumnCt = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  &.scActive {
    animation: ${Up} 0.7s ease-in-out;
    animation-fill-mode: forwards;
  }
  &.Ani01 {
    animation-delay: 0.3s;
  }
  &.Ani02 {
    animation-delay: 0.6s;
  }
  &.Ani03 {
    animation-delay: 0.9s;
  }
  &.Ani04 {
    animation-delay: 1.2s;
  }

  &.type01 {
    flex: 0 0 auto;
    width: 500px;
    height: 330px;

    @media (max-width: 900px) {
      margin-top: 20px;
      height: auto;
    }
    @media (max-width: 480px) {
      max-width: 480px;
      flex: 1 0 auto;
      width: 65%;
      text-align: center;
    }

    .orange {
      color: #ff892a;
    }
    .Desc {
      word-break: keep-all;
    }
    & div {
      margin-top: auto;
    }
  }

  &.type02 {
    img.content {
      margin: 0 auto;
      width: 80%;
    }
  }

  &.type03 {
    .content {
      margin: 0 auto;
      width: 80%;
      @media (max-width: 700px) {
        display: none;
      }
    }
  }

  &.type04 {
    opacity: 0.5;
    width: 120px;

    padding: 17.5px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    margin-top: 30px;

    @media (max-width: 1000px) {
      margin-bottom: 30px;
    }

    @media (max-width: 860px) {
      flex: 1 1 35%;
    }

    @media (max-width: 480px) {
      padding: 10px 0;
      margin: 0 12.5px 15px;

      & .flexbox {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        & img {
          width: 45%;
        }

        & .title {
          margin-left: 40px;
        }
      }
    }

    &.down {
      transform: translateY(30px);
      @media (max-width: 1000px) {
        transform: translateY(0);
      }
    }

    & .title {
      color: #333;
      font-size: 28px;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 15px;

      @media (max-width: 480px) {
        font-size: 18px;
        text-align: left;
      }
    }

    & .desc {
      color: #333;
      font-size: 18px;
      font-weight: 400;
      line-height: normal;

      @media (max-width: 480px) {
        font-size: 16px;
      }
    }

    & img {
      width: 80px;
      height: 80px;
      margin-bottom: 15px;
    }
    @media (max-width: 480px) {
      & img {
        margin-bottom: 10px;
      }
      & .title {
        margin-bottom: 10px;
      }
      & .desc {
        font-weight: 500;
      }
    }
  }
`;

const DataTextRowCt = styled.div`
  /* margin-bottom: 82px; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120%;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
  @media (max-width: 480px) {
    &.type02.mobile {
      margin-bottom: 0;
    }
  }

  &.alvisFlow {
    justify-content: flex-start;
  }

  &.type03 {
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  &.type04 {
    margin-bottom: 0;
    justify-content: space-around;

    @media (max-width: 1100px) and (min-width: 1000px) {
      flex-wrap: wrap;
    }
  }

  &.type05 {
    width: 80%;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    & img.part {
      margin: 15px;
    }

    @media (max-width: 480px) {
      width: unset;
      margin-bottom: 0;
      padding: 0;
      & img.part {
        margin: 10px;
        height: 25px;
      }
    }
  }

  & img.part {
    margin-right: 94px;
    height: 35px;
  }

  &.mobile {
    flex-direction: column;
    align-items: center;
  }
`;

const DataTextSquareCt = styled.div`
  display: flex;

  & img.middleImg {
    margin: 30px 0;
  }
  @media (max-width: 950px) {
    flex-wrap: wrap;
    justify-content: center;
  }
  @media (min-width: 700px) {
    &.onlyMobile {
      display: none;
    }
  }
  @media (max-width: 480px) {
    margin: 20px 0;
  }
`;

const DataTextSquare = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 60px 20px;
  width: 300px;
  height: 380px;
  border-radius: 40px;

  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.18px;

  background: #fff;
  margin: 0 10.5px;

  position: relative;
  transition: 0.2s;
  border: 1px solid transparent;

  &.added {
    justify-content: center !important;
    border: 1px solid #ff892a;
    & img {
      width: 215px;
      height: 22px;
      margin: 10px 0;
    }
    & p {
      text-align: center;
      font-size: 16px;
      font-weight: 300 !important;
      line-height: 24px;
      margin: 5px 0 10px !important;
      .strong {
        font-weight: 700;
      }
    }
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
    transform: translateY(-5%);
  }
  &.active {
    border: 1px solid #ff892a;
    box-shadow: 0px 1px 23px 0px rgba(0, 0, 0, 0.42);
    transform: translateY(-5%);
    @media (max-width: 480px) {
      transform: translateY(-2%);
    }

    p.desc {
      color: #222;
    }
  }

  & img {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }
  p.title {
    color: #333;
    font-size: 22px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 40px;
    word-break: keep-all;
    letter-spacing: -1px;
    text-align: center;
  }
  p.desc {
    color: #999;
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    word-break: keep-all;
    text-align: center;
  }

  @media (max-width: 950px) {
    margin: 10px;
    padding: 20px;
    height: auto;
    width: 30%;

    p.title {
      font-size: 22px;
      letter-spacing: -1px;
      margin-bottom: 10px;
    }
    p.desc {
      font-size: 16px;
      letter-spacing: -1px;
    }
  }

  @media (max-width: 700px) {
    margin: 10px 5px;
    height: auto;

    flex: 1 0 100%;
    align-items: center;

    &.type03 {
      box-shadow: 0px 1px 23px 0px rgba(0, 0, 0, 0.42);
      flex: 0 1 45%;
      padding: 15px 10px;
      height: 50%;

      background: rgb(255, 246, 215);
      justify-content: flex-start;

      &.active {
        background: #fff;
        p.desc {
          color: #111;
          font-weight: 400;
        }
      }
      p.title {
        height: 40px;
        margin-top: 10px;
        margin-bottom: 5px;
        text-align: center;
      }
      p.desc {
        word-break: break-word;
      }
    }
    img {
      margin-bottom: 10px;
    }

    p.title {
      font-size: 18px;
      letter-spacing: -1px;
      margin-bottom: 25px;
      line-height: 20px;
    }
    p.desc {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -1px;
      padding: 10px;

      word-break: keep-all;
    }
  }
`;

const Title = styled.p`
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
  /* text-align: center; */
  margin-bottom: 79px;
  word-break: keep-all;

  &.hasImg {
    margin: 30px 0px;
    @media (max-width: 480px) {
      margin-bottom: 0;
    }
  }
  &.center {
    text-align: center;
  }
  & .desc {
    font-size: 24px;
    font-weight: 400;
  }
  & span.orange {
    color: #ff892a;
  }

  &.type01 {
    margin-bottom: 20px;
    font-size: 35px;

    &.title {
      font-size: 33px;
    }

    & span.desc {
      font-size: 24px;
      display: block;
    }
    & span.orange {
      /* display: block; */
    }

    @media (max-width: 480px) {
      &.title {
        font-size: 26px;
        margin-bottom: 0px;
      }
      & span.desc {
        margin: 10px auto 20px;
        font-size: 19px;
        letter-spacing: -1px;
        font-weight: 400;
      }
    }
  }

  &.type02 {
    letter-spacing: -0.4px;
    font-size: 30px;
    line-height: 45px;

    &.Added {
      /* margin-top: 50px; */
      color: #333;
      text-align: center;
      font-size: 26px;
      font-weight: 300;
      .strong {
        font-weight: 700;
      }

      &.orange {
        color: #ff892a;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
      }

      &.Desc {
        color: #333;
        text-align: center;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        & span {
          font-weight: 700;
        }
        @media (max-width: 480px) {
          font-size: 18px !important;
          line-height: 24px;
        }
      }
    }

    @media (max-width: 480px) {
      font-size: 24px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -1px;
      margin-bottom: 20px;
      text-align: center;

      .strong {
        font-size: 40px;
      }
      .desc {
        display: inline-block;
        margin-top: 8px;
        font-size: 26px;
        font-weight: 400;
        letter-spacing: -1.5px;
      }
    }
  }
  &.type03 {
    @media (max-width: 480px) {
      color: #333;
      font-weight: 600;
      letter-spacing: 0.5px;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 15px;
      & span.orange {
        font-size: 22px;
        letter-spacing: -0.5px;
        line-height: 35px;
      }
    }
  }
  &.type04 {
    @media (max-width: 480px) {
      font-size: 24px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -1px;
      margin-bottom: 30px;
      & span.orange {
        font-size: 21px;
      }
    }
  }
`;

const Type2DataCt = styled.div`
  &.web {
    @media (max-width: 1000px) {
      display: none;
    }
    @media (min-width: 1000px) {
      display: block;
    }
  }

  &.mobile {
    @media (max-width: 1000px) {
      display: block;
    }
    @media (min-width: 1000px) {
      display: none;
    }
  }
`;

const MobileContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 70vw;
  padding: 20px;

  &.under {
    width: auto !important;
  }

  @media (max-width: 480px) {
    &.under {
      flex-wrap: nowrap;
      width: 100%;
      padding: 0 !important;
      & p:not(:last-child) {
        margin-right: 25px;
      }
      & p:last-child {
        margin-right: 0 !important;
      }
    }
  }
`;

const MemberReviewCt = styled.div`
  width: 340px;
  height: 320px;

  display: flex;
  padding: 35px 25px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 47px;

  margin: 0 15px 15px;
  background: #fff6cf;

  &.infoCt {
    width: 307px;
    height: 420px;
    flex-shrink: 1;
    border-radius: 77px;
    background: #fff;
    transition: 0.2s;

    cursor: pointer;
    &:hover {
      opacity: 0.9;
      transform: scale(1.03);
    }
    &.active {
      border: 1px solid #ff892a;
      box-shadow: 0px 1px 23px 0px rgba(0, 0, 0, 0.42);
    }
    & img {
      width: 180px;
      height: 170px;
      margin-bottom: 19px;
    }

    .title {
      color: #333;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 19px;
      word-break: keep-all;
    }
    .desc {
      color: #333;
      text-align: center;
      font-size: 16px;
      font-weight: 300;
      line-height: 27.363px;
    }

    @media (max-width: 1024px) {
      flex: 0 1 30%;
      margin: 5px;
    }

    @media (max-width: 800px) {
      flex: 0 1 65%;
      margin: 15px;
    }
    @media (max-width: 480px) {
      /* width: 280px; */
      /* height: 244px; */
      /* height: auto; */
      height: 300px;
      border-radius: 20px;
      flex: 0 1 65%;
      padding: 20px 15px;

      & img {
        width: 100px;
        height: 90px;
        margin-bottom: 19px;
      }

      .title {
        color: #1a1a1a;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.26px;
      }
      .desc {
        font-size: 14px;
      }
    }
  }
`;

const BtnCt = styled.div`
  display: flex;

  @media (max-width: 480px) {
    justify-content: center;
    & button {
      min-width: 280px;
      height: 56px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      border-radius: 50px;
    }
  }
`;

const ChartWrap = styled.div`
  display: flex;

  align-items: streth;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0 0px;

  flex-wrap: wrap;

  &.noneBtmMargin {
    margin: 0;
  }
`;

const ChartDivedCt = styled(ChartWrap)`
  flex: 0 0 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.onlyImg {
    flex: 1 0 100%;
    height: 30px;

    margin-top: 0;
    background: url(${({ icon }) => icon}) no-repeat;
    background-position: center;
    background-size: contain;
  }
`;

const ChartDivedTitle = styled.div`
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  margin-bottom: auto;
`;

const ChartLeftPart = styled.div`
  position: relative;
  margin-top: 15px;
  & p.item {
    padding: 10px;
    text-align: center;
    color: #333;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    margin: 12px 0;
    letter-spacing: -1px;
    border-radius: 100px;
    background: #e7e8ea;

    &.color {
      font-weight: 600;
      background: #ffedca;
    }
  }

  & div.orangePoint {
    border: 1.5px dashed #ff892a;
    position: relative;
    &.hasArrow {
      img {
        position: absolute;
        content: "";
        width: 25px;
        height: 15px;
        background: url(${({ icon }) => icon}) no-repeat;
        background-position: center;
        background-size: cover;
        top: 50%;
        right: -32px;
        transform: translateY(-50%);
      }
    }
  }
`;
const ChartRightPart = styled(ChartLeftPart)`
  & div.orangePoint {
    border: 1.5px dashed #ff892a;
    position: relative;
    &.hasArrow {
      img {
        position: absolute;
        content: "";
        width: 25px;
        height: 15px;
        background: url(${({ icon }) => icon}) no-repeat;
        background-position: center;
        background-size: cover;
        top: 50%;
        left: -32px;
        transform: translateY(-50%);
      }
    }
  }
`;
