import UserChatBotContextProvider from "./providers/UserChatBotContextProvider.js";
import ChatContextProvider from "./providers/ChatContextProvider";
import SignContextProvider from "./providers/SignContextProvider";
import SubscribeContextProvider from "./providers/SubscribeContextProvider";
import BrowserEnvContextProvider from "./providers/BrowserEnvContextProvider.js";
import ToastMessageProvider from "./providers/ToastMessageProvider.js";

export default function AppContextProvider({
  isMobile,
  signApp,
  subscribeMessage,
  userChatBotList,
  chatBotData,
  children: AppComponent,
}) {
  return (
    <SubscribeContextProvider subscribeMessage={subscribeMessage}>
      <BrowserEnvContextProvider isMobile={isMobile}>
        <ToastMessageProvider>
          <SignContextProvider signApp={signApp}>
            <UserChatBotContextProvider userChatBotList={userChatBotList}>
              <ChatContextProvider chatBotData={chatBotData}>
                {AppComponent}
              </ChatContextProvider>
            </UserChatBotContextProvider>
          </SignContextProvider>
        </ToastMessageProvider>
      </BrowserEnvContextProvider>
    </SubscribeContextProvider>
  );
}
