import styled from "styled-components";
import iconTitle from "../../../../assets/images/icon_mic.svg";

export default function GuideBox({
  setChatPopState,
  setChatPopMessage,
  scSignal,
}) {
  return (
    <ChatInfo>
      <ChatInfoInner icon={iconTitle}>
        <div className="sourceTop">
          <span className="icon"></span>
          <p className="title">답변을 터치하면 관련정보를 확인 가능합니다.</p>
        </div>
      </ChatInfoInner>
    </ChatInfo>
  );
}

const ChatInfo = styled.div`
  width: 100%;
`;

const ChatInfoInner = styled.div`
  width: fit-content;

  display: flex;
  flex-direction: column;

  position: relative;
  color: 666;

  & div.sourceTop {
    display: flex;
    margin: 0px 0px 5px;
    padding: 5px 0px;

    span.icon {
      display: inline-block;
      margin-right: 5px;
      width: 16px;
      height: 16px;
      /* background: url(${({ icon }) => icon}) no-repeat; */
      background: url(${iconTitle}) no-repeat;
      background-position: center;
      background-size: cover;
    }
    p.title {
      color: #4d4945;
      font-size: 14px;
      font-weight: 700;
    }
  }

  & button.sourceBtn {
    margin: 5px 0;
    padding: 3px 7px;

    word-break: keep-all;
    text-align: left;

    color: #4d4945;
    font-weight: 400;
    line-height: 25px;
    width: fit-content;

    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.38);
    border-radius: 10px 10px 0px 10px;

    @media (min-width: 481px) {
      &:hover {
        background: #ff8e2a;
        color: #fff;
      }
    }
  }
`;
