import { useContext, useEffect, useRef, useState, useCallback } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import uuid from "react-uuid";
import "remixicon/fonts/remixicon.css";
import "./chat2.css";
import styled from "styled-components";
import botApi from "../../../api/botApi.js";
import chatApi from "../../../api/chatApi.js";
import ownBotApi from "api/ownBotApi";
import LoadingComponent from "utils/LoadingComponent.js";
import { createMessageData } from "../../../utils/chatUtil.js";
import { getNow } from "../../../utils/dateUtil.js";
import { isMobileContext } from "../../../utils/providers/BrowserEnvContextProvider.js";
import iconMenu from "../../../assets/images/icon_moblieMenu.svg";
import FaqBox from "./FaqBox";
import { AlertPop2 } from "components/templates/popup/AlertPop";

import NormalButton from "../../atomic/NormalButton2.js";
import { Box, FlexBox, FlexColumnBox } from "../dashboard/dashboardStyle.js";
import {
  HeaderContainer,
  WholeChatWrapper,
  PopWrapper,
  Container,
  AwsOptInfoBox,
  AwsOptKeyword,
  MenuBtn,
  DropMenuBg,
  DropMenu,
  ChatMenuCt,
  ChatMenuBtnCt,
  ChatMenuBtn,
} from "./chatStyle.js";

import ChatTypeWriter from "components/organisms/ChatTypeWriter.js";
import ChatIntroCt from "components/templates/chat/ChatIntroCt.js";
import ChatExQCt from "components/templates/chat/ChatExQCt";

import ChatIcon01 from "assets/images/icon_chat01.svg";
import ChatIcon02 from "assets/images/icon_chat02.svg";
import ChatIcon03 from "assets/images/icon_chat03.svg";
import ChatIcon04 from "assets/images/icon_chat04.svg";
import ChatIcon05 from "assets/images/icon_chat05.svg";
import ChatIcon06 from "assets/images/icon_chat06.svg";

export default function BaseChat(props) {
  const {
    showQuestionBox,
    showResponsePop,
    showDesc,
    messages,
    setMessages,
    popupInfo: { isPopup, setOpenChatRoom, openChatRoom, clickFunc },
    chatBotData,
    chatRoomId,
    handleSignOutApp,
    isHistoryBack,
    isHistory,
    isAsst,
    IP,
    exList,
    BottomData,
    botClass,
    setShowOwnBot,
    setPopMessage,
    setPopState,
    keywordRangeValue,
  } = props;

  let { isHeader } = props;
  if (isHeader === undefined) {
    isHeader = true;
  }
  const history = useHistory();
  const [firstMessage, setFirstMessage] = useState(true);
  const [newMessage, setNewMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fullLoading, setFullLoading] = useState(false);
  const [responsePopInfo, setResponsePopInfo] = useState({
    isShow: false,
    data: null,
  });

  const [dropState, setDropState] = useState(false);
  const [descPopState, setDescPopState] = useState("none");
  const [textareaValue, setTextareaValue] = useState(false);

  //alertPop
  const [chatPopStae, setChatPopState] = useState(false);
  const [chatPopMessage, setChatPopMessage] = useState("");

  // 채팅 메뉴 버튼 만들기
  const [chatMenuState, setChatMenuState] = useState(false);
  const [chatMenuToggleState, setChatMenuToggleState] = useState(true);

  // textarae 텍스트 양에 따라서 높이 값이 조절될 수 있도록 수정
  const [textAreaHeight, setTextAreaHeight] = useState("33px");

  const [scSignal, setScSignal] = useState("");
  const chatBodyRef = useRef(null);
  const textAreaRef = useRef();

  const IMAGE_PATH = process.env.REACT_APP_RESOURCE_HOST + "/images/bot/";
  const OWN_IMAGE_PATH = process.env.REACT_APP_CHATBOTHOST + "/images/bot/";
  const botImageUrl =
    botClass !== "own"
      ? IMAGE_PATH + (chatBotData?.botImagePath ?? "test.png")
      : OWN_IMAGE_PATH + (chatBotData?.botImagePath ?? "test.png");

  const isFreeUser = Number(chatBotData?.serviceModel ?? "0") === 0;
  const { isMobile } = useContext(isMobileContext);

  const clickFaqData = async (info) => {
    let isErr = false;
    if (!info.knowledgeId) {
      setChatPopMessage(
        "예시질문의 메타정보가 없습니다. 관리자에게 문의가 필요합니다.",
      );
      setChatPopState(true);
    }
    setFullLoading(true);
    try {
      const { status, data } = await chatApi.getKnowledgeAnswer(
        info.knowledgeId,
      );
      if (status === 200) {
        const resData = JSON.parse(data.resData);

        const recvMessage = [
          createMessageData(
            resData.sendMessage,
            "user",
            "text",
            uuid(),
            uuid(),
            {},
          ),
          createMessageData(
            resData.recvMessage,
            "bot",
            "text",
            uuid(),
            uuid(),
            {},
          ),
        ];
        setMessages((prev) => [...prev, ...recvMessage]);
      }
    } catch (error) {
      console.log("clickFaqData error:", error);
      isErr = true;
    } finally {
      setFullLoading(false);
    }
    if (isErr) {
      setChatPopMessage(
        "예시질문 사용이 불가능합니다 관리자에게 문의해주세요.",
      );
      setChatPopState(true);
    }
  };

  // 버튼에 맞는 icon선택 처리
  const setButtonImage = (imgNo) => {
    if (imgNo === 1) return ChatIcon01;
    else if (imgNo === 2) return ChatIcon02;
    else if (imgNo === 3) return ChatIcon03;
    else if (imgNo === 4) return ChatIcon04;
    else if (imgNo === 5) return ChatIcon05;
    else if (imgNo === 6) return ChatIcon06;
    else return ChatIcon01;
  };

  // drop menu 함수
  const handleDropMenu = (e) => {
    setDropState((prev) => !prev);
    setChatMenuState(false);
    setDescPopState("none");
    setNewMessage("");
    // url1
    //window.open(chatBotData.introOut);
    if (e.target.id === "intro") {
      setDescPopState("intro");
    } else if (e.target.id === "ex") {
      setDescPopState("ex");
    } else if (e.target.id === "url1") {
      window.open(chatBotData.introOut);
    } else if (e.target.id === "url2") {
      window.open(chatBotData.exqOut);
    }
  };

  const onMessageKeyDown = (e) => {
    const { key, shiftKey, type } = e;
    if (
      (key === "Enter" && !shiftKey) ||
      (type === "click" && e.button === 0)
    ) {
      e.preventDefault();
      if (textAreaRef.current.value.trim(" ") !== "") {
        sendMessage();
      }
      // 전송 되고 나면 질문 입력칸을 초기화 해준다.
      textAreaRef.current.value = "";
    }
  };

  // 봇 관련 메세지 생성 ( 특정 키워드 들어가면 이미지가 나타나도록 적용 )
  const createBotMessage = async (messageId, message, ratio, searchType) => {
    try {
      const { recvData, recvSources, recvRsearch } = await sendQ(
        messageId,
        message,
        ratio,
        searchType,
      );
      // 일반 botMessage 전송 시 아래 코드 사용
      return createMessageData(
        recvData,
        "bot",
        "text",
        uuid(),
        messageId,
        ratio,
        {
          recvSources,
          recvRsearch,
        },
      );
    } catch (err) {
      const errorMsg = `죄송합니다. 다시 한번 질문해주세요.`;
      console.log(">>>>> " + errorMsg, err);
      return createMessageData(errorMsg, "bot", "text", uuid(), messageId);
    }
  };

  async function sendQ(messageId, message, ratio, chatType) {
    try {
      if (!chatBotData) {
        return {
          recvData:
            "챗봇의 정보가 정상 전달되지 않았습니다. 관리자에게 문의해주세요.",
          recvCount: 0,
        };
      }

      // if (1 === 1) {
      //   return {
      //     recvData: "테스트중이라 서버 안갑니다. .",
      //     recvCount: 0,
      //   };
      // }

      let indexName = chatBotData.indexName;
      let botUid = chatBotData.botUid;
      let role = chatBotData.botRole;
      const botRatio = chatBotData.botRatio ?? 0.2;
      const showSource = chatBotData.showSource ?? "N";
      const useHistory = chatBotData.useHistory ?? "N";
      const useRsearch = chatBotData.useRsearch ?? "N";
      console.log(">>>> useRsearch: ", useRsearch);
      const useAddPrompt = chatBotData.useAddPrompt ?? "N";
      const { status, data } = setShowOwnBot
        ? await ownBotApi.sendQuestion(
            indexName,
            chatRoomId,
            messageId,
            message,
            firstMessage,
            role,
            botRatio,
            useHistory,
            useRsearch,
            useAddPrompt,
          )
        : await chatApi.sendQuestion(
            indexName,
            botUid,
            chatRoomId,
            messageId,
            message,
            IP,
            firstMessage,
            role,
            ratio,
            chatType,
            useHistory,
            useRsearch,
            useAddPrompt,
          );
      if (status === 200) {
        const resultData = JSON.parse(data.resData);
        const sources = data.sourceList ? JSON.parse(data.sourceList) : "";
        const rsearchs = data.rsearchList ? JSON.parse(data.rsearchList) : "";
        return {
          recvData: resultData.recvMessage,
          recvSources: sources,
          recvRsearch: rsearchs,
        };
      } else {
        console.log(">>>>>>>", "FAIL");
        return "챗봇 응답에 문제가 있습니다. 관리자에게 문의해주세요.";
      }
    } catch (error) {
      console.log(">>>>>>> ERROR: ", error);
      if (error.response && error.response.status === 403) {
        setPopState((prev) => !prev);
        setPopMessage("세션이 만료되었습니다. 다시 로그인해주세요.");
        // alert("세션이 만료되었습니다. 다시 로그인해주세요.");
        handleSignOutApp();
        history.replace({ pathname: "/" });
      } else {
        if (error.response.data.resultMessage) {
          console.log(
            ">>>>>>> sendQ ErrorMsg: ",
            error.response.data.resultMessage,
          );
          return {
            recvData: error.response.data.resultMessage,
            recvSources: "",
          };
        } else {
          return {
            recvData: "서버연결이 원할하지 않습니다. 관리자에게 문의하세요",
            recvSources: "",
          };
        }
      }
    } finally {
      setFirstMessage(false);
    }
  }

  const sendMessage = async () => {
    if (isLoading) return;
    if (textAreaRef.current.value.trim("") === "") return;

    setTextAreaHeight("33px");

    let searchType = 0;
    let ratio = 0.2; // 디폴트
    if (keywordRangeValue) {
      searchType = 1; // 혼합형에서만 레인지 사용
      ratio = (100 - keywordRangeValue.current.value.max) / 100;
      console.log(">>>> ratio: ", ratio);
    }

    const messageId = uuid();
    const userMessage = createMessageData(
      newMessage,
      "user",
      "text",
      uuid(),
      messageId,
    );
    setMessages((prev) => [...prev, userMessage]);
    setNewMessage("");
    setIsLoading(() => true);

    try {
      const recvMessage = await createBotMessage(
        messageId,
        newMessage,
        ratio,
        searchType,
      );
      console.log(">>> recvMessage:", recvMessage);
      if (typeof recvMessage === "object" && Array.isArray(recvMessage)) {
        setMessages((prev) => [...prev, ...recvMessage]);
      } else {
        setMessages((prev) => [...prev, recvMessage]);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(() => false);
    }
    setTextareaValue(false);
  };

  const closeChatbot = () => {
    setNewMessage("");
    setOpenChatRoom(() => false);
    if (isHistoryBack) {
      history.goBack();
    }
  };
  const getSendAndRecvMessageData = (messageId) => {
    const array = messages.filter((d) => d.messageId === messageId);
    return {
      messageId,
      send: array[0].message,
      recv: array[1].message,
    };
  };

  const visableResponsePop = (messageId) => {
    if (isFreeUser) {
      setPopState((prev) => !prev);
      setPopMessage(
        "유료 버전으로 전환하시면 특정 질문에 대한 응답을 수정하실 수 있습니다.",
      );
      return;
    }
    setResponsePopInfo({
      isShow: true,
      data: getSendAndRecvMessageData(messageId),
    });
  };

  const cancelResponsePop = () => {
    setResponsePopInfo({
      isShow: false,
      data: null,
    });
  };

  const updateResponseMsgInPop = (type, value) => {
    setResponsePopInfo((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [type]: value,
      },
    }));
  };

  const submitResponsePop = async () => {
    if (responsePopInfo?.data?.send?.trim().length === 0) {
      setPopMessage("질문을 작성해 주세요.");
      setPopState((prev) => !prev);
      return;
      // return alert("제목을 입력해 주세요.");
    }

    if ((responsePopInfo?.data?.send ?? "").trim().length > 300) {
      setPopMessage("질문의 최대 글자수는 300자 입니다.");
      setPopState((prev) => !prev);
      return;
    }

    if (responsePopInfo?.data?.recv?.trim().length === 0) {
      setPopMessage("답변을 작성해 주세요.");
      setPopState((prev) => !prev);
      return;
      // return alert("내용을 입력해 주세요.");
    }

    if ((responsePopInfo?.data?.recv ?? "").trim().length > 1000) {
      setPopMessage("답변의 최대 글자수는 1000자 입니다.");
      setPopState((prev) => !prev);
      return;
    }

    try {
      const {
        tmp,
        data: { messageId, send, recv },
      } = responsePopInfo;
      if (!messageId) {
        console.log(messageId);
        return;
      }

      let indexName = chatBotData.indexName;
      let botUid = chatBotData.botUid;
      const { data, status } = await botApi.addKnowledge(
        botUid,
        indexName,
        messageId,
        send,
        recv,
      );
      setMessages((prev) =>
        prev.map((prevData) => {
          if (prevData.messageId === messageId && prevData.type === "bot") {
            return { ...prevData, isResponsed: true };
          }
          return prevData;
        }),
      );
    } catch (err) {
      console.log("err", err);
    }
    cancelResponsePop();
  };

  // ratio 계산 함수
  // 기존 data가 가지고 있는 ratio값을 키워드를 기초값으로 정하고 연산
  const handleSearchRatio = (data, wordType) => {
    console.log(">>>. data: ", data);
    let keyword = Number(data) * 10;
    let semantic = 10 - keyword;

    if (keyword === 0) {
      keyword = "0%";
      semantic = "100%";
    } else if (keyword === 10) {
      keyword = "100%";
      semantic = "0%";
    } else {
      keyword = keyword + "0%";
      semantic = semantic + "0%";
    }

    return wordType === "key" ? keyword : semantic;
  };

  // textarea 사이즈 조절 함수
  const resizeTextarea = (e) => {
    setTextAreaHeight(`${e.target.scrollHeight}px`);
  };

  // textarea 값이 들어왔을때 button 색상 변경 함수
  const handleSendBtnColor = (e) => {
    if (e.target.value.trim() === "") {
      setTextareaValue(false);
    } else {
      setTextareaValue(true);
    }
  };

  // BottomBtn 클릭 이벤트
  const handleBottomBtnClick = (item) => {
    if (item.operType === 1) {
      const handleDetail = [
        createMessageData(item.name, "user", "text", uuid(), uuid(), 0.2, {
          fixBot: true,
        }),
        createMessageData(item.detail, "bot", "text", uuid(), uuid(), 0.2, {
          fixBot: true,
        }),
      ];
      setMessages((prev) => [...prev, ...handleDetail]);
      chatBotData?.useBottomMenu !== "A" && setChatMenuState((prev) => !prev);
    } else if (item.operType === 2) {
      window.open(item.detail);
      chatBotData?.useBottomMenu !== "A" && setChatMenuState((prev) => !prev);
    }
  };

  let vh = 0;

  useEffect(() => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  useEffect(() => {
    // messages ( 채팅 기록 )이 변경될 때 마다 스크롤을 최 하단으로 이동
    chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    if (!isHistory && !messages[messages.length - 1]?.fixBot) {
      setScSignal("on");
    }
  }, [messages]);

  useEffect(() => {
    let id = 0;
    if (scSignal === "on") {
      id = setInterval(() => {
        chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
      }, 600);

      console.log(">>. interval start id:", id);
    }

    return () => {
      if (id) {
        // console.log("interval free id:", id);
        clearInterval(id);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scSignal]);

  return (
    <WholeChatWrapper
      id="chat--bot2"
      className={`chat--bot2 ${openChatRoom ? "active" : "none"}
      ${!isPopup ? `chat--cover ${botClass}` : ""}
      `}
    >
      {fullLoading && <LoadingComponent />}
      {createPortal(
        showResponsePop && (
          <PopWrapper className={""} isShow={responsePopInfo.isShow}>
            <Container>
              <Box className="box">
                <p className="title">질문</p>
                <input
                  type="text"
                  value={responsePopInfo?.data?.send ?? ""}
                  onChange={(e) =>
                    updateResponseMsgInPop("send", e.target.value)
                  }
                />
              </Box>
              <Box className="box">
                <p className="title">응답</p>
                <textarea
                  name=""
                  id=""
                  value={responsePopInfo?.data?.recv ?? ""}
                  onChange={(e) =>
                    updateResponseMsgInPop("recv", e.target.value)
                  }
                />
              </Box>
              <FlexBox className="btnCt">
                <NormalButton
                  disabled={false}
                  buttonId="cancel"
                  buttonTitle="취소"
                  buttonClass={"cDel mid"}
                  callBackFunc={cancelResponsePop}
                />
                <NormalButton
                  type={"button"}
                  id={"btn"}
                  disabled={false}
                  className={"btn"}
                  buttonTitle="추가"
                  buttonClass={"cOrange mid"}
                  callBackFunc={submitResponsePop}
                />
              </FlexBox>
            </Container>
          </PopWrapper>
        ),
        document.getElementById("modal"),
      )}
      {botClass !== "own" && isPopup && (
        <div className={"background-cover"}></div>
      )}

      <div className={`${!isPopup ? `chatBox ${botClass}` : "chatBox"} `}>
        {isHeader && (
          <HeaderContainer>
            {!isHistory && (
              <img className="botImg" src={botImageUrl} alt="챗봇이미지02" />
            )}
            <p className="chatName">
              {chatBotData && `${chatBotData?.botName ?? ""}`}
            </p>
            {isHistoryBack && (
              <button
                className="ri-close-line"
                type="button"
                onClick={() => {
                  if (clickFunc) {
                    clickFunc();
                    setShowOwnBot && setShowOwnBot((prev) => !prev);
                  } else {
                    setOpenChatRoom(() => false);
                  }
                  if (isHistoryBack) {
                    history.goBack();
                  }
                }}
              ></button>
            )}
            {/* 소개, 예시질문이 세팅되어있지 않다면 햄버거메뉴는 그리지 않는다. */}
            {(((chatBotData?.introIn?.length ?? 0) > 0 &&
              (chatBotData?.introInName?.length ?? 0) > 0) ||
              ((chatBotData?.introOut?.length ?? 0) > 0 &&
                (chatBotData?.introOutName?.length ?? 0) > 0) ||
              ((exList?.length ?? 0) > 0 &&
                (chatBotData?.exqInName?.length ?? 0) > 0) ||
              ((chatBotData?.exqOut?.length ?? 0) > 0 &&
                (chatBotData?.exqOutName?.length ?? 0) > 0)) && (
              <MenuBtn
                className={isMobile || botClass === "own" ? "small" : "wide"}
                onClick={handleDropMenu}
                icon={iconMenu}
              ></MenuBtn>
            )}
            {isMobile && botClass === "own" && (
              <button
                className="ri-close-line"
                type="button"
                style={{ color: "#000" }}
                onClick={() => {
                  if (clickFunc) {
                    clickFunc();
                    setShowOwnBot((prev) => !prev);
                  } else {
                    setOpenChatRoom(() => false);
                  }
                  if (isHistoryBack) {
                    history.goBack();
                  }
                }}
              ></button>
            )}

            {dropState && (
              <DropMenuBg
                className={!isMobile && botClass === "own" ? "small" : "wide"}
              >
                <DropMenu
                  className={
                    dropState ? `${botClass} drop active` : `${botClass} drop`
                  }
                >
                  <div className="header">
                    <p className="">{/*더보기*/}</p>
                    <button onClick={handleDropMenu}></button>
                  </div>
                  {/* 소개 */}
                  {(chatBotData?.introInName?.length ?? 0) > 0 &&
                    (chatBotData?.introIn?.length ?? 0) > 0 && (
                      <p id="intro" onClick={handleDropMenu}>
                        <i className="ri-megaphone-line"></i>

                        {chatBotData.introInName}
                      </p>
                    )}
                  {/* 예시질문  */}
                  {(chatBotData?.exqInName?.length ?? 0) > 0 &&
                    (exList?.length ?? 0) > 0 && (
                      <p id="ex" onClick={handleDropMenu}>
                        <i className="ri-chat-quote-line"></i>

                        {chatBotData.exqInName}
                      </p>
                    )}
                  {/* 외부URL 1  */}
                  {(chatBotData?.introOutName?.length ?? 0) > 0 &&
                    (chatBotData?.introOut?.length ?? 0) > 0 && (
                      <p id="url1" onClick={handleDropMenu}>
                        <i className="ri-external-link-fill"></i>
                        {chatBotData.introOutName}
                      </p>
                    )}
                  {/* 외부URL 2 */}
                  {(chatBotData?.exqOutName?.length ?? 0) > 0 &&
                    (chatBotData?.exqOut?.length ?? 0) > 0 && (
                      <p id="url2" onClick={handleDropMenu}>
                        <i className="ri-external-link-fill"></i>
                        {chatBotData.exqOutName}
                      </p>
                    )}
                </DropMenu>
              </DropMenuBg>
            )}
          </HeaderContainer>
        )}
        <div className={`chatBody ${botClass}`} ref={chatBodyRef}>
          {messages &&
            messages.map((data, index) => {
              const isBot = data.type === "bot";
              const isUser = data.type === "user";
              const isFixBot = data?.fixBot;

              return (
                <div key={data.id} className={`${data.type} chatObj`}>
                  {isBot && !isHistory && (
                    <img
                      className="botImg"
                      src={botImageUrl}
                      alt="챗봇이미지02"
                    />
                  )}
                  <FlexColumnBox
                    style={{
                      width: "100%",
                      flexDirection: isMobile
                        ? "column"
                        : isBot
                          ? "row"
                          : "none",
                      justifyContent: isUser ? "flex-end" : "flex-start",
                    }}
                  >
                    <FlexBox
                      style={{
                        flexDirection: "column",
                        alignItems: isBot ? "" : "end",
                      }}
                    >
                      <div className="chatText">
                        <div>
                          {isBot && !isFixBot && !isHistory ? (
                            <ChatTypeWriter
                              text={data.message}
                              setScSignal={setScSignal}
                              isLoading={isLoading}
                            />
                          ) : (
                            data.message
                          )}
                        </div>

                        {isBot &&
                          data.messageType === "action" &&
                          data?.action()}
                      </div>
                      <p className="time">{data.time}</p>
                      {isBot && !firstMessage && data.recvRsearch && (
                        <BotChatSourceBox>
                          <FaqBox
                            setChatPopState={setChatPopState}
                            setChatPopMessage={setChatPopMessage}
                            scSignal={scSignal}
                            rsearchs={data.recvRsearch}
                            clickFaqData={clickFaqData}
                          />
                        </BotChatSourceBox>
                      )}
                    </FlexBox>
                    <FlexBox
                      style={{
                        display: "none",
                      }}
                    ></FlexBox>
                  </FlexColumnBox>
                  {isBot && history && isAsst && (
                    <AwsOptInfoBox>
                      <AwsOptKeyword className="keyword">
                        키워드 : {handleSearchRatio(data.ratio, "key")}
                      </AwsOptKeyword>
                      <AwsOptKeyword className="semantic">
                        시맨틱 : {handleSearchRatio(data.ratio, "semantic")}
                      </AwsOptKeyword>
                    </AwsOptInfoBox>
                  )}
                </div>
              );
            })}

          {isLoading && (
            <div className="bot chatObj">
              <img className="botImg" src={botImageUrl} alt="봇이미지" />
              <div
                className="botText chatText"
                style={{ padding: "3px 20px 0 20px" }}
              >
                <LoadingSpinner className="spinner">
                  <React1 className="rect1"></React1>
                  <React1 className="rect2"></React1>
                  <React1 className="rect3"></React1>
                  <React1 className="rect4"></React1>
                  <React1 className="rect5"></React1>
                </LoadingSpinner>
                {/* <img src={LoadingSvg} alt="" /> */}
              </div>
              <p className="time">{getNow()}</p>
            </div>
          )}
          {descPopState === "intro" ? (
            <ChatIntroCt
              introInName={chatBotData?.introInName ?? ""}
              introText={chatBotData?.introIn ?? ""}
              setDescPopState={setDescPopState}
              botClass={botClass}
              botImageUrl={botImageUrl}
            />
          ) : descPopState === "ex" ? (
            <ChatExQCt
              exqInName={chatBotData?.exqInName ?? ""}
              setDescPopState={setDescPopState}
              exList={exList}
              setNewMessage={setNewMessage}
              newMessage={newMessage}
              sendMessage={sendMessage}
              botClass={botClass}
            />
          ) : (
            descPopState === "none" && <></>
          )}
          {chatMenuState && (
            <ChatMenuCt
              className={
                chatMenuState ? `active ${botClass}` : `hidden ${botClass}`
              }
            >
              <ChatMenuBtnCt className={botClass}>
                {BottomData.map((item, idx) => {
                  return (
                    <ChatMenuBtn
                      key={idx}
                      onClick={(e) => {
                        handleBottomBtnClick(item);
                      }}
                      icon={setButtonImage(item.imgNo)}
                      className={botClass}
                    >
                      <span className="icon" />
                      <br />
                      {item.name}
                    </ChatMenuBtn>
                  );
                })}
              </ChatMenuBtnCt>
            </ChatMenuCt>
          )}
        </div>
        {chatBotData?.useBottomMenu === "A" && (
          <div className="chatInput chatFooterSection chatAMenuSection">
            <div className="chatFooterMenuCt">
              <div className="chatFooterMenuCtInner">
                <ChatMenuBtnCt className={botClass}>
                  {BottomData.map((item, idx) => {
                    return (
                      <ChatMenuBtn
                        key={idx}
                        onClick={(e) => {
                          handleBottomBtnClick(item);
                        }}
                        icon={setButtonImage(item.imgNo)}
                        className={botClass}
                      >
                        <span className="icon" />
                        <br />
                        {item.name}
                      </ChatMenuBtn>
                    );
                  })}
                </ChatMenuBtnCt>
              </div>
            </div>
          </div>
        )}
        {showQuestionBox && (
          <div className="chatInput chatFooterSection">
            {/* chatInput === section */}
            <div className="chatFooterCt">
              <div className="chatFooterCtInner">
                {chatBotData?.useBottomMenu === "Y" &&
                  (BottomData?.length ?? 0) > 0 && (
                    <button
                      disabled={isLoading ? true : false}
                      onClick={() => {
                        setChatMenuState((prev) => !prev);
                      }}
                      className={
                        chatMenuState ? "chatInfoBtn active" : "chatInfoBtn "
                      }
                      icon={""}
                    ></button>
                  )}
                <div className="chatTextAreaCt">
                  <textarea
                    spellCheck="false"
                    onKeyUp={resizeTextarea}
                    style={{ height: textAreaHeight }}
                    ref={textAreaRef}
                    name=""
                    id="userChatInput"
                    placeholder="궁금한 것을 물어보면 AI가 답변해 드려요!"
                    value={newMessage}
                    onChange={(e) => {
                      setNewMessage(e.target.value);
                      setDescPopState("none");
                      handleSendBtnColor(e);
                    }}
                    onKeyDown={onMessageKeyDown}
                  ></textarea>
                  <button
                    id="chatSendBtn"
                    className={
                      textareaValue ? "chatSendBtn active" : "chatSendBtn"
                    }
                    onClick={() => sendMessage()}
                  ></button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {chatPopStae && (
        <AlertPop2
          popState={chatPopStae}
          setPopState={setChatPopState}
          text={chatPopMessage}
        />
      )}
    </WholeChatWrapper>
  );
}

const LoadingSpinner = styled.div``;

const React1 = styled.div``;
const React2 = styled.div``;
const React3 = styled.div``;
const React4 = styled.div``;
const React5 = styled.div``;

const BotChatSourceBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-top: 3px; */
`;
