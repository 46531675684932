import styled from "styled-components";

export default function TextArea({
  textId,
  disabled,
  value,
  setValue,
  placeholderText,
  textClass,
}) {
  return (
    <Area
      className={textClass}
      id={textId ? textId : "text-area"}
      disabled={disabled}
      value={value}
      placeholder={placeholderText}
      onChange={(e) => {
        setValue(e.target.value);
      }}
    />
  );
}

const Area = styled.textarea`
  width: 100%;
  height: 90px;
  resize: none;
  border: 1px solid #ddd;

  @media (min-width: 481px) {
    height: 200px;
  }

  &.manage {
    @media (min-width: 481px) {
      height: 100px;
    }
  }

  &.qna {
    @media (max-width: 480px) {
      height: 180px;
    }
  }

  ${(props) =>
    props.id.includes("manage") &&
    `
      border: 1px solid #dcdcdc;
      font-size: 14px;
      padding-left: 10px;
    `}
  ::placeholder {
    font-size: 15px;
  }
  &.createBot {
    padding: 10px;
  }

  padding: 5px 15px;

  @media (max-width: 480px) {
    padding: 5px 10px;
  }
`;
