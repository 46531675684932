import { createContext } from "react";

export const SubscribeContext = createContext({
  subscribeMessage: {},
  setSubscribeMessage: () => {},
  subscribeNotification: {},
  setSubscribeNotification: () => {},
  isNewMessage: false,
  isConnectedSocket: false,
  setIsConnectedSocket: () => {},
  stompClient: null,
});

export default function SubscribeContextProvider({
  subscribeMessage,
  children: Components,
}) {
  return (
    <SubscribeContext.Provider value={subscribeMessage}>
      {Components}
    </SubscribeContext.Provider>
  );
}
