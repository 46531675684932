import styled, { keyframes } from "styled-components";
import iconLoading from "assets/images/progressDot.svg";

export default function LoadingComponent({ width, height }) {
  return (
    <LoadingCt>
      <LoadingProgress img={iconLoading} />
    </LoadingCt>
  );
}

const Progress = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const LoadingCt = styled.div`
  height: 100%;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LoadingProgress = styled.div`
  width: 100%;
  height: 130px;
  background: url(${({ img }) => img}) no-repeat;
  background-position: center;
  background-size: contain;
  filter: invert(1);
  margin: 30px 0;

  animation: ${Progress} 1s infinite linear;
`;
