import InputBox from "components/atomic/InputBox";
import NormalButton from "components/atomic/NormalButton2";
import { useContext } from "react";
import styled from "styled-components";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";

export default function InputForm({
  disabled,
  inputId,
  inputValue,
  inputSetValue,
  placeholderText,
  buttonId,
  buttonTitle,
  buttonCallBackFunc,
  formClass,
  inputType,
  onChangeFunc,
}) {
  const { isMobile } = useContext(isMobileContext);
  return (
    <>
      {inputType !== "checkbox" ? (
        !isMobile ? (
          <InputWrapper className={formClass}>
            <InputBox
              inputclass={formClass}
              disabled={disabled}
              inputId={inputId}
              value={inputValue}
              setValue={inputSetValue}
              onChangeFunc={onChangeFunc}
              placeholderText={placeholderText}
              type={inputType}
            />
            <NormalButton
              inputclass={formClass}
              buttonClass={"mid round cOrange"}
              disabled={disabled}
              buttonId={buttonId}
              buttonTitle={buttonTitle}
              callBackFunc={buttonCallBackFunc}
            />
          </InputWrapper>
        ) : (
          <InputWrapperMobile className={formClass}>
            <InputBox
              inputclass={formClass}
              disabled={disabled}
              inputId={inputId}
              value={inputValue}
              setValue={inputSetValue}
              placeholderText={placeholderText}
              onChangeFunc={onChangeFunc}
              type={inputType}
            />
            <NormalButton
              inputclass={formClass}
              buttonClass={"mid round cOrange"}
              disabled={disabled}
              buttonId={buttonId}
              buttonTitle={buttonTitle}
              callBackFunc={buttonCallBackFunc}
            />
          </InputWrapperMobile>
        )
      ) : (
        <InputBox
          inputclass={formClass}
          disabled={disabled}
          inputId={inputId}
          value={inputValue}
          setValue={inputSetValue}
          onChangeFunc={onChangeFunc}
          placeholderText={placeholderText}
          type={inputType}
        />
      )}
    </>
  );
}

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 63px;
  border-radius: 100px;
  /* border: 1px solid #ff892a; */
  background: #fff;
  box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.11);
  overflow: hidden;

  & button {
    margin-right: 7px;
  }
`;

const InputWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.dashboard.knowledge {
    width: 100%;
  }

  input {
    min-width: 335px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 100px;
    background: #fff;
    box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.11);
    margin-bottom: 22px;

    &::placeholder {
      color: #b0b0b0;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: -0.12px;
    }

    @media (max-width: 480px) {
      min-width: 100%;
    }
  }

  button {
    min-width: 240px;
    height: 50px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
