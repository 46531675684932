import { createContext, useState } from "react";

const ExtractedDataManageCaptionContext = createContext({
  openCaptionModal: () => {},
  closeCaptionModal: () => {},
  updateCaptionInfo: () => {},

  showCaption: false,
  captionContent: "",
});

export { ExtractedDataManageCaptionContext };

export default function ExtractedDataCaptionProvider(props) {
  const { children } = props;

  const [showCaption, setShowCaption] = useState(false);
  const [captionContent, setCaptionContent] = useState("");

  const updateCaptionInfo = (content) => {
    setCaptionContent(content);
  };

  const openCaptionModal = (data) => {
    setShowCaption(true);
  };

  const closeCaptionModal = () => {
    setShowCaption(false);
  };

  return (
    <ExtractedDataManageCaptionContext.Provider
      value={{
        openCaptionModal,
        closeCaptionModal,
        updateCaptionInfo,
        showCaption,
        captionContent,
      }}
    >
      {children}
    </ExtractedDataManageCaptionContext.Provider>
  );
}
