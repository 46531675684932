import { createContext, useState } from "react";

const ExtractedDataManageHtmlContext = createContext({
  openModal: () => {},
  closeModal: () => {},
  updateHtmlInfo: () => {},
  isOpen: false,
  htmlContent: "",
});

export { ExtractedDataManageHtmlContext };

export default function ExtractedDataHtmlProvider(props) {
  const { children } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [htmlContent, setHtmlContent] = useState("");

  const updateHtmlInfo = (updateHtml) => setHtmlContent(updateHtml);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <ExtractedDataManageHtmlContext.Provider
      value={{
        openModal,
        closeModal,
        updateHtmlInfo,
        isOpen,
        htmlContent,
      }}
    >
      {children}
    </ExtractedDataManageHtmlContext.Provider>
  );
}
