import React, { createContext } from "react";

export const chatContext = createContext({
  chatBotData: [],
  setChatBotData: () => {},
  chatRoomId: [],
  setChatRoomId: () => {},
  userPlan: {
    isFreeUser: true,
    isBasicUser: false,
    isPremiumUser: false,
  }
});

export default function ChatContextProvider({
  chatBotData,
  children: Components,
}) {
  return (
    <chatContext.Provider value={chatBotData}>
      {Components}
    </chatContext.Provider>
  );
}
