import { Fragment } from "react";
import styled from "styled-components";

export default function ConfirmPopDefault({
  setShowConfirmDefault,
  callback,
  cancelCallback,
  title,
  description,
  popClass,
}) {
  const Cancel = () => {
    setShowConfirmDefault(false);
    cancelCallback && cancelCallback();
  };

  const CallBack = () => {
    setShowConfirmDefault(false);
    callback && callback();
  };

  return (
    <ConfirmModal>
      <Wrpper className={popClass === "own" ? "own" : ""}>
        <Header>
          <HTitle>
            {title?.split("\\n").map((txt, index) => (
              <Fragment key={index}>
                {txt}
                <br />
              </Fragment>
            ))}
          </HTitle>
        </Header>
        <Body>
          {description && <span className="txt">{description}</span>}
          <ButtonGroup>
            <button className="cancel" onClick={() => Cancel()}>
              취소
            </button>
            <button className="confirm" onClick={() => CallBack()}>
              확인
            </button>
          </ButtonGroup>
        </Body>
      </Wrpper>
    </ConfirmModal>
  );
}

const ConfirmModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Wrpper = styled.div`
  width: 280px;
  text-align: center;
  padding-top: 20px;
  transform: translateX(-50%);

  position: absolute;
  top: 30%;
  left: 40%;

  @media (max-width: 480px) {
    left: 50%;
  }

  border: 1px solid #dbdbdb;
  padding: 0 20px;
  background: #fff;
  z-index: 99;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.2);

  min-width: 82px;
  right: 10px;

  &.own {
    left: 50%;
  }
`;

const Header = styled.strong`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const HTitle = styled.div`
  width: 100%;
  line-height: 1.4em;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  overflow-y: auto;
  padding: 10px 0;
  /* word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis; */
`;

const Body = styled.div`
  padding-top: 10px;
  padding: 17px 0;

  p {
    letter-spacing: -0.6px;
  }
  & span.txt {
    font-size: 13px;
    letter-spacing: -0.6px;
    word-break: keep-all;
  }
`;

const ButtonGroup = styled.div`
  margin-top: 25px;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-bottom: -17px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 50% !important;
    height: 45px !important;
    display: block;
    font-size: 13px !important;

    margin: 0;
    padding: 0;
    border: none;
    border-top: 1px solid #ff892a;
    cursor: pointer;

    font-weight: 500;

    &:hover {
      opacity: 0.8;
    }
    &.cancel {
      color: #ff892a !important;
    }

    &.confirm {
      color: #fff !important;
      background: #ff892a !important;
    }
  }
`;
