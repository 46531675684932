import { getMyInfo } from "api/memberApi";
import "assets/styles/reset.css";
import "assets/styles/style.css";
import AppContextProvider from "utils/AppContextProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { auth, local, session, mesCookie } from "utils/storage";
import SocketService from "utils/SocketService.js";
import Route from "components/pages/route";
import botApi from "api/botApi";
import { useMediaQuery } from "react-responsive";
import cookie from "react-cookies";

function App() {
  const { pathname } = useLocation();
  // 전체적인 처리 제어
  const [globalInit, setGlobalInit] = useState(false);
  // ChatBot Provider 설정 처리
  const [userChatBotList, setUserChatBotList] = useState(null);
  /* ContextProvider 완료 */
  const [chatBotData, setChatBotData] = useState(null);
  const [chatRoomId, setChatRoomId] = useState(null);
  // ChatBot Provider 설정 처리 끝.

  const [myInfo, setMyInfo] = useState(
    auth.getMyInfo()
      ? JSON.parse(auth.getMyInfo())
      : {
          memberUid: "",
          email: "",
        }
  );
  const [isLoggedIn, setIsLoggedIn] = useState(
    session.getAuthorization() ? session.getAuthorization() : null
  );
  
  const userPlan = useMemo(() => {
    return {
      isFreeUser: chatBotData ? chatBotData?.serviceModel === "0" : true,
      isBasicUser: chatBotData && chatBotData?.serviceModel === "1",
      isPremiumUser: chatBotData && chatBotData?.serviceModel === "2",
    };
  }, [chatBotData])

  //로그아웃 처리 ( 개별 채팅창에서 처리시 )
  const handleSignOutAppFromChat = useCallback(() => {
    mesCookie.removeMyInfo();
    session.removeAuth("NoBotData");
    local.removeMyInfo();
    setMyInfo({
      memberUid: "",
      email: "",
    });
    setIsLoggedIn(null);
  }, [myInfo]);

  //로그아웃 처리
  const handleSignOutApp = useCallback(() => {
    mesCookie.removeMyInfo();
    setChatBotData(null);
    setUserChatBotList(null);
    session.removeAuth();
    local.removeMyInfo();
    setMyInfo({
      memberUid: "",
      email: "",
    });
    setIsLoggedIn(null);
  }, [myInfo, chatBotData, userChatBotList]);

  //로그인 처리
  //props 중 grantType은 사용 안하므로 제거
  const handleSignInApp = useCallback(
    async (
      authorization,
      refreshToken,
      email,
      memberUid,
      socialYn,
      signInType
    ) => {
      session.setAuth(
        authorization,
        refreshToken,
        email,
        memberUid,
        socialYn,
        signInType
      );

      try {
        const {
          status,
          data: { myInfo },
        } = await getMyInfo();

        if (status === 200) {
          const { email } = JSON.parse(myInfo);
          setMyInfo({
            memberUid,
            email,
          });
          setIsLoggedIn(authorization);
        }
      } catch (error) {
        console.log(error);
        handleSignOutApp();
      }
    },
    [handleSignOutApp]
  );

  useEffect(() => {
    /*
      suitue 제품군 끼리 같은 로그인정보를 사용하게 하기 위한 처리. 
      최초 새로고침 시 useEffect를 제일 먼저 타는쪽에 세팅했으니 수정 시 주의할것.
      삼항식을 안쓰고 가독성을 위해 변수 활용. 
    */
    const cAuthorization = cookie.load("authorization");
    const cRefreshToken = cookie.load("refresh_token");
    const cEmail = cookie.load("email");
    const cMemberUid = cookie.load("memberUid");

    const sAuthorization = sessionStorage.getItem("authorization")
      ? sessionStorage.getItem("authorization")
      : cAuthorization;
    const sRefreshToken = sessionStorage.getItem("refresh_token")
      ? sessionStorage.getItem("refresh_token")
      : cRefreshToken;
    const sEmail = sessionStorage.getItem("email")
      ? sessionStorage.getItem("email")
      : cEmail;
    const sMemberUid = sessionStorage.getItem("memberUid")
      ? sessionStorage.getItem("memberUid")
      : cMemberUid;

    // access token 정보와 email정보가 없다면
    if (!session.getAuthorization() || !session.getEmail()) {
      // 4개 정보가 모두 세션정보에 있다면 세팅 하도록 한다.
      if (sAuthorization && sRefreshToken && sEmail && sMemberUid) {
        session.setAuth(sAuthorization, sRefreshToken, sEmail, sMemberUid);
        setMyInfo({ memberUid: sMemberUid, email: sEmail });
      }
    } else {
      // access token 정보가 메모리에 있는데 세션스토리지값이 하나라도 비었다면 로그인을 유도해야 한다.
      if (!sAuthorization || !sRefreshToken || !sEmail || !sMemberUid) {
        session.removeAuth();
        setMyInfo({
          memberUid: "",
          email: "",
        });
      }
    }
  }, []);

  useEffect(() => {
    auth.setMyInfo(JSON.stringify(myInfo));
  }, [myInfo]);

  const getActiveChatBotData = useCallback(async (botUid) => {
    try {
      const { status, data } = await botApi.getBotInfoByBotUid(botUid);
      if (status === 200) {
        const resultData = JSON.parse(data.botInfo);
        setChatBotData(resultData);
      }
    } catch (error) {
    } finally {
      setGlobalInit(true);
    }
  }, []);

  const getUserChatBotList = useCallback(async () => {
    try {
      const { status, data } = await botApi.getUserActiveBotList();
      if (status === 200) {
        const resultData = JSON.parse(data.userBotList);
        if (resultData && resultData.length > 0) {
          setUserChatBotList(resultData);
        } else {
          console.log("userChatBotList: ", "ZERO!!");
          setUserChatBotList(null);
        }
      } else {
        console.log("userChatBotList: ", "ZERO!!");
        setUserChatBotList(null);
      }
    } catch (error) {
      console.log(error);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    // 23.12.15.codelua 외부 대화창일 경우 해당에서 botInfo호출 할 것이니 여기서는 동작안하도록 처리.
    if (
      pathname.startsWith("/chat") ||
      pathname.startsWith("/kakaoLoginSuccess")
    ) {
      console.log(">>> init chat!!");
      setGlobalInit(true);
      return;
    }
    if (!chatBotData) {
      // HOME이 아닌 화면에서 새로고침이 될 때 마지막 봇 데이터를 세팅하기 위함. 여기서 상황에 따라 init을 종료시켜주는 기준점으로 잡자.
      // 1. 세션에 없는지를 먼저 판단. (새로고침등을 통해 state가 완전 초기화 된 경우 여기를 탐.)
      var isSelect = true;
      const tmpSessionData = sessionStorage.getItem("botData");
      if (tmpSessionData) {
        const info = JSON.parse(tmpSessionData);
        if (info.botType !== 1) {
          sessionStorage.removeItem("botData");
          console.log(">> Not Assistant Bot");
        } else {
          isSelect = false;
          getActiveChatBotData(info.botUid);
        }
      }
      // 세션에 타입에 맞는 봇데이터가 없다면 신규 로딩 처리.
      if (isSelect) {
        if (userChatBotList && userChatBotList.length >= 1) {
          const pickBot = userChatBotList.reduce(function (acc, tmp) {
            if (acc == null && tmp.status === "01") {
              return tmp;
            }
            return acc;
          }, null);
          if (pickBot !== null) {
            getActiveChatBotData(pickBot.botUid);
          } else {
            // getActiveChatBotData 의 finally에서 init종료를 하지만 현재 그타입을 벗어난 경우가 있어 임시 처리
            // 안정화 작업등을 통해서 이러 불합리한 코드들을 처리해야한다. 23.08.31 codelua
            setGlobalInit(true);
          }
        } else {
          setGlobalInit(true);
        }
      } else {
        setGlobalInit(true);
      }
    } else {
      setGlobalInit(true);
    }
  }, [userChatBotList]);

  useEffect(() => {
    /*
      isLoggedIn은 초기화 시 sessionStorage 또는 localStorage 에서 채워주기 때문에
      isLoggedIn만으로는 현재 로그인 상태(sessionStorage에 Token 값이 Setting된)를 명확히 판단하기 어려움
      sessionStorage의 값은 Login 행위가 끝난 후 채워주기 때문에 
      초기 값을 조회하는 App.js 에서는 isLoggedIn과 session의 값을 함께 체크해주어야 할 듯
     */
    if (!isLoggedIn || !session.getAuthorization()) return;

    // 외부 채팅창에서는 굳이 getUserChatBotList를 가져오지 말자. 어차피 주소바꾸면 새로고침 하면서 다시 정보 부른다.
    if (pathname.startsWith("/chat")) {
      return;
    }

    getUserChatBotList();
    // 최종 챗봇도 하나 가져오도록 하자.
  }, [isLoggedIn]);

  // Socket 설정 처리 23.06.12 codelua
  const [subscribeMessage, setSubscribeMessage] = useState(null);
  const [subscribeNotification, setSubscribeNotification] = useState(null);
  const [isConnectedSocket, setIsConnectedSocket] = useState(false);
  const [isNewMessage, setIsNewMessage] = useState(false);
  const [socket, setSocket] = useState(null);
  const [stompClient, setStompClient] = useState(null);

  const onClose = () => {
    setStompClient(null);
    setSocket(null);
    setIsConnectedSocket(false);
  };

  const onOpen = () => {
    setIsConnectedSocket(false);
  };

  const initSocket = useCallback(() => {
    SocketService.init(setSocket, setStompClient, onOpen, onClose);
  }, []);

  const disconnectSocket = useCallback(() => {
    SocketService.disconnect(socket, stompClient);
  }, [socket, stompClient]);

  useEffect(() => {
    //window initSocket, disconnectSocket 함수 초기화
    window.initSocket = initSocket;
    window.disconnectSocket = disconnectSocket;

    //unmount 시 수행
    return () => {
      window.initSocket = () => {};
      window.disconnectSocket = () => {};
    };
  }, [initSocket, disconnectSocket]);

  const callBackAssistantBotNoti = (response) => {
    const callBackChatBotData = JSON.parse(response.body);
    if (
      callBackChatBotData.status === "01" &&
      callBackChatBotData.division === 1
    ) {
      // 이떄 리스트에 담아준다.
      setUserChatBotList((prev) => {
        if (Array.isArray(prev)) {
          return [...prev, callBackChatBotData];
        } else {
          return [callBackChatBotData];
        }
      });
    }
    setSubscribeMessage(callBackChatBotData);
  };

  const callBackAddKnowDataSetReply = (response) => {
    const addKnowDataInfo = JSON.parse(response.body);
    console.log('addKnowDataInfo~~~', addKnowDataInfo);
    setSubscribeNotification(addKnowDataInfo);
  };
  
  useEffect(() => {
    if (!stompClient) {
      initSocket();
    } else {
      if (stompClient.ws.readyState === 3) {
        initSocket();
      } else if (stompClient.connected !== isConnectedSocket) {
        setIsConnectedSocket(stompClient.connected);
      } else if (!stompClient.connected || !isConnectedSocket) {
        SocketService.connect(stompClient, setIsConnectedSocket);
      }
    }
  }, [stompClient, isConnectedSocket, initSocket]);

  useEffect(() => {
    let recvAssistantBotReply = null;
    let recvAddKnowDataSetReply = null;
    if (
      stompClient &&
      stompClient.connected &&
      isConnectedSocket &&
      myInfo.memberUid
    ) {
      recvAssistantBotReply = SocketService.subscribe(
        stompClient,
        `/bot/${myInfo.memberUid}/assistant`,
        callBackAssistantBotNoti
      );

      recvAddKnowDataSetReply = SocketService.subscribe(
        stompClient,
        `/bot/${myInfo.memberUid}/addknow`,
        callBackAddKnowDataSetReply
      );
    }

    return () => {
      recvAssistantBotReply && SocketService.unsubscribe(recvAssistantBotReply);
      recvAddKnowDataSetReply && SocketService.unsubscribe(recvAddKnowDataSetReply);
    };
  }, [stompClient, isConnectedSocket, myInfo.memberUid]);
  // Socket 설정 처리 끝.

  // chatBotData가 바뀔때마다 session에 정하도록 한다.
  useEffect(() => {
    if (!chatBotData) {
      // 일단 암거도 안함.
    } else {
      // 어디선 setChatBotData가 호출 되면 세션을 처리하기 위함.
      // 다만 진행중인 챗봇은 해당 화면에서만 사용되도록 공유하지 않음.
      if (chatBotData.status !== "00") {
        const stateToPass = JSON.stringify(chatBotData);
        sessionStorage.setItem("botData", stateToPass);
      } else if (chatBotData.status === "00") {
        sessionStorage.removeItem("botData");
      }
    }
  }, [chatBotData]);

  const isMobile = useMediaQuery({
    query: "screen and (min-width: 0px) and (max-width: 480px)",
  });

  if (!globalInit) {
    return <></>;
  }

  return (
    <AppContextProvider
      isMobile={{ isMobile }}
      signApp={{
        isLoggedIn,
        myInfo,
        setMyInfo,
        handleSignInApp,
        handleSignOutApp,
        handleSignOutAppFromChat,
      }}
      subscribeMessage={{
        subscribeMessage,
        setSubscribeMessage,
        subscribeNotification,
        setSubscribeNotification,
        isNewMessage,
        isConnectedSocket,
        setIsConnectedSocket,
        stompClient,
      }}
      userChatBotList={{
        userChatBotList,
        setUserChatBotList,
        getUserChatBotList,
      }}
      chatBotData={{
        chatBotData,
        setChatBotData,
        chatRoomId,
        setChatRoomId,
        userPlan
      }}
    >
      <Route />
    </AppContextProvider>
  );
}

export default App;
