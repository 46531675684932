import React, { useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { signAppContext } from "../../../utils/providers/SignContextProvider.js";
import NormalButton from "components/atomic/NormalButton2.js";
import LogoIcon from "../../../assets/images/LOGO_Assistant.svg";
import iconClose from "../../../assets/images/icon_close.svg";
import { useHistory, useRouteMatch } from "react-router-dom";

export default function MobileMenu({ setMobileMenu, dispatchMenu }) {
  const match = useRouteMatch();
  const { handleSignOutApp } = useContext(signAppContext);
  const [currentPage, setCurrentPage] = useState("");

  const history = useHistory();

  const actionSignOut = () => {
    setMobileMenu((prev) => !prev);
    handleSignOutApp();
  };

  const MenuClickAction = (MenuName) => {
    sessionStorage.setItem("DISPLAY_MENU", MenuName);
    if (dispatchMenu)
      dispatchMenu && dispatchMenu({ type: "DISPLAY_MENU", menu: MenuName });
    else {
      history.push("/dashboard");
      // history.push("/assistant/dashboard");
    }
    setMobileMenu((prev) => !prev);
  };

  useEffect(() => {
    // if (history.location.pathname === "/assistant/dashboard") {
    if (history.location.pathname === "/dashboard") {
      setCurrentPage(sessionStorage.getItem("DISPLAY_MENU"));
    }
  }, []);

  return (
    <MenuCt>
      <MobileMenuHeader className="top">
        <Logo
          icon={LogoIcon}
          onClick={() => {
            // 이미 홈이면 refresh처리해주는 matchURL 처리 해주자.
            if (match.url === "/") {
              setMobileMenu((prev) => !prev);
            } else {
              history.push("/");
            }
          }}
        ></Logo>
        <CloseBtn
          icon={iconClose}
          onClick={(e) => {
            setMobileMenu((prev) => !prev);
          }}
        />
      </MobileMenuHeader>
      <MobileMenuList>
        <MenuBtn
          className={currentPage === "USES" ? "active" : ""}
          onClick={(e) => {
            MenuClickAction("USES");
          }}
        >
          <i className={"ri-brain-line"}></i>
          Assistant 활용
        </MenuBtn>
        <MenuBtn
          className={currentPage === "CREATE" ? "active" : ""}
          onClick={(e) => {
            MenuClickAction("CREATE");
          }}
        >
          <i className={"ri-edit-box-line"}></i>
          Assistant 생성
        </MenuBtn>
        <MenuBtn
          className={currentPage === "MANAGE" ? "active" : ""}
          onClick={(e) => {
            MenuClickAction("MANAGE");
          }}
        >
          <i className={"ri-article-line"}></i>
          Assistant 관리
        </MenuBtn>
        <MenuBtn
          className={currentPage === "HISTORY" ? "active" : ""}
          onClick={(e) => {
            MenuClickAction("HISTORY");
          }}
        >
          <i className={"ri-edit-2-line"}></i>
          Assistant 기록
        </MenuBtn>
        <MenuBtn
          className={currentPage === "KNOWLEDGE" ? "active" : ""}
          onClick={(e) => {
            MenuClickAction("KNOWLEDGE");
          }}
        >
          <i className={"ri-chat-new-fill"}></i>
          지식 관리
        </MenuBtn>
        <MenuBtn
          className={currentPage === "STATS" ? "active" : ""}
          onClick={(e) => {
            MenuClickAction("STATS");
          }}
        >
          <i className={"ri-numbers-line"}></i>
          통계
        </MenuBtn>
        <MenuBtn
          className={currentPage === "QNA" ? "active" : ""}
          onClick={(e) => {
            MenuClickAction("QNA");
          }}
        >
          <i className={"ri-chat-3-line"}></i>
          문의하기
        </MenuBtn>
        <MenuBtn
          className={currentPage === "DIRECTQNA" ? "active" : ""}
          onClick={(e) => {
            MenuClickAction("DIRECTQNA");
          }}
        >
          <i className={"ri-code-s-slash-line"}></i>
          도입 문의
        </MenuBtn>
      </MobileMenuList>
      <MobileMenuFooter>
        <NormalButton
          buttonId="loginBtn"
          buttonTitle={"로그아웃"}
          buttonClass="round"
          callBackFunc={actionSignOut}
          style={{ zIndex: 100 }}
        />
      </MobileMenuFooter>
    </MenuCt>
  );
}

const openModal = keyframes`
0% {
-webkit-transform: translateX(-10%);
transform: translateX(-10%);
opacity: 0;
}

100% {
-webkit-transform: translate(0);
transform: translate(0);
opacity: 1;
}
`;

const MenuCt = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
  /* margin-top: 64px; */
  background: #fff;

  display: flex;
  flex-direction: column;

  overflow: hidden;

  animation: ${openModal} 0.2s ease-in-out;
  animation-fill-mode: both;
`;
const Logo = styled.h2`
  width: 165px;
  height: 27.522px;
  flex-shrink: 0;

  background: url(${({ icon }) => icon}) no-repeat;
  background-size: contain;
  background-position: center;
`;
const CloseBtn = styled.button`
  width: 25px;
  height: 25px;
  background: url(${({ icon }) => icon}) no-repeat;
  background-size: contain;
  background-position: center;
  margin-left: auto;
  margin-right: 10px;
`;

const MobileMenuHeader = styled.div`
  width: 100%;
  height: 60px;
  padding: 21px 30px;
  display: flex;

  &.top {
    padding: 0 30px 0 25px;
    align-items: center;

    & h2 {
      margin-left: 3.75px;
      margin-top: 5px;
    }

    & button {
    }
  }

  &.logout {
    button {
      margin: 0 10px;
    }
  }
`;

const MobileMenuFooter = styled(MobileMenuHeader)`
  margin-top: auto;
  padding: 10px 21px;

  & button {
    line-height: normal !important;
    font-size: 16px !important;
    padding: 0px 20px !important;
    background: #fff !important;
    border: 1px solid #ff892a !important;
    color: #ff892a !important;
    margin-right: auto !important;
  }
`;

const MobileMenuList = styled.div`
  margin-top: 30px;
  padding: 0 15px;

  display: flex;
  flex-direction: column;
  align-items: start;
`;

const MenuBtn = styled.button`
  padding: 10px 20px;

  color: #1a1a1a;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;

  &.active {
    color: #ff892a;
    font-weight: 600;
    position: relative;
    &:after {
      z-index: -1;
      position: absolute;
      bottom: 5px;
      left: 0;
      width: 100%;
      height: 40%;
      content: "";
      background: rgba(255, 206, 70, 0.5);
    }
  }

  & i {
    margin-right: 10px;
  }
`;
