import { memo, useMemo, useState, useContext } from "react";
import { signAppContext } from "utils/providers/SignContextProvider";
import botApi from "api/botApi.js";
import styled from "styled-components";
import { Panel, PanelGroup } from "react-resizable-panels";
import CombineChat from "./CombineChat.js";
import { ErrorCatch } from "api/ErrorCatch";
import { refineToClientMessages } from "utils/chatUtil";
import { PaginationContainer } from "utils/Pagination.js";
import NormalButton from "components/atomic/NormalButton2";

export default function CombineHistView({ props }) {
  const {
    chatBotData,
    currentPage,
    setCurrentPage,
    selectedChatRoom,
    setSelectedChatRoom,
    setKnowLoading,
    setKnowDataCount,
    setKnowDataList,
    setSentenceList,
    setTokens,
  } = props ?? {};

  const { handleSignOutApp } = useContext(signAppContext);
  const [messages, setMessages] = useState([]);

  const pageDataCount = 10;

  const initKnowView = () => {
    setKnowDataCount(0);
    setKnowDataList([]);
    setSentenceList([]);
    setTokens([]);
  };

  const getChatHistoryMessage = async (chatRoomId, botUid) => {
    async function getChatHistoryMessage(chatRoomId, botUid) {
      try {
        const { status, data } = await botApi.getChatHistoryMessage(
          chatRoomId,
          botUid,
        );
        if (status === 200) {
          const chatMessages = data.chatMessages
            ? JSON.parse(data.chatMessages)
            : "";
          if (chatMessages) {
            console.log("getChatHistoryMessage: ", chatMessages);
          }
          if (!Array.isArray(chatMessages)) {
            chatMessages = [chatMessages]; // 배열로 변환
          }
          const refineMessages = refineToClientMessages(chatMessages);
          setMessages(refineMessages);
        } else {
          console.log("getChatHistoryMessage Not Loaded...");
        }
      } catch (error) {
        ErrorCatch(error, handleSignOutApp);
      }
    }

    await getChatHistoryMessage(chatRoomId, botUid);
  };

  const funcSelectChatInfo = (data) => {
    getChatHistoryMessage(data.chatRoomId, chatBotData.botUid);
    setSelectedChatRoom(data);
  };

  const PagingBtn = memo((props) => {
    const { text, ...otherProps } = props ?? {};
    return <PagingBtnComponent {...otherProps}>{text}</PagingBtnComponent>;
  });

  const asyncFuncArgs = useMemo(() => {
    const chatType = 1;
    if (chatBotData?.botUid) {
      return [chatBotData?.botUid, chatType];
    } else {
      return [];
    }
  }, [chatBotData?.botUid]);

  return (
    <ResultSection>
      {selectedChatRoom ? (
        <ExChat>
          <CombineChat
            props={{
              isHistory: true,
              isLoggedIn: true,
              messages,
              setMessages,
              chatBotData,
              setKnowLoading,
              setKnowDataCount,
              setKnowDataList,
              setSentenceList,
              setTokens,
              chatClass: "ComHISTORY",
            }}
          />
          <ChatDetailInfoFooter>
            <NormalButton
              buttonTitle={"목록으로"}
              buttonClass={"round small cDel"}
              callBackFunc={(e) => {
                e.preventDefault();
                initKnowView();
                setSelectedChatRoom(false);
              }}
            ></NormalButton>
          </ChatDetailInfoFooter>
        </ExChat>
      ) : (
        <HistoryTableCt>
          <PaginationContainer
            opt={{
              isAsync: true,
              asyncFuncName: "getChatRoomListByMember",
              asyncFuncArgs: asyncFuncArgs,
              addArrayInClient: [],
              TotalTextCt,
            }}
            pageLength={pageDataCount}
            pageCount={10}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            DataWrapComponent={BotHistoryList}
            DataItemComponent={(props) => (
              <CustomBotHistory
                funcSelectChatInfo={funcSelectChatInfo}
                {...props}
              />
            )}
            itemActiveClassName={"current"}
            dataNullMessage={
              <>
                <i className="ri-alert-fill"></i> 대화 기록이 없습니다
              </>
            }
            ItemComponent={PagingBtnComponent}
            PrevComponent={(props) => (
              <PagingBtn className={"prev"} text={"이전"} {...props} />
            )}
            NextComponent={(props) => (
              <PagingBtn className={"next"} text={"다음"} {...props} />
            )}
            PaginationNumWrap={PagingCt}
          />
        </HistoryTableCt>
      )}
    </ResultSection>
  );
}

// 페이지네이션 헤더 뷰 처리.
const CustomBotHistory = (props) => {
  const { data, keyName, funcSelectChatInfo, ...otherProps } = props;
  return (
    <>
      <InfoHeader>
        <p className="title content">대화 기록</p>
        <p className="title date">작성일</p>
      </InfoHeader>

      <BotHistory
        key={keyName}
        onClick={(e) => {
          e.preventDefault();
          funcSelectChatInfo(data);
        }}
      >
        <InfoIp className="item content">{`${data.ip}`}</InfoIp>
        <InfoCreateAt className="item date">{`${data.createAt}`}</InfoCreateAt>
      </BotHistory>
    </>
  );
};

// 페이지네이션 상단 개수 및 페이지 정보.
const TotalTextCt = (props) => {
  const { dataTotalLength } = props;
  const pageDataCount = 10;
  const handlePageCount = (data) => {
    if (data <= pageDataCount) return 1;

    return data / pageDataCount !== data % pageDataCount
      ? Math.ceil(data / pageDataCount)
      : data / pageDataCount;
  };

  return (
    <SearchItemInfoCt>
      <SearchItemInfoTotal>
        총 개수:
        <span className="num">{dataTotalLength ?? 0}</span>
      </SearchItemInfoTotal>
      <SearchItemInfoTotal>
        페이지수 :
        <span className="num">{handlePageCount(dataTotalLength)}</span>
      </SearchItemInfoTotal>
    </SearchItemInfoCt>
  );
};

const ResultSection = styled.div`
  background-color: #fff;
  color: #000;
  width: 100%;
  height: 100%;
  text-align: center;
  & span {
    font-weight: 500;
  }
`;

const BoardContainer = styled.div`
  margin-top: 10px;
  border-top: 2px solid #b9b9b9;
  border-bottom: 2px solid #b9b9b9;
  /* height: 55vh; */
  height: 100%;
  /* height: calc(100vh - 10px) !important; */
  padding: 5px 20px 5px 5px;

  overflow-x: hidden;
  overflow-y: auto;
`;

const PagingBtnComponent = styled.button`
  border: 1px solid #ff892a;
  border-radius: 5px;
  margin: 10px 5px; // 여기 margin값을 데이터의 개수에 맞춰서 조정해야 하나 페이지가 통일성있게 보일려면?
  padding: 5px 8px;

  color: #888;
  border: 1px solid #888;

  width: 45px;
  height: 35px;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &.current {
    border: 1px solid #ff892a;
    background-color: #ff892a;
    color: #fff;
  }

  &.next {
  }

  &.prev {
  }
`;
const HistoryTableCt = styled.div`
  width: auto;
  height: fit-content;
  margin: 10px 20px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);

  @media (max-width: 480px) {
    min-height: auto;
  }
`;

const BotHistoryList = styled.div`
  width: 100%;
  max-height: auto;
  min-height: 590px;
  overflow: hidden;
  & p.empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* background-color: #ffffff; */
    /* color: tomato; */
    margin-top: 10px;
    color: #888;
    line-height: 40px;
    font-size: 18px;
    & i {
      margin-right: 5px;
      color: #ff892a;
      font-size: 22px;
    }
  }
`;

const PagingCt = styled.div`
  width: 100%;
  text-align: center;

  margin: 30px 0;
`;

const InfoHeader = styled.div`
  width: 100%;
  display: none;

  p {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
    width: 120px;
    text-align: center;

    /* border: 1px solid red; */
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  & p.title.content {
    width: 400px;
    margin-right: 50px;
    @media (max-width: 480px) {
      width: 180px;
    }
  }

  & p.title.date {
    width: 200px;
  }

  &:first-child {
    background: #f2f3f8;
    border-bottom: 1px solid #ccc;
    display: flex;
    padding: 10px 15px;
    display: flex;
    justify-content: space-around;
    width: 100%;

    /* @media (max-width: 480px) {
      justify-content: unset;
      padding: 15px 30px;
      & p:last-child {
        margin-left: 35%;
      }
    } */
  }
`;
const InfoIp = styled.p`
  color: #7a7a7a;
  font-size: 16px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.16px;

  text-align: left;
  width: 400px;
  margin-right: 50px;

  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 480px) {
    width: 300px;
    margin-right: 0;
  }
`;
const InfoCreateAt = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #7a7a7a;
  font-size: 16px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.16px;

  width: 200px;
  text-align: center;
`;

const BotHistory = styled.div`
  //margin: 10px 0;
  padding: 15px;

  display: flex;
  justify-content: space-around;
  width: 100%;

  &:hover {
    background: #f2f3f8;
    border-radius: 0 !important;
    cursor: pointer;
  }

  & .item.content,
  .item.date {
    /* border: 1px solid red; */
  }

  &.active {
    width: 350px;
    padding: 0;
    justify-content: start;

    p {
      margin-right: 10px;
    }
  }
`;
const SearchItemInfoCt = styled.div`
  text-align: right;

  background: #f2f3f8;
  padding: 10px;

  border-bottom: 1px solid #fff;
  border-radius: 15px 15px 0 0;
  @media (min-width: 481px) {
    display: flex;
    justify-content: end;
    align-items: end;
  }
`;
const SearchItemInfoTotal = styled.p`
  padding: 5px;

  display: inline-block;
  font-size: 14px;
  margin-right: 10px;

  padding-right: 5px;
  color: #444;

  &:first-child {
    border-right: 1px solid #fff;
  }
  &:last-child {
    border-right: 0;
    margin-right: 0;
  }
  & span.num {
    font-weight: 500;
    margin-left: 5px;
  }
`;
const ExChat = styled.div`
  border: 1px solid #f7f9ff;
  width: 100%;
  height: 100%;
  /* max-height: 400px; */

  border-radius: 10px;
  .chat--bot2.chat--cover.HISTORY {
    margin: 0;
  }
`;
const ChatDetailInfoFooter = styled.div`
  width: 100%;
  margin: 9.5px 3px;
  display: flex;
  align-items: center;

  button {
    margin: 0 auto;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    flex-direction: column;
  }
`;
