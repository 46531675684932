import axios from "axios";
import { session } from "../utils/storage.js";
import ExtractedManagerUtil from "../utils/extractedManagerUtil.js";

const URL = `${process.env.REACT_APP_API_HOST}/api/preTreatment`;
const api = axios.create({ baseURL: URL });

api.interceptors.response.use(
  (response) => {
    const { headers, config, status } = response;
    //response headers의 Authorization, refresh_token을 전달 받았다면
    //새로 갱신된 토큰 값이므로 기존의 sessionStorage에 Authorization,refresh_token 값을 업데이트한다.
    if (status === 205) {
      session.setAuth(headers.authorization, headers.refresh_token, headers.member_email, headers.member_uid, "", "");

      config.headers.authorization = headers.authorization;
      config.headers.refresh_token = headers.refresh_token;
      console.log("acessToken 갱신. 서비스 재 호출", response);
      return api.request(config);
    }
    return response;
  },
  (error) => {
    const { response, config } = error;

    if (response) {
      if (response.status === 401) {
        console.log("accessToken 만료. refresh token으로 요청");
        // 23.07.12.codelua 빈 토큰이 전송 되는 순간 쉬지 않고 서버와 핑퐁을 치는 현상이 발생하여 분실 된 경우 그냥 강제 로그아웃 처리.
        if (!session.getRefreshToken()) {
          console.log("Refresh Token 분실 상태.");
          error = {
            response: {
              status: 403,
              errorMessage: "accessToken 만료, refreshToken 만료. 강제 로그아웃 !",
            },
          };
          return Promise.reject(error);
        }
        config.headers.refresh_token = session.getRefreshToken();
        return api.request(config);
      } else if (response.status === 403) {
        console.log("accessToken 만료, refreshToken 만료. 강제 로그아웃 !");
        // 각 처리 쪽에서 강제 로그아웃 처리 중.
        return Promise.reject(error);
      } else {
        console.log("JWT처리 외 에러. : ", response);
        error = {
          response: {
            status: response.status, //Service Unavailable
            //chatBotData?.botName ?? ""
            errorMessage: response.data.resultMessage ?? "에러가 발생했습니다.",
          },
        };
        return Promise.reject(error);
      }
    } else if (!error?.response && error.message === "Network Error") {
      console.log("서버와 통신 할 수 없습니다.");
      error = {
        response: {
          status: 503, //Service Unavailable
          errorMessage: "서버와 통신 할 수 없습니다.",
        },
      };
      return Promise.reject(error);
    } else {
      // 명삼 여기서 전역으로 오류처리해버리면 각 화면단에서 api호출 마다 뭘 안해도 될거같은데 어떨지 생각해봐줘요
      //이 부분은 errorcode를 504(Gateway Timeout)로 해야하는지..
      console.log("서버와의 통신에 알 수 없는 문제가 발생했습니다.");
      error = {
        response: {
          status: 503,
          errorMessage: "서버와의 통신에 알 수 없는 문제가 발생했습니다.",
        },
      };
      return Promise.reject(error);
    }
  }
);

const getDefaultHeaders = () => ({
  authorization: session.getAuthorization(),
});

export const preTreatmentApi = {
  selectByPagination: (pagination) => {
    const {
      currentPage,
      lengthPerPage,
      param: {
        dataUid,
        search, // 검색어
        searchType, // 'content' = 내용 / 'keyword' = 키워드 / 'contentAndKeyword' = 내용 + 키워드
        tabState,
      },
    } = pagination;
    return api.post(
      "/select_pagination",
      { currentPage, lengthPerPage, param: { dataUid, search, searchType, tabState } },
      {
        headers: getDefaultHeaders(),
      }
    );
  },

  tableToNlp: ({ tbUid, fixContent }) => {
    return api.post(
      "tableToNlp",
      {
        tbUid,
        fixContent,
      },
      {
        headers: getDefaultHeaders(),
      }
    );
  },

  /**
   * @param
   * - tbUid: 각 테이블의 uuid
   * - keywords: 키워드를 가지고 있는 문자열 배열 ex) ['keyword1', 'keyword2', 'keyword3']
   * */
  updateKeyword: ({ tbUid, keywords }) => {
    const keywordStr = ExtractedManagerUtil.keywordToStr(keywords);

    return api.patch(
      `/update/keyword`,
      {
        tbUid,
        keyword: keywordStr,
      },
      {
        headers: getDefaultHeaders(),
      }
    );
  },

  updateData: ({ tbUid, ptStatus, caption, fixContent, indexName, fileName, botUid }) => {
    return api.patch(
      `/update`,
      {
        botUid,
        tbUid: tbUid,
        ptStatus: ptStatus,
        caption,
        fixContent: fixContent,
        indexName,
        fileName,
      },
      {
        headers: getDefaultHeaders(),
      }
    );
  },

  deleteData: ({ tbUids, tabState, indexName, botUid, email }) => {
    return api.delete(`/delete/list?tbUids=${tbUids.join(",")}&tabState=${tabState}&indexName=${indexName}&botUid=${botUid}&email=${email}`, {
      headers: getDefaultHeaders(),
    });
  },
};
