import { memo, useMemo, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import InputForm from "components/molecules/InputForm.js";
import RangeSlider from "react-range-slider-input";
import { PaginationContainer } from "utils/Pagination.js";
import SearchItemCt from "./SearchItemCt.js";
import botIcon from "assets/images/default_bot.png";
import NormalButton from "components/atomic/NormalButton2.js";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider.js";

export default function CombineSearchBox(props) {
  const { keyword, setKeyword, chatBotData, setPopState, setPopMessage } =
    props ?? {};

  const [showResultState, setShowResultState] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [addArrayInClient, setAddArrayInClient] = useState([]);
  const [dynamicValue, setDynamicValue] = useState([]);
  // pagination으로 부터 받아오는 값들.
  const [answer, setAnswer] = useState(null);
  const [tokens, setTokens] = useState([]);
  const [useSentence, setUseSentence] = useState(true);
  //
  const [rangeChkState, setRangeChkState] = useState(false);

  const [useNewLine, setUseNewLine] = useState(false);
  const RefRange = useRef("");
  const RefMin01 = useRef();
  const RefMax01 = useRef();

  const { isMobile } = useContext(isMobileContext);

  const PagingBtn = memo((props) => {
    const { text, ...otherProps } = props ?? {};
    return <PagingBtnComponent {...otherProps}>{text}</PagingBtnComponent>;
  });

  const asyncFuncArgs = useMemo(() => {
    if (chatBotData?.botUid) {
      return [chatBotData?.botUid, ...dynamicValue];
    } else {
      return [];
    }
  }, [dynamicValue]);

  // 하이라이트 관리 함수
  const handleHighLight = (normalText_Par) => {
    // console.log(">>> tokens: ", tokens);
    if (tokens && tokens.length === 0) {
      return normalText_Par;
    }
    const highLightWord = tokens
      .map((word) => word.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"))
      .join("|");
    const regex = new RegExp(`(\\b|\\B)(${highLightWord})(\\b|\\B)`, "gi");

    // 주어진 answer문장에서 highLightWord와 일치하는 단어를 찾아 <span> 태그로 감싸기 *스타일 포함되어있음
    // 23.11.13.codelua 답변에서는 너무 지저분하여 하이라이팅 효과 안하도록 css주석처리함.
    const wrapText = normalText_Par.replace(
      regex,
      `<span class="highLight">$2</span>`,
    );
    return wrapText;
  };

  const handleSliderMouseUp = () => {
    if (!keyword) {
      return;
    } else {
      if (rangeChkState) {
        setAnswer(null);
        setCurrentPage(1);
        setShowResultState(true);
        const ratio = (100 - RefRange.current.value.max) / 100;
        setDynamicValue([keyword, ratio]);
      }
    }
  };

  const SearchBtnOnClick = () => {
    if (!keyword) {
      setPopMessage("검색어를 입력해주세요");
      setPopState((prev) => !prev);
      return;
    }
    setAnswer(null);
    setCurrentPage(1);
    setShowResultState(true);
    const ratio = (100 - RefRange.current.value.max) / 100;
    setDynamicValue([keyword, ratio]);
  };

  // 하이라이트 개행 버튼 관리 함수
  const handleTextState = (e) => {
    if (e.target.id === "enter") {
      setUseNewLine(!useNewLine);
    } else if (e.target.id === "useSentence") {
      setUseSentence(!useSentence);
    }
  };

  useEffect(() => {
    setKeyword("");
    setAnswer(null);
    setShowResultState(false);
  }, [chatBotData]);

  return (
    <>
      <InputCt>
        <InputForm
          formClass="dashboard asst"
          inputValue={keyword}
          inputSetValue={setKeyword}
          inputType={"text"}
          placeholderText={"검색어를 입력하세요"}
          buttonTitle="검색"
          buttonCallBackFunc={SearchBtnOnClick}
        />

        <SearchOptCt>
          {/* 슬라이더 */}
          <SearchOpCt
            style={
              !isMobile
                ? { width: "50%", textAlign: "left" }
                : { width: "100%" }
            }
          >
            <CheckCt>
              <p className="title">검색 조건</p>
              <InputForm
                inputId={"directChk"}
                inputValue={rangeChkState}
                inputSetValue={setRangeChkState}
                formClass={""}
                inputType={"checkbox"}
              />{" "}
              <label htmlFor={"directChk"} className="inputDesc">
                변경 시 검색
              </label>
            </CheckCt>

            <div className="optionValueCt">
              <p className="minValueTitle">
                키워드{" "}
                <span ref={RefMin01} className="minValue">
                  20
                </span>
              </p>

              <p className="maxValueTitle">
                시맨틱{" "}
                <span ref={RefMax01} className="maxValue">
                  80
                </span>
              </p>
            </div>
            <RangeSlider
              ref={RefRange}
              onThumbDragEnd={rangeChkState && handleSliderMouseUp}
              onInput={(value) => {
                RefMin01.current.innerText = 100 - RefRange.current.value.max;

                RefMax01.current.innerText = RefRange.current.value.max;
              }}
              thumbsDisabled={[true, false]}
              rangeSlideDisabled={true}
              defaultValue={[0, 80]}
              min={0}
              max={100}
              step={10}
            />
          </SearchOpCt>

          {/* 하이라이트 개행 */}
          <TextOptnBtnCt>
            <div className="unitCt">
              <p className="title">줄 바꿈</p>
              <div
                id="enter"
                className={useNewLine ? "toggle active" : "toggle"}
                onClick={handleTextState}
              >
                <div
                  id="enter"
                  onClick={handleTextState}
                  className="circle"
                ></div>
              </div>
            </div>
            <div className="unitCt">
              <p className="title">문장모드</p>
              <div
                id="useSentence"
                onClick={handleTextState}
                className={useSentence ? "toggle active" : "toggle"}
              >
                <div
                  id="useSentence"
                  onClick={handleTextState}
                  className="circle"
                ></div>
              </div>
            </div>
          </TextOptnBtnCt>
          {/* 하이라이트 개행 */}
        </SearchOptCt>
      </InputCt>

      {showResultState && (
        <ResultSection>
          {answer && (
            <BotTextBox>
              <BotImg src={botIcon} />
              <div
                className="answer"
                dangerouslySetInnerHTML={{
                  __html: handleHighLight(answer),
                }}
              />
              {/* <BotText>{answer}</BotText> */}
            </BotTextBox>
          )}

          {/* 페이지 */}
          <PaginationContainer
            state={answer}
            setState={setAnswer}
            tokens={tokens}
            setTokens={setTokens}
            useSentence={useSentence}
            setUseSentence={setUseSentence}
            useNewLine={useNewLine}
            opt={{
              isAsync: true,
              asyncFuncName: "usesBotToCombineSerach",
              asyncFuncArgs: asyncFuncArgs,
              addArrayInClient: addArrayInClient,
              TotalTextCt,
            }}
            pageLength={10}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            DataWrapComponent={BoardContainer}
            DataItemComponent={(props) => <SearchItemCt {...props} />}
            ItemComponent={PagingBtnComponent}
            itemActiveClassName={"current"}
            dataNullMessage={"검색 결과가 없습니다."}
            PrevComponent={(props) => (
              <PagingBtn className={"prev"} text={"이전"} {...props} />
            )}
            NextComponent={(props) => (
              <PagingBtn className={"next"} text={"다음"} {...props} />
            )}
            PaginationNumWrap={PagingCt}
          />
        </ResultSection>
      )}
    </>
  );
}

/* PaginationContainer에서 TotalTextCt를 그려주는데, 해당 부분에 추가한 props를 여기에 가져와서 사용할 수 있다. */
const TotalTextCt = (props) => {
  const { dataTotalLength, setExpandCollapse } = props;
  const { isMobile } = useContext(isMobileContext);
  return (
    <SearchItemInfoCt>
      {!isMobile && (
        <>
          <NormalButton
            className="open"
            buttonClass={"cOrange small"}
            buttonTitle={
              <>
                {" "}
                <i className="ri-zoom-in-line"></i> 모두 펼침
              </>
            }
            onClick={() => {
              setExpandCollapse(1); // 1: 모두펼침, 2: 모두닫힘
            }}
          ></NormalButton>
          &nbsp;&nbsp;&nbsp;
          <NormalButton
            className="close"
            buttonClass={"cOrangeReverse small"}
            buttonTitle={
              <>
                {" "}
                <i className="ri-zoom-out-line"></i> 모두 닫힘
              </>
            }
            onClick={() => {
              setExpandCollapse(2); // 1: 모두펼침, 2: 모두닫힘
            }}
          ></NormalButton>
          &nbsp;&nbsp;&nbsp;
        </>
      )}
      <SearchItemInfoTotal>
        총 개수:
        <span className="num">{dataTotalLength ?? 0}</span>
      </SearchItemInfoTotal>
    </SearchItemInfoCt>
  );
};

const InputCt = styled.form`
  margin-bottom: 30px;

  @media (max-width: 480px) {
    margin-bottom: 0;
  }
`;

const ResultSection = styled.section`
  width: 100%;
  height: 100%;
  margin-top: 30px;

  & p.empty {
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    color: #888;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vw;
    width: 100%;
  }
`;

const BoardContainer = styled.div`
  margin-top: 10px;
  border-top: 2px solid #b9b9b9;
  border-bottom: 2px solid #b9b9b9;
`;

const PagingCt = styled.div`
  width: 100%;
  text-align: center;

  margin-top: auto;
  margin-bottom: 30px;
`;

const SearchOpCt = styled.div`
  margin: 20px 0;
  & p.title {
    color: #777;
    font-weight: 600;
    margin: 10px 0;
  }

  & .optionValueCt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    & p {
      color: #888;
      & span {
        font-weight: 500;
      }
    }
  }
`;
const Desc = styled.span`
  color: #363636;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.5px;
  word-break: keep-all;
  /* margin-bottom: 30px; */
  @media (max-width: 480px) {
    text-align: center;
    margin: 10px 0 20px;
  }
`;

const BotTextBox = styled.div`
  display: flex;

  & div.answer {
    white-space: pre-line;
    padding: 10px;
    line-height: 26px;
    border-radius: 0 10px 10px 10px;
    background: rgba(255, 137, 42, 0.23);

    max-height: 400px;
    overflow-y: auto;
    color: #555454;
    cursor: default;

    & span.highLight {
      /* font-size: 1.05rem; 
      font-weight: 600;
      color: #d5d5d5;
      padding: 0.5px;
      margin: 0 1px;
      word-break: keep-all; */
    }

    @media (max-width: 480px) {
      font-size: 15px;
    }
  }
`;

const BotImg = styled.img`
  flex: 0 0 auto;
  width: 45px;
  height: 45px;
  /* transform: rotate(90deg); */
  transform: scaleX(-1);
  margin-right: 5px;
`;

const BotText = styled.pre`
  white-space: pre-line;
  padding: 10px;
  line-height: 26px;
  border-radius: 0 10px 10px 10px;
  background: rgba(255, 137, 42, 0.23);

  max-height: 400px;
  overflow-y: auto;
  color: #555454;
  cursor: default;
  @media (max-width: 480px) {
    font-size: 15px;
  }
`;

const PagingBtnComponent = styled.button`
  border: 1px solid #ff892a;
  border-radius: 5px;
  margin: 10px 5px;
  padding: 5px 8px;

  color: #888;
  border: 1px solid #888;

  width: 45px;
  height: 35px;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &.current {
    border: 1px solid #ff892a;
    background-color: #ff892a;
    color: #fff;
  }

  &.next {
  }

  &.prev {
  }
`;

const SearchItemInfoCt = styled.div`
  margin-top: 20px;
  text-align: right;
  @media (min-width: 481px) {
    display: flex;
    justify-content: end;
    align-items: end;
  }
`;
const SearchItemInfoTotal = styled.p`
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;

  padding-right: 5px;

  &:first-child {
    border-right: 1px solid #dcdcdc;
  }
  &:last-child {
    border-right: 0;
    margin-right: 0;
  }
  & span.num {
    font-weight: 500;
    margin-left: 5px;
  }
`;

const CheckCt = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;
  input[type="checkbox"] {
    flex: 0 0 auto;
  }
  p.title {
    margin-right: auto;
  }
  label {
    font-size: 14px;
    color: #ff892a;
  }
`;

const SearchOptCt = styled.div`
  display: flex;
`;

const TextOptnBtnCt = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  flex: 1 0 35%;
  padding-bottom: 10px;

  .unitCt {
    display: flex;
    align-items: center;
    margin: 5px 0;
    .title {
      color: #777;
      margin-right: 10px;
      width: 80px;
      letter-spacing: -1px;
      font-size: 14px;
    }
  }
  .toggle {
    width: 40px;
    height: 20px;
    background: #ffe7ca;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    &.active {
      background-color: #b9f4bb;
    }
  }

  .circle {
    width: 20px;
    height: 20px;
    background: #f8b400;
    border-radius: 50%;
    position: absolute;
    /* top: 50%; */
    top: 0%;
    transition: transform 0.3s;
  }

  /* 활성화 스타일 */
  .active .circle {
    /* top: 50%; */
    transform: translateX(25px) translateY(0%);
    background: #4caf50; /* 색상 변경 */
    box-shadow: 0px 1px 9px 1px rgba(0, 0, 0, 0.11);
  }
`;
