import { ErrorCatch } from "api/ErrorCatch.js";
import BotInfoTable from "components/templates/dashboard/BotInfoTable.js";
import { memo, useContext, useEffect, useState } from "react";
import Select from "react-select"; // Select Component import시, "react-select/base" 로 하면 에러가 난다. ( 추가 설정이 필요한 것으로 추정 )
import styled from "styled-components";
import { chatContext } from "utils/providers/ChatContextProvider.js";
import { signAppContext } from "utils/providers/SignContextProvider.js";
import { userChatBotContext } from "utils/providers/UserChatBotContextProvider.js";
import botApi from "../../../api/botApi.js";
import uuid from "react-uuid";
import { DASH_HOME, FlexColumnBox } from "./dashboardStyle.js";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider.js";
import ChatBotSelect from "../../../utils/ChatBotSelect.js";
import { AlertPop2, AlertPop3 } from "components/templates/popup/AlertPop.js";
import ChatEmpty from "./AsstEmpty.js";

export default function AsstManage() {
  const { handleSignOutApp } = useContext(signAppContext);
  const { userChatBotList, setUserChatBotList, getUserChatBotList } =
    useContext(userChatBotContext);
  const { chatBotData, setChatBotData, userPlan } = useContext(chatContext);
  const { isMobile } = useContext(isMobileContext);
  const [useUserList, setUseUserList] = useState([]);

  // popupState
  const [popState, setPopState] = useState(false);
  const [popMessage, setPopMessage] = useState("");
  const [popStateExpire, setPopStateExpire] = useState(false);

  const modifyBotInfo = async (botInfoParams) => {
    try {
      const { status, data } = await botApi.botInfoModify(botInfoParams);

      if (status === 200) {
        const botInfo = JSON.parse(data.botInfo);
        setUserChatBotList((prev) =>
          prev.map((item) => {
            if (item.botUid === botInfo.botUid) {
              botInfo.label = item.botName;
              return Object.assign({}, item, botInfo);
            }
            return item;
          }),
        );
        setChatBotData(botInfo);
        setPopMessage("수정 되었습니다.");
        setPopState((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
      ErrorCatch(error, handleSignOutApp);
    }
  };

  const getUseUserListFunc = async () => {
    try {
      const { status, data } = await botApi.getAsstUsesUserList(
        chatBotData.botUid,
      );
      setUseUserList([]);
      if (status === 200) {
        if (status === 200) {
          const resultData = JSON.parse(data.usesUserList);
          if (resultData && resultData.length > 0) {
            setUseUserList(resultData);
          } else {
            console.log("getUseUserListFunc: ", "ZERO!!");
            setUseUserList([]);
          }
        } else {
          console.log("getUseUserListFunc: ", "ZERO!!");
          setUseUserList([]);
        }
      }
    } catch (error) {
      console.log(error);
      ErrorCatch(error, handleSignOutApp);
    }
  };

  const addUseUserFunc = async (email) => {
    try {
      const aubUid = uuid();
      const { status, data } = await botApi.addAsstUsesUser(
        aubUid,
        chatBotData.botUid,
        email,
      );

      if (status === 200) {
        const data = {
          dataUid: aubUid,
          botUid: chatBotData.botUid,
          memberUid: "",
          email,
        };
        console.log(">>>>>>> add data:", data);
        setUseUserList((prevUserList) => [...prevUserList, data]);
        setPopMessage("추가 되었습니다.");
        setPopState((prev) => !prev);
      } else {
        setPopMessage("미가입 된 이메일 주소입니다. " + email);
        setPopState((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
      ErrorCatch(error, handleSignOutApp);
    }
  };

  const delUseUserFunc = async (dataUid) => {
    // aubUid >>  dataUid 로 사용하고 있음.
    try {
      const { status, data } = await botApi.deleteAsstUsesUser(dataUid);
      if (status === 200) {
        setUseUserList(useUserList.filter((item) => item.dataUid !== dataUid));
      }
    } catch (error) {
      console.log(error);
      ErrorCatch(error, handleSignOutApp);
    }
  };

  const disposalBot = async () => {
    try {
      const { status } = await botApi.disposalBot(
        chatBotData.botUid,
        chatBotData.indexName,
      );
      if (status === 200) {
        setPopMessage("정상적으로 삭제 했습니다.");
        setPopState((prev) => !prev);
        setChatBotData(null);
        sessionStorage.removeItem("botData"); // 당연하게도 현재 세션의 봇은 삭제한 봇일거니..
        getUserChatBotList();
      }
    } catch (error) {
      ErrorCatch(error, handleSignOutApp);
    }
  };

  useEffect(() => {
    if (!chatBotData) return;
    getUseUserListFunc();
  }, [chatBotData]);

  if (!chatBotData || !userChatBotList) {
    return (
      <DASH_HOME.Container>
        <ChatEmpty>아직 생성 된 챗봇이 없습니다.</ChatEmpty>
      </DASH_HOME.Container>
    );
  }

  return (
    <DASH_HOME.Container>
      {/* <Title>챗봇 관리</Title> */}
      <DASH_HOME.ContentCol style={{ height: "auto" }}>
        <SelectCt>
          <FlexColumnBox
            style={
              !isMobile
                ? {
                    marginBottom: "20px",
                    width: "70%",
                  }
                : {
                    marginBottom: "20px",
                    width: "100%",
                  }
            }
          >
            <ChatBotSelect />
          </FlexColumnBox>
        </SelectCt>

        {/* botInfoTable이 기존에 받아오던 api 정보가 없어서 하드코딩으로 대체하여 ui 표현 */}
        <div style={{ width: "100%" }}>
          <BotInfoTable
            botData={chatBotData}
            userPlan={userPlan}
            modifyFunc={modifyBotInfo}
            deleteFunc={disposalBot}
            addUseUserFunc={addUseUserFunc}
            delUseUserFunc={delUseUserFunc}
            useUserList={useUserList}
            setPopState={setPopState}
            setPopMessage={setPopMessage}
            setPopStateExpire={setPopStateExpire}
          />
        </div>

        {popState &&
          (popStateExpire ? (
            <AlertPop3
              text={popMessage}
              popState={popState}
              setPopState={setPopState}
              setPopStateExpire={setPopStateExpire}
            />
          ) : (
            <AlertPop2
              text={popMessage}
              popState={popState}
              setPopState={setPopState}
            />
          ))}
      </DASH_HOME.ContentCol>
    </DASH_HOME.Container>
  );
}

const SelectCt = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  /* 700임시 */
  @media (max-width: 700px) {
    width: 100%;
    margin: 10px auto 50px;
  }
  @media (max-width: 480px) {
    margin-bottom: 10px;
  }
`;

const StyledSelect = styled(Select)`
  & .Select {
    &__control {
      display: flex;
      align-items: center;
      border-radius: 0;
      border: none;
      height: 100%;

      &--is-focused {
        box-shadow: 0 0 1px darkgray;
      }

      &--menu-is-open {
        border-color: transparent;
        box-shadow: none;

        :hover {
          border-color: transparent;
        }

        svg {
          color: white;
        }
      }
    }

    &__menu {
      margin-top: 15px;
      top: calc(100% - 2px);
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);

      border-top: 0;

      &-list {
        padding: 0;
        justify-content: center;
        align-items: center;
      }
    }

    &__option {
      height: 40px;
      display: "flex";
      align-items: "center";
      padding: 9px 0px 9px 15px;
      border-top: 1px solid gray;
      color: black;
      background-color: white;

      &--is-selected {
        font-weight: bold;
      }

      &--is-focused {
        box-shadow: none;
        background-color: darkgray;
      }
    }
  }
`;

const PagingCt = styled.div`
  width: 100%;
  text-align: center;

  margin: 30px 0;
`;
const PagingBtn = memo((props) => {
  const { text, ...otherProps } = props ?? {};
  return <PagingBtnComponent {...otherProps}>{text}</PagingBtnComponent>;
});
export const PagingBtnComponent = styled.button`
  //border: 1px solid #ff892a;
  border-radius: 5px;
  margin: 5px;
  padding: 5px 8px;

  color: #888;
  border: 1px solid #888;

  width: 45px;
  height: 35px;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &.current {
    border: 1px solid #ff892a;
    background-color: #ff892a;
    color: #fff;
  }

  &.next {
  }

  &.prev {
  }
`;
