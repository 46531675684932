import { useEffect, useState } from "react";
import NormalButton from "components/atomic/NormalButton2";
import styled from "styled-components";
import Loader from "react-spinners/HashLoader";
import KnowItemBox from "./KnowItemBox";
import iconMenu from "../../../assets/images/icon_moblieMenu.svg";
import {
  DropMenu,
  DropMenuBg,
  HeaderContainerComplex,
  MenuBtn,
} from "../chat/chatStyle";

export default function CombineKnowView({ props }) {
  const {
    knowLoading,
    tokens,
    knowDataCount,
    knowDataList,
    useNewLine,
    setUseNewLine,
    useSentence,
    setUseSentence,
  } = props;

  const [expandCollapse, setExpandCollapse] = useState(0);
  const [dropState, setDropState] = useState(false);

  // 하이라이트 개행 버튼 관리 함수
  const handleTextState = (e) => {
    if (e.target.id === "enter") {
      setUseNewLine(!useNewLine);
    } else if (e.target.id === "useSentence") {
      setUseSentence(!useSentence);
    }
  };

  // drop menu 함수
  const handleDropMenu = (e) => {
    setDropState((prev) => !prev);
  };

  if (knowLoading) {
    return (
      <Section>
        <Header>
          <p className="viewTitle">지식 뷰</p>
        </Header>
        <ResultSection className="loader">
          <Loader color={"#000000"} loading={true} size={100} />
        </ResultSection>
      </Section>
    );
  }

  return (
    <>
      <Section>
        {/* 헤더영역 */}
        <HeaderContainerComplex>
          <div className="complexHeader">
            <p className="viewTitle ">지식 뷰</p>
          </div>
          <MenuBtn
            className={""}
            onClick={handleDropMenu}
            icon={iconMenu}
          ></MenuBtn>

          {dropState && (
            <DropMenuBg className={"small knowVeiw"}>
              <DropMenu className={dropState ? "drop active" : "drop"}>
                <div className="header">
                  <p className="">지식 뷰 설정</p>
                  <button onClick={handleDropMenu}></button>
                </div>
                <SearchOptCt>
                  <TextOptnBtnCt>
                    <div className="unitCt">
                      <p className="title">줄 바꿈</p>
                      <div
                        id="enter"
                        className={useNewLine ? "toggle active" : "toggle"}
                        onClick={handleTextState}
                      >
                        <div
                          id="enter"
                          onClick={handleTextState}
                          className="circle"
                        ></div>
                      </div>
                    </div>
                    <div className="unitCt">
                      <p className="title">문장모드</p>
                      <div
                        id="useSentence"
                        onClick={handleTextState}
                        className={useSentence ? "toggle active" : "toggle"}
                      >
                        <div
                          id="useSentence"
                          onClick={handleTextState}
                          className="circle"
                        ></div>
                      </div>
                    </div>
                  </TextOptnBtnCt>
                </SearchOptCt>
              </DropMenu>
            </DropMenuBg>
          )}
        </HeaderContainerComplex>
        {/* 바디영역 */}
        <ResultSection>
          {/* 검색 상단 바  */}
          <SearchItemInfoCt>
            <>
              <NormalButton
                className="open"
                callBackFunc={() => {
                  setExpandCollapse(1); // 1: 모두펼침, 2: 모두닫힘
                }}
                buttonTitle={
                  <>
                    <i className="ri-zoom-in-line"></i> 모두 펼침
                  </>
                }
                buttonClass={"cOrange small"}
              ></NormalButton>

              <NormalButton
                className="close"
                callBackFunc={() => {
                  setExpandCollapse(2); // 1: 모두펼침, 2: 모두닫힘
                }}
                buttonTitle={
                  <>
                    <i className="ri-zoom-out-line"></i> 모두 닫힘
                  </>
                }
                buttonClass={"cOrangeReverse small"}
              ></NormalButton>
            </>
            <SearchItemInfoTotal>
              총 개수:
              <span className="num">{knowDataCount ?? 0}</span>
            </SearchItemInfoTotal>
          </SearchItemInfoCt>
          {/* 검색 상단 정보와 리스트 분리 바 */}
          <BoardContainer>
            {knowDataList.length <= 0 ? (
              <p className="empty">
                <i className="ri-alert-fill"></i> 검색 된 지식이 없습니다
              </p>
            ) : (
              knowDataList.map((itemData, index) => {
                return (
                  // 지식 반복문 진행.
                  <KnowItemBox
                    data={itemData}
                    useNewLine={useNewLine}
                    tokens={tokens}
                    expandCollapse={expandCollapse}
                    key={"searchItem_" + index}
                    keyName={"searchItem_" + index}
                  />
                );
              })
            )}
          </BoardContainer>
        </ResultSection>
      </Section>
    </>
  );
}

const Section = styled.section`
  height: 100%;
  /* background-color: #fff; */

  position: relative;
`;

const Header = styled.header`
  border-bottom: 1px solid #dcdcdc;
  background-color: #fff;
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */

  & p {
    flex: 1 0 auto;
    /* width: 100%; */
    padding: 14px 14px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #1a1a1a;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & p.viewTitle {
    color: #1a1a1a;
  }
  & button.closeBtn {
    margin-right: 10px;
  }
`;

const ResultSection = styled.div`
  width: 100%;
  /* height: 100%; */
  height: calc(90vh - 120px) !important;
  &.loader {
    /* background-color: tomato; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & p.empty {
    text-align: center;
    font-size: 18px;
    line-height: 40px;
    color: #888;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 10vw; */
    margin-top: 20%;

    word-break: keep-all;

    flex-wrap: wrap;

    & i {
      margin-right: 5px;
      color: #ff892a;
      font-size: 22px;
    }
  }
`;

const SearchItemInfoCt = styled.div`
  margin-top: 20px;
  margin-right: 10px;
  text-align: right;
  @media (min-width: 481px) {
    display: flex;
    justify-content: end;
    align-items: end;
    flex-wrap: wrap;
  }

  & button {
    margin: 2.5px 5px;
  }
`;

const SearchItemInfoTotal = styled.p`
  display: inline-block;
  font-size: 14px;
  color: #666;
  margin-right: 10px;

  padding-left: 10px;
  padding-right: 5px;
  padding: 10px 4px 4px;

  &:first-child {
    border-right: 1px solid #dcdcdc;
  }
  &:last-child {
    border-right: 0;
    margin-right: 0;
  }
  & span.num {
    font-weight: 500;
    margin-left: 5px;
  }
`;

const BoardContainer = styled.div`
  margin-top: 10px;
  border-top: 2px solid #b9b9b9;
  border-bottom: 2px solid #b9b9b9;
  /* height: 55vh; */
  height: 100%;
  /* height: calc(100vh - 10px) !important; */
  padding: 5px 20px 5px 5px;

  overflow-x: hidden;
  overflow-y: auto;
`;
const SearchOptCt = styled.div`
  flex: 0 1;
`;

const TextOptnBtnCt = styled.div`
  margin-right: 10px;
  display: flex;

  padding: 5px 0;
  .unitCt {
    padding: 0 10px;
    display: flex;
    align-items: center;
    margin: 2.5px 0;

    &:first-child {
      margin-left: 20px;
    }
    .title {
      color: #000000b8;
      font-weight: 400;
      width: auto;
      max-width: 70px;
      margin-right: 8.5px;
      letter-spacing: -1px;
      /* font-size: 13px; */
      font-size: 1rem;
      padding: 0;
      cursor: default;

      display: flex;
      align-items: center;
    }
  }
  .toggle {
    width: 40px;
    height: 20px;
    background: #ffe7ca;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    &.active {
      background-color: #b9f4bb;
    }
  }

  .circle {
    width: 20px;
    height: 20px;
    background: #f8b400;
    border-radius: 50%;
    position: absolute;
    /* top: 50%; */
    top: 0%;
    transition: transform 0.3s;
  }

  /* 활성화 스타일 */
  .active .circle {
    /* top: 50%; */
    transform: translateX(25px) translateY(0%);
    background: #4caf50; /* 색상 변경 */
    box-shadow: 0px 1px 9px 1px rgba(0, 0, 0, 0.11);
  }
`;
