import { useEffect, useState } from "react";
import styled from "styled-components";
import IconDetailArrow from "../../../assets/images/icon_arrowDownBlack.svg";
import { chatMiddle } from "../../../utils/chatUtil.js";
import NormalButton from "../../atomic/NormalButton2.js";
import TextArea from "../../atomic/TextArea.js";
import { FlexBox } from "../../pages/dashboard/dashboardStyle.js";
import { InputForm } from "./BotInfoTable.js";

export default function BoardItem(props) {
  const {
    data,
    keyName,
    refetch,
    chatbotData,
    removeItem,
    setPopMessage,
    setPopState,
  } = props ?? {};
  const [toggleBoard, setToggleBoard] = useState(false);
  const isAnswered = (data?.answer ?? "").length > 0;
  const [isModify, setIsModify] = useState(false);
  const [isAnswerModify, setIsAnswerModify] = useState(false);
  const [subject, setSubject] = useState(data.subject);
  const [answer, setAnswer] = useState(data?.answer ?? "");
  const [detail, setDetail] = useState(data?.detail ?? "");
  const isAdmin = false;

  useEffect(() => {
    setToggleBoard(false);
    setSubject(data.subject);
    setDetail(data.detail);
    setAnswer(data?.answer ?? "");
  }, [data]);

  const cancel = () => {
    setSubject(data.subject);
    setDetail(data.detail);
    setIsModify((prev) => !prev);
  };
  const modify = async () => {
    if (isModify) {
      if (subject?.trim().length === 0) {
        setPopMessage("제목을 입력해 주세요");
        setPopState((prev) => !prev);
        return;
        // return alert("제목을 입력해 주세요.");
      }

      if (subject?.trim().length > 200) {
        setPopMessage("제목란의 최대 글자수는 200자 입니다.");
        setPopState((prev) => !prev);
        return;
        // return alert("제목을 입력해 주세요.");
      }

      if (detail?.trim().length === 0) {
        setPopMessage("내용을 입력해 주세요");
        setPopState((prev) => !prev);
        return;
        // return alert("내용을 입력해 주세요.");
      }

      if (detail?.trim().length > 500) {
        setPopMessage("내용란의 최대 글자수는 500자 입니다.");
        setPopState((prev) => !prev);
        return;
        // return alert("제목을 입력해 주세요.");
      }

      // api logic ...

      const result = await chatMiddle(
        {},
        "updateQnaDetail",
        data.qnaUid,
        subject,
        detail,
      );
      console.log("result", result);
    }

    setIsModify((prev) => !prev);
  };
  // const modifyAnswer = async () => {
  //   if (isAnswerModify) {
  //     if (subject?.trim().length === 0) {
  //       return alert("제목을 입력해 주세요.");
  //     }

  //     if (detail?.trim().length === 0) {
  //       return alert("대답을 입력해 주세요.");
  //     }

  //     // api logic ...

  //     const result = await chatMiddle(
  //       {},
  //       "updateQnaAnswer",
  //       data.qnaUid,
  //       answer
  //     );
  //     console.log("result", result);
  //   }

  //   setIsAnswerModify((prev) => !prev);
  // };

  const deleteItem = async () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      console.log("delete...");
      // api logic...
      const result = await chatMiddle({}, "deleteQna", data.qnaUid);
      removeItem("qnaUid", data.qnaUid);
    }
  };

  return (
    <BoardItemCt key={keyName}>
      {/* item 기본 div */}
      <BoardItemPrev>
        {!isModify ? (
          <>
            {/* 수정 버튼 누르기 전, 기존 보이는 부분 */}
            <ItemContents>{subject}</ItemContents>
          </>
        ) : (
          <>
            {/* 수정 버튼 누른 후 보이는 화면 ( input box */}
            <InputForm
              target={"name"}
              value={subject}
              setValue={setSubject}
              formClass={"Qna"}
            />
          </>
        )}
        <ItemDate>{data.createAt}</ItemDate>
        <ItemState>{isAnswered ? "답변완료" : "대기중"}</ItemState>
        <DetailBtn
          className={toggleBoard ? "active" : ""}
          onClick={(e) => {
            setToggleBoard((prev) => !prev);
          }}
          icon={IconDetailArrow}
        ></DetailBtn>
      </BoardItemPrev>
      {/* 토글버튼 통제 div */}
      <DetailContainer className={toggleBoard ? "active" : ""}>
        {!isModify ? (
          <>
            {/* 수정 버튼 누르기 전, 기존 보이는 부분 */}
            <DetailQ>{detail}</DetailQ>
          </>
        ) : (
          <>
            {/* 수정 버튼 누른 후 보이는 화면 ( input box */}
            <TextArea value={detail} setValue={setDetail} />
          </>
        )}
        {!isAnswerModify ? (
          <>
            {/* 대답하기 버튼 누르기 전, 기존 보이는 부분 */}
            <DetailA className={answer.trim("") === "" && "empty"}>
              {answer.trim("") === "" ? "아직 등록된 답변이 없습니다" : answer}
            </DetailA>
          </>
        ) : (
          <>
            {/* 대답하기 버튼 누른 후 보이는 화면 ( input box */}
            <TextArea value={answer} setValue={setAnswer} />
          </>
        )}
        <FlexBox className="btnCt">
          {!isModify ? (
            <NormalButton
              buttonClass={"cDel small"}
              buttonTitle={"삭제"}
              callBackFunc={deleteItem}
            />
          ) : (
            <NormalButton
              buttonTitle={"취소"}
              callBackFunc={cancel}
              buttonClass={"cDel small"}
            />
          )}
          {!isAnswered && !isAdmin && (
            <NormalButton
              buttonTitle={isModify ? "적용" : "수정"}
              callBackFunc={modify}
              buttonClass={"cOrange small"}
            />
          )}
        </FlexBox>
      </DetailContainer>
    </BoardItemCt>
  );
}

const BoardItemCt = styled.div`
  border-bottom: 1px solid #b9b9b9;

  /* &:hover {
    background: #f2f3f8;
    cursor: pointer;
  } */

  &:last-child {
    border-bottom: none;
  }
`;

const BoardItemPrev = styled.div`
  display: flex;
  align-items: center;
  padding: 13.5px 5.5px 13.5px 10px;

  @media (min-width: 481px) {
    & input {
      flex: 0 1 auto;
      width: 500px;
      min-width: 300px;
      margin-right: 100px;
    }
  }
  @media (max-width: 488px) {
    & input {
      flex: 0 0 auto;
      width: 150px;
      min-width: 100px;
      margin-right: 10px;
    }
  }
`;

const ItemState = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff6161;

  padding: 6px;
  margin-right: 30px;

  min-width: 58px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;

  &.wait {
    background: #c0c0c0;
  }

  @media (max-width: 480px) {
    margin-right: 5px;
    flex-shrink: 0;
  }
`;
const ItemContents = styled.p`
  color: #363636;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.14px;

  @media (min-width: 481px) {
    width: 500px;
    min-width: 300px;
    margin-right: 100px;

    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media (max-width: 480px) {
    margin-right: 30px;
    width: 100%;
    max-width: 130px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
  }
`;
const ItemDate = styled.p`
  color: #555454;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
  margin-right: auto;

  @media (min-width: 481px) {
    width: 80px;
    min-width: 60px;
  }

  @media (max-width: 480px) {
    margin-right: 20px;
    flex-shrink: 0;
  }
`;
const DetailBtn = styled.button`
  width: 30px;
  height: 30px;
  background: url(${({ icon }) => icon}) no-repeat;
  background-size: contain;
  background-position: center;

  &.active {
    transform: rotate(180deg);
  }
`;

const DetailContainer = styled.div`
  height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: 0.5s;

  &.active {
    height: auto;
  }

  & button.answer {
    font-size: 14px;
    margin: 10px 0;
    margin-left: auto;
    line-height: 18px;
    padding: 9.5px 12.5px;
  }

  & div.btnCt {
    margin: 10px 0;
    justify-content: end;

    & button {
      margin-left: 5px;
      font-size: 16px;
      height: 40px;
      padding: 5px 10px;
    }
  }
`;
const DetailQ = styled.pre`
  padding: 29.5px 26px;
  background: #f7f9ff;
  margin-bottom: 5px;
  word-break: break-all;
  white-space: break-spaces;
`;
const DetailA = styled.pre`
  word-break: break-all;
  white-space: break-spaces;
  padding: 29.5px 26px;
  margin-top: 5px;
  background: rgba(255, 137, 42, 0.14);

  &.empty {
    color: #555454;
  }
`;
