import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useCallback, useContext, useState } from "react";
import styled from "styled-components";

import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import StorageIcon from "@mui/icons-material/Storage";
import VerticalAlignBottomOutlinedIcon from "@mui/icons-material/VerticalAlignBottomOutlined";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import useSearch from "utils/hooks/useSearch.js";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import LinkIcon from "@mui/icons-material/Link";
import { Button, Chip } from "@mui/material";
import { debounce } from "lodash";
import { popupContext } from "utils/providers/PopupProvider.js";

// 데이터 타입 정의
const checkType = (item) => {
  if (item === 20) {
    return "Folder";
  } else if (item === 0) {
    return "URL";
  } else if (item === 1) {
    return "File";
  } else if (item === 2) {
    return "DB";
  }
  return "";
};

const checkTypeReturnIcon = (item) => {
  if (item === 20) {
    return <FolderIcon className="" fontSize="small" />;
  } else if (item === 0) {
    return <LinkIcon className="" fontSize="small" />;
  } else if (item === 1) {
    return <InsertDriveFileIcon className="" fontSize="small" />;
  } else if (item === 2) {
    return <Inventory2Icon className="" fontSize="small" />;
  }
  return "";
};

export default function AsstKnowledgeDataGridMobile(props) {
  const { knowledgeList, crawlInfo, funcProps, dataProps } = props;

  const {
    toggleInKnowledge,
    handleDeleteKnow,
    handleFolderName,
    clickInfoOrDownBtn,
  } = funcProps;

  const {
    setParentUid,
    parentUid,
    goBack,
    setCurrentPage,
    setSearchValue,
    searchValue,
    infiniteList,

    setCurrentTarget,
  } = dataProps;

  const handleActiveToggle = useCallback(
    debounce((dataInfo) => toggleInKnowledge(dataInfo), 500),
    [],
  );

  const handleCurrentPath = useCallback(() => {
    let defaultPosition = ["home"];

    if (knowledgeList && knowledgeList?.length <= 0) {
      if (parentUid !== null) {
        defaultPosition.push(parentUid?.dataName);
        return defaultPosition;
      } else {
        return defaultPosition;
      }
    } else {
      let breadCrumb = knowledgeList[0]?.currentPath.split("/");
      breadCrumb = breadCrumb?.slice(1, breadCrumb.length);
      return breadCrumb;
    }
  }, [knowledgeList]);

  return (
    <MainWrapper>
      <TableControlCt className="">
        {/* <BreadCrumbCt>
          {handleCurrentPath()?.map((item, index) => {
            return (
              <MyChip
                className={
                  index === handleCurrentPath().length - 1 ? "current" : ""
                }
                variant="outlined"
                key={item + index}
              >
                {item}/
              </MyChip>
            );
          })}
        </BreadCrumbCt> */}
        <SearchComp
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          goBack={goBack}
        />

        {parentUid !== null && (
          <Button
            sx={{ width: "50px", borderRadius: "100px" }}
            variant="contained"
            color="warning"
            onClick={() => {
              goBack();
            }}
          >
            <KeyboardBackspaceIcon />
          </Button>
        )}
        <DataContainer>
          {infiniteList?.length === 0 || infiniteList[0]?.total <= 0 ? (
            <>{CustomNoRowsOverlay()}</>
          ) : (
            infiniteList.map((item, index) => {
              return item.data.map((data, dataIdx) => {
                return (
                  <DataCardItem
                    key={dataIdx}
                    dataInfo={data}
                    setCurrentPage={setCurrentPage}
                    setParentUid={setParentUid}
                    setCurrentTarget={setCurrentTarget}
                    apiProps={{
                      toggleInKnowledge,
                      handleDeleteKnow,
                      handleFolderName,
                      clickInfoOrDownBtn,
                      handleActiveToggle,
                    }}
                  />
                );
              });
            })
          )}
        </DataContainer>
      </TableControlCt>
    </MainWrapper>
  );
}

function CustomNoRowsOverlay() {
  return (
    <EmptyDataWrapper>
      <ErrorOutlineIcon color="warning" fontSize="medium" sx={{ mr: 1 }} /> 지식
      데이터를 찾을 수 없습니다.{" "}
    </EmptyDataWrapper>
  );
}

function DataCardItem(props) {
  const { dataInfo, apiProps, setCurrentPage, setParentUid, setCurrentTarget } =
    props;

  const {
    toggleInKnowledge,
    handleDeleteKnow,
    handleFolderName,
    clickInfoOrDownBtn,
    handleActiveToggle,
  } = apiProps;

  const { showTextPop } = useContext(popupContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTitleLength = (str) => {
    console.log(str);
    if (str.length >= 15) {
      let computedStr = str.slice(0, 15);
      return computedStr + "...";
    } else {
      return str;
    }
  };

  // 활성화 상태 정의
  const checkActiveSign = (info) => {
    // activatedFileCount
    // fileCount

    if (info.dataType === 20) {
      if (info.activatedFileCount === 0) {
        return <RadioButtonUncheckedIcon color="disabled" />;
      } else if (info.activatedFileCount === info.fileCount) {
        return <RadioButtonCheckedIcon color="success" />;
      } else {
        return <RadioButtonCheckedIcon color="warning" />;
      }
    } else {
      if (info.dataStatus === 1) {
        return <RadioButtonCheckedIcon color="success" />;
      } else {
        return <RadioButtonUncheckedIcon color="disabled" />;
      }
    }
  };

  const handleTitleClick = (data) => {
    if (data.dataType === 20) {
      setCurrentPage(0);
      setParentUid(data);
      console.log("datainfo,", data);
      console.log("setParentUid,", setParentUid);
      return;
    } else {
      return;
    }
  };

  return (
    <MyCard
      className={anchorEl !== null ? "active" : ""}
      variant="outlined"
      sx={{
        flex: "0 1 47%",
        width: "45%",
        margin: "5px 4px",
        resize: "horizontal",
        zIndex: 0,
        gap: "0.2rem 1rem",
        padding: "7px 8px",
      }}
    >
      <CardContent sx={{ padding: "3px" }}>
        <ItemTitleBox
          onClick={() => {
            handleTitleClick(dataInfo);
          }}
        >
          <Title className="titleBox" level="title-md">
            <span className={checkType(dataInfo.dataType) + " iconSpan"}>
              {checkTypeReturnIcon(dataInfo.dataType)}
            </span>
            <span className="text">{handleTitleLength(dataInfo.dataName)}</span>
          </Title>
        </ItemTitleBox>
        <Divider sx={{ mb: 0.5 }} />
      </CardContent>

      <CardContent sx={{ padding: 0 }}>
        <MyIconButton
          sx={{ mr: 1 }}
          onClick={(e) => {
            e.preventDefault();
            if (dataInfo.dataType === 20 && dataInfo.fileCount === 0) {
              showTextPop("활성화 할 폴더의 하위 지식이 없습니다.");
            } else {
              toggleInKnowledge && handleActiveToggle(dataInfo);
            }
            handleActiveToggle(dataInfo);
          }}
          className="use"
          variant="outlined"
          color="default"
        >
          {checkActiveSign(dataInfo)}
        </MyIconButton>

        <MyIconButton
          onClick={() => {
            if (dataInfo.fileCount === 0 && dataInfo.dataType === 20) {
              return showTextPop(
                "빈 폴더를 선택할 경우 다운로드를 진행할 수 없습니다.",
              );
            }
            clickInfoOrDownBtn(dataInfo);
            handleMenuClose();
          }}
          className="download"
          variant="soft"
          color="default"
        >
          <VerticalAlignBottomOutlinedIcon color="primary" />
        </MyIconButton>

        <IconButton
          onClick={handleMenuClick}
          id="fade-button"
          aria-controls={menuOpen ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? "true" : undefined}
          variant="soft"
          color=""
          sx={{ ml: "auto" }}
        >
          <MoreVertIcon />
        </IconButton>
      </CardContent>
      <DataCardMenu
        dataInfo={dataInfo}
        anchorEl={anchorEl}
        menuOpen={menuOpen}
        handleMenuClick={handleMenuClick}
        handleMenuClose={handleMenuClose}
        handleDeleteKnow={handleDeleteKnow}
        handleFolderName={handleFolderName}
        clickInfoOrDownBtn={clickInfoOrDownBtn}
        setCurrentTarget={setCurrentTarget}
      />
    </MyCard>
  );
}

function DataCardMenu({
  dataInfo,
  anchorEl,
  menuOpen,
  handleMenuClose,
  handleDeleteKnow,
  handleFolderName,
  clickInfoOrDownBtn,
  setCurrentTarget,
}) {
  const { showTextPop } = useContext(popupContext);

  const crawlInfo = (botData) => {
    if (!botData.crawlPage || !botData.crawlSize) return;
    const pageStr = botData.crawlPage > 1 ? `${botData.crawlPage} page /` : "";
    return `${pageStr} ${botData.crawlSize.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} byte`;
  };

  return (
    <div>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        TransitionComponent={Fade}
      >
        {dataInfo.dataType === 20 && (
          <MenuItem
            onClick={() => {
              handleFolderName(dataInfo);
              setCurrentTarget(dataInfo);
              handleMenuClose();
            }}
          >
            <BorderColorIcon
              fontSize="small"
              sx={{ mr: 1, color: "rgba(0, 0, 0, 0.26)" }}
            />{" "}
            이름 수정
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            let size = crawlInfo(dataInfo);
            showTextPop(
              <>
                <span level="body-sm">지식이름 : {dataInfo.dataName}</span>
                <br />
                <span level="body-sm">
                  지식타입 : {checkType(dataInfo.dataType)}
                </span>
                <br />
                <span level="body-sm">
                  지식크기 : {size !== undefined ? size : "미분류"}
                </span>
              </>,
            );
            // handleDeleteKnow(dataInfo);
            handleMenuClose();
          }}
        >
          <InfoOutlinedIcon
            fontSize="small"
            sx={{ mr: 1, color: "rgba(0, 0, 0, 0.26)" }}
          />{" "}
          지식 확인
        </MenuItem>
        <MenuItem
          style={{ color: "#F86969" }}
          onClick={() => {
            handleDeleteKnow(dataInfo);
            handleMenuClose();
          }}
        >
          <DeleteForeverIcon fontSize="small" sx={{ mr: 1 }} /> 삭제
        </MenuItem>
      </Menu>
    </div>
  );
}

function SearchComp({ setSearchValue, searchValue, goBack }) {
  const [inputvalue, handleInputValue, resetValue] = useSearch("");
  return (
    <>
      <InputContainer>
        <div className="inputFormCt">
          <FormControl sx={{ height: "100%" }} size="md">
            <Input
              value={inputvalue}
              onKeyUp={(e) => {
                e.key === "Enter" && setSearchValue(inputvalue);
              }}
              onChange={handleInputValue}
              startAdornment={<SearchIcon style={{ color: "#CECCCC" }} />}
              sx={{
                borderRadius: "10px",
              }}
              endAdornment={
                inputvalue !== "" ? (
                  <CloseIcon
                    onClick={() => {
                      resetValue();
                    }}
                  />
                ) : (
                  <></>
                )
              }
              placeholder="지식 이름을 검색해보세요"
            />
          </FormControl>
        </div>
      </InputContainer>
      {searchValue !== null && searchValue !== "" && (
        <Button
          sx={{ width: "50px", borderRadius: "100px" }}
          variant="contained"
          color="warning"
          onClick={() => {
            goBack();
            resetValue();
          }}
        ></Button>
      )}
    </>
  );
}

const MainWrapper = styled.div`
  .MuiDataGrid-cell:focus {
    outline: none;
  }
  .MuiDataGrid-virtualScrollerContent,
  .css-axafay-MuiDataGrid-virtualScroller {
    min-height: 100px !important;
  }
  .css-6xugel,
  .css-1d6wzja-MuiButton-startIcon {
    margin: 0;
  }
`;

const TableControlCt = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 5px;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 7px 0 15px;
  & .inputFormCt {
    width: 100%;
    .MuiFormControl-root {
      flex-direction: row;
      width: 100%;
      .MuiInput-root {
        width: 100%;
      }
    }
  }
`;

const DataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Title = styled(Typography)`
  display: flex !important;
  align-items: center;

  .iconSpan {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    background: #e3efsfe;
    border-radius: 100px;

    &.Folder {
      & svg {
        color: #ffd874;
      }
    }
    &.URL {
      & svg {
        color: #3ccde1;
      }
    }
    &.File {
      & svg {
        color: #9b41ef;
      }
    }
    &.DB {
      & svg {
        color: #e8413d;
      }
    }
  }
  .text {
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 15px;

    max-height: 52px;
    min-height: 50px;
    margin-bottom: 2px;

    display: flex;
    align-items: center;
  }
`;

const EmptyDataWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */

  width: 100%;
  padding: 10px 0;
  padding-left: 10px;
  background: #dcdcdc;
  border-left: 3px solid #ff892a;
  margin: 10px 0;
`;

const MyChip = styled(Chip)`
  padding: 2.5px !important;
  padding-right: 0 !important;
  background: transparent !important;
  color: #ccc !important;
  font-weight: 500;
  font-size: 14px !important;
  border: none !important;
  border-radius: 0 !important;
  & .MuiChip-label {
    display: flex;
    align-items: center;
  }
  &.current {
    color: #5e6fd7 !important;
  }
`;

const BreadCrumbCt = styled.div`
  width: fit-content;
  margin-top: 10px;
  padding: 5px 0px;
  border-radius: 0.5rem;
`;

const ItemTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;

  &:hover {
    color: #2a8cff;
  }
  .descBox {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

const MyCard = styled(Card)`
  flex: 0 1 47%;
  width: 45%;
  margin: 5px 4px;
  resize: horizontal;
  z-index: 0;
  padding: 12px 14px 5px 14px;

  &.active {
    border: 1px solid #ff892a;
  }
`;

const MyIconButton = styled(IconButton)`
  &.use {
    border: none;
  }
  &.download {
    background: transparent;
    border: 1px solid #ccc;
    border-radius: 5px;
    & svg {
      color: #1a1a1a;
    }
  }
`;
