export class alertMessageUtil {
  static captionMessages = {
    captionComplete: "캡션이 수정되었습니다.",
    captionEmpty: "캡션은 빈 값을 넣을 수 없습니다.",
    captionLength: "캡션은 1000자를 넘을 수 없습니다.",
  };

  static htmlMessages = {
    htmlCompelete: "데이터가 수정되었습니다.",
    htmlEmpty: "데이터는 빈 값을 넣을 수 없습니다.",
    HtmlToNatureComplete: "Table Data가 자연어로 수정되었습니다.",
    NatureToHtmlComplete: "자연어가 Table Data로 수정되었습니다.",
  };

  static defaultMessages = {
    saveCompelete: "데이터가 저장되었습니다.",
    saveReCompelete: "필터링 데이터로 복원되었습니다.",
    deleteComplete: "삭제가 완료되었습니다.",
    deleteEmpty: "삭제할 데이터가 없습니다.",
    dataEmpty: "추출된 데이터가 없습니다.",

    alreadyChanged: "이미 변경된 데이터입니다.",
  };

  static getMessage(keyword) {
    return this.messages[keyword] || "알 수 없는 오류입니다.";
  }
}
