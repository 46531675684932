import { ErrorCatch } from "api/ErrorCatch.js";
import botApi from "api/botApi.js";
import InputForm from "components/molecules/InputForm.js";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { chatContext } from "utils/providers/ChatContextProvider";
import { signAppContext } from "utils/providers/SignContextProvider";
import { SubscribeContext } from "utils/providers/SubscribeContextProvider";
import { userChatBotContext } from "utils/providers/UserChatBotContextProvider";
import { DASH_HOME } from "./dashboardStyle.js";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";
import LoadingComponent from "utils/LoadingComponent";
import introImgWeb from "assets/images/IntroImg_web.png";
import ConfirmPop from "components/templates/popup/ConfirmPop.js";
import LoadingWeb from "assets/images/loadingImg_web.svg";
import {
  AlertPop2,
  AlertPopWithRadio,
} from "components/templates/popup/AlertPop.js";

import IconDocu from "../../../assets/images/iconTabDocu.svg";
import IconUrl from "../../../assets/images/iconTabFile.svg";
import IconStart from "../../../assets/images/chatBotImg_new.svg";
import NormalButton from "components/atomic/NormalButton2.js";
import { ALLOWED_EXTENSIONS } from "../../../utils/hooks/useFileHook.js";

export default function AsstCreate() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { handleSignOutApp } = useContext(signAppContext);
  const { userChatBotList, getUserChatBotList } =
    useContext(userChatBotContext);
  const [homeStatus, setHomeStatus] = useState(null);
  const [popCallback, setPopCallback] = useState({
    normal: () => {},
    cancel: () => {},
  });
  /** 펑션 모음 시작 */
  /** 마지막 챗봇 상태 확인 */
  const LastChatBotStatusCheck = async () => {
    async function getLastUserChatBotData() {
      try {
        const { status, data } = await botApi.checkHomeChatBot();
        if (status === 200) {
          const botInfo = data.botInfo ? JSON.parse(data.botInfo) : "";
          if (botInfo) {
            console.log("getLastUserChatBotData: ", botInfo);
            setHomeStatus(botInfo); // 챗봇의 상태에 대한 처리를 위한 status용
            if (botInfo.status === "01") {
              // 현재 체크한 챗봇이 성공의 경우에만 setChatBotData를 처리하여 모든 영역에서 사용 할 수 잇도록 하자.
              setChatBotData(botInfo);
            }
          }
        } else {
          console.log("getLastUserChatBotData", data);
        }
      } catch (error) {
        ErrorCatch(error, handleSignOutApp);
      }
    }
    await getLastUserChatBotData();
  };
  //
  const createBotToFile = async (file) => {
    try {
      setErrorMessage(null);
      console.log(">> Create Bot With File !!");
      const crawlRestricted = "Y";
      let params = {
        buildFilePath: file,
        dataName: file.name,
        crawlRestricted: crawlRestricted,
      };
      const { status, data } = await botApi.buildFile(params);
      const resultData = data ? data.resultMessage : "";
      if (data.resultCode === "200") {
        setCreateBotState("loading");
      } else {
        setErrorMessage(resultData);
        if (createBotState !== "complete") setCreateBotState("error");
        return false;
      }
    } catch (error) {
      setErrorMessage("error");
      if (createBotState !== "complete") setCreateBotState("error");
      ErrorCatch(error, handleSignOutApp);
    } finally {
    }
  };
  //
  const createBotToEmpty = async () => {
    try {
      setLoading(true);
      setErrorMessage(null);
      console.log(">> Create Bot With Empty !!");
      const { status, data } = await botApi.buildEmpty();
      const resultData = data ? data.resultMessage : "";
      if (data.resultCode === "200") {
        sessionStorage.removeItem("botData");
        const botInfo = data.botInfo ? JSON.parse(data.botInfo) : "";
        if (botInfo) {
          setHomeStatus(botInfo);
          setChatBotData(botInfo);
        }
        await getUserChatBotList();
        setCreateBotState("complete");
      } else {
        setErrorMessage(resultData);
        setCreateBotState("error");
      }
    } catch (error) {
      setErrorMessage("error");
      setCreateBotState("error");
      ErrorCatch(error, handleSignOutApp);
    } finally {
      setLoading(false);
    }
  };
  // url 유효 체크
  const urlValid = () => {
    if (urlInputState.trim(" ") !== "") {
      const urlRegex =
        /^(?:(http:\/\/|https:\/\/))?((?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(\/.*)?$/;
      if (!urlRegex.test(urlInputState)) {
        // alert("유효한 URL 형식이 아닙니다!");
        setPopMessage("유효한 URL 형식이 아닙니다!");
        setPopState((prev) => !prev);
      } else {
        setUrlBotCreateState((prev) => !prev);
      }
    } else if (urlInputState.trim(" ") === "") {
      setPopMessage("URL을 입력해주세요.");
      setPopState((prev) => !prev);
    }
  };

  // url 생성
  const createBotToURL = async (radioValue) => {
    let url = urlInputState;
    try {
      setErrorMessage(null);
      const crawlRestricted = "Y";
      const { status, data } = await botApi.buildWeb(
        url,
        crawlRestricted,
        radioValue,
      );
      console.log(">>>>>>> SendBuildWeb >> data: ", data);
      const resultData = data ? data.resultMessage : "";
      if (data.resultCode === "200") {
        console.log(">>>>>>> SendBuildWeb OK: ", resultData);
        setCreateBotState("loading");
      } else {
        setErrorMessage(resultData);
        if (createBotState !== "complete") setCreateBotState("error");
        return false;
      }
    } catch (error) {
      setErrorMessage("error");
      if (createBotState !== "complete") setCreateBotState("error");
      ErrorCatch(error, handleSignOutApp);
    }
  };

  const changeAddType = (type) => {
    setAddType(type);
    setUrlInputState("");
  };

  const validateFile = (file) => {
    if (!file) {
      setPopState((prev) => !prev);
      setPopMessage(`파일을 선택해 주세요.`);
      return false;
    }
    // 파일 크기 제한 설정 ( 50MB )
    const maxFileSizeBytes = 50 * 1024 * 1024;

    const fileCheckArray = ALLOWED_EXTENSIONS.map(item => item.replaceAll("\.", ""));

    const fileNameSplitList = file.name.split(".");
    const fileType = fileNameSplitList[fileNameSplitList.length - 1];
    const isValid = fileCheckArray.some((type) => {
      return fileType.includes(type);
    });
    
    const isAllowEmptyFileType = ["hwpx"].includes(fileType);
    const existFileType = isAllowEmptyFileType && file?.type === "" || !isAllowEmptyFileType && !!file?.type;

    if (!existFileType || !isValid) {
      setPopState((prev) => !prev);
      setPopMessage(`${fileType} 형태의 파일은 지원하지 않습니다.`);
      return false;
    }

    if (file.size > maxFileSizeBytes) {
      setPopState((prev) => !prev);
      setPopMessage("파일의 크기가 50MB를 초과하였습니다.");
      return false;
    }

    return true;
  };

  const changeFileHandle = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (validateFile(file)) {
      setPopMessage(file.name + "파일을 업로드 하시겠습니까? ");
      setPopCallback(() => ({
        cancel: () => {
          e.target.value = null;
        },
        normal: () => {
          createBotToFile(file);
        },
      }));
      setConfirmPopState(true);
    } else {
      e.target.value = null;
    }
  };

  const procFreeBotCount = () => {
    if (!userChatBotList) return;
    setFreeBotCount(
      userChatBotList &&
        userChatBotList.filter((botInfo) => botInfo.serviceModel === "0")
          .length,
    );
  };

  /** 펑션 모음 끝! */

  const { subscribeMessage, setSubscribeMessage } =
    useContext(SubscribeContext);
  const { chatBotData, setChatBotData } = useContext(chatContext);
  const [createBotState, setCreateBotState] = useState("home");
  const [errorMessage, setErrorMessage] = useState(null);
  const [urlInputState, setUrlInputState] = useState("");
  const [freeBotCount, setFreeBotCount] = useState(0);
  const { isLoggedIn } = useContext(signAppContext);

  // popupState
  const [popState, setPopState] = useState(false);
  const [popMessage, setPopMessage] = useState("");
  const [confirmPopState, setConfirmPopState] = useState(false);

  // 챗봇 무료 생성 버튼 popupState
  const [urlBotCreateState, setUrlBotCreateState] = useState(false);

  useEffect(() => {
    LastChatBotStatusCheck();
  }, []);

  useEffect(() => {
    if (!homeStatus) return;
    let dataName = homeStatus.dataName;
    if (homeStatus.status === "00") {
      setUrlInputState(dataName); // 23.07.05.codelua 로딩중일때만 어떤 URL을 수집중인지 알려준다.
      setCreateBotState("loading");
    } else if (homeStatus.status === "01") {
      setUrlInputState("");
      setCreateBotState("complete");
    }
  }, [homeStatus]);

  useEffect(() => {
    const botInfo = subscribeMessage;
    if (botInfo) {
      if (
        botInfo.status === "01" &&
        botInfo.division === 1 &&
        botInfo.isInit !== "EmptyMainKnow"
      ) {
        setHomeStatus(botInfo);
        setChatBotData(botInfo);
      } else if (botInfo.status === "02" && botInfo.division === 1) {
        setErrorMessage(botInfo.errorReason);
        // 리턴값이 왔을때 기존 봇 정보가 있었다면 complete해주면 됨.
        if (homeStatus) {
          setCreateBotState("complete");
        } else {
          setCreateBotState("error"); // 실제 error state는 없으나 오류 사항 표기를 위함.
        }
      }
    }
    setSubscribeMessage(null);
  }, [setChatBotData, subscribeMessage]);

  useEffect(() => {
    procFreeBotCount();
  }, [userChatBotList]);

  useEffect(() => {
    if (freeBotCount) console.log("freeBotCount:", freeBotCount);
  }, [freeBotCount]);

  const { isMobile } = useContext(isMobileContext);
  const [addType, setAddType] = useState("file");

  return (
    <DASH_HOME.Container>
      <DASH_HOME.ContentCol>
        <IntroContainer>
          {createBotState === "home" ? (
            <img src={introImgWeb} alt="인트로이미지" />
          ) : createBotState === "loading" ? (
            <img src={LoadingWeb} alt="인트로이미지" />
          ) : (
            createBotState === "complete" && (
              <img src={introImgWeb} alt="인트로이미지" />
            )
          )}
        </IntroContainer>

        {createBotState !== "loading" && (
          <UrlCt>
            <MainTitle className="main">
              업무 생산성을 <span className="orange">향상</span>시키는 MESwiser
              Assistant
            </MainTitle>
            <ButtonContainer className="tab">
              <button
                className={addType === "file" ? "active" : ""}
                onClick={(e) => {
                  setAddType("file");
                }}
              >
                {!isMobile && <img src={IconUrl} alt={"url생성"} />}
                문서 생성
                {/* 문서 생성 */}
              </button>
              <button
                className={addType === "url" ? "active" : ""}
                onClick={(e) => {
                  setAddType("url");
                }}
              >
                {!isMobile && <img src={IconDocu} alt={"문서로생성"} />}
                URL 생성
                {/* URL 생성 */}
              </button>

              <button
                className={addType === "direct" ? "active" : ""}
                onClick={(e) => {
                  setAddType("direct");
                }}
              >
                {!isMobile && <img src={IconStart} alt={"빈봇 생성"} />}
                빈봇 생성
                {/* 빈봇 생성 */}
              </button>
            </ButtonContainer>
            <UrlFrom name="" id="mainform" action="">
              <InputCt style={{ flexDirection: "column" }}>
                {addType === "url" ? (
                  <InputForm
                    formClass="dashboard"
                    disabled={
                      createBotState === "loading"
                        ? true
                        : freeBotCount >= 2
                          ? true
                          : false
                    }
                    inputValue={urlInputState}
                    inputSetValue={setUrlInputState}
                    placeholderText={
                      freeBotCount >= 2
                        ? "무료 생성 개수 초과 '문의하기'를 통해 개수를 늘려보세요."
                        : "http(s)://로 시작하는 URL을 입력해 보세요."
                    }
                    buttonTitle="Assistant 생성"
                    buttonCallBackFunc={urlValid}
                  />
                ) : (
                  <>
                    {freeBotCount >= 2 ? (
                      <CompleteText
                        style={{ marginBottom: "0", fontWeight: "400" }}
                        className=" desc"
                      >
                        무료 생성 개수 초과 '문의하기'를 통해 개수를 늘려보세요.
                      </CompleteText>
                    ) : createBotState === "loading" ? (
                      <></>
                    ) : addType === "file" ? (
                      <>
                        <input
                          type="file"
                          id="fileUpload"
                          style={{ display: "none" }}
                          accept=".json, .txt, .text, .csv, .pdf, .doc, .docx, .epub, .ppt, .pptx, .xml, .xls, .xlsx, .hwp, .hwpx"
                          onChange={changeFileHandle}
                        ></input>
                        <FileUpLoadLabel htmlFor="fileUpload">
                          <i className="ri-file-upload-fill" />
                          파일 업로드
                        </FileUpLoadLabel>
                      </>
                    ) : (
                      addType === "direct" && (
                        <>
                          {freeBotCount >= 2 ? (
                            <CompleteText
                              style={{ marginBottom: "0", fontWeight: "400" }}
                              className=" desc"
                            >
                              무료 생성 개수 초과 '문의하기'를 통해 개수를
                              늘려보세요.
                            </CompleteText>
                          ) : (
                            <CompleteCt>
                              <CompleteText className="desc">
                                최초 지식 없이 비어있는 챗봇을 생성합니다
                                <br />
                                활용을 위해서는 지식관리를 통해 지식추가가
                                필요합니다.
                              </CompleteText>
                              <NormalButton
                                callBackFunc={createBotToEmpty}
                                buttonClass={"big round cOrange"}
                                buttonTitle={"빈 Assistant 생성"}
                              ></NormalButton>
                            </CompleteCt>
                          )}
                        </>
                      )
                    )}
                  </>
                )}
              </InputCt>
            </UrlFrom>
          </UrlCt>
        )}
        {createBotState === "home" ? (
          <InputCt></InputCt>
        ) : createBotState === "loading" ? (
          <LoadingCt>
            <LoadingIcons className="load-icon center">
              <span></span>
              <span></span>
              <span></span>
            </LoadingIcons>
            <MainTitle className="creating">
              MESwiser Assistant를 생성 중입니다.
              <br />수 분이 소요 될 수 있습니다. 생성이 완료되면 메일로
              알려드릴게요!
            </MainTitle>
            <MainTitle className="creating mobile">
              MESwiser가 인공지능 Assistant를 생성 중입니다.
              <br />수 분이 소요 될 수 있습니다.
              <br />
              생성이 완료되면 메일로 알려드릴게요!
            </MainTitle>
            {/* <LoadingProgress img={tongLoading} /> */}
            <LaodingText>MESwiser Assistant 생성중...</LaodingText>
          </LoadingCt>
        ) : (
          <CompleteCt id="chat--complete">
            {errorMessage && (
              <ErrorCt>
                <CompleteText className="mainTitle error red">
                  MESwiser Assistant 생성 실패
                </CompleteText>
                {errorMessage === "error" ? (
                  <CompleteText className="mainTitle ">
                    MESwiser Assistant 생성에 실패했습니다. <br /> 유효한
                    홈페이지 주소&#40;URL&#41;인지 확인해 보시기 바라며, <br />{" "}
                    계속해서 실패했다면 &#91;어시스턴트 관리&#93;의 문의하기
                    메뉴를 활용하시기 바랍니다.
                  </CompleteText>
                ) : (
                  <CompleteText className="mainTitle error url">
                    {errorMessage}
                  </CompleteText>
                )}
              </ErrorCt>
            )}
            {createBotState === "complete" && (
              <>
                {/* <CompleteImg src={iconChatBot} alt="챗봇이미지01" /> */}
                <CompleteText className="mainTitle">
                  {`[${homeStatus.dataName}] MESwiser Assistant가 생성 되었습니다!`}
                </CompleteText>
                {homeStatus && homeStatus.crawlRestricted === "Y" && (
                  <CompleteText className="mainTitle desc">
                    MESwiser Assistant를 완성하였습니다. <br /> &#40; 무료 생성
                    Assistant는 용량 제한이 있습니다. &#91;MESwiser Assistant
                    관리&#93; 메뉴에서 확인하세요. &#41;
                  </CompleteText>
                )}
                <CompleteBtn
                  id="useChatBtn"
                  className="useChatBtn"
                  onClick={(e) => {
                    sessionStorage.setItem("DISPLAY_MENU", "USES");
                    history.go(0);
                  }}
                >
                  Assistant 사용
                </CompleteBtn>
              </>
            )}
          </CompleteCt>
        )}

        {confirmPopState && (
          <ConfirmPop
            setShowConfirmPop={setConfirmPopState}
            callback={popCallback?.normal}
            cancelCallback={popCallback?.cancel}
            title={popMessage}
          />
        )}
        {popState && (
          <AlertPop2
            text={popMessage}
            popState={popState}
            setPopState={setPopState}
          />
        )}

        {urlBotCreateState && (
          <AlertPopWithRadio
            callback={createBotToURL}
            cancelCallback={popCallback?.cancel}
            popState={urlBotCreateState}
            setPopState={setUrlBotCreateState}
            text={popMessage}
          />
        )}
      </DASH_HOME.ContentCol>
      {loading && <LoadingComponent />}
    </DASH_HOME.Container>
  );
}

const MainTitle = styled.p`
  margin-left: 10px;
  color: #000;
  letter-spacing: -0.01em;
  text-align: left;

  font-size: 30px;
  font-weight: 700;
  line-height: 60px;
  text-align: center;

  @media screen and (max-width: 600px) {
    font-size: 24px;
  }
  @media (max-width: 480px) {
    margin-left: 0;
    color: #1a1a1a;
    font-size: 24px;
    line-height: 162.3%;
    letter-spacing: -1px;
    min-width: 100%;
  }
  & strong {
    font-weight: bold;
  }

  & span.orange {
    color: #ff892a;
  }
  &.desc {
    font-size: 25px;
    font-weight: 400;
    word-break: keep-all;
    &.web {
      width: 100%;
      letter-spacing: -0.5px;
    }

    @media (max-width: 480px) {
      font-size: 18px;
    }
  }

  &.creating {
    font-size: 25px;
    text-align: center;
    line-height: normal;

    &.mobile {
      display: none;
    }

    @media (max-width: 480px) {
      display: none;

      &.mobile {
        display: block;
        font-size: 18px;
        letter-spacing: -0.01em;
      }
    }
  }

  &.main {
    margin-bottom: 30px;
  }
`;

const UrlCt = styled.div`
  width: 100%;
`;
const UrlFrom = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const InputCt = styled.div`
  margin-bottom: 45px;

  @media (max-width: 480px) {
    margin-bottom: 30px;
  }

  & .dashboard {
    button {
      flex: 0 0 auto;
      width: 25%;
    }
  }

  &:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 480px) {
    & > div {
      width: 66%;
    }
  }
`;

const Progress = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
`;

const LoadingCt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LoadingProgress = styled.div`
  width: 100px;
  height: 100px;
  background: url(${({ img }) => img}) no-repeat;
  background-position: center;
  background-size: contain;
  margin: 30px 0;

  animation: ${Bounce} 0.4s infinite linear;
`;

const LaodingText = styled.p`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  /* color: #fff; */
  margin: 15px 0;
`;

// complete
const CompleteCt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BotAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const CompleteText = styled.p`
  margin-bottom: 33px;
  font-size: 25px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  word-break: break-all;
  letter-spacing: -0.5px;

  &.chargeInfo {
    font-size: 17.5px;
    /* color: #fff; */
  }

  &.desc {
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    color: #6a6a6a;
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
  @media (max-width: 480px) {
    text-align: center;
    &.desc {
      font-size: 14px;
    }
  }

  &.error.red {
    color: tomato;
    @media (min-width: 481px) {
      font-weight: 600;
    }
  }

  &.error.url {
    padding: 0 20px;
    width: 80%;
    margin: 20px auto;
    color: tomato;
    font-weight: 400;
    line-height: 30px;
    @media (max-width: 480px) {
      font-weight: 300;
      padding: 0;
      word-break: keep-all;
    }
    @media (min-width: 481px) {
      width: 100%;
    }
  }
`;

const CompleteBtn = styled.button`
  min-width: 240px;
  width: 240px;
  height: 78px;
  margin: 0 auto 20px;
  padding: 10px;

  justify-content: center;
  align-items: center;

  border-radius: 100px;
  background: linear-gradient(340deg, #ff892a 0%, #ff892a 48.78%, #ffe618 100%);
  color: #fff;

  font-size: 25px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: -0.01em;

  transition: 0.2s;

  @media (max-width: 480px) {
    /* width: 100%; */
    /* border-radius: 10px; */
    height: 50px;
    font-size: 18px;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const Desc = styled.p`
  color: #1a1a1a;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 198.3%;
  letter-spacing: -0.4px;

  width: 100%;

  /* border: 1px solid red; */

  &.main {
    margin-top: 50px;
  }
  &.sub {
    font-size: 30px;
    font-weight: 400;
    & strong {
      font-weight: 700;
    }
  }

  @media (max-width: 480px) {
    &.main {
      font-size: 24px;
      color: #1a1a1a;
      font-style: normal;
      font-weight: 500;
      line-height: 162.3%;
      letter-spacing: -1px;
    }
    &.sub {
      font-size: 18px;
      letter-spacing: -1px;
    }
  }
`;

const ErrorCt = styled.div`
  text-align: center;
`;

const IntroContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1000px;
  /* padding-top: 150px; */

  @media (max-width: 1000px) {
    /* padding-top: 20vw; */
  }

  & img {
    flex: 0 1 30%;
    width: 300px;
    margin-right: 30px;

    @media (max-width: 480px) {
      height: 200px;
      width: 100%;
      margin: 0;
      padding: 0 20px;
    }
    @media (max-width: 1000px) {
      margin-bottom: 40px;
      margin-top: 30px;
    }
  }
`;

const LoadingIcons = styled.div`
  width: 100px;
  height: 20px;

  margin: 20px 0px 30px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: relative;

  & span {
    position: absolute;
    display: inline-block;
    background: #ff892a;
    width: 20px;
    height: 20px;
    border-radius: 100px;
  }
  & span:nth-of-type(1) {
    left: 0;
  }
  & span:nth-of-type(2) {
    left: 40%;
  }
  & span:nth-of-type(3) {
    right: 0;
  }

  /* Animation */
  & span {
    animation-name: loading;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  & span:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  & span:nth-of-type(3) {
    animation-delay: 0.4s;
  }

  /* Keyframes */
  @keyframes loading {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const InputOpt = styled.div`
  margin-top: 20px;

  margin-bottom: 15px;

  padding: 5px 0;
  width: auto !important;
  border-bottom: 1px solid #ff892a;

  & input {
    display: none;
  }
  & label {
    padding: 0 15px;
    font-size: 16px;

    &.active {
      color: #fff;
      border: 1px solid #ff892a;
      background: #ff892a;
      font-weight: 500;
    }
    &:hover {
      cursor: pointer;
      /* color: #ff892a; */
      opacity: 0.7;
    }
  }
`;

const FileUpLoadLabel = styled.label`
  background: #808080;
  color: #fff;
  padding: 15px 25px;
  font-size: 26px;
  border-radius: 5px;
  cursor: pointer;

  margin-top: 10px;
  width: 300px;

  &:hover {
    opacity: 0.7;
  }

  & i {
    margin-right: 10px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
    /* width: 100%; */
    width: auto;
  }
`;

const ButtonContainer = styled.div`
  &.tab {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100px;
    background: #ededf3;
    width: fit-content;
    margin: 0 auto 10px;
    @media (max-width: 480px) {
      margin-top: 30px;
    }
    & button {
      padding: 10px 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      color: #8c8c8c;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;
      &:hover {
        opacity: 0.8;
      }

      &.active {
        color: #ff892a;
        text-align: center;
        font-family: Noto Sans KR;
        font-weight: 700;
        background: #fff;
        border: 1px solid #ff892a;
        border-radius: 100px;
        flex: 1 0 auto;
      }

      @media (max-width: 480px) {
        font-size: 14px;
      }

      & img {
        width: 25px;
        height: 25px;
        margin-right: 4px;
      }
    }
  }
  &.default {
  }
`;
