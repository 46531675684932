import { useQuery } from "@tanstack/react-query";
import botApi from "../../api/botApi.js";

export default function useKnowledgePagination(props) {
  const { botUid, pdataUid, useBookmarkCheck, searchValue, currentPage, lengthPerPage, mode, currentBotUid } =
    props ?? {};
  const currentMode = mode ?? "default";
  
  const getDataStatus = (item) => item.dataType === 20 ? (item.activatedFileCount === 0 ? 4 : 1) : item.dataStatus;

  const queryKey = [
    "knowledgePagination",
    {
      currentPage,
      lengthPerPage,
      param: {
        mode: currentMode,
        currentBotUid,
        botUid,
        pdataUid,
        searchValue,
        useBookmarkCheck: useBookmarkCheck === undefined ? "N" : useBookmarkCheck,
      },
    },
  ];

  const { data, refetch, isLoading } = useQuery({
    queryKey,
    queryFn: () => botApi.getUseKnowDataSetFromBotUid_Pagination(queryKey[1]),
    staleTime: 1000 * 60 * 3,
    cacheTime: 1000 * 60 * 3,
    enabled: !!botUid,
  });

  const {
    total,
    data: list,
    activedFileCnt,
  } = JSON.parse(
    data?.data?.botKnowDataSetInfo ??
      JSON.stringify({
        total: 0,
        data: [],
      })
  );
  
  const refineList = list.map(item => ({
    ...item,
    dataStatus: getDataStatus(item)
  }))

  return {
    currentPage,
    lengthPerPage,
    activedFileCnt,
    total,
    list: refineList,
    isLoading,
    refetch,
    queryKey,
  };
}
