import { createContext, useMemo, useRef, useState } from "react";
import { AlertListPop, AlertPop2, AlertPop3 } from "../../components/templates/popup/AlertPop.js";
import ConfirmPop from "../../components/templates/popup/ConfirmPop.js";

/**
 * showTextPop = (보여줄 텍스트) = 텍스트만 넘기면 해당 함수에서 처리 후 AlertPop으로 보여준다.
 * */
export const popupContext = createContext({
  popInfo: {}, // 전체 팝업 변수 관리 ( 팝업 보여줄지 여부, 보여줄 팝업명 등 )
  callbackInfo: {}, // 팝업창에서 확인/취소 등을 누른 후 실행할 callback Func 관리
  updatePopInfo: () => {}, // 전체 팝업 변수 값 변경
  updateCallback: () => {}, // callback func 관리
  open: () => {}, // 현재 설정된 팝업 정보를 띄움
  close: () => {}, // 현재 설정된 팝업 정보를 숨김
  showTextPop: () => {}, // popInfo 정보를 간편하게 변경, 텍스트만 띄우는 팝업으로 설정 후 띄움.
  showExpirePop: () => {},
  showConfirmPop: () => {},
  showConfirmPopCommonCallback: () => {},
  showConfirmPopCommonAndCancelCallback: () => {},
  showListPop: () => {},
});
export default function PopupProvider(props) {
  const { children: Components } = props;
  /**
   * @param
   * - isEnabled: 팝업 on/off 여부
   * - currentState: 팝업 종류 ( common / 일반 팝업, confirm / 요청 팝업, 등등. )
   * - title: 팝업의 제목
   * - content: 팝업의 내용
   * - 팝업에 추가적으로 필요한 prop
   * */
  const [popInfo, setPopInfo] = useState({
    isEnabled: false,
    currentState: null,
    title: "",
    content: "",
    props: {},
  });

  /**
   * @param
   * - common: 확인 눌렀을 시 실행될 callback
   *   - state: currentState의 값
   *   - fn: 실행될 함수
   * - cancel: 취소 눌렀을 시 실행될 callback 정보
   *   - state: currentState의 값
   *   - fn: 실행될 함수
   *
   * @description
   * callback 정보는 바뀔 떄 마다 렌더링 되는건 안 좋다고 생각해서 ref로 관리.
   * */
  const callbackInfo = useRef({
    common: {
      state: "",
      fn: () => {},
    },
    cancel: {
      state: "",
      fn: () => {},
    },
  });

  /**
   * @description
   *  updateInfo 값은 확인 callback, 취소 callback 등 다양한 상태의 callback을
   * 한 번에 바꿀 수 있도록 고려해 배열 또는 하나의 object로 넘겨줄 수 있다.
   * ex) { type, fn } or [{type, fn}, {type, fn}]
   * */
  const updateCallbackInfo = (updateInfo) => {
    if (Array.isArray(updateInfo)) {
      callbackInfo.current = {
        ...callbackInfo.current,
        ...updateInfo.reduce((acc, data) => {
          const { type, state, fn } = data;
          acc[type] = { state, fn: !fn ? () => {} : fn };
          return acc;
        }, {}),
      };
    } else {
      const { type, state, fn } = updateInfo;
      callbackInfo.current = {
        ...callbackInfo.current,
        [type]: { state, fn: !fn ? () => {} : fn },
      };
    }
  };

  /**
   * @description
   *  popInfo의 currentState 즉 현재 선택된 팝업 종류와, 등록한 callback의 state값이 일치하는지 확인.
   * 팝업, callback 설정 => 팝업 정보만 변경 후 실행 시 callback이 원치 않은데도 동작할 경우를 방지
   * */
  const isSameCallbackState = (nm) => nm === popInfo.currentState;

  /** @description
   * isSameCallbackState 값이 true일 경우에만 확인 관련 지정한 callback을 반환
   * */
  const commonCallback = () =>
    isSameCallbackState(callbackInfo.current.common.state) ? callbackInfo.current.common.fn : () => {};

  /** @description isSameCallbackState 값이 true일 경우에만 확인 관련 지정한 callback을 반환 */
  const cancelCallback = () =>
    isSameCallbackState(callbackInfo.current.cancel.state) ? cancelCallback.current.cancel.fn : () => {};

  /** @description  팝업 정보만 미리 설정 후, 특정 상황에서 열고 싶을 때 사용.  */
  const open = () => {
    setPopInfo({ ...popInfo, isEnabled: true });
  };

  /** @description 팝업창을 닫고싶을 때 사용 */
  const close = () => {
    setPopInfo({ ...popInfo, isEnabled: false });
  };

  /** @description 팝업 정보를 변경하고 싶을 때 사용. */
  const updatePopInfo = (prop) => {
    setPopInfo({ ...popInfo, ...prop });
  };

  /** @description
   *  실질적으로 화면에 띄울 팝업 컴포넌트를 결정하는 함수
   *  popInfo의 currentState값에 따라 바뀌도록 지정.
   *  다른 팝업 종류도 추가하고 싶은 경우 case만 추가하면 가능하도록 구현.
   * */
  const CurrentPop = useMemo(() => {
    switch (popInfo.currentState) {
      case "common":
        return (
          <AlertPop2 {...props} text={popInfo.content} callback={commonCallback()} itemList={false} close={close} />
        );
      case "expire":
        return <AlertPop3 {...props} text={popInfo.content} callback={commonCallback()} close={close} />;
      case "list":
        return <AlertPop2 {...(popInfo?.props ?? {})} itemList={true} popState={popInfo.isEnabled} close={close} />;
      case "list2":
        return <AlertListPop {...(popInfo?.props ?? {})} title={popInfo.title} close={close} />;
      case "confirm":
        return (
          <ConfirmPop
            {...popInfo.props}
            title={popInfo.title}
            description={popInfo.content}
            setShowConfirmPop={close}
            callback={commonCallback()}
            cancelCallback={cancelCallback()}
          />
        );
      default:
      case null:
        return "";
    }
  }, [popInfo]);

  // 여기부터는 다른 컴포넌트에서 사용할 함수를 선언.
  // 위의 함수들로도 외부에서 팝업 띄우고 설정이 가능하지만,
  // 사용하기 불편하고, 외우기 어려워 따로 함수화.
  // ( 원하는 팝업 종류를 추가하고 아래 사용하기 쉽게 함수화 후 내보낼 수 있음 )

  /** @description
   *  - 일반 텍스트 팝업창 띄우기
   *  - 첫 번째 인자로 텍스트를, 두 번째 인자로 그 후 실행할 callback 함수를 받음
   *  - 해당 함수 실행 시 즉시 관련 모달창이 나타나도록 isEnabled: true로 변경.
   * */
  const showTextPop = (text, callback = null) => {
    // type: 확인/취소 등 종류
    // state: popInfo의 currentState와 동일한 값.
    updateCallbackInfo({ type: "common", state: "common", fn: callback });

    setPopInfo({
      ...popInfo,
      isEnabled: true,
      currentState: "common",
      content: text,
    });
  };

  const showExpirePop = (text, callback = null) => {
    // type: 확인/취소 등 종류
    // state: popInfo의 currentState와 동일한 값.
    updateCallbackInfo({ type: "common", state: "expire", fn: callback });

    setPopInfo({
      ...popInfo,
      isEnabled: true,
      currentState: "expire",
      content: text,
    });
  };

  const showConfirmPop = (title, content, callbackArray) => {
    console.log("callbackArray", callbackArray);
    updateCallbackInfo(callbackArray.map((item) => ({ type: item.type, state: "confirm", fn: item.callback })));

    setPopInfo({
      ...popInfo,
      isEnabled: true,
      currentState: "confirm",
      title,
      content,
    });
  };

  const showConfirmPopCommonCallback = (title, content, callback) => {
    return showConfirmPop(title, content, [{ type: "common", callback }]);
  };

  const showConfirmPopCommonAndCancelCallback = (title, content, commonCallback, cancelCallback) => {
    return showConfirmPop(title, content, [
      { type: "common", callback: commonCallback },
      { type: "common", callback: cancelCallback },
    ]);
  };

  const showListPop = ({ title, list, callback = null, type, content }) => {
    console.log("title, list, callback", title, list, callback);
    if (callback) updateCallbackInfo({ type: "common", state: "list2", fn: callback });
    setPopInfo({
      ...popInfo,
      isEnabled: true,
      title,
      currentState: "list2",
      props: {
        checkItemList: list,
        type,
        msg: content,
      },
    });
  };

  return (
    <popupContext.Provider
      value={{
        popInfo,
        callbackInfo,
        updatePopInfo,
        updateCallback: updateCallbackInfo,
        close,
        open,
        showTextPop,
        showExpirePop,
        showConfirmPop,
        showConfirmPopCommonCallback,
        showConfirmPopCommonAndCancelCallback,
        showListPop,
      }}
    >
      {/* isEnabled값이 true인 경우에만 현재 선택된 팝업이 띄워지도록 조치. */}
      {!!popInfo?.isEnabled && CurrentPop}
      {Components}
    </popupContext.Provider>
  );
}
