// 혼합대화창, 혼합검색을 합친 싱글 뷰어.
import { useEffect, useState } from "react";
import styled from "styled-components";
import uuid from "react-uuid";
import CombineChat from "./CombineChat.js";
import CombineKnowView from "./CombineKnowView.js";
import { getNow } from "../../../utils/dateUtil.js";
import { Panel, PanelGroup } from "react-resizable-panels";
import styles from "./styles.module.css";
import ConfirmPop from "components/templates/popup/ConfirmPop.js";
import ResizeHandle from "./ResizeHandle";
import NormalButton from "components/atomic/NormalButton2.js";
import CombineHistView from "./CombineHistView.js";

import Logo from "../../../assets/images/LOGO_Assistant.svg";

export default function CompleXView({ props }) {
  const {
    isLoggedIn,
    chatBotData,
    chatRoomId,
    setChatRoomId,
    openLoginPop,
    handleSignOutAppFromChat,
  } = props;

  // 이전기록 탭 ( 히스토리 사용 )
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedChatRoom, setSelectedChatRoom] = useState(null);

  // 혼합대화창 탭 ( 로그인을 했을 경우에만 사용 되어진다. )
  const [mainTabState, setMainTabState] = useState("chat");

  // 로딩 설정
  const [knowLoading, setKnowLoading] = useState(false);
  // const [showKnowPanel, setShowKnowPanel] = useState(false);
  // 채팅창
  const [firstMessage, setFirstMessage] = useState(true); // 탭이 변경 될때 초기화 되서 여기서 보내기.
  const [messages, setMessages] = useState([]);

  // 지식창
  const [tokens, setTokens] = useState([]);
  const [knowDataCount, setKnowDataCount] = useState(0);
  const [knowDataList, setKnowDataList] = useState([]);
  const [sentenceList, setSentenceList] = useState([]);

  // 채팅 응답 설정
  const [useNewLine, setUseNewLine] = useState(false);
  const [useSentence, setUseSentence] = useState(true);
  const [sliderMaxVal, setSliderMaxVal] = useState(80); // 시맨틱
  const [sliderMinVal, setSliderMinVal] = useState(20); // 키워드
  const [botRatioVal, setBotRatioVal] = useState([0, 80]);

  // 확인 메시지 창
  const [confirmPopState, setConfirmPopState] = useState(false);
  const [confirmPopMessage, setConfirmPopMessage] = useState("");
  const [popCallback, setPopCallback] = useState({
    normal: () => {},
    cancel: () => {},
  });

  const handleTabChange = (tabName) => {
    if (tabName === "chat") {
      // 챗방을 초기화 해준다.
      setInitChatTab();
      setMainTabState("chat");
    } else if (tabName === "histChat") {
      if (!firstMessage) {
        setConfirmPopMessage("대화내용이 초기화 됩니다. 진행하시겠습니까?");
        setPopCallback(() => ({
          cancel: () => {},
          normal: () => {
            setMessages([]);
            setMainTabState("histChat");
          },
        }));
        setConfirmPopState(true);
      } else {
        setMainTabState("histChat");
      }
    }
  };

  const setInitChatTab = () => {
    setFirstMessage(true);
    setChatRoomId(uuid());
    setIntroMessage();
    setTokens([]);
    setKnowDataCount(0);
    setKnowDataList([]);
    setSentenceList([]);
  };

  const setIntroMessage = () => {
    if (messages.length === 0 && chatBotData) {
      let message = "";
      if (chatBotData.greetingMessage) message = chatBotData.greetingMessage;
      else message = "궁금한 것을 물어보면 AI가 답변해 드려요!";

      setMessages([
        {
          message: message,
          type: "bot",
          messageType: "text",
          time: getNow(),
          id: uuid(),
          isFirst: true,
        },
      ]);
    }
  };

  useEffect(() => {
    setIntroMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatBotData]);

  useEffect(() => {
    // if (messages.length > 1) setShowKnowPanel(true);
  }, [messages]);

  return (
    <MainCt>
      <div className={styles.Container}>
        {!isLoggedIn && (
          <div className={styles.TopRow}>
            <img src={Logo} alt="메스와이저 로고" />
            <span className="desc login">{`로그인을 하시면 대화의 기록을 저장합니다`}</span>
            <NormalButton
              buttonId="loginBtn"
              buttonTitle={"로그인"}
              buttonClass="round small cOrange"
              callBackFunc={openLoginPop}
            ></NormalButton>
          </div>
        )}
        {isLoggedIn && (
          <div className={styles.TopRow}>
            <div className={styles.LoginCt}>
              <img src={Logo} alt="메스와이저 로고" />
              <NormalButton
                buttonId="loginBtn"
                buttonTitle={"로그아웃"}
                buttonClass="round small cOrange"
                callBackFunc={() => {
                  if (window.confirm("로그아웃을 진행 하시겠습니까?")) {
                    handleSignOutAppFromChat();
                  }
                }}
              ></NormalButton>
            </div>
            <TabBtnCt>
              <button
                className={mainTabState === "chat" ? "active" : ""}
                onClick={() => {
                  handleTabChange("chat");
                }}
              >
                채팅창
              </button>
              <button
                className={mainTabState === "histChat" ? "active" : ""}
                onClick={() => {
                  handleTabChange("histChat");
                }}
              >
                이전기록
              </button>
            </TabBtnCt>
          </div>
        )}
        {mainTabState === "chat" && (
          <div className={styles.BottomRow}>
            <PanelGroup autoSaveId="example" direction="horizontal">
              <Panel
                className={styles.Panel}
                collapsible={false}
                minSizePercentage={50}
                defaultSizePercentage={60}
                order={1}
              >
                <div className={styles.panelContentChat}>
                  <CombineChat
                    props={{
                      isLoggedIn,
                      messages,
                      setMessages,
                      chatBotData,
                      chatRoomId,
                      botRatioVal,
                      setBotRatioVal,
                      sliderMaxVal,
                      setSliderMaxVal,
                      sliderMinVal,
                      setSliderMinVal,
                      setTokens,
                      setKnowDataCount,
                      setKnowDataList,
                      setSentenceList,
                      setKnowLoading,
                      firstMessage,
                      setFirstMessage,
                    }}
                  />
                </div>
              </Panel>

              <ResizeHandle />
              <Panel
                className={styles.Panel}
                collapsible={false}
                minSizePercentage={10}
                defaultSizePercentage={40}
                order={2}
              >
                <div className={styles.PanelContent}>
                  <KnowSection>
                    <CombineKnowView
                      props={{
                        knowLoading,
                        tokens: useSentence ? sentenceList : tokens,
                        knowDataCount,
                        knowDataList,
                        useNewLine,
                        setUseNewLine,
                        useSentence,
                        setUseSentence,
                      }}
                    />
                  </KnowSection>
                </div>
              </Panel>
            </PanelGroup>
          </div>
        )}
        {/* 이전기록 탭 처리 부분 */}
        {mainTabState === "histChat" && (
          <div className={styles.BottomRow}>
            <PanelGroup autoSaveId="hist" direction="horizontal">
              <Panel
                className={styles.Panel}
                collapsible={false}
                minSizePercentage={50}
                defaultSizePercentage={60}
                order={1}
              >
                <div className={styles.panelContentView}>
                  <CombineHistView
                    props={{
                      chatBotData,
                      currentPage,
                      setCurrentPage,
                      selectedChatRoom,
                      setSelectedChatRoom,
                      setKnowLoading,
                      setTokens,
                      setKnowDataCount,
                      setKnowDataList,
                      setSentenceList,
                    }}
                  />
                </div>
              </Panel>
              {selectedChatRoom && (
                <>
                  <ResizeHandle />
                  <Panel
                    className={styles.Panel}
                    collapsible={false}
                    minSizePercentage={10}
                    defaultSizePercentage={40}
                    order={2}
                  >
                    <div className={styles.PanelContent}>
                      <KnowSection>
                        <CombineKnowView
                          props={{
                            knowLoading,
                            tokens: useSentence ? sentenceList : tokens,
                            knowDataCount,
                            knowDataList,
                            useNewLine,
                            setUseNewLine,
                            useSentence,
                            setUseSentence,
                          }}
                        />
                      </KnowSection>
                    </div>
                  </Panel>
                </>
              )}
            </PanelGroup>
          </div>
        )}
      </div>
      {confirmPopState && (
        <ConfirmPop
          setShowConfirmPop={setConfirmPopState}
          callback={popCallback?.normal}
          cancelCallback={popCallback?.cancel}
          title={confirmPopMessage}
        />
      )}
    </MainCt>
  );
}

const MainCt = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  /* height: 100vh; */
  margin: 0;
  padding: 0 10vw;

  /* background-color: #081120; */
  /* background-color: #f7f9ff; */
  background: #fff;
  color: #ffffff;

  font-size: 14px;
  & p {
    margin: 0;
  }

  & span.desc {
    color: #888;
    font-size: 13px;
    margin: auto 10px 10px 0;
  }
`;

const KnowSection = styled.section`
  width: 100%;
  height: 100%;
`;

const TabBtnCt = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (min-width: 481px) {
    width: fit-content;
    align-self: start;
    justify-content: unset;
  }
  & button {
    font-size: 15px;
    padding-right: 15px;
    color: #888;
    position: relative;
    &:not(:last-child):after {
      content: "";
      position: absolute;

      width: 1.5px;
      height: 100%;
      right: 0;
      background: #dcdcdc;
    }
    &:last-child {
      margin-left: 10px;
    }
    &.active {
      color: #ff892a;
      font-weight: 500;
      text-decoration: underline;
      text-underline-position: under;
      text-underline-offset: 5px;
      text-decoration-thickness: 3px;
    }
  }
`;
