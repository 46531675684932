import { ErrorCatch } from "api/ErrorCatch";
import botApi from "api/botApi";
import InputForm from "components/molecules/InputForm";
import { AlertPop2 } from "components/templates/popup/AlertPop";
import ConfirmPop from "components/templates/popup/ConfirmPop.js";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";
import { chatContext } from "utils/providers/ChatContextProvider";
import { signAppContext } from "utils/providers/SignContextProvider";
import { SubscribeContext } from "utils/providers/SubscribeContextProvider";
import { userChatBotContext } from "utils/providers/UserChatBotContextProvider";
import introImgWeb from "../../assets/images/IntroImg_web.png";
import introImgWeb2 from "../../assets/images/IntroImg_web02.png";
import iconDownArrow from "../../assets/images/icon_downArrow.svg";

import IconDocu from "../../assets/images/iconTabDocu.svg";
import IconUrl from "../../assets/images/iconTabFile.svg";
import checkIcon from "../../assets/images/icon_checkBlack.png";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function ChatMain({
  crawlRestricted,
  setLoginPopState,
  mainContentRef,
}) {
  const history = useHistory();
  const { handleSignOutApp } = useContext(signAppContext);
  const { userChatBotList } = useContext(userChatBotContext);
  const [popState, setPopState] = useState(false);
  const [popMessage, setPopMessage] = useState("");
  const [confirmPopState, setConfirmPopState] = useState(false);
  const [homeStatus, setHomeStatus] = useState(null);
  const [popCallback, setPopCallback] = useState({
    normal: () => {},
    cancel: () => {},
  });

  /** 펑션 모음 시작! */
  const mainCtRef = useRef();

  /** 마지막 챗봇 상태 확인 */
  const LastChatBotStatusCheck = async () => {
    async function getLastUserChatBotData() {
      try {
        const { status, data } = await botApi.checkHomeChatBot();
        if (status === 200) {
          const botInfo = data.botInfo ? JSON.parse(data.botInfo) : "";
          if (botInfo) {
            // console.log("getLastUserChatBotData: ", botInfo);
            setHomeStatus(botInfo);
            if (botInfo.status === "01") {
              // 현재 체크한 챗봇이 성공의 경우에만 setChatBotData를 처리하여 모든 영역에서 사용 할 수 잇도록 하자.
              setChatBotData(botInfo);
            }
          }
        } else {
          // console.log("getLastUserChatBotData", data);
        }
      } catch (error) {
        ErrorCatch(error, handleSignOutApp);
      }
    }
    await getLastUserChatBotData();
  };
  //
  const createBotToFile = async (file) => {
    try {
      setErrorMessage(null);
      console.log(">> Create Bot With File !!");
      const restricted = crawlRestricted ? crawlRestricted : "Y";
      let params = {
        buildFilePath: file,
        dataName: file.name,
        crawlRestricted: restricted,
      };
      const { status, data } = await botApi.buildFile(params);
      const resultData = data ? data.resultMessage : "";
      if (data.resultCode === "200") {
        setCreateBotState("loading");
      } else {
        setErrorMessage(resultData);
        if (createBotState !== "complete") setCreateBotState("error");
        return false;
      }
    } catch (error) {
      setErrorMessage("error");
      if (createBotState !== "complete") setCreateBotState("error");
      ErrorCatch(error, handleSignOutApp);
    }
  };
  //
  const createBotToURL = async () => {
    async function SendBuildWeb(url) {
      try {
        setErrorMessage(null);
        // 명시적으로 sync사용.
        // crawlRestricted // 크롤의 제한을 둘것인지 여부. 대문자 N인 경우에만 제한 없는 처리 동작.
        const { status, data } = await botApi.buildWeb(
          url,
          crawlRestricted ? crawlRestricted : "Y",
        );
        console.log(">>>>>>> SendBuildWeb >> data: ", data);
        const resultData = data ? data.resultMessage : "";
        if (data.resultCode === "200") {
          setErrorMessage(null);
          setCreateBotState("loading");
        } else {
          setErrorMessage(resultData);
          if (createBotState !== "complete") setCreateBotState("error");
          return false;
        }
      } catch (error) {
        setErrorMessage("error");
        if (createBotState !== "complete") setCreateBotState("error");
        ErrorCatch(error, handleSignOutApp);
      }
    }

    if (!isLoggedIn) {
      setLoginPopState(true);
      return;
    }

    if (urlInputState.trim(" ") !== "") {
      // const urlRegex =/^(http:\/\/|https:\/\/)(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(\/.*)?$/;
      const urlRegex =
        /^(?:(http:\/\/|https:\/\/))?((?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(\/.*)?$/;
      if (!urlRegex.test(urlInputState)) {
        setPopMessage("유효한 URL 형식이 아닙니다!");
        setPopState((prev) => !prev);
      } else {
        SendBuildWeb(urlInputState);
      }
    } else if (urlInputState.trim(" ") === "") {
      setPopMessage("URL을 입력해주세요.");
      setPopState((prev) => !prev);
    }
  };
  const validateFile = (file) => {
    if (!file) {
      setPopState((prev) => !prev);
      setPopMessage(`파일을 선택해 주세요.`);
      return false;
    }
    // 파일 크기 제한 설정 ( 50MB )
    const maxFileSizeBytes = 50 * 1024 * 1024;

    const fileCheckArray = [
      "json",
      "txt",
      "text",
      "csv",
      "pdf",
      "doc",
      "docx",
      "epub",
      "ppt",
      "pptx",
      "xml",
      "xls",
      "xlsx",
      "hwp",
      "hwpx",
    ];

    const fileNameSplitList = file.name.split(".");
    const fileType = fileNameSplitList[fileNameSplitList.length - 1];
    const isValid = fileCheckArray.some((type) => {
      return fileType.includes(type);
    });

    if (!file?.type || !isValid) {
      setPopState((prev) => !prev);
      setPopMessage(`${fileType} 형태의 파일은 지원하지 않습니다.`);
      return false;
    }

    if (file.size > maxFileSizeBytes) {
      setPopState((prev) => !prev);
      setPopMessage("파일의 크기가 50MB를 초과하였습니다.");
      return false;
    }

    return true;
  };
  const changeFileHandle = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (validateFile(file)) {
      setPopMessage(file.name + "파일을 업로드 하시겠습니까? ");
      setPopCallback(() => ({
        cancel: () => {
          e.target.value = null;
        },
        normal: () => {
          createBotToFile(file);
        },
      }));
      setConfirmPopState(true);
    } else {
      e.target.value = null;
    }
  };
  /** 펑션 모음 끝! */

  const { subscribeMessage, setSubscribeMessage } =
    useContext(SubscribeContext);
  const { chatBotData, setChatBotData } = useContext(chatContext);
  const [createBotState, setCreateBotState] = useState("home");
  const [errorMessage, setErrorMessage] = useState(null);
  const [urlInputState, setUrlInputState] = useState("");
  const [freeBotCount, setFreeBotCount] = useState(0);
  const { isLoggedIn } = useContext(signAppContext);
  const { isMobile } = useContext(isMobileContext);
  const [inputBtnState, setInputBtnState] = useState("file");

  useEffect(() => {
    if (!isLoggedIn) {
      setCreateBotState("home");
      setErrorMessage("");
      setFreeBotCount(0);
      setUrlInputState("");
      return;
    }
    // console.log("제한여부: ", crawlRestricted ? crawlRestricted : "Y");
    LastChatBotStatusCheck();
  }, [isLoggedIn]);

  useEffect(() => {
    if (!homeStatus) return;
    let dataName = homeStatus.dataName;
    if (homeStatus.status === "00") {
      setUrlInputState(dataName); // 23.07.05.codelua 로딩중일때만 어떤 URL을 수집중인지 알려준다.
      setErrorMessage(null);
      setCreateBotState("loading");
    } else if (homeStatus.status === "01") {
      setUrlInputState("");
      setErrorMessage(null);
      setCreateBotState("complete");
    } else if (homeStatus.status === "02") {
      const errorReason = homeStatus.errorReason;
      setUrlInputState("");
      setErrorMessage(errorReason);
      setCreateBotState("error");
    }
  }, [homeStatus]);

  useEffect(() => {
    const botInfo = subscribeMessage;
    if (botInfo) {
      // division 1이 메인이므로 챗봇 생성은 division 1일경우에만 동작하면 된다.
      if (botInfo.status === "01" && botInfo.division === 1) {
        setChatBotData(botInfo);
        setHomeStatus(botInfo);
      } else if (botInfo.status === "02" && botInfo.division === 1) {
        setErrorMessage(botInfo.errorReason);
        // 리턴값이 왔을때 기존 봇 정보가 있었다면 complete해주면 됨.
        if (homeStatus) {
          setCreateBotState("complete");
        } else {
          setCreateBotState("error"); // 실제 error state는 없으나 오류 사항 표기를 위함.
        }
      }
    }
    setSubscribeMessage(null);
  }, [setChatBotData, subscribeMessage]);

  useEffect(() => {
    if (!userChatBotList) return;
    setFreeBotCount(
      userChatBotList &&
        userChatBotList.filter((botInfo) => botInfo.serviceModel === "0")
          .length,
    );
  }, [userChatBotList]);

  const handleScroll = (e) => {
    mainContentRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // 메인 스와이퍼 이미지
  const mainSwiper = [
    {
      img: introImgWeb,
      title: "효율적인 업무 관리의 모든 것",
      title2: (
        <span className="orange">
          업무용 GPT MESwiser Assistant로
          <br />
          지금바로 시작하세요!
        </span>
      ),
      desc: "내부 자료를 Assistant에 추가하세요. AI엔진이 방대한 문서를 학습해 언제 어디서든 원하는 정보를 빠르게 찾아드립니다",
    },
    {
      img: introImgWeb2,
      title: "효율적인 업무 관리의 모든 것",
      title2: (
        <span className="orange">
          업무용 GPT MESwiser Assistant로
          <br />
          지금바로 시작하세요!
        </span>
      ),
      desc: "내부 자료를 Assistant에 추가하세요. AI엔진이 방대한 문서를 학습해 언제 어디서든 원하는 정보를 빠르게 찾아드립니다",
    },
  ];

  return (
    <ChatMainCt className={createBotState} id="chat--main">
      <div ref={mainCtRef} className="MainCt">
        <MainSwiperCt>
          <Swiper
            autoplay={true}
            className="swiper-container live-swiper"
            spaceBetween={isMobile ? 0 : 1}
            slidesPerView={1}
            loop={false}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            onSwiper={(swiper) => {}}
            onSlideChange={() => {}}
          >
            {mainSwiper.map((data, idx) => {
              return (
                <SwiperSlide key={idx} className="swiper-slide live-item">
                  <IntroContainer>
                    <img className="main" src={data.img} alt={data.img} />
                    {createBotState !== "loading" && (
                      <UrlCt>
                        <MainTitle
                          className={
                            isMobile ? "mainTitle top mobile" : "mainTitle top"
                          }
                        >
                          <span className="main">
                            효율적인 업무 관리의 모든 것
                            <br />
                          </span>

                          {/* <span className="orange">{data.title2}</span> */}
                          {data.title2}
                        </MainTitle>

                        {!isMobile ? (
                          <MainTitle className="desc web">
                            내부 자료를 Assistant에 추가하세요.
                            <br /> AI엔진이 방대한 문서를 학습해 언제 어디서든
                            원하는 정보를 빠르게 찾아드립니다
                          </MainTitle>
                        ) : (
                          <></>
                        )}

                        <MainTitleInfoBox className={isMobile ? "mobile" : ""}>
                          <div className="InfoCt ani01">
                            <img src={checkIcon} alt="checkImg" />
                            <p className="">업무 향상성 극대화</p>
                          </div>
                          <div className="InfoCt ani02">
                            <img src={checkIcon} alt="checkImg" />
                            <p icon={checkIcon} className="">
                              빠르고 정확한 정보 검색
                            </p>
                          </div>
                          <div className="InfoCt ani03">
                            <img src={checkIcon} alt="checkImg" />
                            <p icon={checkIcon} className="">
                              ChatGPT기반
                            </p>
                          </div>
                          <div className="InfoCt ani04">
                            <img src={checkIcon} alt="checkImg" />
                            <p icon={checkIcon} className="">
                              스마트한 지식관리
                            </p>
                          </div>
                        </MainTitleInfoBox>
                      </UrlCt>
                    )}
                  </IntroContainer>
                </SwiperSlide>
              );
            })}

            <SwiperPaginationBtn className="swiper-pagination"></SwiperPaginationBtn>
          </Swiper>
        </MainSwiperCt>
        {createBotState !== "loading" ? (
          <>
            <ButtonContainer className="tab">
              <button
                className={inputBtnState === "file" ? "active" : ""}
                onClick={(e) => {
                  setInputBtnState("file");
                }}
              >
                <img src={IconDocu} alt={"문서로생성"} /> 문서로 생성
              </button>
              <button
                className={inputBtnState === "url" ? "active" : ""}
                onClick={(e) => {
                  setInputBtnState("url");
                }}
              >
                <img src={IconUrl} alt={"url생성"} />
                URL로 생성
              </button>
            </ButtonContainer>
          </>
        ) : (
          <></>
        )}

        <UrlForm name="" id="mainform" action="">
          {createBotState !== "loading" &&
            (inputBtnState === "url" ? (
              <InputCt>
                <InputForm
                  disabled={
                    createBotState === "loading"
                      ? true
                      : freeBotCount > 2
                        ? true
                        : false
                  }
                  inputValue={urlInputState}
                  inputSetValue={setUrlInputState}
                  placeholderText={
                    freeBotCount > 2
                      ? "무료 생성 개수 초과 '문의하기'를 통해서 생성 개수를 늘려보세요."
                      : "http(s)://로 시작하는 URL을 입력해 보세요."
                  }
                  buttonTitle="Assistant 생성"
                  buttonCallBackFunc={createBotToURL}
                />
              </InputCt>
            ) : (
              <>
                {freeBotCount > 2 ? (
                  <CompleteText
                    style={{ marginBottom: "0", fontWeight: "400" }}
                    className="chargeInfo"
                  >
                    무료 생성 개수 초과 '문의하기'를 통해 개수를 늘려보세요.
                  </CompleteText>
                ) : (
                  <InputCt>
                    <input
                      type="file"
                      id="fileUpload"
                      style={{ display: "none" }}
                      accept=".json, .txt, .text, .csv, .pdf, .doc, .docx, .epub, .ppt, .pptx, .xml, .xls, .xlsx, .hwp, .hwpx"
                      onChange={changeFileHandle}
                    />

                    <FileUpLoadLabel
                      htmlFor="fileUpload"
                      onClick={(e) => {
                        if (!isLoggedIn) {
                          e.preventDefault();
                          setLoginPopState(true);
                          return;
                        }
                      }}
                    >
                      <i className="ri-file-upload-fill" />
                      파일 업로드
                    </FileUpLoadLabel>
                  </InputCt>
                )}
              </>
            ))}
        </UrlForm>

        {createBotState === "home" ? (
          <InputCt></InputCt>
        ) : createBotState === "loading" ? (
          <LoadingCt>
            <LoadingIcons className="load-icon center">
              <span></span>
              <span></span>
              <span></span>
            </LoadingIcons>
            <MainTitle className="creating">
              MESwiser Assistant를 생성 중입니다.
              <br />수 분이 소요 될 수 있습니다. 생성이 완료되면 메일로
              알려드릴게요!
            </MainTitle>
            <MainTitle className="creating mobile">
              MESwiser Assistant를 생성 중입니다.
              <br />수 분이 소요 될 수 있습니다.
              <br />
              생성이 완료되면 메일로 알려드릴게요!
            </MainTitle>
            <LoadingText>MESwiser Assistant 생성중...</LoadingText>
          </LoadingCt>
        ) : (
          <CompleteCt id="chat--complete">
            {errorMessage && (
              <ErrorCt>
                <CompleteText className="mainTitle error red">
                  MESwiser Assistant 생성 실패
                </CompleteText>
                {errorMessage === "error" ? (
                  <CompleteText className="mainTitle ">
                    MESwiser Assistant 생성에 실패했습니다. <br /> 유효한
                    홈페이지 주소&#40;URL&#41;인지 확인해 보시기 바라며, <br />{" "}
                    계속해서 실패했다면 &#91;어시스턴트 관리&#93;의 문의하기
                    메뉴를 활용하시기 바랍니다.
                  </CompleteText>
                ) : (
                  <CompleteText className="mainTitle error url">
                    {errorMessage}
                  </CompleteText>
                )}
              </ErrorCt>
            )}
            {createBotState === "complete" && (
              <>
                <CompleteText className="mainTitle">
                  {`[${homeStatus.dataName}] MESwiser Assistant 생성 되었습니다! `}
                </CompleteText>
                {homeStatus.crawlRestricted === "Y" && (
                  <CompleteText className="mainTitle desc">
                    MESwiser Assistant을 완성하였습니다. <br /> &#40; 무료 생성
                    Assistant는 용량 제한이 있습니다. &#91;MESwiser Assistant
                    관리&#93; 메뉴에서 확인하세요. &#41;
                  </CompleteText>
                )}
                <CompleteBtn
                  id="useChatBtn"
                  className="useChatBtn"
                  onClick={(e) => {
                    sessionStorage.setItem("DISPLAY_MENU", "USES");
                    history.push("/dashboard");
                    //history.push("/assistant/dashboard");
                  }}
                >
                  Assistant 사용
                </CompleteBtn>
              </>
            )}
          </CompleteCt>
        )}
        <MoreInfoBtn
          onClick={(e) => {
            handleScroll();
          }}
          className="moreInfoBtn"
          aria-label="더보기 버튼"
          icon={iconDownArrow}
        ></MoreInfoBtn>
      </div>
      {confirmPopState && (
        <ConfirmPop
          setShowConfirmPop={setConfirmPopState}
          callback={popCallback?.normal}
          cancelCallback={popCallback?.cancel}
          title={popMessage}
        />
      )}
      {popState && (
        <AlertPop2
          popState={popState}
          setPopState={setPopState}
          text={popMessage}
        />
      )}
    </ChatMainCt>
  );
}

const ChatMainCt = styled.div`
  display: flex;
  justify-content: center;
  /* min-height: fit-content; */
  min-height: calc(100vh - 64px);
  padding-bottom: 50px;

  /*스와이퍼가 최상으로 자꾸 올라 오기 때문에 + 위젯때문에 zindex를 먹이기 위해서 position sticky 삽입  */
  position: sticky;

  @media (max-width: 480px) {
    /* height: calc(100vh - 64px); */
    height: fit-content;
    padding: 0 20px 50px;
  }

  & .MainCt {
    flex: 0 1 auto;
    height: auto;
    position: relative;
    /* background-color: #f7f9ff; */
    background-color: #fff;
  }
`;

const IntroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  //max-width: 1050px;
  /* padding-top: 150px; */
  padding-top: 8vh;

  @media (max-width: 1000px) {
    padding-top: 5vw;
    flex-direction: column;
  }
`;

const MainSwiperCt = styled.div`
  max-width: 1200px;
  @media (max-width: 1024px) {
    max-width: 100vw;
    margin: 0 auto;
  }
  @media (max-width: 480px) {
    max-width: 480px;
  }
  text-align: center;
  /* border: 1px solid red; */
  /* overflow: hidden; */
  & img.main {
    width: 300px;
    height: 300px;

    margin-bottom: 20px;
    @media (max-width: 1000px) {
      width: 200px;
      height: 200px;
    }
  }
`;

const MainTitle = styled.p`
  color: #000;
  letter-spacing: -0.01em;
  text-align: left;

  font-size: 40px;
  font-weight: 700;
  line-height: 55px;

  margin-bottom: 15px;

  &.mainTitle.top {
    & span.main {
      display: block;
      margin-bottom: 10px;
    }
  }
  & span.orange {
    font-size: 30px;
    color: #ff892a;
    line-height: 35px !important;
    display: inline-block;
  }

  &.mobile {
    font-size: 20px;
  }

  & strong {
    font-weight: bold;
  }

  &.desc {
    font-size: 18px;
    font-weight: 300;
    word-break: keep-all;
    &.web {
      width: 100%;
      letter-spacing: -1px;
      line-height: 30px;
    }

    @media (max-width: 480px) {
      font-size: 18px;
    }
  }

  &.creating {
    font-size: 25px;
    text-align: center;
    line-height: normal;

    &.mobile {
      display: none;
    }

    @media (max-width: 480px) {
      display: none;

      &.mobile {
        display: block;
        font-size: 18px;
      }
    }
  }

  @media (max-width: 1000px) {
    /* text-align: center; */
    font-size: 35px;
  }
  @media (max-width: 740px) {
    font-size: 28px;
    text-align: center;
    &.mainTitle {
      word-break: keep-all;
      .orange {
        font-size: 20px;
        letter-spacing: -1px;
        line-height: 34px !important;
      }
    }
  }

  @media (max-width: 480px) {
    margin-left: 0;
    color: #1a1a1a;
    font-size: 22px;
    line-height: 33px;
    min-width: 100%;
    &.mainTitle {
      font-size: 20px;
    }
    &.mainTitle {
      & span.orange {
        font-size: 20px;
        line-height: 24px !important;
      }
    }

    &.mainTitle.top {
      & span.main {
        font-size: 26px;
      }
    }
  }
`;
const UrlCt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;

  @media (min-width: 481px) {
    max-width: 1000px;
    width: 48%;
    margin-right: 30px;
  }
  @media (max-width: 1000px) {
    flex: 0 1 60%;
    order: 1;
    margin-right: 0;
  }

  @media (max-width: 480px) {
    flex: 1 1 auto;
    margin-left: 0;
  }
`;

const UrlForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 75%;
  margin: 20px auto;
`;

const InputCt = styled.div`
  margin-bottom: 25px;

  &:first-child {
    display: flex;
    justify-content: center;
    align-items: center;

    & button {
      width: unset;
      padding: 10px 25px;

      @media (max-width: 480px) {
        font-size: 18px;
      }
    }
  }
`;

const LoadingCt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LoadingText = styled.p`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;

  @media (max-width: 480px) {
    font-size: 18px;
    margin: 10px 0;
  }
`;

// complete
const CompleteCt = styled.div`
  text-align: center;
`;

const BotAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const CompleteText = styled.p`
  margin: 20px auto 25px;
  font-size: 25px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  word-break: break-all;

  max-width: 980px;

  @media (max-width: 480px) {
    max-width: 300px;
    word-break: keep-all;
  }

  &.chargeInfo {
    font-size: 17.5px;
    /* color: #fff; */
  }

  &.desc {
    font-weight: 300;
    font-size: 16px;
    color: #dcdcdc;
  }

  @media screen and (max-width: 600px) {
    font-size: 18px;
  }

  &.error.red {
    color: tomato;
  }

  &.error.url {
    padding: 0 20px;
    width: 80%;
    margin: 0 auto;
    color: tomato;
    font-weight: 400;
  }
`;

const CompleteBtn = styled.button`
  min-width: 240px;
  width: 137px;
  height: 78px;
  margin-right: 5px;
  padding: 10px;
  margin-bottom: 20px;
  border: 1.5px solid #fff;
  justify-content: center;
  align-items: center;

  color: #fff;

  font-size: 25px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: -0.01em;

  transition: 0.2s;

  border-radius: 100px;
  background: linear-gradient(340deg, #ff892a 0%, #ff892a 48.78%, #ffe618 100%);

  &.homeViewBtn {
    border: 1px solid #ff892a;
    background: transparent;
    color: #ff892a;
  }
  @media (max-width: 480px) {
    z-index: 1;
    height: 50px;
    font-size: 18px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const MoreInfoBtn = styled.button`
  width: 72px;
  height: 19px;

  position: absolute;
  bottom: -30px;
  left: calc(50% - 36px);

  background: url(${({ icon }) => icon}) no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;

  animation: ${BotAnimation} 1s infinite linear;

  @media (max-width: 480px) {
    bottom: -30px;
  }
`;

const LoadingIcons = styled.div`
  width: 100px;
  height: 20px;

  margin: 20px 0px 30px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: relative;

  & span {
    position: absolute;
    display: inline-block;
    background: #ff892a;
    width: 20px;
    height: 20px;
    border-radius: 100px;
  }
  & span:nth-of-type(1) {
    left: 0;
  }
  & span:nth-of-type(2) {
    left: 40%;
  }
  & span:nth-of-type(3) {
    right: 0;
  }

  /* Animation */
  & span {
    animation-name: loading;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  & span:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  & span:nth-of-type(3) {
    animation-delay: 0.4s;
  }

  /* Keyframes */
  @keyframes loading {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const ErrorCt = styled.div`
  text-align: center;
`;

const Up = keyframes`
    0% {
      transform: translateX(-3%);
    }
    100% {
      transform: translateY(0%);
    }

`;

const MainTitleInfoBox = styled.div`
  display: flex;
  /* width: 480px; */
  width: auto;
  flex-wrap: wrap;
  margin-bottom: 15px;

  &.mobile {
    width: 100%;
    margin-bottom: 50px;
    display: none;
  }

  & .InfoCt {
    display: flex;
    align-items: center;
    flex: 1 1 45%;
    /* @media (max-width: 1000px) {
      flex: 1 1 45%;
    } */

    @media (max-width: 480px) {
      flex: 1 1 45%;
    }
    p {
      color: #333;
      font-size: 14px;
      font-weight: 300;
      line-height: normal;
      margin: 3px 8px;
      letter-spacing: -1px;
    }
    img {
      width: 12px;
      height: 12px;
      margin-right: 0;

      @media (max-width: 1000px) {
        margin-bottom: 0;
      }
    }
    &.ani01,
    &.ani02,
    &.ani03,
    &.ani04 {
      animation: ${Up} 0.4s linear;
      animation-fill-mode: forwards;
    }
    &.ani01 {
      animation-delay: 0.3s;
    }
    &.ani02 {
      animation-delay: 0.45s;
    }
    &.ani03 {
      animation-delay: 0.6s;
    }
    &.ani04 {
      animation-delay: 0.75s;
    }
  }
`;

const ButtonContainer = styled.div`
  &.tab {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100px;
    background: #ededf3;
    width: 280px;
    margin: 0 auto 10px;

    @media (max-width: 1000px) {
      margin: 20px auto 10px;
    }

    & button {
      padding: 10px 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      color: #8c8c8c;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;
      flex: 1 0 auto;
      &:hover {
        opacity: 0.8;
      }

      &.active {
        color: #ff892a;
        text-align: center;
        font-weight: 700;
        background: #fff;
        border: 1px solid #ff892a;
        border-radius: 100px;
      }
      & img {
        margin-right: 4px;
      }
    }

    @media (max-width: 480px) {
      & button {
        font-size: 14px;
        letter-spacing: -0.5px;
      }
    }
  }
  &.default {
  }
`;

const FileUpLoadLabel = styled.label`
  background: #808080;
  color: #fff;
  padding: 15px 25px;
  font-size: 26px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  & i {
    margin-right: 10px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;
const SwiperPaginationBtn = styled.div`
  &.swiper-pagination {
    text-align: center !important;
    & .swiper-pagination-bullet-active {
      color: #3289fc;
    }
    & .swiper-pagination-bullet {
      background: rgba(50, 137, 252, 0.4);
      width: 5px;
      height: 5px;
      margin: 0 3px;
      bottom: 10px;
    }
  }
`;
