import { useContext, useEffect } from "react";
import "remixicon/fonts/remixicon.css";
import "../../../components/pages/chat/chat2.css";
import styled, { keyframes } from "styled-components";

export default function ChatExQCt({
  exqInName,
  setDescPopState,
  exList,
  setNewMessage,
  newMessage,
  sendMessage,
  botClass,
}) {
  // 닫기 버튼
  const closePopState = () => {
    setDescPopState("none");
  };

  // 메시지에 질문 담기
  const handleMessage = (e) => {
    e.stopPropagation();
    setNewMessage(e.target.innerText);
  };

  useEffect(() => {
    if (newMessage !== "") {
      sendMessage();
      setDescPopState("none");
    }
  }, [newMessage]);

  return (
    <Bg className={botClass}>
      <TextPopUpCt className={botClass}>
        <HeaderCt className={botClass}>
          <p className="title">{exqInName}</p>
          <button onClick={closePopState} className="closeBtn"></button>
        </HeaderCt>
        <TextPopUpContent>
          <ExQBox>
            {exList.map((data, idx) => {
              return (
                <ExQItem className={botClass} onClick={handleMessage} key={idx}>
                  {data.exData}
                </ExQItem>
              );
            })}
          </ExQBox>
        </TextPopUpContent>
      </TextPopUpCt>
    </Bg>
  );
}

const fadeIn = keyframes`
  from {
    transform: scale(1.1);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: scale(1.1);
    opacity: 1;
  }
  to {
    transform:    scale(1);
    opacity: 0;
  }
`;

const Bg = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  /* position: absolute; */
  position: fixed;

  animation: ${fadeIn} 0.1s ease-in-out forwards;
  transform: scale(1);

  &.own {
    border-radius: 25px;
    @media (max-width: 480px) {
      border-radius: 0;
    }
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const TextPopUpCt = styled.div`
  position: absolute;
  background: #fff;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65%;

  &.own,
  &.widget {
    width: 85%;
  }
  /* height: 90%; */
  padding: 10px;
  @media (max-width: 480px) {
    top: 50px;
    transform: translate(-50%, 5%);
    width: 90%;
    max-height: 70%;
    /* height: 80%; */
  }
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.42);
`;
const HeaderCt = styled.div`
  color: #000;

  font-family: Noto Sans KR;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & p.title {
    display: flex;

    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  &.own {
    p.title {
      font-size: 13px;
      letter-spacing: -1px;
      margin-bottom: 0;
    }
  }

  & button {
    @media (min-width: 481px) {
      width: 20px;
      height: 20px;
    }
    margin-bottom: 11.5px;
  }

  @media (max-width: 480px) {
  }
  @media (min-width: 481px) {
    p.title {
      font-size: 20px;
      margin: 10px 0;
      img {
        width: 60px;
        height: 60px;
        margin-bottom: 15px;
        border-radius: 15px;
      }
    }
  }
`;

const TextPopUpContent = styled.div`
  top: 0;
  left: 0;

  min-height: 100px;
  max-height: 350px;
  max-width: 100%;
  width: 100%;

  padding: 5px 10px 10px;
  overflow-y: auto;
  word-break: break-all;
  white-space: break-spaces;
  z-index: -1;
`;

const ExQBox = styled.ul``;
const ExQItem = styled.li`
  margin: 5px 0;
  padding: 10px;
  border-radius: 3px;
  line-height: normal;
  background: #e8edff;
  word-break: keep-all;
  &:hover {
    cursor: pointer;
    /* background: rgba(255, 137, 42, 0.23) !important; */
    background: #2a8cff;
    color: #fff;
    /* font-size: 13px; */
    font-weight: 700;
  }
  &.widget,
  &.own {
    margin: 5px 0;
    font-size: 12px;
    letter-spacing: -1px;
    word-break: keep-all;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 16px;
  }
`;
