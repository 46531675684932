import { useRef, useState } from "react";

export default function useSearch(initialValue) {
  const [inputValue, setInputValue] = useState(initialValue);

  const inputValueRef = useRef(inputValue);

  const handleInputValue = (e) => {
    setInputValue(e.target.value);
    inputValueRef.current = e.target.value;
  };

  const resetValue = () => {
    setInputValue("");
  };

  return [inputValue, handleInputValue, resetValue, inputValueRef];
}
