import { memo, useMemo, useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import InputForm from "components/molecules/InputForm.js";
import { PaginationContainer } from "utils/Pagination.js";
// 하이라이트 및 개행 처리 컴포넌트
import SearchItemCt from "./SearchItemCt";
import NormalButton from "components/atomic/NormalButton2";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider.js";
// import { PaginationContainer2 } from "../../../../utils/Pagination2.js";

export default function PlainSearchBox(props) {
  const {
    keyword,
    setKeyword,
    chatBotData,
    setPopState,
    setPopMessage,
    setTextState,
    textState,
  } = props ?? {};

  const [showResultState, setShowResultState] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [addArrayInClient, setAddArrayInClient] = useState([]);
  const [dynamicValue, setDynamicValue] = useState([]);
  const [initData, setInitData] = useState(false);
  const [answer, setAnswer] = useState(null);
  const [tokens, setTokens] = useState([]);

  // 개행 스테이트
  const [useNewLine, setUseNewLine] = useState(false);

  const emptyDataHistory = useRef(false);

  const PagingBtn = memo((props) => {
    const { text, ...otherProps } = props ?? {};
    return <PagingBtnComponent {...otherProps}>{text}</PagingBtnComponent>;
  });

  const asyncFuncArgs = useMemo(() => {
    if (chatBotData?.botUid) {
      return [chatBotData?.botUid, ...dynamicValue];
    } else {
      return [];
    }
  }, [dynamicValue]);

  const PlainSearchBtnOnClick = () => {
    if (!keyword) {
      setPopMessage("검색어를 입력해주세요");
      setPopState((prev) => !prev);
      return;
    }
    emptyDataHistory.current = true;
    setAnswer(null);
    setCurrentPage(1);
    setShowResultState(true);
    const searchType = 0;
    const ratio = 1;
    setDynamicValue([keyword, searchType, ratio]);
  };

  // 하이라이트 개행 버튼 관리 함수
  const handleTextState = (e) => {
    if (e.target.id === "enter") {
      setUseNewLine(!useNewLine);
    }
  };

  useEffect(() => {
    setAnswer(null);
    setKeyword("");
    setShowResultState(false);
  }, [chatBotData]);

  return (
    <>
      <InputCt>
        <InputForm
          formClass="dashboard asst"
          inputValue={keyword}
          inputSetValue={setKeyword}
          placeholderText={"검색어를 입력하세요"}
          buttonTitle="검색"
          buttonCallBackFunc={PlainSearchBtnOnClick}
        />
      </InputCt>

      <OptTitle>검색 조건</OptTitle>
      {/* 하이라이트 개행*/}
      <TextOptnBtnCt>
        <div className="unitCt">
          <p className="title">줄 바꿈</p>
          <div
            id="enter"
            className={useNewLine ? "toggle active" : "toggle"}
            onClick={handleTextState}
          >
            <div className="circle" id="enter" onClick={handleTextState}></div>
          </div>
        </div>
      </TextOptnBtnCt>

      {showResultState && (
        <ResultSection>
          {/* 페이지 */}
          <PaginationContainer
            state={answer}
            setState={setAnswer}
            tokens={tokens}
            setTokens={setTokens}
            useNewLine={useNewLine}
            opt={{
              isAsync: true,
              asyncFuncName: "usesBotToSearch",
              asyncFuncArgs: asyncFuncArgs,
              addArrayInClient: addArrayInClient,
              TotalTextCt,
            }}
            initData={initData}
            pageLength={5}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            DataWrapComponent={BoardContainer}
            DataItemComponent={(props) => <SearchItemCt {...props} />}
            ItemComponent={PagingBtnComponent}
            itemActiveClassName={"current"}
            dataNullMessage={"검색 결과가 없습니다"}
            PrevComponent={(props) => (
              <PagingBtn className={"prev"} text={"이전"} {...props} />
            )}
            NextComponent={(props) => (
              <PagingBtn className={"next"} text={"다음"} {...props} />
            )}
            PaginationNumWrap={PagingCt}
          />
        </ResultSection>
      )}
    </>
  );
}

/* PaginationContainer에서 TotalTextCt를 그려주는데, 해당 부분에 추가한 props를 여기에 가져와서 사용할 수 있다. */
const TotalTextCt = (props) => {
  const { dataTotalLength, setExpandCollapse } = props;
  const { isMobile } = useContext(isMobileContext);
  const handlePageCount = (data) => {
    if (data <= 5) return 1;

    return data / 5 !== data % 5 ? Math.ceil(data / 5) : data / 5;
  };

  return (
    <SearchItemInfoCt>
      {!isMobile && (
        <>
          <NormalButton
            className="history backList open"
            onClick={() => {
              setExpandCollapse(1); // 1: 모두펼침, 2: 모두닫힘
            }}
            buttonTitle={
              <>
                <i className="ri-zoom-in-line"></i> 모두 펼침
              </>
            }
            buttonClass={"cOrange small"}
          ></NormalButton>
          &nbsp;&nbsp;&nbsp;
          <NormalButton
            className="close"
            onClick={() => {
              setExpandCollapse(2); // 1: 모두펼침, 2: 모두닫힘
            }}
            buttonTitle={
              <>
                <i className="ri-zoom-out-line"></i> 모두 닫힘
              </>
            }
            buttonClass={"cOrangeReverse small"}
          ></NormalButton>
          &nbsp;&nbsp;&nbsp;
        </>
      )}

      <SearchItemInfoTotal>
        총 개수:
        <span className="num">{dataTotalLength ?? 0}</span>
      </SearchItemInfoTotal>
      <SearchItemInfoTotal>
        페이지수 :
        <span className="num">{handlePageCount(dataTotalLength)}</span>
      </SearchItemInfoTotal>
    </SearchItemInfoCt>
  );
};

const InputCt = styled.form`
  margin-bottom: 30px;

  @media (max-width: 480px) {
    margin-bottom: 0;
  }
`;

const ResultSection = styled.section`
  width: 100%;
  height: 100%;
  margin-top: 50px;

  & p.empty {
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    color: #888;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vw;
    width: 100%;

    @media (max-width: 480px) {
      font-size: 20px;
    }
  }
`;

const BoardContainer = styled.div`
  border-top: 2px solid #b9b9b9;
  border-bottom: 2px solid #b9b9b9;
`;

const PagingCt = styled.div`
  width: 100%;
  text-align: center;

  margin-top: auto;
  margin-bottom: 30px;
`;

const PagingBtnComponent = styled.button`
  border: 1px solid #ff892a;
  border-radius: 5px;
  margin: 10px 5px;
  padding: 5px 8px;

  color: #888;
  border: 1px solid #888;

  width: 45px;
  height: 35px;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &.current {
    border: 1px solid #ff892a;
    background-color: #ff892a;
    color: #fff;
  }

  &.next {
  }

  &.prev {
  }
`;

const SearchItemInfoCt = styled.div`
  margin-bottom: 10px;
  text-align: right;

  @media (min-width: 481px) {
    display: flex;
    justify-content: end;
    align-items: end;
  }
`;
const SearchItemInfoTotal = styled.p`
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;

  padding-right: 5px;

  &:first-child {
    border-right: 1px solid #dcdcdc;
  }
  &:last-child {
    border-right: 0;
    margin-right: 0;
  }
  & span.num {
    font-weight: 500;
    margin-left: 5px;
  }
`;
const SearchItemInfoPages = styled(SearchItemInfoTotal)`
  & span.num {
  }
`;

const TextOptnBtnCt = styled.div`
  display: flex;
  .unitCt {
    align-items: center;
    margin: 5px 0;
    display: flex;
    flex: 0 0 20%;
    @media (max-width: 480px) {
      flex: 0 0 35%;
    }
    .title {
      display: inline-block;
      color: #777;
      margin-right: 10px;

      letter-spacing: -1px;
      font-size: 14px;
    }
  }
  .toggle {
    display: inline-block;
    width: 40px;
    height: 20px;
    background: #ffe7ca;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    &.active {
      background-color: #b9f4bb;
    }
  }

  .circle {
    width: 20px;
    height: 20px;
    background: #f8b400;
    border-radius: 50%;
    position: absolute;
    /* top: 50%; */
    top: 0%;
    transition:
      transform 0.3s,
      background 0.3s;
  }

  /* 활성화 스타일 */
  .active .circle {
    /* top: 50%; */
    transform: translateX(25px) translateY(0%);
    background: #4caf50; /* 색상 변경 */
    box-shadow: 0px 1px 9px 1px rgba(0, 0, 0, 0.11);
  }
`;

const OptTitle = styled.p`
  color: #777;
  font-weight: 600;
  margin: 10px 0;
`;
