import "assets/styles/widget.css";
import { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import BaseChat from "./BaseChat.js";

import botApi from "../../../api/botApi.js";
import ownBotApi from "api/ownBotApi.js";
import { getNow } from "../../../utils/dateUtil.js";
import uuid from "react-uuid";

export default function OwnChat({ setShowOwnBot, showOwnBot, position }) {
  const [openChatRoom, setOpenChatRoom] = useState(false);
  const [messages, setMessages] = useState([]);
  const [chatBotData, setchatBotData] = useState(null);
  const [showGreeting, setShowGreeting] = useState(false);
  const randomUUID = useRef(uuid());
  const [exList, setExList] = useState([]);
  const [bottomData, setBottomData] = useState([]);

  const getBotExampleQuestionList = async (botUid) => {
    try {
      const { status, data } = await ownBotApi.selectBotExQuestion();
      if (status === 200 && data?.infoList) {
        setExList(JSON.parse(data.infoList));
      } else {
        setExList([]);
      }
      // 하단메뉴를 사용 안한다면 굳이 보여주지 않는다.
      if (["Y", "A"].includes(chatBotData?.useBottomMenu)) {
        const bottomMenuResponse = await ownBotApi.selectBottomMenuList();
        if (
          bottomMenuResponse.status === 200 &&
          bottomMenuResponse.data?.bottomMenuList
        ) {
          setBottomData(JSON.parse(bottomMenuResponse.data.bottomMenuList));
        } else {
          setBottomData([]);
        }
      } else {
        setBottomData([]);
      }
    } catch (error) {
      //
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    let isMount = true;
    (async () => {
      const { status, data } = await ownBotApi.getBotInfoByBotUid();
      if (status === 200) {
        const resultData = JSON.parse(data.botInfo);
        // console.log("resultData", resultData);
        setchatBotData(resultData);
      }
    })();
  }, []);

  useEffect(() => {
    if (messages.length === 0 && chatBotData) {
      let message = "";
      if (chatBotData.greetingMessage) message = chatBotData.greetingMessage;
      else message = "입력하신 URL주소에 포함 된 내용을 기반으로 답변드립니다.";

      setMessages([
        {
          message: message,
          type: "bot",
          messageType: "text",
          time: getNow(),
          id: uuid(),
        },
      ]);

      // 예시질문에 대해서 쿼리한다.
      getBotExampleQuestionList(chatBotData.botUid);
    }
  }, [chatBotData]);

  useEffect(() => {
    handleImgClick();
  }, [showOwnBot]);

  const handleImgClick = () => {
    // if (!chatBotData) alert("123");
    if (showGreeting) {
      setShowGreeting(() => false);
    }

    const messageData = {
      text: !openChatRoom ? "showChatBot" : "hideChatBot",
    };

    const targetWindow = window.parent;
    targetWindow.postMessage(messageData, "*");
    removeFollow();
  };

  const removeFollow = () => {
    setOpenChatRoom((prev) => !prev);
  };

  return (
    <Container className={position === "RB" ? "RB" : ""}>
      <BaseChat
        showQuestionBox={true}
        messages={messages}
        setMessages={setMessages}
        popupInfo={{
          isPopup: false,
          openChatRoom,
          setOpenChatRoom,
          clickFunc: handleImgClick,
        }}
        chatBotData={chatBotData}
        chatRoomId={randomUUID.current}
        handleSignOutApp={() => {}}
        isHistoryBack={false}
        exList={exList}
        BottomData={bottomData}
        // botclass로 basechat ui 스타일 지정
        botClass="own"
        // sidenave button 클릭시에도 사라질 수 있도록 스테이트 조정
        setShowOwnBot={setShowOwnBot}
      />
    </Container>
  );
}

const openModal = keyframes`
  0% {
    transform: translate(-0%,5%);
    -webkit-transform: translate(-0%,5%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(-0%, -0%);
    transform: translate(-0%, -0%);
    opacity: 1;
  }
`;

const Container = styled.div`
  transition: 0.3s;
  &.RB {
    width: 390px;
    height: 600px;
    position: absolute;
    top: -615px;
    right: 10px;

    box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.26);
    border-radius: 25px;
    background-color: transparent;

    @media (max-width: 1024px) {
      width: 350px;
      height: 450px;
      top: -460px;
    }
    @media (max-width: 480px) {
      position: fixed;

      top: 0;
      right: 0;
      border-radius: 0px;

      width: 100%;
      height: 100%;
      z-index: 99999;
      /* top: 65px; */
      /* height: calc(100% - 64px); */
      /* margin-top: 64px; */
    }

    animation: ${openModal} 0.2s ease-in-out;
  }
`;

const ChatAdsTextBox = styled.div`
  padding: 10px 15px;
  border-radius: 10px;
  position: relative;
  background: #ff892a;
  margin: 10px 5px 0 0;
  color: #fff;
  &::after {
    content: "";
    position: absolute;
    background: #ff892a;
    z-index: -1;
    width: 10px;
    height: 10px;
    bottom: -5px;
    left: 70%;
    transform: rotate(45deg);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  }
`;
