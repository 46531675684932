import { memo, useMemo, useState } from "react";
import styled from "styled-components";
import { PaginationContainer } from "utils/Pagination.js";
import HistSearchItemCt from "./HistSearchItemCt";

export default function HistPlainSearchBox(props) {
  const { chatBotData, setPopState, setPopMessage } = props ?? {};
  const [currentPage, setCurrentPage] = useState(1);
  const [addArrayInClient, setAddArrayInClient] = useState([]);
  const PagingBtn = memo((props) => {
    const { text, ...otherProps } = props ?? {};
    return <PagingBtnComponent {...otherProps}>{text}</PagingBtnComponent>;
  });

  const asyncFuncArgs = useMemo(() => {
    const searchType = 0;
    if (chatBotData?.botUid) {
      return [chatBotData?.botUid, searchType];
    } else {
      return [];
    }
  }, [chatBotData?.botUid]);

  return (
    <div>
      <BoardWrapper className={""}>
        <BoardHeader>
          <BoardHeadTitle className="subject">검색어</BoardHeadTitle>
          <BoardHeadTitle className="date">작성일</BoardHeadTitle>
          <DetailBtn></DetailBtn>
          {/* <BoardHeadTitle className="state">상태</BoardHeadTitle> */}
        </BoardHeader>
        {/* 테이블 */}
        {/* 페이지 */}
        <PaginationContainer
          opt={{
            isAsync: true,
            asyncFuncName: "getSearchHistList",
            asyncFuncArgs: asyncFuncArgs,
            addArrayInClient: addArrayInClient,
          }}
          pageLength={5}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          DataWrapComponent={BoardContainer}
          DataItemComponent={(props) => (
            <HistSearchItemCt
              setPopState={setPopState}
              setPopMessage={setPopMessage}
              {...props}
            />
          )}
          ItemComponent={PagingBtnComponent}
          itemActiveClassName={"current"}
          dataNullMessage={"검색 기록이 없습니다"}
          PrevComponent={(props) => (
            <PagingBtn className={"prev"} text={"이전"} {...props} />
          )}
          NextComponent={(props) => (
            <PagingBtn className={"next"} text={"다음"} {...props} />
          )}
          PaginationNumWrap={PagingCt}
        />
      </BoardWrapper>
    </div>
  );
}

const BoardContainer = styled.div`
  border-top: 2px solid #b9b9b9;
  border-bottom: 2px solid #b9b9b9;

  & p.empty {
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    color: #888;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vw;
    width: 100%;

    @media (max-width: 480px) {
      font-size: 20px;
    }
  }
`;

const PagingCt = styled.div`
  width: 100%;
  text-align: center;

  margin-top: auto;
  margin-bottom: 30px;
`;

const PagingBtnComponent = styled.button`
  border: 1px solid #ff892a;
  border-radius: 5px;
  margin: 10px 5px;
  padding: 5px 8px;

  color: #888;
  border: 1px solid #888;

  width: 45px;
  height: 35px;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &.current {
    border: 1px solid #ff892a;
    background-color: #ff892a;
    color: #fff;
  }

  &.next {
  }

  &.prev {
  }
`;

const BoardWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  &.hide {
    margin-top: 0px;
    margin-bottom: 0px;
    overflow: hidden;
    height: 0%;
  }

  & button.regist {
    font-size: 15px;
    line-height: 18px;
    padding: 9.5px 30.5px;
    float: right;
  }
`;

const BoardHeader = styled.div`
  display: flex;
  padding: 20px 10px;
  background: rgba(192, 192, 192, 0.1);

  @media (max-width: 480px) {
    padding: 10px 15px;
  }
`;

const BoardHeadTitle = styled.p`
  color: #555454;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;

  @media (min-width: 481px) {
    &.subject {
      width: 500px;
      /* min-width: 300px; */
      margin-right: 100px;

      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.date {
      width: 80px;
      min-width: 60px;
      margin-right: auto;
    }

    &.state {
      margin-right: 75px;
      flex: 0 0 auto;
    }
  }

  @media (max-width: 480px) {
    margin-right: 45px;
    font-size: 12px;
    flex-shrink: 0;
    &.subject {
      margin-right: 140px;
    }

    &.date {
    }
  }
`;
const DetailBtn = styled.button`
  width: 30px;
  height: 30px;
  background: url(${({ icon }) => icon}) no-repeat;
  background-size: contain;
  background-position: center;

  &.active {
    transform: rotate(180deg);
  }
`;
