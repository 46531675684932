import { Input, TextField } from "@mui/material";
import NormalButton from "components/atomic/NormalButton2";
import { useContext, Fragment, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { chatContext } from "utils/providers/ChatContextProvider";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function ConfirmPopInput({
                                          setShowConfirmPop,
                                          callback,
                                          cancelCallback,
                                          title,
                                          description,
                                          btnText,
                                          popClass,
                                          target,
                                        }) {
  const { chatBotData } = useContext(chatContext);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef();

  const Cancel = () => {
    setShowConfirmPop(false);
    cancelCallback && cancelCallback();
  };

  const CallBack = () => {
    setShowConfirmPop(false);
    callback && callback(inputValue, chatBotData.botUid);
  };

  const handleInputValue = (e) => {
    setInputValue(e.target.value);
  };

  // setInputValue로 하니깐 inputValue값은 바뀌지만, input 태그 안의 값은 그대로 바뀌지 않아서, 아래처럼 tag 속성값을 직접 변경
  useEffect(() => {
    if (!!inputRef.current && !!target?.dataName) {
      const input = inputRef.current.querySelector("input");
      if (input) input.value = target.dataName;
    }
  }, [target]);

  return (
    <ConfirmModal>
      <Wrpper className={popClass}>
        <Header>
          <HTitle>
            {title.split("\\n").map((txt, index) => (
              <Fragment key={index}>
                {txt}
                <br />
              </Fragment>
            ))}
          </HTitle>
        </Header>

        <Body>
          {description && <span className="txt">{description}</span>}
          <Input
            ref={inputRef}
            autoFocus
            vlaue={inputValue}
            onChange={handleInputValue}
            placeholder={"폴더명을 입력해주세요"}
          />
          <ButtonGroup>
            <NormalButton
              disabled={false}
              buttonId="cancel"
              buttonTitle="취소"
              buttonClass="cDel"
              callBackFunc={Cancel}
            />
            <NormalButton
              disabled={false}
              buttonTitle={btnText ? btnText : "확인"}
              buttonClass="cOrange"
              callBackFunc={() => {
                CallBack();
              }}
            />
          </ButtonGroup>
        </Body>
      </Wrpper>
    </ConfirmModal>
  );
}

export function ConfirmPopSelect({
                                   setShowConfirmPop,
                                   callback,
                                   cancelCallback,
                                   title,
                                   description,
                                   btnText,
                                   popClass,
                                   // veiwFolder,
                                   folderList,
                                   setFolderList,
                                 }) {
  const { chatBotData } = useContext(chatContext);

  const [selectedValue, setSelectedValue] = useState("");
  const [requireMessage, setRequireMessage] = useState();

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const Cancel = () => {
    setShowConfirmPop(false);
    cancelCallback && cancelCallback();
  };

  const CallBack = () => {
    if (callback) {
      if (selectedValue !== "") {
        callback(folderList[selectedValue].dataUid);
        setShowConfirmPop(false);
      } else {
        setRequireMessage("폴더를 선택해주세요");
      }
    }
  };

  // useEffect(() => {
  //   veiwFolderList();
  // }, []);

  return (
    <ConfirmModal>
      <Wrpper className={popClass}>
        <Header>
          <HTitle>
            {title.split("\\n").map((txt, index) => (
              <Fragment key={index}>
                {txt}
                <br />
              </Fragment>
            ))}
          </HTitle>
        </Header>

        <Body>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">폴더</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedValue}
                label="Age"
                onChange={handleChange}
              >
                {folderList?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={index}>
                      {item.dataName ?? ""}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          {requireMessage && <span className="txt">{requireMessage}</span>}

          <ButtonGroup>
            <NormalButton
              disabled={false}
              buttonId="cancel"
              buttonTitle="취소"
              buttonClass="cDel"
              callBackFunc={Cancel}
            />
            <NormalButton
              disabled={false}
              buttonTitle={btnText ? btnText : "확인"}
              buttonClass="cOrange"
              callBackFunc={CallBack}
            />
          </ButtonGroup>
        </Body>
      </Wrpper>
    </ConfirmModal>
  );
}

const ConfirmModal = styled.div`
  /* position: absolute; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const Wrpper = styled.div`
  width: 280px;
  text-align: center;
  padding-top: 20px;

  position: absolute;

  top: 40%;
  left: 50%;
  transform: translateX(-50%);

  &.dash {
    /* top: 40%;
      left: 50%; */
    @media (min-width: 1600px) {
      top: 40%;
      left: 40%;
    }
  }

  @media (max-width: 480px) {
    left: 50%;
  }

  border: 1px solid #dbdbdb;
  /* padding: 0 10px; */
  background: #fff;
  z-index: 99;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.2);

  min-width: 82px;
  right: 10px;

  &.own {
    left: 50%;
  }

  max-height: 250px;
`;

const Header = styled.strong`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const HTitle = styled.div`
  width: 100%;
  line-height: normal;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 0;
  padding-top: 30px;
  word-break: keep-all;
`;

const Body = styled.div`
  background: #fff;
  border: 1px solid transparent;
  & span.txt {
    font-size: 13px;
    letter-spacing: -0.5px;
    word-break: keep-all;
    color: tomato;
    margin: 15px 0 5px;
    display: inline-block;
  }
`;

const ButtonGroup = styled.div`
  margin-top: 25px;
  display: flex;

  button {
    width: 50% !important;
    height: 45px !important;
    display: block;
    font-size: 13px !important;
    font-weight: 500;
    margin: 0;
    padding: 0;

    border: none !important;
    border-top: 1px solid #ff892a !important;
    border-radius: 0;
    cursor: pointer;

    &.cDel {
      color: #ff892a !important;
    }
  }
`;
