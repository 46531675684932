import React, { createContext } from "react";

//초기값 따로 지정없이 dashboard의 index에서 Provider에 props로 전달받아 사용
export const userChatBotContext = createContext({
  userChatBotList: [],
  setUserChatBotList: () => {},
  getUserChatBotList: () => {},
});

export default function UserChatBotContextProvider({
  userChatBotList,
  children: Components,
}) {
  return (
    <userChatBotContext.Provider value={userChatBotList}>
      {Components}
    </userChatBotContext.Provider>
  );
}
