import { Box } from "@mui/material";
import InputBox from "components/atomic/InputBox";
import NormalButton from "components/atomic/NormalButton2";
import styled from "styled-components";

export default function InputForm({
  disabled,
  inputId,
  inputValue,
  inputSetValue,
  placeholderText,
  buttonId,
  buttonTitle,
  buttonCallBackFunc,
  formClass,
  inputType,
  addType,
  onChangeFunc,
}) {
  return (
    <Box style={{ width: "100%" }}>
      <InputWrapper className={formClass}>
        <InputBox
          inputclass={formClass}
          disabled={disabled}
          inputId={inputId}
          value={inputValue}
          setValue={inputSetValue}
          onChangeFunc={onChangeFunc}
          placeholderText={placeholderText}
          type={inputType}
        />
        <NormalButton
          inputclass={formClass}
          buttonClass={"input round"}
          disabled={disabled}
          buttonId={buttonId}
          buttonTitle={buttonTitle}
          callBackFunc={buttonCallBackFunc}
        />
      </InputWrapper>
      {addType === "url" && (
        <Desc>http &#40;s&#41;://로 시작하는 URL을 입력해 보세요.</Desc>
      )}
    </Box>
  );
}

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  /* border-radius: 100px; */
  /* border: 1px solid #ff892a; */
  /* box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.11); */
  border-radius: 6px 20px 20px 6px;
  background: #e5f1ff;
  overflow: hidden;
  margin-bottom: 10px;

  @media (min-width: 481px) {
    height: 45px;
  }
`;

const Desc = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: #757373;
`;
