import { ErrorCatch } from "api/ErrorCatch.js";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import botApi from "api/botApi.js";
import { signAppContext } from "utils/providers/SignContextProvider.js";
import { chatContext } from "./providers/ChatContextProvider.js";
import { userChatBotContext } from "./providers/UserChatBotContextProvider.js";
import styled from "styled-components";

/**
 * @property
 * - externalOptions: Array<string>; -> selectBox에 사용할 추가 데이터 ( value, label 속성값은 필수 )
 *   - botUid에 "일반" 과 같이 특수 형태로 넣을 경우, 아래의 exterlnalMenus에도 값을 추가 하여야 함.
 * - externalHandleChange: Function; -> 특수 형태로 넣어진 메뉴들이 선택될 때 마다 실행할 함수.
 * - usesOptions: 어시스턴트 활용 처럼 동적인 LIST를 넣을 경우 전체 처리.
 * */
const ChatBotSelect = (props) => {
  const { externalHandleChange, externalOptions, initialOption, usesOptions } =
    props;

  const { chatBotData, setChatBotData } = useContext(chatContext);
  const { userChatBotList } = useContext(userChatBotContext);
  const { handleSignOutApp } = useContext(signAppContext);
  const [selectedOption, setSelectedOption] = useState(
    initialOption ??
      (chatBotData
        ? {
            ...chatBotData,
            value: chatBotData?.botUid,
            label: chatBotData?.botName,
          }
        : usesOptions
          ? {
              ...usesOptions[0],
              value: usesOptions[0]?.botUid,
              label: usesOptions[0]?.botName,
            }
          : "-"),
  );

  const externalMenus = useMemo(() => ["general"], []);

  useEffect(() => {
    if (chatBotData?.botUid && selectedOption === "-") {
      // 새로고침에 의한 처리라면 데이터를 새로 불러오지 말고. 선택 봇을 변경 처리.
      const botInfo = chatBotData;
      botInfo.label = botInfo.botName;
      botInfo.value = botInfo.botUid;
      setSelectedOption(botInfo);
      if (props?.setSelectedOption) {
        props?.setSelectedOption(botInfo);
      }
      //handleChange(chatBotData);
    }
  }, [chatBotData, selectedOption]);

  const handleChange = async (option) => {
    let { botUid } = option;
    if (!externalMenus.includes(botUid)) {
      // 같은걸 고른거라고 판단되면 굳이 추가 로딩 하지 말자.
      if (
        chatBotData &&
        chatBotData.botUid === botUid &&
        selectedOption?.botUid === botUid
      ) {
        console.log("same chatbot selected.");
        // return;
      }

      try {
        const { status, data } = await botApi.getBotInfoByBotUid(botUid);
        if (status === 200) {
          const botInfo = JSON.parse(data.botInfo);
          botInfo.label = botInfo.botName;
          botInfo.value = botInfo.botUid;
          console.log("Select Bot:", botInfo.botName);
          setSelectedOption(botInfo);
          //23.08.31.codelua memberUid가 현재 로그인한 사용자가 아니라면 남의 봇을 활용하는것임. 메모리에 안올림.
          if (botInfo.memberUid === sessionStorage.getItem("memberUid"))
            setChatBotData(botInfo);
          if (props?.setSelectedOption) {
            props?.setSelectedOption(botInfo);
          }
        }
      } catch (err) {
        ErrorCatch(err, handleSignOutApp);
      }
    } else {
      externalHandleChange && externalHandleChange(option);
      setSelectedOption(option);
    }
  };

  useEffect(() => {
    // 유저 자신의 챗봇데이터는 없고 usesOption으로 넘어온게 있다면 첫번째 데이터를 기준으로 셀렉션을 줄것이니 해당 데이터로 세팅
    if (!chatBotData && usesOptions) {
      var botUid = usesOptions[0].botUid;
      handleChange({ botUid: botUid });
    }
  }, []);

  // 0725 챗봇 이름 변경시 selectbox내 변경이 안되는 부분 수정
  useEffect(() => {
    setSelectedOption({
      ...chatBotData,
      value: chatBotData?.botUid,
      label: chatBotData?.botName,
    });
  }, [chatBotData]);

  return (
    <Container>
      {((userChatBotList && chatBotData) || externalOptions || usesOptions) && (
        <Select
          value={selectedOption ?? "-"}
          onChange={handleChange}
          options={[
            ...(externalOptions ?? []),
            ...(userChatBotList ?? [])
              .filter((info) => info.status === "01")
              .map((d) => ({
                ...d,
                value: d.botUid,
                label: d.botName,
              })),
            ...(usesOptions ?? [])
              .filter((info) => info.status === "01")
              .map((d) => ({
                ...d,
                value: d.botUid,
                label: d.botName,
              })),
          ]}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  .css-1dimb5e-singleValue {
    overflow: unset;
  }
`;

export default memo(ChatBotSelect);
