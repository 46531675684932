import React from "react";
import styled from "styled-components";

export default function AgreeToTermsOfUse({
  checkTermsOfUse,
  setCheckTermsOfUse,
  setTermsOfUse,
}) {
  return (
    <ServiceAgreeItem>
      <input
        onChange={(e) => {
          setCheckTermsOfUse((current) => !current);
        }}
        id="serviceChk"
        type="checkbox"
        checked={checkTermsOfUse ? "checked" : ""}
      ></input>
      <SaLabel htmlFor="serviceChk">
        서비스 이용약관<SaSpan>(필수)</SaSpan>
      </SaLabel>
      <ServiceBtn
        className="serviceBtn"
        onClick={(e) => {
          e.preventDefault();
          setTermsOfUse((prev) => !prev);
        }}
      >
        전문 보기
      </ServiceBtn>
    </ServiceAgreeItem>
  );
}

const ServiceAgreeItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
`;

const SaLabel = styled.label`
  font-size: 12px;
`;

const SaSpan = styled.span`
  font-size: 10px;
  margin-left: 4px;
  color: #333;
`;

const ServiceBtn = styled.button`
  margin-left: auto;
  background-color: transparent;
  text-decoration: underline;
  text-underline-offset: 2px;
  font-size: 12px;
  &:focus {
    font-size: 12px;
  }
`;
