// 혼합대화창, 혼합검색을 합친 싱글 뷰어.
import { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import CombineChatMobile from "./CombineChatMobile.js";
import CombineKnowViewMobile from "./CombineKnowViewMobile.js";
import CombineHistViewMobile from "./CombineHistViewMobile.js";
import uuid from "react-uuid";
import { getNow } from "../../../../utils/dateUtil.js";
import styles from "../styles.module.css";
import ConfirmPop from "components/templates/popup/ConfirmPop.js";
import NormalButton from "components/atomic/NormalButton2.js";
import Logo from "../../../../assets/images/LOGO_Assistant.svg";

export default function ComplexViewMobile({ props }) {
  const {
    isLoggedIn,
    chatBotData,
    chatRoomId,
    setChatRoomId,
    openLoginPop,
    handleSignOutAppFromChat,
    setPopState2,
    setPopMessage,
  } = props;

  // 이전기록 탭 ( 히스토리 사용 )
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedChatRoom, setSelectedChatRoom] = useState(null);

  // 혼합대화창 탭 ( 로그인을 했을 경우에만 사용 되어진다. )
  const [mainTabState, setMainTabState] = useState("chat");
  // const [mainTabState, setMainTabState] = useState("histChat");

  // 로딩 설정
  const [knowLoading, setKnowLoading] = useState(false);
  // 채팅창
  const [firstMessage, setFirstMessage] = useState(true); // 탭이 변경 될때 초기화 되서 여기서 보내기.
  const [messages, setMessages] = useState([]);

  // 지식창
  const [knowViewState, setKnowViewState] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [knowDataCount, setKnowDataCount] = useState(0);
  const [knowDataList, setKnowDataList] = useState([]);
  const [sentenceList, setSentenceList] = useState([]);

  // 채팅 응답 설정
  const [useNewLine, setUseNewLine] = useState(false);
  const [useSentence, setUseSentence] = useState(true);
  const [sliderMaxVal, setSliderMaxVal] = useState(80); // 시맨틱
  const [sliderMinVal, setSliderMinVal] = useState(20); // 키워드
  const [botRatioVal, setBotRatioVal] = useState([0, 80]);

  // 확인 메시지 창
  const [confirmPopState, setConfirmPopState] = useState(false);
  const [confirmPopMessage, setConfirmPopMessage] = useState("");
  const [popCallback, setPopCallback] = useState({
    normal: () => {},
    cancel: () => {},
  });

  // 함수모음
  const handleTabChange = (tabName) => {
    if (tabName === "chat") {
      // 챗방을 초기화 해준다.
      setInitChatTab();
      setMainTabState("chat");
    } else if (tabName === "histChat") {
      if (!firstMessage) {
        setConfirmPopMessage("대화내용이 초기화 됩니다. 진행하시겠습니까?");
        setPopCallback(() => ({
          cancel: () => {},
          normal: () => {
            setMessages([]);
            setMainTabState("histChat");
          },
        }));
        setConfirmPopState(true);
      } else {
        setMainTabState("histChat");
      }
    }
  };

  const setInitChatTab = () => {
    setFirstMessage(true);
    setChatRoomId(uuid());
    setIntroMessage();
    setTokens([]);
    setKnowDataCount(0);
    setKnowDataList([]);
    setSentenceList([]);
  };

  const setIntroMessage = () => {
    if (messages.length === 0 && chatBotData) {
      let message = "";
      if (chatBotData.greetingMessage) message = chatBotData.greetingMessage;
      else message = "궁금한 것을 물어보면 AI가 답변해 드려요!";

      setMessages([
        {
          message: message,
          type: "bot",
          messageType: "text",
          time: getNow(),
          id: uuid(),
          isFirst: true,
        },
      ]);
    }
  };

  useEffect(() => {
    setIntroMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatBotData]);

  useEffect(() => {
    setSelectedChatRoom(false);
  }, [mainTabState]);

  return (
    <MainCt>
      {/* 로그인 및 로고 header 영역 */}
      {!isLoggedIn && (
        <div className={styles.TopRowMobile}>
          <img src={Logo} alt="메스와이저 로고" />
          <NormalButton
            buttonId="loginBtn"
            buttonTitle={"로그인"}
            buttonClass="round small cOrange"
            callBackFunc={openLoginPop}
          ></NormalButton>
          <span className="desc login">{`로그인을 하시면 대화의 기록을 저장합니다`}</span>
        </div>
      )}

      {isLoggedIn && (
        <div className={styles.TopRowMobile}>
          <div className={styles.LoginCt}>
            <img src={Logo} alt="메스와이저 로고" />
            <NormalButton
              buttonId="loginBtn"
              buttonTitle={"로그아웃"}
              buttonClass="round small cOrange"
              callBackFunc={() => {
                if (window.confirm("정말 로그아웃 하실거여유우~?")) {
                  handleSignOutAppFromChat();
                }
              }}
            ></NormalButton>
          </div>
          <TabBtnCt>
            <button
              className={mainTabState === "chat" ? "active" : ""}
              onClick={() => {
                handleTabChange("chat");
              }}
            >
              채팅창
            </button>
            <button
              className={mainTabState === "histChat" ? "active" : ""}
              onClick={() => {
                handleTabChange("histChat");
              }}
            >
              이전기록
            </button>
          </TabBtnCt>
        </div>
      )}
      {/* 로그인 및 로고 header 영역 */}

      {/*complex chat & veiw container 영역 */}
      <BodyCt>
        {mainTabState === "chat" && (
          <>
            {knowViewState && (
              <CombineKnowViewMobile
                props={{
                  knowLoading,
                  tokens: useSentence ? sentenceList : tokens,
                  knowDataCount,
                  knowDataList,
                  useNewLine,
                  setUseNewLine,
                  useSentence,
                  setUseSentence,
                  setKnowViewState,
                }}
              />
            )}
            <CombineChatMobile
              props={{
                isLoggedIn,
                messages,
                setMessages,
                chatBotData,
                chatRoomId,
                botRatioVal,
                setBotRatioVal,
                sliderMaxVal,
                setSliderMaxVal,
                sliderMinVal,
                setSliderMinVal,
                setTokens,
                setKnowDataCount,
                setKnowDataList,
                setSentenceList,
                setKnowLoading,
                firstMessage,
                setFirstMessage,
                setKnowViewState,
                setPopState2,
                setPopMessage,
                chatClass: "COMBINEMOBILE",
              }}
            />
          </>
        )}
        {/* 이전기록 탭 처리 부분 */}
        {mainTabState === "histChat" && (
          <>
            {knowViewState && (
              <CombineKnowViewMobile
                props={{
                  knowLoading,
                  tokens: useSentence ? sentenceList : tokens,
                  knowDataCount,
                  knowDataList,
                  useNewLine,
                  setUseNewLine,
                  useSentence,
                  setUseSentence,
                  setKnowViewState,
                }}
              />
            )}
            <CombineHistViewMobile
              props={{
                chatBotData,
                currentPage,
                setCurrentPage,
                selectedChatRoom,
                setSelectedChatRoom,
                setKnowLoading,
                setTokens,
                setKnowDataCount,
                setKnowDataList,
                setSentenceList,
                setKnowViewState,
              }}
            />
          </>
        )}
      </BodyCt>
      {confirmPopState && (
        <ConfirmPop
          setShowConfirmPop={setConfirmPopState}
          callback={popCallback?.normal}
          cancelCallback={popCallback?.cancel}
          title={confirmPopMessage}
        />
      )}
    </MainCt>
  );
}

const MainCt = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  margin: 0;
  /* padding: 0 10vw; */
  /* background-color: #081120; */
  background-color: #f7f9ff;
  color: #ffffff;

  font-size: 14px;
  & p {
    margin: 0;
  }
`;

const KnowSection = styled.section`
  width: 100%;
  height: 100%;
`;

const TabBtnCt = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 100%;

  @media (min-width: 481px) {
    width: fit-content;
    align-self: start;
    justify-content: unset;
  }
  & button {
    padding-right: 15px;
    color: #888;
    position: relative;
    &:not(:last-child):after {
      content: "";
      position: absolute;

      width: 1.5px;
      height: 100%;
      right: 0;
      background: #dcdcdc;
    }
    &:last-child {
      margin-left: 7px;
    }
    &.active {
      color: #ff892a;
      font-weight: 500;
      text-decoration: underline;
      text-underline-position: under;
      text-underline-offset: 5px;
      text-decoration-thickness: 3px;
    }
  }
`;

const SearchOptCt = styled.div`
  display: flex;
`;

const BodyCt = styled.div`
  /* border: 1px solid tomato; */
  /* height: 100%; */
`;
