import "remixicon/fonts/remixicon.css";
import styled, { keyframes } from "styled-components";
import logo from "assets/images/LOGO_Assistant.svg";
import MarketingPolicy from "../../pages/docs/MarketingPolicy";
import PersonalPolicy from "../../pages/docs/PersonalPolicy";
import TermOfUsePolicy from "../../pages/docs/TermOfUsePolicy";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AgreeToOverAge from "./AgreeToOverAge";
import AgreeToPersonalData from "./AgreeToPersonalData";
import AgreeToTermsOfUse from "./AgreeToTermsOfUse";
import AgreeToMarketingInfo from "./AgreeToMarketingInfo";

import { isMobileContext } from "../../../utils/providers/BrowserEnvContextProvider";

export default function SignUpAgreeLayer({
  socialSignIn,
  infoStruct,
  setInfoStruct,
  setOpenTermsOfAlvis,
}) {
  const { isMobile } = useContext(isMobileContext);

  const history = useHistory();

  const [requiredAgreeMessage, setRequiredAgreeMessage] = useState("");

  // 약관 동의 시리즈.
  const [allCheck, setAllCheck] = useState(false);
  const [checkOverAge, setCheckOverAge] = useState(false);
  const [checkTermsOfUse, setCheckTermsOfUse] = useState(false);
  const [checkPersonalData, setCheckPersonalData] = useState(false);
  const [checkMarketingInfo, setCheckMarketingInfo] = useState(false);

  const [termsOfUse, setTermsOfUse] = useState(false);
  const [personalData, setPersonalData] = useState(false);
  const [marketingInfo, setMarketingInfo] = useState(false);

  /* 이용동의 항목 값 */
  const agreeItems = {
    overAge: checkOverAge,
    termsOfUse: checkTermsOfUse,
    personalData: checkPersonalData,
    marketingInfo: checkMarketingInfo,
  };

  useEffect(() => {
    setCheckOverAge(allCheck);
    setCheckTermsOfUse(allCheck);
    setCheckPersonalData(allCheck);
    setCheckMarketingInfo(allCheck);
  }, [allCheck]);

  return (
    <>
      <Wrapper>
        <Container>
          <Header>
            <LogoCt>
              <Logo src={logo} alt="로고" />
              회원가입
            </LogoCt>
            {isMobile && (
              <button
                type="button"
                className="ri-close-line"
                onClick={() => {
                  setOpenTermsOfAlvis(false);
                  history.push({
                    pathname: "/",
                  });
                }}
              ></button>
            )}
            {!isMobile && (
              <button
                className="ri-close-line"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTermsOfAlvis(false);
                  history.push({
                    pathname: "/",
                  });
                }}
              ></button>
            )}
          </Header>
          {(termsOfUse || personalData || marketingInfo) && (
            <ServiceArgreeContainer className="full-content">
              <PolicyContainer>
                {termsOfUse && (
                  <TermOfUsePolicy setTermsOfUse={setTermsOfUse} />
                )}
                {personalData && (
                  <PersonalPolicy setPersonalData={setPersonalData} />
                )}
                {marketingInfo && (
                  <MarketingPolicy
                    setMarketingInfo={setMarketingInfo}
                    callFrom={"signUp"}
                  />
                )}
              </PolicyContainer>
            </ServiceArgreeContainer>
          )}
          {!termsOfUse && !personalData && !marketingInfo && (
            <>
              <ResignHeader>
                <img src={logo} alt="인트로이미지" />
                {/* <ResignHeaderLogo img={logo}></ResignHeaderLogo> */}
                <br />
                <ResignNotice>
                  MESwiser에 오신걸 환영합니다.
                  <br /> MESwiser 사용을 위해&nbsp;
                  {isMobile && <br />}
                  먼저 약관을 동의해주세요.
                </ResignNotice>
              </ResignHeader>

              <ServiceArgreeContainer>
                <ServiceArgreeAll>
                  <input
                    onChange={(e) => setAllCheck((current) => !current)}
                    id="serviceAllChk"
                    type="checkbox"
                    checked={allCheck}
                  ></input>
                  <label htmlFor="serviceAllChk">모든 약관 동의</label>
                </ServiceArgreeAll>
                <ServiceAgreeContainerWapper>
                  <AgreeToOverAge
                    checkOverAge={checkOverAge}
                    setCheckOverAge={setCheckOverAge}
                  />
                  <AgreeToTermsOfUse
                    checkTermsOfUse={checkTermsOfUse}
                    setCheckTermsOfUse={setCheckTermsOfUse}
                    setTermsOfUse={setTermsOfUse}
                  />
                  <AgreeToPersonalData
                    checkPersonalData={checkPersonalData}
                    setCheckPersonalData={setCheckPersonalData}
                    setPersonalData={setPersonalData}
                  />
                  <AgreeToMarketingInfo
                    checkMarketingInfo={checkMarketingInfo}
                    setCheckMarketingInfo={setCheckMarketingInfo}
                    setMarketingInfo={setMarketingInfo}
                  />
                </ServiceAgreeContainerWapper>
              </ServiceArgreeContainer>
              <InValidText>
                {requiredAgreeMessage && (
                  <span className="invalid">{requiredAgreeMessage}</span>
                )}
              </InValidText>
              <ResignFooter>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      !checkOverAge ||
                      !checkTermsOfUse ||
                      !checkPersonalData
                    ) {
                      setRequiredAgreeMessage("필수 동의 항목을 체크해주세요.");
                    } else {
                      socialSignIn(
                        infoStruct.id,
                        infoStruct.email,
                        infoStruct.socialType,
                        agreeItems
                      );
                      setInfoStruct(null);
                    }
                  }}
                >
                  회원가입
                </button>
              </ResignFooter>
            </>
          )}
        </Container>
      </Wrapper>
    </>
  );
}

/* 기존 애니메이션 */
const openModal = keyframes`
  0% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
`;
const Container = styled.div`
  width: 500px;
  height: auto;

  animation: ${openModal} 0.2s ease-in-out;
  animation-fill-mode: both;
  display: inline-block;
  position: absolute;
  top: 20%;
  left: 50%;
  background: #fff;
  opacity: 0;
  border-radius: 15px;

  //padding: 0 30px 30px;

  @media (max-width: 480px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //max-height: none;
    border-radius: 0;
  }
`;

const Header = styled.div`
  border-radius: 15px 15px 0px 0px;
  display: flex !important;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  padding: 0 20px;
  border-bottom: solid 1px #dcdcdc;
  background: #fff;

  @media (max-width: 480px) {
    margin: 30px 0 5px 0;
  }

  & button.ri-close-line {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 35px;
  }
`;

const LogoCt = styled.h2`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
`;

const Logo = styled.img`
  //width: 50px;
  height: 14px;
  margin-right: 5px;
`;

const ResignHeader = styled.div`
  //width: 92%;
  //margin: 15px 15px;
  //left: 0;
  //top: 18px;
  padding: 20px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  & img {
    //flex: 0 1 30%;
    width: 300px;
    margin: 20px auto;

    @media (max-width: 480px) {
      height: auto;
      width: 70%;
      margin: 0;
    }
  }
`;

const ResignNotice = styled.div`
  margin: 10px 0;
  font-size: 16px;
  letter-spacing: -0.01em;
  line-height: normal;
  text-align: center;
`;

const PolicyContainer = styled.div`
  @media (max-width: 1000px) {
    height: 600px !important;
    overflow-y: scroll;
    transform: translate(0%, -1rem);
    height: 70%;
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 15px 55px;
  }
  @media (max-width: 480px) {
    left: 0;
    background-color: #fff;
    overflow-y: scroll;

    top: 50px;
    padding: 15px 20px;
    padding-bottom: 40px;
    position: relative;
    height: 87vh;
    width: 100%;
    overflow-y: scroll;
    transform: translateY(0%);
  }
`;

const ServiceArgreeContainer = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.full-content {
    height: 70vh;
    max-height: calc(75vh - 90px);
    @media (max-width: 480px) {
      height: 80vh;
      max-height: 100vh;
    }
  }
`;

const ServiceArgreeAll = styled.div`
  width: 100%;
  //margin: 0px 10px 15px 10px;
  //text-align: center;
  font-size: 16px;
  font-weight: 300;
  padding: 10px 0;
  //background-color: #ccc;
  border-radius: 3px;

  & label {
    margin-left: 5px;
  }
`;

const ServiceAgreeContainerWapper = styled.div`
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 3px;
  margin-bottom: 20px;

  max-height: 190px;
`;

const ResignFooter = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 0 20px;
  text-align: center;

  & button {
    //border: 1.5px solid;
    //margin: 2% 5%;
    //padding: 0;
    //display: inline-block;
    width: 100%;
    //min-width: 178px;
    height: 45px;
    border-radius: 5px;
    text-align: center;
    //line-height: 50px;
    //font-size: 1rem;
    background-color: #ff892a;
    color: #fff;
    //font-weight: normal;
    //-webkit-transition: 0.15s;
    //transition: 0.15s;
  }
`;

const InValidText = styled.div`
  display: block;
  margin-bottom: 10px;
  color: #cf514a;
  font-size: 12px;
  text-align: center;
`;
