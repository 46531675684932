import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

export default function ToastMessage(props) {
  const { toastMessage, toastState, handleToastClose } = props;
  return (
    <Snackbar
      open={toastState.open}
      onClose={handleToastClose}
      TransitionComponent={toastState.Transition}
      // message={toastMessage}
      key={toastState.Transition.name}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <Alert onClose={handleToastClose} severity={toastState.type}>
        {toastMessage}
      </Alert>
    </Snackbar>
  );
}
// error , info , success , warning
