import { createContext, useState } from "react";

const TAB_STATE = {
  TAB1: "initial",
  TAB2: "filtered",
};

const ExtractedDataTabManageContext = createContext({
  tabState: TAB_STATE.TAB1,
  updateTabState: () => {},
});

export { ExtractedDataTabManageContext, TAB_STATE };

export default function ExtractedDataTabManageProvider(props) {
  const { children } = props;

  const [tabState, setTabState] = useState(TAB_STATE.TAB1);

  const updateTabState = (updateTabState) => {
    setTabState(updateTabState);
  };

  return (
    <ExtractedDataTabManageContext.Provider
      value={{
        tabState,
        updateTabState,
      }}
    >
      {children}
    </ExtractedDataTabManageContext.Provider>
  );
}
