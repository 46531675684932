import React from "react";
import styled from "styled-components";

export default function AgreeToOverAge({ checkOverAge, setCheckOverAge }) {
  return (
    <ServiceAgreeItem>
      <input
        onChange={(e) => {
          setCheckOverAge((current) => !current);
        }}
        id="serviceAgeChk"
        type="checkbox"
        checked={checkOverAge ? "checked" : ""}
      ></input>
      <SaLabel htmlFor="serviceAgeChk">
        14세 이상입니다.<SaSpan>(필수)</SaSpan>
      </SaLabel>
    </ServiceAgreeItem>
  );
}

const ServiceAgreeItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
`;

const SaLabel = styled.label`
  font-size: 12px;
`;

const SaSpan = styled.span`
  font-size: 10px;
  margin-left: 4px;
  color: #333;
`;
