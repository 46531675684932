import styled from "styled-components";
import BotIcon from "../../../assets/images/default_bot.png";

export default function AsstEmpty(props) {
  const { MessageText } = props;
  // 진희 뒤를 부탁해요.
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <TextWrapper>
        <EmptyIcon icon={BotIcon} />
        {MessageText ? (
          <EmptyText>{MessageText}</EmptyText>
        ) : (
          <EmptyText>아직 생성 된 Assistant가 없습니다.</EmptyText>
        )}
      </TextWrapper>
    </div>
  );
}

const TextWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const EmptyIcon = styled.div`
  width: 50%;
  height: 150px;
  margin-bottom: 20px;
  background: url(${({ icon }) => icon}) no-repeat;
  background-size: contain;
  background-position: center;
  filter: grayscale(1);
`;

const EmptyText = styled.p`
  font-size: 16px;
  color: #666;
  & span {
    color: tomato;
  }
`;
