import botApi from "api/botApi.js";
import { useContext, useEffect, useReducer, useState } from "react";
import {
  Route,
  useHistory
} from "react-router-dom/cjs/react-router-dom.min.js";
import styled, { keyframes } from "styled-components";
import { signAppContext } from "utils/providers/SignContextProvider.js";
import GlobalHeader from "../../organisms/GlobalHeader.js";
import iconLoading from "assets/images/progressDot.svg";
import AsstUses from "./AsstUses.js";
import AsstCreate from "./AsstCreate.js";
import AsstManage from "./AsstManage.js";
import AsstHistorye from "./AsstHistory.js";
import AsstKnowledge from "./AsstKnowledge.js";
import AsstQNA from "./AsstQNA.js";
import AsstDirectQna from "./AsstDirectQna.js";
import "./dashboard.css";
import DashboardHome from "./DashboardHome.js";
import {
  DashboardContainer,
  DashboardContent,
  DashboardFlexContainer,
  DashboardNavComponent,
  DashboardSubContainer
} from "./dashboardStyle.js";
import Statistics from "./Statistics.js";
import { chatContext } from "../../../utils/providers/ChatContextProvider.js";
import { userChatBotContext } from "utils/providers/UserChatBotContextProvider.js";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider.js";
import PopupProvider from "../../../utils/providers/PopupProvider.js";

const menuReducer = (state, action) => {
  switch (action.type) {
    case "DISPLAY_MENU":
      return {
        ...state,
        menu: action.menu
      };
    default:
      return state;
  }
};

// CREATE - 챗봇 생성, MANAGE - 챗봇 관리, CHAT - 챗봇 기록, RESPONSE - 응답 관리, QNA - 문의하기, DIRECTQNA - 도입 문의
export default function Dashboard() {
  const initialMenuState = sessionStorage.getItem("DISPLAY_MENU")
    ? { menu: sessionStorage.getItem("DISPLAY_MENU") }
    : {
      menu: "USES"
    };

  const [loginPopState, setLoginPopState] = useState(false);

  const history = useHistory();
  const { chatBotData, setChatBotData } = useContext(chatContext);
  const { userChatBotList } = useContext(userChatBotContext);
  const { handleSignOutApp, isLoggedIn } = useContext(signAppContext);
  const [activeMenu, dispatchMenu] = useReducer(menuReducer, initialMenuState);
  const [myBotList, setMyBotList] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const { isMobile } = useContext(isMobileContext);
  const [mobileMenu, setMobileMenu] = useState(false);

  const getChatBotData = async (botUid) => {
    try {
      const { status, data } = await botApi.getBotInfoByBotUid(botUid);
      if (status === 200) {
        const resultData = JSON.parse(data.botInfo);
        setChatBotData(resultData);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    // dashboard 어느 메뉴던 새로고침이 되거나 첫 진입 시 세션의 botData status가 00 이거나 99인 경우 다른 챗봇으로 교체 처리.
    // App.js 에서 수정하기에는 홈에서 진입 하는 경우 제어를 할 수 없어 여기서 처리. 23.07.12.codelua
    if (chatBotData) {
      if (["00", "99"].includes(chatBotData.status)) {
        setChatBotData(null);
        // 다른 챗봇이 있다면 교체 없다면 널 처리
        if (userChatBotList && userChatBotList.length > 1) {
          const pickBot = userChatBotList.reduce(function(acc, tmp) {
            if (acc == null && tmp.status === "01") {
              return tmp;
            }
            return acc;
          }, null);
          if (pickBot !== null) {
            getChatBotData(pickBot.botUid);
          }
        } else {
          sessionStorage.removeItem("botData");
          setChatBotData(null);
        }
      }
    }
  }, [chatBotData]);

  return (
    <PopupProvider>
      <DashboardContainer className={"container--main"}>
        <>
          <GlobalHeader
            setLoginPopState={setLoginPopState}
            setMobileMenu={setMobileMenu}
            mobileMenu={mobileMenu}
            dispatchMenu={dispatchMenu}
          />
          <DashboardFlexContainer className={"container--sub"}>
            <DashboardNav
              DashboardNav={DashboardNav}
              activeMenu={activeMenu}
              dispatchMenu={dispatchMenu}
            />
            <DashboardSubContainer>
              <DashboardContent className={"dashboard--container"}>
                {activeMenu.menu === "DASH" &&
                  (loadingStatus ? (
                    <LoadingCt>
                      <LoadingProgress img={iconLoading} />
                    </LoadingCt>
                  ) : (
                    <DashboardHome
                      myBotList={myBotList}
                      setMyBotList={setMyBotList}
                    />
                  ))}

                <MenuComponent activeMenu={activeMenu} />
              </DashboardContent>
            </DashboardSubContainer>
          </DashboardFlexContainer>
        </>
      </DashboardContainer>
    </PopupProvider>
  );
}

const MenuComponent = ({ activeMenu }) => {
  return (
    <>
      {activeMenu.menu === "USES" && <AsstUses />}
      {activeMenu.menu === "CREATE" && <AsstCreate />}
      {activeMenu.menu === "MANAGE" && <AsstManage />}
      {activeMenu.menu === "HISTORY" && <AsstHistorye />}
      {activeMenu.menu === "KNOWLEDGE" && <AsstKnowledge />}
      {activeMenu.menu === "QNA" && <AsstQNA />}
      {activeMenu.menu === "DIRECTQNA" && <AsstDirectQna />}
      {activeMenu.menu === "STATS" && <Statistics />}
    </>
  );
};

const DashboardNav = ({ activeMenu, dispatchMenu }) => {
  // const { pathname } = useLocation();
  const menuList = [
    // {
    //   menu: "DASH",
    //   icon: "ri-home-3-line",
    //   name: "상황판",
    // },
    {
      menu: "USES",
      icon: "ri-brain-line",
      name: "Assistant 활용"
    },
    {
      menu: "CREATE",
      icon: "ri-edit-box-line",
      name: "Assistant 생성"
    },
    {
      menu: "MANAGE",
      icon: "ri-article-line",
      name: "Assistant 관리"
    },
    {
      menu: "HISTORY",
      icon: "ri-edit-2-line",
      name: "Assistant 기록"
    },
    {
      menu: "KNOWLEDGE",
      icon: "ri-chat-new-fill",
      name: "지식 관리"
    },
    {
      menu: "STATS",
      icon: "ri-numbers-line",
      name: "통계"
    },
    {
      menu: "QNA",
      icon: "ri-chat-3-line",
      name: "문의하기"
    },
    {
      menu: "DIRECTQNA",
      icon: "ri-code-s-slash-fill",
      // icon: "ri-time-line",
      name: "도입 문의"
    }
    // {
    //   menu: "SUBS",
    //   icon: "ri-bank-card-line",
    //   name: "서비스모델 구독",
    // },
  ];

  return (
    <DashboardNavComponent>
      {/*<div className={"dashboard--choose--chatbot"}>*/}
      {/*  <div></div>*/}
      {/*</div>*/}
      <nav className={"dashboard--menu"}>
        <ul>
          {menuList.map(({ menu, icon, name }) => {
            return (
              <li
                key={menu}
                className={activeMenu.menu === menu ? "active" : ""}
                onClick={(e) => {
                  sessionStorage.setItem("DISPLAY_MENU", menu);
                  dispatchMenu({ type: "DISPLAY_MENU", menu: `${menu}` });
                }}
              >
                <i className={icon}></i> {name}
              </li>
            );
          })}
        </ul>
      </nav>
    </DashboardNavComponent>
  );
};

const Progress = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const LoadingCt = styled.div`
  height: 100%;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LoadingProgress = styled.div`
  width: 100%;
  height: 130px;
  background: url(${({ img }) => img}) no-repeat;
  background-position: center;
  background-size: contain;
  filter: invert(1);
  margin: 30px 0;

  animation: ${Progress} 1s infinite linear;
`;
