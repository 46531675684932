import { useContext, useState } from "react";
import styled, { css } from "styled-components";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";
import { PriceUtil } from "utils/priceUtil";
import checkOrange from "../../assets/images/icon_checkOrange.svg";

export default function MainContentPriceMobile() {
  const { isMobile } = useContext(isMobileContext);
  const [tabState, setTabState] = useState("02");
  const [subTabState, setSubTabState] = useState("basic");
  const { pricingPolicyContentMobile } = PriceUtil;

  const handleSubTab = (currnetTab) => {
    setSubTabState(currnetTab);
  };

  return (
    <SectionCt className="typePrice">
      <Section className="typePrice">
        <p className="title">
          지금 필요한 플랜을 비교해 보고
          <br />
          <span className="strong">
            최신 GPT가 탑재된 {isMobile && <br />} MESwiser를 경험해 보세요
          </span>
        </p>

        <TabBtnCt>
          <button
            className={tabState === "01" ? "active" : ""}
            onClick={(e) => {
              setTabState("01");
              setSubTabState("basic");
            }}
          >
            무료 버전
          </button>
          <button
            className={tabState === "02" ? "active" : ""}
            onClick={(e) => {
              setTabState("02");
              setSubTabState("basic");
            }}
          >
            유료 버전
          </button>
        </TabBtnCt>

        <TabContent className="tabContent">
          {tabState === "01" ? (
            <TabContentCt tabState={tabState} className="free">
              <TabContentHeader className="header">
                <p className="text">{pricingPolicyContentMobile[0].title}</p>
              </TabContentHeader>
              <TabContentBody className="tabContentBody">
                <div className="data">
                  <img
                    src={checkOrange}
                    className="checkIcon"
                    alt="check icon"
                  />
                  <p>{pricingPolicyContentMobile[0].unit}</p>
                </div>

                <div className="data">
                  <img
                    src={checkOrange}
                    className="checkIcon"
                    alt="check icon"
                  />
                  <p>{pricingPolicyContentMobile[0]?.VectorStore}</p>
                </div>

                <div className="data">
                  <img
                    src={checkOrange}
                    className="checkIcon"
                    alt="check icon"
                  />
                  <p>{pricingPolicyContentMobile[0]?.QuestionNum}</p>
                </div>

                <div className="data">
                  <img
                    src={checkOrange}
                    className="checkIcon"
                    alt="check icon"
                  />
                  <p className="">
                    {pricingPolicyContentMobile[0]?.knowledgeManage}
                  </p>
                </div>

                <div className="data">
                  <img
                    src={checkOrange}
                    className="checkIcon"
                    alt="check icon"
                  />
                  <p className="">
                    {pricingPolicyContentMobile[0]?.serviceUpgrade}
                  </p>
                </div>
              </TabContentBody>
            </TabContentCt> // 유료 콘텐츠는 2개 Tab으로 다시 관리
          ) : (
            <TabContentCt tabState={tabState} className="charge">
              <TabContentHeader
                subTabState={subTabState}
                className="header charge"
              >
                <button
                  className="basicBtn text"
                  onClick={() => {
                    handleSubTab("basic");
                  }}
                >
                  베이직
                </button>
                <button
                  onClick={() => {
                    handleSubTab("premium");
                  }}
                  className="premiumBtn text"
                >
                  프리미엄
                </button>
              </TabContentHeader>
              {pricingPolicyContentMobile
                .filter((item) => item.tag === subTabState)
                .map((item, idx) => (
                  <TabContentBody
                    key={idx + item.tag}
                    className="tabContentBody charge"
                  >
                    <p className="price">
                      <span className="priceTxt">{item.price}</span>
                      <span className="monthText priceTxt">원/월(봇당)</span>
                    </p>

                    <div className="data">
                      <img
                        src={checkOrange}
                        className="checkIcon"
                        alt="check icon"
                      />
                      <div className="textCt">
                        <p>{item.unit}</p>
                        <p className="desc">{item?.unitDesc}</p>
                      </div>
                    </div>

                    <div className="data">
                      <img
                        src={checkOrange}
                        className="checkIcon"
                        alt="check icon"
                      />
                      <p>{item?.dataStorage}</p>
                    </div>

                    <div className="data">
                      <img
                        src={checkOrange}
                        className="checkIcon"
                        alt="check icon"
                      />
                      <div className="textCt">
                        <p>{item?.VectorStore}</p>
                        <p className=" desc">{item?.VectorStoreDesc}</p>
                      </div>
                    </div>

                    <div className="data">
                      <img
                        src={checkOrange}
                        className="checkIcon"
                        alt="check icon"
                      />
                      <div className="textCt">
                        <p>{item?.QuestionNum}</p>
                        <p className="desc">{item?.QuestionNumDesc}</p>
                      </div>
                    </div>

                    <div className="data">
                      <img
                        src={checkOrange}
                        className="checkIcon"
                        alt="check icon"
                      />
                      <div className="textCt">
                        <p className="">{item?.inviteUser}</p>
                        <p className="desc">{item?.inviteUserDesc}</p>
                      </div>
                    </div>

                    <div className="data">
                      <img
                        src={checkOrange}
                        className="checkIcon"
                        alt="check icon"
                      />
                      <div className="textCt">
                        <p className="">{item?.knowledgeManage}</p>
                        <p className="desc">{item?.knowledgeManageDesc}</p>
                      </div>
                    </div>

                    <div className="data">
                      <img
                        src={checkOrange}
                        className="checkIcon"
                        alt="check icon"
                      />
                      <div className="textCt">
                        <p className="">{item?.adminSupport}</p>
                        <p className="desc">{item?.adminSupportDesc}</p>
                      </div>
                    </div>

                    <div className="data">
                      <img
                        src={checkOrange}
                        className="checkIcon"
                        alt="check icon"
                      />
                      <p className="">{item?.serviceUpgrade}</p>
                    </div>
                  </TabContentBody>
                ))}
            </TabContentCt>
          )}
        </TabContent>

        <PriceDescCt>
          <p className="priceDesc">
            <span className="mark">※</span>
            <span className="text">부가세 별도, 봇 기준 단가</span>
          </p>
          <p className="priceDesc">
            <span className="mark">※</span>
            <span className="text">
              고급 지식 분석, 정제, 변화, 표준화 및 품질검사로 더
              {isMobile && <br />} 만족도 높은 Assistant를 이용하실 수 있습니다.
              {isMobile && <br />}
              <strong> (가격별도 협의)</strong>
            </span>
          </p>
        </PriceDescCt>
      </Section>
    </SectionCt>
  );
}

const SectionCt = styled.div`
  display: flex;
  justify-content: center;

  &.typePrice {
    padding: 100px;
    background: #f7f9ff;
  }
`;

const Section = styled.section`
  .title {
    word-break: keep-all;
    color: #1a1a1a;
    text-align: center;
    font-size: 40px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.4px;
    margin-bottom: 41px;
    .strong {
      font-weight: bold;
    }
    @media (max-width: 1000px) {
      width: 85vw;
      font-size: 34px;
    }
    @media (max-width: 480px) {
      font-size: 26px;
      letter-spacing: -2px;
      .strong {
      }
    }
  }

  &.typePrice {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
  }
`;

const TabBtnCt = styled.div`
  margin-bottom: 60px;
  max-width: 540px;
  border-radius: 87px;
  background: #e7ebf9;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1000px) {
    font-size: 32px;
  }
  @media (max-width: 480px) {
    width: 335px;
    height: 79px;
    margin-bottom: 40px;
  }

  button {
    transition: 0.2s;
    font-size: 30px;

    line-height: normal;
    letter-spacing: -0.34px;
    margin: 9px 13px 10px;

    color: rgba(0, 0, 0, 0.4);
    padding: 8px 50px;
    border-radius: 54px;

    @media (max-width: 1000px) {
      padding: 8px 20px;
    }

    @media (max-width: 480px) {
      font-size: 26px;
      flex: 0 0 auto;
      margin: 9px;
      width: 149px;
      height: 61px;
    }
  }

  button.active {
    padding: 8px 57px;
    color: #1a1a1a;
    border-radius: 54px;
    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    font-weight: 700;
    @media (max-width: 1000px) {
      padding: 8px 20px;
    }
  }
`;

const TabContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const TabContentCt = styled.div`
  transition: 0.2s;
  position: relative;
  overflow: hidden;
  background: #fff;
  width: 350px;

  border: 1px solid #aab7db;
  border-radius: 39px;

  /* 무료 유료 영역 */
  &.free,
  &.charge {
    display: none;
  }

  &.free {
    ${({ tabState }) => {
      if (tabState === "01") {
        return css`
          display: flex;
          flex-direction: column;
          border: 3px solid #9bade4;
          box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.15);

          .header {
            color: #ff6600;
          }
        `;
      }
    }}
  }

  &.charge {
    ${({ tabState }) => {
      if (tabState === "02") {
        return css`
          display: flex;
          flex-direction: column;
          border: 3px solid #9bade4;

          box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.15);

          .header {
            color: #ff6600;
          }
        `;
      }
    }}
  }
  /* 무료 유료 영역 */
`;

const TabContentHeader = styled.div`
  height: 80px;
  padding: 30px 35px;

  font-size: 24px;
  line-height: normal;

  display: flex;
  align-items: center;
  justify-content: space-between;

  letter-spacing: -0.24px;
  font-weight: 600;
  border-bottom: 1px solid #e9edf9;

  &.charge {
    border-radius: 39px 39px 0 0;
    padding: 0;
    background: #7d95de;

    ${({ subTabState }) => {
      if (subTabState === "basic") {
        return css`
          & button.basicBtn {
            background: #ffffff;
            color: #ff6600;
          }
        `;
      } else {
        return css`
          & button.premiumBtn {
            background: #ffffff;
            color: #ff6600;
          }
        `;
      }
    }}

    button {
      font-size: 24px;
      font-weight: 600;
      height: 100%;
      flex: 1 0 50%;
      color: #fff;
      border-radius: 35px 35px 0 0;
      background: #7d95de;
    }
  }
`;

const TabContentBody = styled.div`
  padding: 30px 35px;
  background: #ffffff;

  & .data {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    color: #444444;

    & .checkIcon {
      margin-right: 10px;
    }

    & .desc {
      color: #6271a5;
      font-size: 12px;
      letter-spacing: -1px;
    }
  }

  /* 무료 유료 영역 */
  & .text {
    font-size: 26px;
    color: #455db1;
  }

  & .price {
    font-size: 32px;
    font-weight: 700;
    margin-left: auto;
    margin-bottom: 25px;
    position: relative;
    letter-spacing: -1px;

    & .priceTxt {
      z-index: 1;
      position: relative;
    }

    &::after {
      content: "";
      position: absolute;
      width: 110%;
      height: 13px;
      background: #e7ebf9;
      bottom: -5px;
      left: -2%;
      z-index: 0;
      opacity: 0.5;
    }
    & .monthText {
      font-size: 16px;
      font-weight: 400;
      color: #515151;
      letter-spacing: -1px;
    }
  }

  &.charge {
    display: flex;
    flex-direction: column;
    .data {
      & .textCt {
        display: flex;
        flex-wrap: wrap;
      }
      & .desc {
        color: #7c7c7c;
        margin-top: 5px;
      }
    }
  }
  /* 무료 유료 영역 */
`;

const PriceDescCt = styled.div`
  width: 100%;
  text-align: left;

  .priceDesc {
    color: #727272;
    font-size: 14px;
    margin-bottom: 9px;

    display: flex;
    align-items: start;
    .mark {
      font-size: 13px;
      font-weight: 700;
      color: #ff6600;
      margin-right: 5px;
    }
    .text {
      line-height: 18px;
    }

    strong {
      font-weight: 500;
      color: #101010;
    }
  }
`;
