import uuid from "react-uuid";
import { getNow } from "utils/dateUtil.js";
import { useEffect, useState } from "react";
import styled from "styled-components";
import BaseChat from "components/pages/chat/BaseChat.js";

export default function PlainChatBox(props) {
  const { chatBotData, setPopState, setPopMessage, handleSignOutApp } =
    props ?? {};
  // 대화사용
  const [openChatRoom, setOpenChatRoom] = useState(null);
  const [messages, setMessages] = useState([]);
  const [chatRoomId, setChatRoomId] = useState(null);

  useEffect(() => {
    if (chatBotData) {
      setChatRoomId(uuid()); // 채팅방 초기화
      let message = "";
      if (chatBotData.greetingMessage) message = chatBotData.greetingMessage;
      else message = "입력하신 URL주소에 포함 된 내용을 기반으로 답변드립니다.";

      setMessages([
        {
          message: message,
          type: "bot",
          messageType: "text",
          time: getNow(),
          id: uuid(),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatBotData]);

  return (
    <div style={{ width: "100%" }}>
      <div>
        {/* baseChat 자리 */}
        <ExChat>
          <BaseChat
            popupInfo={{
              isPopup: false,
              setOpenChatRoom,
              openChatRoom,
            }}
            showQuestionBox={true}
            messages={messages}
            setMessages={setMessages}
            chatBotData={chatBotData}
            chatRoomId={chatRoomId}
            handleSignOutApp={handleSignOutApp}
            isHeader={true}
            botClass="HISTORY"
            setPopMessage={setPopMessage}
            setPopState={setPopState}
          />
        </ExChat>
      </div>
    </div>
  );
}

const ExChat = styled.div`
  /* border: 1px solid #dcdcdc; */
  width: 100%;
  height: 600px;

  /* border-radius: 10px; */
  .chat--bot2.chat--cover.HISTORY {
    margin: 0;
  }
`;
