import  React from 'react';
import Tooltip from '@mui/material/Tooltip';

import InfoIcon from '@mui/icons-material/Info';
import styled from 'styled-components';

export default function ToolTips({type, message, children,data }) {

  // let updatedMessage
  // if(type === "alert"){
  // }else if(type === "info"){
  // }else if(type==="error")

  return (<Tooltip title={message}>
      <MyIconBtn>
        <InfoIcon color={type} sx={{mr:1, ml:1}}/>
        <span className='desc'>
                    {data}
                    </span>
      </MyIconBtn>
    </Tooltip>
  )
}


const MyIconBtn = styled.button`
    margin: 0;
    padding: 0;
    display: flex;
    align-items:center;
    & svg{
        margin: 0;
    }
    & .desc{
        display: inline-block;
        width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    `