import { createContext, useEffect, useState } from "react";

const ExtractedDataManageContext = createContext({
  fileInfo: {
    dataName: null,
    dataUid: null,
    dataType: null,
    memberUid: null,
    extension: null,
  },
  updateFileInfo: () => {},

  selectedPage: 0,
  updateSelectedPage: () => {},
  goFirstPage: () => {},

  selectedItemList: [],
  updateSelectedItem: () => {},
  resetSelectedItem: () => {},
});

export { ExtractedDataManageContext };

export default function ExtractedDataManageProvider(props) {
  const { children, fileInfo: propFileInfo } = props;
  const [fileInfo, setFileInfo] = useState(
    JSON.parse(sessionStorage.getItem("fileInfo") || null) ||
      propFileInfo || {
        dataName: null,
        dataUid: null,
        dataType: null,
        memberUid: null,
        extension: null,
      },
  );

  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedItemList, setSelectedItemList] = useState([]);

  const updateFileInfo = (updatedFileInfo) =>
    setFileInfo({ ...updatedFileInfo });

  // 넘어온 값이 이상한 값이 아닐 경우에만 동작하도록 아래 조건 추가.
  const updateSelectedPage = (page) =>
    !isNaN(Number(page)) && setSelectedPage(Number(page));

  const goFirstPage = () => updateSelectedPage(1);

  const updateSelectedItem = (data, list) => {
    if (typeof data === "boolean") {
      // data는 기본 true false임

      if (data) {
        let tbuidList = list.map((item) => item.tbUid);
        setSelectedItemList(() => {
          return tbuidList;
        });
      } else {
        setSelectedItemList(() => {
          return [];
        });
      }
    } else {
      // data는 기본 tbUid임
      if (selectedItemList.includes(data)) {
        let updateList = selectedItemList.filter((item) => item !== data);
        setSelectedItemList(updateList);

        return updateList;
      } else {
        let updateList = [...selectedItemList, data];
        setSelectedItemList(updateList);

        return updateList;
      }
    }
  };

  const resetSelectedItem = () => {
    setSelectedItemList(() => {
      return [];
    });
  };

  useEffect(() => {
    if (!!fileInfo.dataName)
      sessionStorage.setItem("fileInfo", JSON.stringify(fileInfo));
    return () => {
      sessionStorage.removeItem("fileInfo");
      sessionStorage.removeItem("currentView");
    };
  }, [fileInfo]);

  return (
    <ExtractedDataManageContext.Provider
      value={{
        fileInfo,
        setFileInfo,
        selectedPage,
        updateFileInfo,
        updateSelectedPage,
        goFirstPage,
        updateSelectedItem,
        selectedItemList,
        resetSelectedItem,
      }}
    >
      {children}
    </ExtractedDataManageContext.Provider>
  );
}
