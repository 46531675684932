import SockJS from "sockjs-client";
import Stomp from "stompjs";
//sockJS - web_socket의 기능을 갖는 javascript 라이브러리
//stompJS - web_socket의 서브프로토컬로 메시지의 형식, 유형, 내용 등을 정의
const SocketService = {
  init: (setSocket, setClient, onOpen, onClose) => {
    //WebSocketConfig.java에 설정한 EndPoint(통신을 위한 주소)로 socekt 생성
    const socket = new SockJS(
      `${process.env.REACT_APP_API_HOST}/socket/botMessage`
    );
    socket.onclose = onClose;
    socket.onopen = onOpen;
    setSocket(socket);

    //생성한 socket을 기반으로 client 생성
    const client = Stomp.over(socket);
    const serverName = `${process.env.REACT_APP_API_HOST}/socket/botMessage`;

    //debug 함수 실제로 통신내용(message)을 볼 수 있도록
    client.debug = function (message) {
      if (message.includes("connected to server")) {
        console.log("Connected to server ", serverName);
      } else {
        console.log(message);
      }
    };

    client.reconnect_delay = 1000;
    setClient(client);
  },
  disconnect: (socket, client) => {
    client && client.connected && client.disconnect(() => {});
    socket && socket.close();
  },
  connect: (client, setIsConnectedSocket) => {
    //stompClient.connect( header, connectCallbackFunc, errorCallbackFunc, closeEventCallback)
    client &&
      client.connect(
        { login: "", passcode: "" },
        (frame) => {
          setIsConnectedSocket(true);
        },
        (error) => {
          setIsConnectedSocket(false);
          console.log("connect msg :::: ", error);
        }
      );
  },
  subscribe: (client, url, callback) => {
    if (client) {
      //url로 발행되는 message를 client가 받고 callback을 수행
      return client.subscribe(url, callback);
    }
    return null;
  },
  unsubscribe: (subscription) => {
    subscription && subscription.unsubscribe(); 
  },  
};

export default SocketService;
