import { ErrorCatch } from "api/ErrorCatch";
import {
  sendPwdChangeCertKey,
  checkPwdChangeCertKey,
  updateMemberPassword,
} from "api/memberApi";
import LoadingComponent from "components/templates/LoadingComponent";
import { signAppContext } from "utils/providers/SignContextProvider";
import React, { useEffect, useState, useCallback, useContext } from "react";
import { createPortal } from "react-dom";
import styled, { keyframes } from "styled-components";
import icon_Logo from "../../../assets/images/LOGO_Assistant.svg";
import CloseButton from "../../atomic/CloseButton.js";
import InputBox from "../../atomic/InputBox.js";

export default function FindPasswordLayer({ setShowFindPasswordLayer }) {
  const { handleSignOutApp } = useContext(signAppContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [waitStatus, setWaitStatus] = useState(false);
  //이메일이 인증되었는지 -> 가입 시 체크
  const [isCertificatedEmail, setIsCertificatedEmail] = useState(false);
  const [sendCertificationEmail, setSendCertificationEmail] = useState(false);
  const [responseCertification, setResponseCertification] = useState("");

  //Validation 플래그
  const [checkPwdValid, setCheckPwdValid] = useState(false);
  const [checkConfirmPwdValid, setCheckConfirmPwdValid] = useState(false);

  //각 Input 필드 상태 메세지
  const [emailStateMessage, setEmailStateMessage] = useState("");
  const [pwdStateMessage, setPwdStateMessage] = useState("");
  const [confirmPwdStateMessage, setConfirmPwdStateMessage] = useState("");
  //
  const [loading, setLoading] = useState(false);

  // 실제 패스워드 변경.
  const pwdChangeFunc = async () => {
    setLoading(true);
    try {
      const response = await updateMemberPassword(email, password);
      alert("변경되었습니다.");
      setShowFindPasswordLayer((prev) => !prev);
    } catch (error) {
      ErrorCatch(error, handleSignOutApp);
    } finally {
      setLoading(false);
    }
  };

  //인증메일보내기
  const handleSendCertificationEmail = (e) => {
    setWaitStatus(true);
    if (isCertificatedEmail) return;
    let emailRegExp =
      /^(([^<>()\\[\].,;:\s@"]+(\.[^<>()\\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    if (!email) {
      setEmailStateMessage("이메일을 입력하세요.");
      return;
    } else if (!emailRegExp.test(email)) {
      setEmailStateMessage("이메일 형식이 잘못되었습니다.");
      return;
    } else {
      setEmailStateMessage("");
    }

    const response = sendPwdChangeCertKey(email);
    setSendCertificationEmail(true);

    response
      .then(({ data: { certificationKey }, status }) => {
        if (status === 200) {
          setResponseCertification(certificationKey);
          setEmailStateMessage("");
        } else if (status === 204) {
          setSendCertificationEmail(false);
          setEmailStateMessage("가입되지 않은 이메일입니다.");
        }
      })
      .catch((error) => {
        console.log(">>>>>>>>>>>>>>>>>>>", error);
        setSendCertificationEmail(false);

        if (error.response) {
          const {
            response: { data, status },
          } = error;

          console.log(data.errorDescription);
          setEmailStateMessage("발송 오류. 관리자에게 문의 해주세요.");
        }
      })
      .finally(() => {
        setWaitStatus(false);
      });
  };

  // 비밀번호 변경 실제 처리
  const handleChangePassword = async (e) => {
    const response = checkPwdChangeCertKey(responseCertification, email);
    response
      .then(({ data, status, data: { certificationYn } }) => {
        if (certificationYn === "Y") {
          setEmailStateMessage("");
          setIsCertificatedEmail(true);
          if (!password) {
            setPwdStateMessage("비밀번호를 입력하세요.");
            return;
          }
          if (!confirmPassword) {
            setConfirmPwdStateMessage("동일한 비밀번호를 입력하세요.");
            return;
          }
          if (!checkPwdValid || !checkConfirmPwdValid) {
            return;
          }
          // 실제 패스워드 변경 처리합시다.
          pwdChangeFunc();
        } else {
          console.log(">>> 메일 인증을 하지 않은 상태..");
          setEmailStateMessage("메일 인증을 처리 해주세요.");
          return;
        }
      })
      .catch((error) => {
        !waitStatus && setSendCertificationEmail(false);
        console.log(error);
      });
  };

  const closePopup = () => setShowFindPasswordLayer(false);

  const handleValidPassword = useCallback(
    (e) => {
      if (password.length < 6 || password.length > 20) {
        setPwdStateMessage(
          "영문, 숫자, 특수 문자를 조합하여 6 ~ 20자 이내로 작성하세요."
        );
        setCheckPwdValid(false);
      } else if (
        !/[a-zA-Z]/g.test(password) ||
        !/[0-9]/g.test(password) ||
        !/[\\{\\}\\[\]\\/?.,;:|\\)*~`!^\-_+<>@\\#$%&\\=\\(\\'\\"]/g.test(
          password
        ) ||
        /[\s]/g.test(password)
      ) {
        setPwdStateMessage(
          "영문, 숫자, 특수 문자를 조합하여 6 ~ 20자 이내로 작성하세요."
        );
        setCheckPwdValid(false);
      } else {
        setPwdStateMessage("");
        setCheckPwdValid(true);
      }
    },
    [password]
  );

  useEffect(() => {
    password && handleValidPassword();
    !password && setPwdStateMessage("");
  }, [password, handleValidPassword]);

  useEffect(() => {
    if (confirmPassword && password) {
      if (password === confirmPassword) {
        setConfirmPwdStateMessage("");
        setCheckConfirmPwdValid(true);
      } else {
        setConfirmPwdStateMessage("비밀번호가 일치하지 않습니다.");
        setCheckConfirmPwdValid(false);
      }
    }
  }, [confirmPassword, password]);

  return (
    <>
      {createPortal(
        <Wrapper>
          <Container>
            <Header>
              <LogoCt>
                <Logo src={icon_Logo} alt="로고" />
                비밀번호 변경
              </LogoCt>

              <CloseButton callBack={closePopup} />
            </Header>
            <Body>
              <InputGroup>
                <label>이메일</label>
                <div className="inputCt">
                  <InputBox
                    inputId="email"
                    placeholderText="가입하신 이메일 주소를 입력해주세요."
                    value={email}
                    setValue={setEmail}
                    // disabled={isCertificatedEmail ? true : false}
                    inputclass={"signUp"}
                  />
                  <button
                    type="button"
                    className="input-group-btn certiBtn"
                    onClick={() =>
                      !sendCertificationEmail && handleSendCertificationEmail()
                    }
                  >
                    {sendCertificationEmail ? "전송완료" : "인증전송"}
                  </button>
                </div>

                {emailStateMessage && (
                  <span className="invalid">{emailStateMessage}</span>
                )}
              </InputGroup>
              <InputGroup>
                <label>새 비밀번호</label>
                <InputBox
                  type="password"
                  inputId="password"
                  placeholderText="새 비밀번호(영문, 숫자, 특수문자 조합 6~20자 이내)"
                  value={password}
                  setValue={setPassword}
                  inputclass={"signUp"}
                />
                {pwdStateMessage && (
                  <span className="invalid">{pwdStateMessage}</span>
                )}
              </InputGroup>
              <InputGroup>
                <label>새 비밀번호 확인</label>
                <InputBox
                  type="password"
                  inputId="passwordConfirm"
                  placeholderText="새 비밀번호 확인"
                  value={confirmPassword}
                  setValue={setConfirmPassword}
                  inputclass={"signUp"}
                />
                {confirmPwdStateMessage && (
                  <span className="invalid">{confirmPwdStateMessage}</span>
                )}
              </InputGroup>

              <MakeAccBtn
                className="modal-btn joinBtn"
                onClick={(e) => {
                  handleChangePassword();
                }}
              >
                비밀번호 변경
              </MakeAccBtn>
            </Body>
          </Container>
        </Wrapper>,
        document.getElementById("modal")
      )}
      {loading && (
        <div className="modal-wrap open">
          <LoadingComponent />
        </div>
      )}
    </>
  );
}
const openModal = keyframes`
  0% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
`;

const Container = styled.div`
  width: 500px;
  height: auto;

  animation: ${openModal} 0.35s ease-in-out;
  animation-fill-mode: both;
  display: inline-block;
  position: absolute;
  top: 20%;
  left: 50%;
  background: #fff;
  opacity: 0;
  border-radius: 15px;

  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 0;
  }
`;

const Header = styled.div`
  border-radius: 15px 15px 0 0;
  display: flex !important;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 20px;
  border-bottom: solid 1px #dcdcdc;
  background: #fff;

  @media (max-width: 480px) {
    margin: 30px 0 5px 0;
  }
`;

const Body = styled.div`
  padding: 20px;
  max-height: none !important;
`;

const InputGroup = styled.div`
  margin-bottom: 20px;
  position: relative;

  &.joinBtn {
    border: 1px dashed blue;
    height: 30px;
  }
  & .inputCt {
    display: flex;
  }

  label {
    display: block;
    font-size: 13px;
    margin-bottom: 5px;
    color: #000;
  }

  span {
    font-size: 11px;
    color: tomato;
  }

  input {
    width: 100% !important;
    height: 45px !important;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding-left: 13px;
    position: relative;
    box-sizing: border-box;
    font-size: 14px;
  }

  button {
    position: absolute;
    right: 0;
    top: -0.1vh;
    z-index: 2;
    width: 100px;
    height: 45px;
    font-size: 0.7rem;
    color: var(--text-linkColor);
    text-align: center;
    background: transparent;
    border: none;
  }
  & .certiBtn {
    position: static;
    flex: 0 0 auto;
    background-color: #ccc;
    color: #333;
  }

  strong {
    position: absolute;
    right: -5%;
    top: 50%;
    z-index: 2;
    width: 100px;
    height: 45px;
    font-size: 12px;
    color: #1a78c9;
    text-align: center;
  }

  & .inputCt {
    & button {
      width: 60px;
      color: #000;
    }
  }

  @media (max-width: 480px) {
    margin: 0 0 15px 0;
  }
`;

const LogoCt = styled.h2`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
`;

const Logo = styled.img`
  //width: 50px;
  height: 14px;
  margin-right: 5px;
`;

const MakeAccBtn = styled.button`
  border-radius: 5px;
  width: 100%;
  height: 45px;
  background-color: #ff892a;
  color: #fff;

  //margin-bottom: 20px;
`;
