import NormalButton from "components/atomic/NormalButton2";
import MobileMenu from "components/pages/menu/MobileMenu";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";
import { signAppContext } from "utils/providers/SignContextProvider";
import icon_Logo from "../../assets/images/LOGO_Assistant.svg";
import iconMobileMenu from "../../assets/images/icon_moblieMenu_old.svg";

import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";

export default function GlobalHeader({
  loginPopState,
  setLoginPopState,
  mobileMenu,
  setMobileMenu,
  dispatchMenu,
  headerClass,
}) {
  const history = useHistory();

  const openLoginPop = () => setLoginPopState((prev) => !prev);
  const { handleSignOutApp, isLoggedIn, myInfo } = useContext(signAppContext);
  const { isMobile } = useContext(isMobileContext);
  const [checkLogin, setCheckLogin] = useState(false);

  const location = useLocation();
  const currentPath = location.pathname;

  const [myalvisBtn, setMyalvisBtn] = useState(
    //currentPath !== "/assistant/dashboard"
    currentPath !== "/dashboard",
  );

  useEffect(() => {
    /* dashboard 에서 로그아웃 시
      isLoggedIn 은 빈 값이 되고, route.js 에 작성한 코드에 의해 <Home /> 을 렌더링
      이 때 Home에서 Props에 의해 history replace 함수를 통해 url의 /dashboard가 제거
      currentPath가 변경됨에도 state 변수에 변화를 줄 수 있는 구문이 없기 때문에 useEffect 추가 */
    //setMyalvisBtn(currentPath !== "/assistant/dashboard");
    setMyalvisBtn(currentPath !== "/dashboard");
  }, [currentPath]);

  const handleHistory = () => {
    history.push({
      // pathname: `/assistant/dashboard`,
      pathname: `/dashboard`,
    });
  };

  return (
    <HeaderBackCt className={headerClass}>
      <HeaderCt className="header">
        <Logo
          icon_Logo={icon_Logo}
          onClick={(e) => {
            e.preventDefault();
            history.replace("/");
          }}
        />

        {!isMobile ? (
          // web일때
          <LoginCt>
            <MyAlvisBtn>
              <NormalButton
                buttonId="loginBtn"
                buttonTitle={
                  <>
                    <SettingsIcon />
                    어시스턴트 관리
                  </>
                }
                buttonClass={
                  currentPath === "/dashboard"
                    ? "myPage mid round pageActive withIcon"
                    : "myPage mid round withIcon"
                }
                callBackFunc={() => {
                  isLoggedIn ? handleHistory("dash") : openLoginPop();
                }}
                style={{ zIndex: 100, height: "47px" }}
                setMyalvisBtn={setMyalvisBtn}
              />

              {isLoggedIn ? (
                <NormalButton
                  buttonId="loginBtn"
                  buttonTitle={
                    <>
                      <LogoutIcon />
                      로그아웃
                    </>
                  }
                  buttonClass="round mid cOrange withIcon"
                  callBackFunc={isLoggedIn ? handleSignOutApp : openLoginPop}
                  style={{ zIndex: 100 }}
                  onClick={(e) => {
                    e.preventDefault();
                    setCheckLogin((prev) => !prev);
                  }}
                />
              ) : (
                <NormalButton
                  buttonId="loginBtn"
                  buttonTitle={
                    <>
                      <LoginIcon />
                      로그인
                    </>
                  }
                  buttonClass="round mid cOrange withIcon"
                  callBackFunc={isLoggedIn ? handleSignOutApp : openLoginPop}
                />
              )}
            </MyAlvisBtn>

            {checkLogin && (
              <NormalButton
                type={"drop"}
                buttonId="loginBtn"
                buttonTitle={
                  <>
                    <LogoutIcon />
                    로그아웃
                  </>
                }
                buttonClass="round mid cOrange withIcon"
                callBackFunc={isLoggedIn ? handleSignOutApp : openLoginPop}
                style={{ zIndex: 100 }}
              />
            )}
          </LoginCt>
        ) : isLoggedIn ? (
          <MobileMenuBtn
            onClick={(e) => {
              setMobileMenu((prev) => !prev);
            }}
            icon={iconMobileMenu}
          />
        ) : (
          <NormalButton
            buttonId="loginBtn"
            buttonTitle={
              <>
                <LoginIcon />
                로그인
              </>
            }
            buttonClass="round small cOrange withIcon"
            callBackFunc={isLoggedIn ? handleSignOutApp : openLoginPop}
          />
        )}
      </HeaderCt>
      {mobileMenu && (
        <MobileMenu setMobileMenu={setMobileMenu} dispatchMenu={dispatchMenu} />
      )}
    </HeaderBackCt>
  );
}

const HeaderBackCt = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  background-color: #fff;
  width: 100%;
  flex: 0 0 auto;

  box-shadow: 0px 0px 22px 0px #00000040;
  z-index: 2;

  padding: 0 25px;

  &.home {
    position: fixed;
  }
`;

const HeaderCt = styled.header`
  /* width: 1140px; */
  width: 100%;
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 620px) {
    padding: 0 1vw;
  }
`;

const Logo = styled.a`
  margin-right: auto;
  width: 220px;
  height: 34px;
  background: url(${({ icon_Logo }) => icon_Logo}) no-repeat;
  background-size: contain;
  background-position: center left;
  color: transparent;
  cursor: pointer;

  @media (max-width: 480px) {
    width: 165px;
  }
`;

const MyAlvisBtn = styled.div`
  font-weight: 400;
  display: flex;

  & button.myPage {
    position: relative;
    background: #fff;
    border: 0;
    margin-right: 30px;
    color: #ff892a;
    padding: 9.5px 20px;

    &:after {
      content: "";
      position: absolute;
      background: #ccc;
      width: 1px;
      height: 50%;
      right: -10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media (max-width: 750px) {
    & button {
      flex: 0 1 auto;
      padding: 5px 20px !important;
      font-size: 17px !important;
      letter-spacing: -1px;
    }
  }
  @media (max-width: 530px) {
    & button {
      font-size: 15px !important;
      padding: 5px 15px !important;
    }
  }
`;
const LoginCt = styled.div`
  position: relative;
`;

const MobileMenuBtn = styled.button`
  width: 45px;
  height: 45px;
  background: url(${({ icon }) => icon}) no-repeat;
`;
