import { getNow, timestampToTime } from "./dateUtil.js";
import uuid from "react-uuid";
import botApi from "../api/botApi.js";

export const createMessageData = (
  message,
  type,
  messageType,
  id = "",
  messageId = "",
  ratio,
  props = {}
) => {
  const {
    action,
    time,
    isFirst,
    recvRsearch,
    fixBot,
    guideMsg,
    recvTokens,
    recvTotalCount,
    recvKnowDataList,
    recvSentenceList,
  } = props;
  return {
    id,
    message,
    type,
    messageType,
    messageId,
    ratio,
    action,
    time: time ?? getNow(),
    isFirst,
    recvRsearch,
    fixBot,
    guideMsg,
    recvTokens,
    recvTotalCount,
    recvKnowDataList,
    recvSentenceList,
  };
};

export const createTextMessageData = (
  message,
  type,
  messageId,
  time = getNow(),
  ratio
) => {
  return createMessageData(message, type, "text", uuid(), messageId, ratio, {
    time: timestampToTime(time),
  });
};

export const refineToClientMessages = (serverMessages) => {
  return (
    serverMessages?.reduce((acc, data) => {
      const { sendMessage, recvMessage, messageId, createAt, updateAt, ratio } =
        data;
      const userMessage = createTextMessageData(
        sendMessage,
        "user",
        messageId,
        createAt,
        ratio
      );
      const botMessage = createTextMessageData(
        recvMessage,
        "bot",
        messageId,
        updateAt,
        ratio
      );
      acc.push(userMessage, {
        ...botMessage,
        isResponsed: data.chatRoomId === "OK",
      });

      return acc;
    }, []) ?? []
  );
};

const getStatusByCallback = (data, toObjTargetName) => {
  const result = {
    status: 200,
    message: "success",
    data: null,
  };
  try {
    if (!data) {
      throw Error(`결과값이 존재하지 않음.`);
    }

    if (toObjTargetName) {
      if (!data[toObjTargetName]) {
        throw Error(`결과값에 ${toObjTargetName} 속성값이 존재하지 않음`);
      }

      result.data =
        typeof data[toObjTargetName] === "string"
          ? JSON.parse(data[toObjTargetName])
          : data[toObjTargetName];
    } else {
      result.data = typeof data === "string" ? JSON.parse(data) : data;
    }

    return result;
  } catch (err) {
    console.log("err.message", err.message);
    return {
      status: 404,
      message: err.message,
      data: null,
    };
  }
};

const callback = {
  def: async (promiseFunc, toObjTargetName) => {
    try {
      let { status, data, resultCode } = await promiseFunc;
      resultCode = Number(resultCode);

      if (status === 200 || resultCode === 200) {
        return getStatusByCallback(data, toObjTargetName);
      }
    } catch (error) {
      console.log("catch!!!!", error.response);
      if (error.response) {
        const result = {
          status: error.response?.status ?? 405,
          message: "",
          data: null,
        };
        if (error.response.status === 403) {
          result.message = "세션이 만료되었습니다. 다시 로그인해주세요.";
        } else if (error.response.status === 500) {
          result.message = error.response?.data?.resultMessage ?? "";
        } else if (error.response.status === 503) {
          error.response.errorMessage
            ? alert(error.response.errorMessage)
            : console.log("네트워크 오류 발생");
        }

        return result;
      }
    }
  },
};

export const handleError = (props) => {
  const { history, handleSignOutApp, status, message, handleOpt, resultCode } =
    props;
  switch (status || resultCode) {
    case 403:
      if (handleOpt?.isAlert) {
        alert(message);
      }
      if (handleSignOutApp) {
        handleSignOutApp();
      }

      if (history) {
        history?.replace({ pathname: "/" });
      }
      break;
    case 404:
      if (handleOpt?.isAlert) {
        alert(message);
      }
      break;
    case 500:
      if (handleOpt?.isAlert) {
        alert(message);
      }
      if (history) {
        history?.replace({ pathname: "/" });
      }
      break;
    default:
      return true;
  }
};

const defaultHandleOpt = { isAlert: false };

/**
 *
 * @property
 * opt: {
 *     history: React의 history
 *     handleSignOutApp: signAppContext 의 변수
 *     toObjTargetName: object형태의 문자열을 object 객체로 변환할 대상의 이름
 * }
 * apiName: botApi에서 사용하는 키값
 * ...args: 매개변수 ( 0개 이상 )
 *
 * @description
 * bot 관련 api를 전송할 때마다 반복적으로 catch문을 만들어줘야 하는 것 같아서, 중간에 처리해주는 로직을 구현해 보았음.
 * 1. apiName과 일치하는 callback 함수를 찾는다. ( botApi의 키 네임과 동일 / 없으면 callback.def 함수가 실행되도록 구현 )
 * 2. 데이터를 await 을 통해 가져오지 않고, Promise 형태 그대로 가져온다.
 * 3. callback 함수에서 await 통해 비동기 데이터를 가져온다. ( 에러나면 catch로 이동됨 )
 *   3-1. 서버에서 가져온 값으로, 에러가 나면 에러 관련 로직 처리
 *   3-2. 제대로 가져 왔다면. 값이 toObjTargetName 변수값과 일치하는 data의 key 값을 찾아, 해당 value 값을 object 객체로 변환
 *   3-4. 그리고 data, message, status 반환
 * 4. handleError 함수를 통해, statusCode가 에러 관련이면, 해당 로직 처리 ( alert, history.replace 등 )
 *
 * @return {
 *    status: number;
 *    message: string;
 *    data: null or object;
 * }
 * */
export const chatMiddle = async (opt, apiName, ...args) => {
  if (opt.handleOpt === undefined || opt.handleOpt === null) {
    opt.handleOpt = defaultHandleOpt;
  }

  const { history, handleSignOutApp, toObjTargetName, handleOpt } = opt;

  if (!botApi[apiName]) {
    return {
      status: 404,
      message: "해당 함수를 찾을 수 없음",
      data: null,
    };
  }
  const getDataInNoArgs = () =>
    (callback[apiName]
      ? callback[apiName](botApi[apiName](), toObjTargetName)
      : callback["def"](botApi[apiName](), toObjTargetName)) ?? {};

  const getDataInArgs = (...args) =>
    (callback[apiName]
      ? callback[apiName](botApi[apiName](...args), toObjTargetName)
      : callback["def"](botApi[apiName](...args), toObjTargetName)) ?? {};

  let test = await (args.length === 0
    ? getDataInNoArgs()
    : getDataInArgs(...args));
  const { resultCode, status, message, data } = test ?? {};

  if (
    !handleError({
      history,
      handleSignOutApp,
      status,
      message,
      handleOpt,
      resultCode,
    })
  )
    return {
      status: 0,
      message: "",
      data: null,
    };

  return { status, message, data, resultCode };
};
