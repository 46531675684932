import { existMemberYn, signInToSocial } from "api/memberApi";
import axios from "axios";
import { signAppContext } from "./providers/SignContextProvider";
import qs from "query-string";
import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SignUpAgreeLayer from "components/templates/login/SignUpAgreeLayer";

export default function KakaoLoginSuccess() {
  const kakao = window.Kakao;

  const location = useLocation();
  const history = useHistory();
  const { code, state } = qs.parse(location.search);

  const { handleSignInApp } = useContext(signAppContext);

  const [openTermsOfAlvis, setOpenTermsOfAlvis] = useState(false);
  const [infoStruct, setInfoStruct] = useState(null);
  const [openComplete, setOpenComplete] = useState(false);
  const [memberYn, setMemberYn] = useState("");
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    setOpenTermsOfAlvis(infoStruct ? true : false);
  }, [infoStruct]);

  const socialSignIn = useCallback(
    async (id, email, socialType, agreeItems) => {
      try {
        const { headers } = await signInToSocial(
          id,
          email,
          socialType,
          agreeItems
        );

        handleSignInApp(
          headers.authorization,
          headers.refresh_token,
          headers.member_email,
          headers.member_uid,
          headers.social_yn,
          socialType
        );
        if (state) {
          const sParam = "/" + decodeURIComponent(state);
          history.replace({ pathname: sParam });
        } else {
          history.replace({ pathname: "/" });
        }
      } catch (error) {
        console.error("error : ", error);
      }
    },
    [memberYn, handleSignInApp, history]
  );

  const getKakaoUserInfo = useCallback(
    async (userInfo) => {
      // console.log(">>>>>>>>>> getKakaoUserInfo userInfo:", userInfo);
      const { id, kakao_account } = userInfo;

      // 회원여부 질의
      const { data } = await existMemberYn(id, "kakao", kakao_account.email);
      setMemberYn(data.existMemberYn);
      if (data.existMemberYn === "N") {
        setInfoStruct({
          id: id,
          email: kakao_account.email,
          socialType: "kakao",
        });
      } else {
        socialSignIn(id, kakao_account.email, "kakao");
      }
    },
    [socialSignIn]
  );

  const kakaoInit = useCallback(() => {
    !kakao.isInitialized() && kakao.init(process.env.REACT_APP_KAKAO_APP_KEY);
  }, [kakao]);

  useEffect(() => {
    kakaoInit();
  }, [kakaoInit]);

  const success = useCallback(() => {
    const retUrl = `${process.env.REACT_APP_HOST}/kakaoLoginSuccess`;
    // console.log(">>>>>>>>>>>>>>> kakao retUrl: ", retUrl);
    // console.log(">>>>>>>>>>>> kakao state: ", state);
    const data = {
      grant_type: "authorization_code",
      client_id: process.env.REACT_APP_KAKAO_APP_KEY,
      retdirect_uri: retUrl,
      // redirect_uri: `${process.env.REACT_APP_HOST}/assistant/kakaoLoginSuccess`,
      code: code,
    };
    // console.log("My DATA!!!: ", data);
    const queryString = Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");

    //토큰 발급 REST API

    axios
      .post("https://kauth.kakao.com/oauth/token", queryString, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
      .then((res) => {
        if (res.status === 200 && res.data && res.data.access_token) {
          setIsAuth(true);
          const {
            data: { access_token },
          } = res;
          kakao.Auth.setAccessToken(access_token);
          kakao.API.request({
            url: "/v2/user/me",
            success: getKakaoUserInfo,
            fail: (error) => {
              console.log("kakao get userInfo error : ", error);
            },
          });
        }
      })
      .catch((error) => {
        console.log(">>>>>>>>>>>>>>>>>>", error);
      });
  }, [kakao.API, kakao.Auth, code, getKakaoUserInfo]);

  useEffect(() => {
    // isAuth && success();
    success();
  }, []);

  return openTermsOfAlvis ? (
    <SignUpAgreeLayer
      socialSignIn={socialSignIn}
      infoStruct={infoStruct}
      setInfoStruct={setInfoStruct}
      setOpenTermsOfAlvis={setOpenTermsOfAlvis}
    />
  ) : (
    <></>
  );
}
