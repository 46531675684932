import { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { signAppContext } from "utils/providers/SignContextProvider";
import botApi from "api/botApi";
import { ErrorCatch } from "api/ErrorCatch";
import CloseButton from "components/atomic/CloseButton";
import styled from "styled-components";

export default function CrawlUrlView(props) {
  const { handleSignOutApp } = useContext(signAppContext);
  const history = useHistory();
  const [urldata, setUrldata] = useState(null);
  const location = useLocation();
  const { dataUid } = location.state;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUrlFileDataFunc = () => {
    async function callFunc() {
      try {
        const { status, data } = await botApi.getUrlFileData(dataUid);
        if (status === 200) {
          const resultInfo = data.resultData ? JSON.parse(data.resultData) : "";
          if (resultInfo) {
            setUrldata(resultInfo.urlData);
          }
        }
      } catch (error) {
        ErrorCatch(error, handleSignOutApp);
      }
    }

    callFunc();
  };

  useEffect(() => {
    getUrlFileDataFunc();
  }, [getUrlFileDataFunc]);

  const closePopup = () => {
    history.goBack();
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <UrlCtWrapper>
        <CloseButton callBack={closePopup} />

        {urldata ? (
          <UrlCT>
            <p>크롤링 된 URL 목록</p>
            <UrlTextList>
              {urldata &&
                urldata.length > 0 &&
                urldata
                  .split("\n")
                  .map((data, idx) => (
                    <UrlTextItem key={idx}>{data}</UrlTextItem>
                  ))}
            </UrlTextList>
          </UrlCT>
        ) : (
          <UrlCT className="empty">크롤링 된 URL이 없습니다.</UrlCT>
        )}
      </UrlCtWrapper>
    </div>
  );
}

// http://guide.jebo.io/

const UrlCtWrapper = styled.div`
  position: relative;
  width: 600px;
  height: fit-content;
  //max-height: 800px;
  min-height: 300px;
  padding: 20px 10px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  border-radius: 15px;

  @media (max-width: 480px) {
    width: 90vw;
    max-height: 80vh;

    & button {
      width: 15px;
      height: 15px;
      top: 22px;
      right: 25px;
    }
  }
`;

const UrlCT = styled.div`
  width: 100%;
  height: 100%;

  & p {
    margin-bottom: 30px;
  }

  &.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    @media (max-width: 480px) {
      font-size: 15px;
    }
  }
`;

const UrlTextList = styled.ul`
  width: 100%;
  max-height: calc(80vh - 116px);
  word-break: break-all;
  overflow-y: auto;
`;

const UrlTextItem = styled.li`
  width: calc(100% - 10px);
  word-break: break-all;
  font-size: 14px;
  line-height: 18px;

  border-bottom: 1px solid #dcdcdc;
  padding: 15px 0;
  text-align: left;

  &:first-child {
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
  }

  &:last-child {
    border: none;
  }

  &:hover {
    opacity: 0.8;
    text-decoration: underline;
  }
`;
