import Select from "react-select";
import React, { useContext, useMemo } from "react";
import useAddKnowledgeChatBotSelect from "../../../../utils/hooks/useAddKnowledgeChatBotSelect.js";

export default function KnowledgeChatBotSelect() {
  const { loadAddKnowBotList, submitSelectedOption, selectedOption } =
    useAddKnowledgeChatBotSelect();

  return (
    <Select
      value={selectedOption ?? "-"}
      onChange={submitSelectedOption}
      options={loadAddKnowBotList}
    />
  );
}
