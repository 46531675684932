import { useContext, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider";
import { PriceUtil } from "utils/priceUtil";
import icon_Logo from "../../assets/images/alvis_logo.svg";
import icon_checkBlue from "../../assets/images/icon_checkBlue.png";

export default function MainContentPrice() {
  const { isMobile } = useContext(isMobileContext);
  const [tabState, setTabState] = useState("01");
  const { pricingPolicyContent, pricingHeader } = PriceUtil;

  return (
    <SectionCt className="typePrice">
      <Section className="typePrice">
        <p className="title">
          지금 필요한 플랜을 비교해 보고
          <br />
          <span className="strong">
            최신 GPT가 탑재된 {isMobile && <br />} MESwiser를 경험해 보세요
          </span>
        </p>

        <TabBtnCt>
          <button
            className={tabState === "01" ? "active" : ""}
            onClick={(e) => {
              setTabState("01");
            }}
          >
            무료 버전
          </button>
          <button
            className={tabState === "02" ? "active" : ""}
            onClick={(e) => {
              setTabState("02");
            }}
          >
            유료 버전
          </button>
        </TabBtnCt>

        <TabContent className="tabContent">
          <TabContentCt className="tabContent main">
            <TabContentHeader className="header logo">
              <img src={icon_Logo} alt="logoImg" />
            </TabContentHeader>
            <TabContentBody className="pricingContent">
              <p className="data">{pricingHeader.unit}</p>
              <div className="data twoCol">
                <div className="">
                  <p className="colHeader">{pricingHeader.knowledgeStorage}</p>
                </div>
                <div className="twoRowCt">
                  <p className="dataInRow">{pricingHeader.dataStorage}</p>
                  <p className="dataInRow">{pricingHeader.VectorStore}</p>
                </div>
              </div>

              <p className="data">{pricingHeader.QuestionNum}</p>

              <p className="data DirCol">
                {pricingHeader.knowledgeManage}
                <span className="desc">
                  (지식관리 기능을 통한 다수의 학습 지식 관리)
                </span>
              </p>

              <p className="data">
                {pricingHeader.inviteUser}
                <span className="desc">(MESwiser 회원 기준)</span>
              </p>

              <p className="data">
                {pricingHeader.adminSupport}
                <span className="desc">(로그 관리 및 분석지원)</span>
              </p>
              <p className="data">{pricingHeader.serviceUpgrade}</p>
            </TabContentBody>
          </TabContentCt>

          {pricingPolicyContent.map((item, idx) => {
            return (
              <TabContentCt
                tabState={tabState}
                key={item.tag + idx}
                className={item.tag === "free" ? "free" : "charge"}
              >
                <TabContentHeader className="header">
                  <p className="text">{item.title}</p>
                  <p className="num">
                    {item.price}{" "}
                    <span className="monthText">
                      {item.tag !== "free" && "원/월"}
                    </span>
                  </p>
                </TabContentHeader>
                <TabContentBody className="tabContentBody">
                  <p className="data">{item.unit}</p>
                  <div className="data twoCol">
                    <div className="">
                      <p className="">{item.knowledgeStorage}</p>
                    </div>
                    <div
                      className={
                        item.tag === "free" ? "free twoRowCt" : "twoRowCt"
                      }
                    >
                      {item.tag !== "free" && (
                        <p className="dataInRow">{item.dataStorage}</p>
                      )}
                      <p className="dataInRow">{item.VectorStore}</p>
                    </div>
                  </div>
                  <p className="data">{item.QuestionNum}</p>
                  <p className="data">
                    {item.knowledgeManage === true ? (
                      <img
                        className="checkIcon"
                        alt="check_Icon"
                        src={icon_checkBlue}
                      />
                    ) : (
                      ""
                    )}
                  </p>

                  <p className="data">{item.inviteUser}</p>

                  <p className="data">
                    {item.adminSupport === true ? (
                      <img
                        className="checkIcon"
                        alt="check_Icon"
                        src={icon_checkBlue}
                      />
                    ) : (
                      ""
                    )}
                  </p>
                  <p className="data">
                    {item.serviceUpgrade === true ? (
                      <img
                        className="checkIcon"
                        alt="check_Icon"
                        src={icon_checkBlue}
                      />
                    ) : (
                      ""
                    )}
                  </p>
                </TabContentBody>
              </TabContentCt>
            );
          })}
        </TabContent>

        <PriceDescCt>
          <p className="priceDesc">※ 부가세 별도, 봇 기준 단가</p>
          <p className="priceDesc">
            ※ 고급 지식 분석, 정제, 변화, 표준화 및 품질검사로 더 만족도 높은
            Assistant를 이용하실 수 있습니다.
            <strong> (가격별도 협의)</strong>
          </p>
        </PriceDescCt>
      </Section>
    </SectionCt>
  );
}

const SectionCt = styled.div`
  display: flex;
  justify-content: center;

  &.typePrice {
    padding: 100px;
    background: #f7f9ff;
  }
`;

const Section = styled.section`
  .title {
    word-break: keep-all;
    color: #1a1a1a;
    text-align: center;
    font-size: 40px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.4px;
    margin-bottom: 41px;
    .strong {
      font-weight: bold;
    }
    @media (max-width: 1000px) {
      width: 85vw;
      font-size: 34px;
    }
    @media (max-width: 480px) {
      font-size: 26px;
      letter-spacing: -2px;
      .strong {
      }
    }
  }

  &.typePrice {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
  }
`;

const TabBtnCt = styled.div`
  margin-bottom: 60px;
  width: 540px;
  border-radius: 87px;
  background: #e7ebf9;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1000px) {
    font-size: 32px;
  }
  @media (max-width: 480px) {
    width: 335px;
    height: 79px;
    margin-bottom: 40px;
  }

  button {
    transition: 0.2s;
    font-size: 30px;

    line-height: normal;
    letter-spacing: -0.34px;
    margin: 9px 13px 10px;

    color: rgba(0, 0, 0, 0.4);
    padding: 8px 57px;
    border-radius: 54px;

    @media (max-width: 1000px) {
      padding: 8px 20px;
    }

    @media (max-width: 480px) {
      font-size: 26px;
      flex: 0 0 auto;
      margin: 9px;
      width: 149px;
      height: 61px;
    }
  }

  button.active {
    padding: 8px 57px;
    color: #1a1a1a;
    border-radius: 54px;
    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    font-weight: 700;
    @media (max-width: 1000px) {
      padding: 8px 20px;
    }
  }
`;

const TabContent = styled.div`
  display: flex;
  width: 1024px;
  max-width: 1200px;
  justify-content: center;
  margin-bottom: 30px;
  /* border-radius: 50px;
  border: 1px solid #aab7db; */
  /* overflow: hidden; */
`;

const TabContentCt = styled.div`
  transition: 0.2s;
  position: relative;
  background: #f7f9ff;
  overflow: hidden;

  border: 1px solid #aab7db;

  flex: 0 1 100%;

  &.main {
    border-radius: 30px 0 0 30px;
    width: 300px;
    min-width: 270px;
  }

  &:last-child {
    border-radius: 0 30px 30px 0;
  }

  /* 무료 유료 영역 */
  &.free,
  &.charge {
    width: 275px;
  }

  &.free {
    ${({ tabState }) => {
      if (tabState === "01") {
        return css`
          /* scale: 1.04;
          z-index: 1; */
          border: 3px solid #9bade4;
          border-radius: 30px;
          background: #fff;

          transform: translateY(-17px);
          box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.15);
          z-index: 1;
          height: 105%;

          .header {
            height: 95px;
            border-bottom: 1px solid #9bade4;
          }
          .header,
          .tabContentBody {
            background: #fff;
          }
          .tabContentBody img.checkIcon {
            opacity: 1;
          }

          /* 헤더 */
          & .header .text {
            font-size: 22px;
            color: #ff6600;
            align-self: flex-start;
          }

          & .header .num {
            color: #000000;
            align-self: flex-end;
            & .monthText {
              color: #999999;
            }
          }
          /* 헤더 */
        `;
      }
    }}
  }

  &.charge {
    ${({ tabState }) => {
      if (tabState === "02") {
        return css`
          /* scale: 1.04;
          z-index: 1; */
          border: 3px solid #9bade4;
          border-radius: 30px;
          background: #fff;

          transform: translateY(-17px);
          box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.15);
          z-index: 1;

          height: 105%;

          /* &:last-child {
            transform: translateX(10px);
          } */

          .header {
            height: 95px;
            border-bottom: 1px solid #9bade4;
          }
          .header,
          .tabContentBody {
            background: #fff;
          }
          .tabContentBody img.checkIcon {
            opacity: 1;
          }

          /* 헤더 */
          & .header .text {
            font-size: 22px;
            color: #ff6600;
            align-self: flex-start;
          }

          & .header .num {
            color: #000000;
            align-self: flex-end;
            & .monthText {
              color: #999999;
            }
          }
          /* 헤더 */
        `;
      }
    }}
  }
  /* 무료 유료 영역 */
`;

const TabContentHeader = styled.div`
  height: 80px;
  line-height: normal;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e7ebf9;

  padding: 15px;
  letter-spacing: -0.24px;
  font-weight: 600;

  &.logo {
    justify-content: center;
  }
  & img {
    width: 170px;
    height: 17px;
  }

  /* 무료 유료 영역 */
  & .text {
    font-size: 24px;
    color: #455db1;
  }

  & .num {
    font-size: 32px;
    font-weight: 700;
    & .monthText {
      margin-left: -5px;
      font-size: 16px;
      font-weight: 400;
      color: #515151;
    }
  }
  /* 무료 유료 영역 */
`;

const TabContentBody = styled.div`
  &.pricingContent {
    & .data {
      margin-left: 40px;
      padding-left: 0;
      justify-content: start;
    }
    & .twoCol {
      padding-right: 0;
    }
    & .colHeader {
      /* margin-right: 10px; */
      margin-right: 20px;
    }
  }

  & .data {
    border-bottom: 1px solid #e8ebf3;
    padding: 10px 25px;
    color: #444444;
    font-size: 16px;
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -1px;

    &.DirCol {
      flex-direction: column;
      align-items: start;
      padding: 10px 0;
      .desc {
        margin-top: 5px;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    & .checkIcon {
      width: 24px;
      height: 24px;
      opacity: 0.5;
    }
  }

  /* row가 두개인 경우 CSS 처리 */
  .twoCol {
    display: flex;
    align-items: center;
    min-height: 90px;
    padding: 15px 0;
  }

  & .dataInRow {
    &:first-child {
      padding-bottom: 10px;
    }
    &:last-child {
      padding-top: 10px;
    }
  }

  .twoRowCt {
    flex: 1 0 auto;
    text-align: center;
    & :first-child {
      border-bottom: 1px solid #dcdcdc;
    }
    &.free :first-child {
      border-bottom: none;
    }
  }
  /* row가 두개인 경우 CSS 처리 */

  /* 설명이 있는 경우 CSS */
  .desc {
    color: #6271a5;
    font-size: 12px;
    letter-spacing: -1px;
  }
  /* 설명이 있는 경우 CSS */
`;

const PriceDescCt = styled.div`
  width: 100%;
  text-align: left;

  .priceDesc {
    color: #727272;
    font-size: 14px;
    margin-bottom: 9px;
    letter-spacing: -1px;

    strong {
      font-weight: 500;
      color: #101010;
    }
  }
`;
