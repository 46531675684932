import { ErrorCatch } from "api/ErrorCatch";
import botApi from "api/botApi.js";
import { signAppContext } from "utils/providers/SignContextProvider.js";
import ChatEmpty from "components/pages/dashboard/AsstEmpty.js";
import LoadingComponent from "utils/LoadingComponent";
import { AlertPop2 } from "components/templates/popup/AlertPop";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider.js";
import ChatBotSelect from "../../../utils/ChatBotSelect.js";
import { chatContext } from "../../../utils/providers/ChatContextProvider.js";
import { DASH_HOME, FlexColumnBox } from "./dashboardStyle.js";
import HistPlainSearchBox from "components/templates/dashboard/hist/HistPlainSearchBox.js";
import HistCombineSearchBox from "components/templates/dashboard/hist/HistCombineSearchBox.js";
import HistPlainChatBox from "components/templates/dashboard/hist/HistPlainChatBox.js";
import HistCombineChatBox from "components/templates/dashboard/hist/HistCombineChatBox.js";

export default function AsstHistory() {
  const { handleSignOutApp } = useContext(signAppContext);
  const { chatBotData, setChatBotData } = useContext(chatContext);
  const [popState, setPopState] = useState(false);
  const [popMessage, setPopMessage] = useState("");
  const [tabState, setTabState] = useState("search");
  const { isMobile } = useContext(isMobileContext);

  const [extBotList, setExtBotList] = useState(null); // 남이 사용하라고 준 챗봇 목록.
  const [optData, setOptData] = useState(null); // 남이 사용하라고 준 목록중 선택 목록.
  const [pickChatBotData, setPickChatBotData] = useState(null); // 기록에서 사용할 챗봇

  const [keyword, setKeyword] = useState("");

  // 나에게 사용하라고 연결한 봇이 있는가 추가 체크 리스트
  const getExternalUsesBot = async () => {
    try {
      const { status, data } = await botApi.getExternalUsesBot();
      if (status === 200) {
        const resultData = JSON.parse(data.userExtBotList);
        if (resultData && resultData.length > 0) {
          setExtBotList(resultData);
        } else {
          setExtBotList([]);
        }
      } else {
        setExtBotList([]);
      }
    } catch (error) {
      setExtBotList([]);
      ErrorCatch(error, handleSignOutApp);
    }
  };

  useEffect(() => {
    if (optData) {
      setPickChatBotData(optData);
    } else if (chatBotData) {
      setPickChatBotData(chatBotData);
    }
  }, [optData, chatBotData]);

  // 나에게 사용 가능한 봇이 있는지 확인한다.
  useEffect(() => {
    getExternalUsesBot();
  }, []);

  if (!extBotList) {
    return (
      <>
        <ResultSection>
          <p className="empty">로딩중입니다...</p>
        </ResultSection>
        <LoadingComponent />
      </>
    );
  }
  if (!chatBotData && extBotList.length === 0) {
    return (
      <DASH_HOME.Container>
        <ChatEmpty>아직 생성 된 챗봇이 없습니다.</ChatEmpty>
      </DASH_HOME.Container>
    );
  }

  return (
    <DASH_HOME.Container>
      <FlexColumnBox
        style={
          !isMobile
            ? {
                marginBottom: "20px",
                width: "70%",
              }
            : {
                marginBottom: "20px",
                width: "100%",
              }
        }
      >
        <ChatBotSelect
          usesOptions={extBotList}
          setSelectedOption={setOptData}
        />
      </FlexColumnBox>
      {chatBotData?.dataType === 10 || optData?.dataType === 10 ? (
        <ChatEmpty
          MessageText={
            <span>
              아직 기록을 확인 할 지식이 없습니다.
              <br />
              <br /> 지식관리를 이용해주세요
            </span>
          }
        />
      ) : (
        <>
          {/* TabState 버튼 */}
          <TabBtnCt>
            <button
              className={tabState === "search" ? "active" : ""}
              onClick={() => {
                setTabState("search");
              }}
            >
              검색형
            </button>
            <button
              className={tabState === "commu" ? "active" : ""}
              onClick={() => {
                setTabState("commu");
              }}
            >
              대화형
            </button>
            <button
              className={tabState === "mixSearch" ? "active" : ""}
              onClick={() => {
                setTabState("mixSearch");
              }}
            >
              혼합검색형
            </button>
            <button
              className={tabState === "mixCommu" ? "active" : ""}
              onClick={() => {
                setTabState("mixCommu");
              }}
            >
              혼합대화형
            </button>
          </TabBtnCt>
          {/* TabState 버튼 */}
          {/* TabState 콘텐츠 */}
          {/* 챗봇 기록에 있는 목록 ui로 만들었음 */}
          {/* TabState
      검색형 : 클릭시 다음으로 넘어가는 기능 X 
      대화형 : 클릭시 mesiwerchat에서 사용되었던 챗봇 기록에서 개별 아이템 클릭시 대화(응답관리) 영억으로 넘어감
      */}
          {tabState === "search" ? (
            <TabContentCt className="tab01">
              <HistPlainSearchBox
                chatBotData={pickChatBotData}
                setPopState={setPopState}
                setPopMessage={setPopMessage}
              />
            </TabContentCt>
          ) : tabState === "commu" ? (
            <TabContentCt className="tab02">
              <HistPlainChatBox
                keyword={keyword}
                setKeyword={setKeyword}
                chatBotData={pickChatBotData}
                setPopState={setPopState}
                setPopMessage={setPopMessage}
              />
            </TabContentCt>
          ) : tabState === "mixSearch" ? (
            <TabContentCt className="tab03">
              <HistCombineSearchBox
                type={"combineSearch"}
                chatBotData={pickChatBotData}
                setPopState={setPopState}
                setPopMessage={setPopMessage}
              />
            </TabContentCt>
          ) : (
            tabState === "mixCommu" && (
              <TabContentCt className="tab04">
                <HistCombineChatBox
                  keyword={keyword}
                  setKeyword={setKeyword}
                  chatBotData={pickChatBotData}
                  setPopState={setPopState}
                  setPopMessage={setPopMessage}
                />
              </TabContentCt>
            )
          )}
          {/* TabState 콘텐츠 */}
        </>
      )}
      {popState && (
        <AlertPop2
          popState={popState}
          setPopState={setPopState}
          text={popMessage}
        />
      )}
    </DASH_HOME.Container>
  );
}

const TabBtnCt = styled.div`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;

  @media (max-width: 480px) {
    margin-bottom: 20px;
    display: flex;
    & button {
      flex: 1 0 auto;
      font-size: 14px !important;
      padding-right: 5px;
      margin-right: 5px;
    }
  }

  & button {
    font-size: 16px;
    border-right: 1px solid #dcdcdc;
    margin-right: 10px;
    padding-right: 10px;
    color: #000;

    &.active {
      color: #ff892a;
      font-weight: 500;
      text-decoration: underline;
      text-underline-offset: 5px;
    }

    &:last-child {
      border-right: none;
    }
    &:hover {
      font-weight: 500;
      opacity: 0.8;
      color: #ff892a;
    }
  }
`;

const TabContentCt = styled.div`
  /* border: 1px solid red; */

  width: 70%;

  @media (max-width: 480px) {
    width: 100%;
  }

  &.tab01 {
    /* border: 1px dashed red; */
  }
  &.tab02 {
    /* border: 1px dashed blue; */
  }
  &.tab03 {
    /* border: 1px dashed green; */
  }
  &.tab04 {
    /* border: 1px dashed purple; */
  }
`;

const ResultSection = styled.section`
  width: 100%;
  height: 100%;
  margin-top: 200px;

  & p.empty {
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    color: #888;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vw;
    width: 100%;

    @media (max-width: 480px) {
      font-size: 20px;
    }
  }
`;
