import { ErrorCatch } from "api/ErrorCatch";
import {
  checkCertificationKey,
  sendCertification,
  signUpToEmail,
} from "api/memberApi";
import CloseButton from "components/atomic/CloseButton";
import InputBox from "components/atomic/InputBox";
import ConfirmPop from "components/templates/popup/ConfirmPop";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import styled, { keyframes } from "styled-components";
import icon_Logo from "../../../assets/images/LOGO_Assistant.svg";
import AgreeToOverAge from "./AgreeToOverAge";
import AgreeToTermsOfUse from "./AgreeToTermsOfUse";
import AgreeToPersonalData from "./AgreeToPersonalData";
import AgreeToMarketingInfo from "./AgreeToMarketingInfo";
import PersonalPolicy from "components/pages/docs/PersonalPolicy";
import TermOfUsePolicy from "components/pages/docs/TermOfUsePolicy";
import MarketingPolicy from "components/pages/docs/MarketingPolicy";

import { AlertPop2 } from "../popup/AlertPop";

export default function SignUpPop({ setOpenSignUp, signIn }) {
  const [waitStatus, setWaitStatus] = useState(false);

  // 약관 동의 시리즈
  const [allCheck, setAllCheck] = useState(false);
  const [checkOverAge, setCheckOverAge] = useState(false);
  const [checkTermsOfUse, setCheckTermsOfUse] = useState(false);
  const [checkPersonalData, setCheckPersonalData] = useState(false);
  const [checkMarketingInfo, setCheckMarketingInfo] = useState(false);
  const [requiredAgreeMessage, setRequiredAgreeMessage] = useState("");

  const [termsOfUse, setTermsOfUse] = useState(false);
  const [personalData, setPersonalData] = useState(false);
  const [marketingInfo, setMarketingInfo] = useState(false);

  /* 이용동의 항목 값 */
  const agreeItems = {
    overAge: checkOverAge,
    termsOfUse: checkTermsOfUse,
    personalData: checkPersonalData,
    marketingInfo: checkMarketingInfo,
  };

  //각 Input 필드 Value
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  //Validation 플래그

  const [checkPwdValid, setCheckPwdValid] = useState(false);
  const [checkConfirmPwdValid, setCheckConfirmPwdValid] = useState(false);

  //각 Input 필드 상태 메세지
  const [emailStateMessage, setEmailStateMessage] = useState("");
  const [pwdStateMessage, setPwdStateMessage] = useState("");
  const [confirmPwdStateMessage, setConfirmPwdStateMessage] = useState("");

  //이메일이 인증되었는지 -> 가입 시 체크
  const [isCertificatedEmail, setIsCertificatedEmail] = useState(false);
  const [sendCertificationEmail, setSendCertificationEmail] = useState(false);
  const [responseCertification, setResponseCertification] = useState("");

  //이메일 검증 정규식
  const emailRegExp = useMemo(
    () =>
      /^(([^<>()\\[\].,;:\s@"]+(\.[^<>()\\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    []
  );

  // 팝업 state
  const [popState, setPopState] = useState(false);
  const [popMessage, setPopMessage] = useState("");

  const [showConfirmPop, setShowConfirmPop] = useState(false);

  useEffect(() => {
    setCheckOverAge(allCheck);
    setCheckTermsOfUse(allCheck);
    setCheckPersonalData(allCheck);
    setCheckMarketingInfo(allCheck);
  }, [allCheck]);

  useEffect(() => {
    if (checkOverAge && checkTermsOfUse && checkPersonalData) {
      setRequiredAgreeMessage("");
    }
  }, [checkOverAge, checkPersonalData, checkTermsOfUse]);

  const validationCheck = () => {
    if (!checkOverAge || !checkTermsOfUse || !checkPersonalData) {
      setRequiredAgreeMessage("필수 동의 항목을 체크해주세요.");
      return;
    }

    if (!email) {
      setEmailStateMessage("이메일을 입력하세요.");
      return;
    } else if (!emailRegExp.test(email)) {
      setEmailStateMessage("이메일 형식이 잘못되었습니다.");
      return;
    } else if (!sendCertificationEmail) {
      setEmailStateMessage("인증 메일을 전송하여 인증하세요.");
    }

    const response = checkCertificationKey(responseCertification, email);

    response
      .then(({ data, status, data: { certificationYn } }) => {
        if (certificationYn === "Y") {
          setEmailStateMessage("");
          setIsCertificatedEmail(true);

          if (!password) {
            setPwdStateMessage("비밀번호를 입력하세요.");
            return;
          }

          if (!confirmPassword) {
            setConfirmPwdStateMessage("동일한 비밀번호를 입력하세요.");
            return;
          }

          if (!checkPwdValid || !checkConfirmPwdValid) {
            return;
          }

          setShowConfirmPop(true);
        } else {
          setEmailStateMessage("전송된 메일에서 인증을 완료 해주세요.");
          return;
        }
      })
      .catch((error) => {
        !waitStatus && setSendCertificationEmail(false);
        console.log(error);
      });
  };

  //인증메일보내기
  const handleSendCertificationEmail = (e) => {
    setWaitStatus(true);
    if (isCertificatedEmail) return;

    if (!email) {
      setEmailStateMessage("이메일을 입력하세요.");
      return;
    } else if (!emailRegExp.test(email)) {
      setEmailStateMessage("이메일 형식이 잘못되었습니다.");
      return;
    } else {
      setEmailStateMessage("");
    }

    const response = sendCertification(email);
    setSendCertificationEmail(true);

    response
      .then(({ data: { certificationKey }, status }) => {
        if (status === 200) {
          setResponseCertification(certificationKey);
          setEmailStateMessage("");
        }
      })
      .catch((error) => {
        setSendCertificationEmail(false);

        if (error.response) {
          const {
            response: { data, status },
          } = error;
          if (status === 409) {
            setEmailStateMessage(data.errorDescription);
          } else {
            console.log(data.errorDescription);
            setEmailStateMessage(
              "이메일 발송 중 오류 발생. 관리자에게 문의 해주세요."
            );
          }
        }
      })
      .finally(() => {
        setWaitStatus(false);
      });
  };

  const signUp = async () => {
    try {
      const response = await signUpToEmail(email, password, agreeItems);

      if (response.status !== 200) {
        setPopState((prev) => !prev);
        setPopMessage("알 수 없는 에러 발생 다시 시도해 주세요.");
        // alert("알 수 없는 에러 발생 다시 시도해 주세요.");
      } else {
        setPopState((prev) => !prev);
        setPopMessage("가입 되었습니다.");
        // alert("가입 되었습니다.");
        signIn(email, password);
      }
    } catch (error) {
      ErrorCatch(error, null);
    } finally {
    }
  };

  const closePopup = () => setOpenSignUp(false);

  const handleValidPassword = useCallback(
    (e) => {
      if (password.length < 6 || password.length > 20) {
        setPwdStateMessage(
          "영문, 숫자, 특수 문자를 조합하여 6 ~ 20자 이내로 작성하세요."
        );
        setCheckPwdValid(false);
      } else if (
        !/[a-zA-Z]/g.test(password) ||
        !/[0-9]/g.test(password) ||
        !/[\\{\\}\\[\]\\/?.,;:|\\)*~`!^\-_+<>@\\#$%&\\=\\(\\'\\"]/g.test(
          password
        ) ||
        /[\s]/g.test(password)
      ) {
        setPwdStateMessage(
          "영문, 숫자, 특수 문자를 조합하여 6 ~ 20자 이내로 작성하세요."
        );
        setCheckPwdValid(false);
      } else {
        setPwdStateMessage("");
        setCheckPwdValid(true);
      }
    },
    [password]
  );

  useEffect(() => {
    password && handleValidPassword();
    !password && setPwdStateMessage("");
  }, [password, handleValidPassword]);

  useEffect(() => {
    if (confirmPassword && password) {
      if (password === confirmPassword) {
        setConfirmPwdStateMessage("");
        setCheckConfirmPwdValid(true);
      } else {
        setConfirmPwdStateMessage("비밀번호가 일치하지 않습니다.");
        setCheckConfirmPwdValid(false);
      }
    }
  }, [confirmPassword, password]);

  useEffect(() => {
    setSendCertificationEmail(false);
  }, [email]);

  return (
    <>
      {createPortal(
        <Wrapper>
          <Container>
            <Header>
              <LogoCt>
                <Logo src={icon_Logo} alt="로고" />
                회원가입
              </LogoCt>

              <CloseButton callBack={closePopup} />
            </Header>
            <Body>
              <InputGroup>
                <label>이메일</label>
                <div className="inputCt">
                  <InputBox
                    inputId="email"
                    placeholderText="ex) help@meswiser.ai"
                    value={email}
                    setValue={setEmail}
                    disabled={isCertificatedEmail ? true : false}
                    inputclass={"signUp"}
                  />
                  <button
                    type="button"
                    className="input-group-btn certiBtn"
                    onClick={() =>
                      !sendCertificationEmail && handleSendCertificationEmail()
                    }
                  >
                    {sendCertificationEmail ? "전송완료" : "인증메일 전송"}
                  </button>
                </div>

                {emailStateMessage && (
                  <span className="invalid">{emailStateMessage}</span>
                )}
              </InputGroup>
              <InputGroup>
                <label>비밀번호</label>
                <InputBox
                  type="password"
                  inputId="password"
                  placeholderText="비밀번호(영문, 숫자, 특수문자 조합 6~20자 이내)"
                  value={password}
                  setValue={setPassword}
                  inputclass={"signUp"}
                />
                {pwdStateMessage && (
                  <span className="invalid">{pwdStateMessage}</span>
                )}
              </InputGroup>
              <InputGroup>
                <label>비밀번호 확인</label>
                <InputBox
                  type="password"
                  inputId="passwordConfirm"
                  placeholderText="비밀번호 확인"
                  value={confirmPassword}
                  setValue={setConfirmPassword}
                  inputclass={"signUp"}
                />
                {confirmPwdStateMessage && (
                  <span className="invalid">{confirmPwdStateMessage}</span>
                )}
              </InputGroup>

              <ServiceArgreeContainer>
                <ServiceArgreeAll>
                  <input
                    onChange={(e) => setAllCheck((current) => !current)}
                    id="serviceAllChk"
                    type="checkbox"
                    checked={allCheck}
                  ></input>
                  <label htmlFor="serviceAllChk">모든 약관 동의</label>
                </ServiceArgreeAll>
                {requiredAgreeMessage && (
                  <span className="invalid">{requiredAgreeMessage}</span>
                )}
              </ServiceArgreeContainer>

              <ServiceAgreeContainerWapper>
                <AgreeToOverAge
                  checkOverAge={checkOverAge}
                  setCheckOverAge={setCheckOverAge}
                />
                <AgreeToTermsOfUse
                  checkTermsOfUse={checkTermsOfUse}
                  setCheckTermsOfUse={setCheckTermsOfUse}
                  setTermsOfUse={setTermsOfUse}
                />
                <AgreeToPersonalData
                  checkPersonalData={checkPersonalData}
                  setCheckPersonalData={setCheckPersonalData}
                  setPersonalData={setPersonalData}
                />
                <AgreeToMarketingInfo
                  checkMarketingInfo={checkMarketingInfo}
                  setCheckMarketingInfo={setCheckMarketingInfo}
                  setMarketingInfo={setMarketingInfo}
                />
              </ServiceAgreeContainerWapper>

              <MakeAccBtn
                className="modal-btn joinBtn"
                onClick={(e) => {
                  validationCheck();
                }}
              >
                회원가입
              </MakeAccBtn>
            </Body>
          </Container>
          {showConfirmPop && (
            <ConfirmPop
              setShowConfirmPop={setShowConfirmPop}
              callback={signUp}
              title="회원가입을 하시겠습니까?"
              description=""
              btnText="가입하기"
              isConfirm={true}
            />
          )}
          {(termsOfUse || personalData || marketingInfo) && (
            <>
              {/* <PolicyContainer> */}
              {termsOfUse && (
                <TermOfUsePolicy
                  setTermsOfUse={setTermsOfUse}
                  callFrom={"signUp"}
                />
              )}
              {personalData && (
                <PersonalPolicy
                  setPersonalData={setPersonalData}
                  callFrom={"signUp"}
                />
              )}
              {marketingInfo && (
                <MarketingPolicy
                  setMarketingInfo={setMarketingInfo}
                  callFrom={"signUp"}
                />
              )}
              {/* </PolicyContainer> */}
            </>
          )}
        </Wrapper>,
        document.getElementById("modal")
      )}
      {popState && (
        <AlertPop2
          popState={popState}
          setPopState={setPopState}
          text={popMessage}
        />
      )}
    </>
  );
}

/* 기존 애니메이션 */
const openModal = keyframes`
  0% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
`;

const Container = styled.div`
  width: 500px;
  height: auto;

  animation: ${openModal} 0.35s ease-in-out;
  animation-fill-mode: both;
  display: inline-block;
  position: absolute;
  top: 20%;
  left: 50%;
  background: #fff;
  opacity: 0;
  border-radius: 15px;

  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
    left: 0%;
    top: 0%;
    border-radius: 0px;
  }
`;

const Header = styled.div`
  border-radius: 15px 15px 0 0;
  display: flex !important;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 20px;
  border-bottom: solid 1px #dcdcdc;
  background: #fff;

  @media (max-width: 480px) {
    margin: 30px 0px 5px 0px;
  }
`;

const Body = styled.div`
  padding: 20px;
  max-height: none !important;
`;

const InputGroup = styled.div`
  margin-bottom: 20px;
  position: relative;

  &.joinBtn {
    border: 1px dashed blue;
    height: 30px;
  }
  & .inputCt {
    display: flex;
  }

  label {
    display: block;
    font-size: 13px;
    margin-bottom: 5px;
    color: #000;
  }

  span {
    font-size: 11px;
    color: tomato;
  }

  input {
    width: 100% !important;
    height: 45px !important;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding-left: 13px;
    position: relative;
    box-sizing: border-box;
    font-size: 14px;
  }

  button {
    position: absolute;
    right: 0;
    top: -0.1vh;
    z-index: 2;
    width: 100px;
    height: 45px;
    font-size: 0.7rem;
    color: var(--text-linkColor);
    text-align: center;
    background: transparent;
    border: none;
  }
  & .certiBtn {
    position: static;
    flex: 0 0 auto;
    background-color: #ccc;
    color: #333;
  }

  strong {
    position: absolute;
    right: -5%;
    top: 50%;
    z-index: 2;
    width: 100px;
    height: 45px;
    font-size: 12px;
    color: #1a78c9;
    text-align: center;
  }

  @media (max-width: 480px) {
    margin: 0 0 15px 0;
  }
`;

const LogoCt = styled.h2`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
`;

const Logo = styled.img`
  //width: 50px;
  height: 14px;
  margin-right: 5px;
`;

// const AccountDescCt = styled.div`
//   font-size: 12px;
//   color: #999;
//   text-align: center;
//   & .join {
//     color: #999;
//     font-size: 12px;
//   }
// `;

const MakeAccBtn = styled.button`
  border-radius: 5px;
  width: 100%;
  height: 45px;
  background-color: #ff892a;
  color: #fff;

  //margin-bottom: 20px;
`;

/* 약관 동의 시리즈. */
const ServiceArgreeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    font-size: 15px;
    color: tomato;
    margin-bottom: 10px;
  }
`;

const ServiceArgreeAll = styled.div`
  width: 100%;
  //margin: 0px 10px 15px 10px;
  //text-align: center;
  font-size: 16px;
  font-weight: 300;
  padding: 10px 0;
  //background-color: #dcdcdc;
  border-radius: 3px;
  & label {
    margin-left: 5px;
  }
`;

const ServiceAgreeContainerWapper = styled.div`
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 3px;
  margin-bottom: 20px;

  max-height: 190px;
`;
