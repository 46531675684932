export class PriceUtil {
  static pricingHeader = {
    unit: "단위",
    knowledgeStorage: "지식용량",
    dataStorage: "data(file용량)",
    VectorStore: "Vector Store",
    QuestionNum: "질문수",
    knowledgeManage: "지식관리",
    inviteUser: "사용자 초대",
    adminSupport: "운영지원",
    serviceUpgrade: "서비스 무상 업그레이드 지원",
  };
  static pricingPolicyContent = [
    {
      tag: "free",
      title: "무료",
      price: "FREE",
      unit: "무료봇 2개",
      knowledgeStorage: "",
      dataStorage: "",
      VectorStore: "학습지식 용량 1M 제공",
      QuestionNum: "50개/일",
      knowledgeManage: true,
      inviteUser: false,
      adminSupport: false,
      serviceUpgrade: true,
    },
    {
      tag: "basic",
      title: "베이직",
      price: "15만",
      unit: "무료의 모든 기능 + 유료봇 1",
      knowledgeStorage: "",
      dataStorage: "Disk 용량 200M 제공",
      VectorStore: "학습지식 용량 60M 제공",
      QuestionNum: "800개/일",
      knowledgeManage: true,
      inviteUser: "3명",
      adminSupport: true,
      serviceUpgrade: true,
    },
    {
      tag: "premium",
      title: "프리미엄",
      price: "30만",
      unit: "무료의 모든 기능 + 유료봇 1",
      knowledgeStorage: "",
      dataStorage: "Disk 용량 1G 제공",
      VectorStore: "학습지식 용량 300M 제공",
      QuestionNum: "2,500개/일",
      knowledgeManage: true,
      inviteUser: "15명",
      adminSupport: true,
      serviceUpgrade: true,
    },
  ];

  static pricingPolicyContentMobile = [
    {
      tag: "free",
      title: "무료",
      price: "FREE",
      unit: "무료봇 2개",
      VectorStore: "학습지식 용량 1M 제공",
      QuestionNum: "일 질문수50개",
      knowledgeManage: "지식관리 기능",
      serviceUpgrade: "서비스 무상 업그레이드 지원",
    },
    {
      tag: "basic",
      title: "베이직",
      price: "15만",
      unit: "유료봇 1 + 무료봇 2",
      unitDesc: "(무료봇은 학습지식, 질문수 무료버전 기준)",
      dataStorage: "Disk 용량 200M 제공",
      VectorStore: "학습지식 용량 60M 제공",
      QuestionNum: "일 질문수 800개",
      inviteUser: "사용자 초대 3명",
      inviteUserDesc: "(MESwiser 회원 기준)",
      knowledgeManage: "지식관리 기능",
      knowledgeManageDesc: "(지식관리 기능을 통한 다수의 학습 지식 관리)",
      adminSupport: "운영지원",
      adminSupportDesc: "(로그 관리 및 분석지원)",
      serviceUpgrade: "서비스 무상 업그레이드 지원",
    },
    {
      tag: "premium",
      title: "프리미엄",
      price: "30만",
      unit: "유료봇 1 + 무료봇 2",
      unitDesc: "(무료봇은 학습지식, 질문수 무료버전 기준)",
      dataStorage: "Disk 용량 1G 제공",
      VectorStore: "학습지식 용량 300M 제공",
      VectorStoreDesc: "(추가용량은 별도 협의)",
      QuestionNum: "일 질문수 2,500개",
      QuestionNumDesc: "(추가용량은 별도 협의)",
      inviteUser: "사용자 초대 15명",
      inviteUserDesc: "(추가인원은 별도 협의/MESwiser 회원 기준)",
      knowledgeManage: "지식관리 기능",
      knowledgeManageDesc: "(지식관리 기능을 통한 다수의 학습 지식 관리)",
      adminSupport: "운영지원",
      adminSupportDesc: "(로그 관리 및 분석지원)",
      serviceUpgrade: "서비스 무상 업그레이드 지원",
    },
  ];
  static botServiceModel(serviceModel) {
    switch (serviceModel) {
      case "0":
      default:
        return "무료";
      case "1":
        return "베이직";
      case "2":
        return "프리미엄";
    }
  };
}
