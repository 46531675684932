import { useEffect, useRef, useState } from "react";
import TypeWriter from "typewriter-effect";

export default function ChatTypeWriter({ text, isLoading, setScSignal }) {
  const [showCursor, setShowCursor] = useState(true);

  const typeWrapperRef = useRef(null);

  useEffect(() => {
    !showCursor &&
      (typeWrapperRef.current.typewriter.lastChild.style.display = "none");
  }, [showCursor]);

  return (
    <TypeWriter
      ref={typeWrapperRef}
      options={{
        autoStart: true,
        delay: 30,
        // loop: true,
        // pauseFor: 100,
      }}
      onInit={(typewriter) => {
        typewriter
          .typeString(text)
          .start()
          .callFunction(() => {
            setShowCursor((prev) => !prev);
            setScSignal && setScSignal("off");
            // let cursor = document.querySelector(".Typewriter__cursor");
            // cursor.style.display = "none";
          });
      }}
    />
  );
}
