import { createContext, useState } from "react";

const ExtractedDataModalInfoContext = createContext({
  getInfo: () => {},
  resetInfo: () => {},
  modalInfo: null,
});

export { ExtractedDataModalInfoContext };

export default function ExtractedDataModalInfoProvider(props) {
  const { children } = props;

  const [modalInfo, setModalInfo] = useState(null);

  const getInfo = (data) => {
    if (data !== undefined) {
      setModalInfo(data);
    } else {
      return modalInfo;
    }
  };

  const resetInfo = () => {
    setModalInfo(null);
  };

  return (
    <ExtractedDataModalInfoContext.Provider
      value={{ modalInfo, getInfo, resetInfo }}
    >
      {children}
    </ExtractedDataModalInfoContext.Provider>
  );
}
