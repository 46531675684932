import uuid from "react-uuid";
import { getNow } from "../../../../utils/dateUtil.js";
import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import RangeSlider from "react-range-slider-input";
import BaseChat from "components/pages/chat/BaseChat.js";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider.js";

export default function CombineChatBox(props) {
  const { chatBotData, setPopState, setPopMessage, handleSignOutApp } =
    props ?? {};
  // 대화사용
  const [openChatRoom, setOpenChatRoom] = useState(null);
  const [messages, setMessages] = useState([]);
  const [chatRoomId, setChatRoomId] = useState(null);
  // 혼합검색형 사용.
  const RefRange = useRef("");
  const RefMin02 = useRef();
  const RefMax02 = useRef();

  const { isMobile } = useContext(isMobileContext);

  useEffect(() => {
    if (chatBotData) {
      setChatRoomId(uuid()); // 채팅방 초기화
      let message = "";
      if (chatBotData.greetingMessage) message = chatBotData.greetingMessage;
      else message = "입력하신 URL주소에 포함 된 내용을 기반으로 답변드립니다.";

      setMessages([
        {
          message: message,
          type: "bot",
          messageType: "text",
          time: getNow(),
          id: uuid(),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatBotData]);

  return (
    <>
      <SearchOpCt
        style={
          !isMobile ? { width: "50%", textAlign: "left" } : { width: "100%" }
        }
      >
        <p className="title">검색 조건</p>

        <div className="optionValueCt">
          <p className="minValueTitle">
            키워드{" "}
            <span ref={RefMin02} className="minValue">
              20
            </span>
          </p>

          <p className="maxValueTitle">
            시맨틱{" "}
            <span ref={RefMax02} className="maxValue">
              80
            </span>
          </p>
        </div>

        <RangeSlider
          // value={0}
          ref={RefRange}
          onInput={(value) => {
            RefMin02.current.innerText = 100 - RefRange.current.value.max;

            RefMax02.current.innerText = RefRange.current.value.max;
          }}
          thumbsDisabled={[true, false]}
          rangeSlideDisabled={true}
          defaultValue={[0, 80]}
          min={0}
          max={100}
          step={10}
        />
      </SearchOpCt>
      <div style={{ width: "100%" }}>
        <div>
          {/* baseChat 자리 */}
          <ExChat>
            <BaseChat
              popupInfo={{
                isPopup: false,
                setOpenChatRoom,
                openChatRoom,
              }}
              showQuestionBox={true}
              messages={messages}
              setMessages={setMessages}
              chatBotData={chatBotData}
              chatRoomId={chatRoomId}
              handleSignOutApp={handleSignOutApp}
              isHeader={true}
              botClass="HISTORY"
              setPopMessage={setPopMessage}
              setPopState={setPopState}
              keywordRangeValue={RefRange}
            />
          </ExChat>
        </div>
      </div>
    </>
  );
}

const SearchOpCt = styled.div`
  margin: 20px 0 50px;
  & p.title {
    color: #777;
    font-weight: 600;
    margin: 10px 0;
  }

  & .optionValueCt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    & p {
      color: #888;
      & span {
        font-weight: 500;
      }
    }
  }
`;

const ExChat = styled.div`
  /* border: 1px solid #dcdcdc; */
  width: 100%;
  height: 600px;

  /* border-radius: 10px; */
  .chat--bot2.chat--cover.HISTORY {
    margin: 0;
  }
`;
