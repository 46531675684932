import { useState, useEffect } from "react";
import "remixicon/fonts/remixicon.css";
import styled from "styled-components";
import iconTogggleDown from "assets/images/caret_up.png";

export default function KnowItemBox(props) {
  const { useNewLine, tokens, expandCollapse, data } = props ?? {};
  const [toggleBoard, setToggleBoard] = useState(false);

  //  defaultAns (답변)
  const [defaultAns, setDefaultAns] = useState(data.answer);

  // 개행 관리 함수
  const handleEnterText = (normalText_Par) => {
    // 23.11.13. 로마숫자에 대해서 나올지 모르므로  근데 이렇게 까지 해야 되나 싶긴함..
    // var pattern = /\b(?:i{1,3}|iv|v|vi{0,3}|ix|x)\.\b/gi;

    // 숫자 다음의 .는 split 되지 않도록 임시 변경 > 숫자와 영문자 _ 포함으로 변경 하이라이팅이 먼저 수행 되기에 </span> 들어가는 경우도 방어
    const tmpStr = normalText_Par.replace(/[\w<\/span>]\./g, (match) =>
      match.replace(".", "@@DOT@@")
    );
    // 문장 마침표로 split 하고 \n 개행 처리
    const sentences = tmpStr.split(".");
    // 임시 처리를 원복.
    const newText = sentences
      .map((sentence) => sentence.trim().replace(/@@DOT@@/g, "."))
      .join(".\n");
    return newText;
  };

  // 하이라이트 관리 함수
  const handleHighLight = (normalText_Par) => {
    // console.log(">>> searchItemCt: ", tokens);
    if (tokens && tokens.length === 0) {
      return normalText_Par;
    }

    // console.log(">>>>>> tokens:", tokens);
    const highLightWord = tokens
      .map((word) => word.trim().replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"))
      .join("|");
    const regex = new RegExp(`(\\b|\\B)(${highLightWord})(\\b|\\B)`, "gi");

    // 주어진 answer문장에서 highLightWord와 일치하는 단어를 찾아 <span> 태그로 감싸기 *스타일 포함되어있음
    const wrapText = normalText_Par.replace(
      regex,
      `<span class="highLight">$2</span>`
    );
    return wrapText;
  };

  // 개행, 하이라이트 관리 함수
  const handleTextView = () => {
    let newText = defaultAns;
    newText = handleHighLight(newText); // 하이라이트는 무조건
    if (useNewLine) {
      newText = handleEnterText(newText);
    }
    return newText;
  };

  useEffect(() => {
    if (expandCollapse === 1) {
      setToggleBoard(true);
    } else if (expandCollapse === 2) {
      setToggleBoard(false);
    }
  }, [expandCollapse]);

  return (
    <>
      <ResultList>
        <ResultItem className="">
          <ResultTypeText className={data.searchUid === "URL" ? "url" : "file"}>
            {data.searchUid === "URL" ? "URL" : "FILE"}
          </ResultTypeText>
          <div className="textCt">
            <pre
              className={
                toggleBoard
                  ? "fileName url source"
                  : "fileName url source hidden"
              }
            >
              <span className="keyword">{data.keyword}</span>
            </pre>
            <pre
              className={toggleBoard ? "fileName url" : "fileName url hidden"}
            >
              <div
                className="answer"
                dangerouslySetInnerHTML={{
                  __html: handleTextView(),
                }}
              />
            </pre>
          </div>

          <MoreBtn
            className={!toggleBoard ? "moreBtn down" : "moreBtn up"}
            onClick={(e) => {
              setToggleBoard((prev) => !prev);
            }}
            icon={iconTogggleDown}
          />
        </ResultItem>
      </ResultList>
    </>
  );
}

const ResultList = styled.ul`
  padding-bottom: 5px;
`;

const ResultItem = styled.li`
  margin: 7.5px 0 15px;

  position: relative;

  & div.textCt {
    border-radius: 0 0 5px 5px;
    border: 1px solid #dcdcdc;
    padding: 10px;
    background: #fff;

    border: 1px solid #dcdcdc;
    width: 100%;
    & div.answer {
      line-height: normal;
      & span.highLight {
        font-size: 1.05rem;
        color: #000;
        background: rgba(255, 242, 168, 0.6);
        padding: 0.5px;
        margin: 0 1px;
        word-break: keep-all;
      }
    }
  }

  & pre.fileName {
    display: block;
  }

  & pre.fileName,
  pre.fileName {
    font-size: 16px;
    color: #000;
    font-weight: 350;
    margin-right: 10px;
    max-width: 100%;
    line-height: 20px;
    word-break: break-all;
    /* padding: 10px; */
    margin-bottom: 10px;
    white-space: pre-wrap;
    & span.keyword {
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.url {
      cursor: default;
      &:hover {
        /* text-decoration: underline; */
        /* opacity: 0.7; */
      }
    }
  }
  & pre.hidden,
  pre.hidden {
    min-height: 25px;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    & div.answer {
      min-height: 25px;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  pre.source {
    font-weight: 500;
    & span.hidden {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  & span.pageNum {
    display: inline-block;
    font-size: 15px;
    color: #000;
    font-weight: 300;
    position: relative;

    @media (max-width: 480px) {
      font-weight: 500;
    }

    @media (min-width: 481px) {
      &:after {
        content: "";
        border-left: 1px solid #999;
        position: absolute;
        top: 0;
        left: -7px;
        height: 100%;
      }
    }
  }

  & button.moreBtn {
  }
`;

const ResultTypeText = styled.span`
  padding: 2px 5px;
  font-size: 12px;
  border-radius: 3px 3px 3px 0;
  margin-right: 5px;
  color: #fff;
  background: #999;

  display: inline-block;
  &.url {
    background: #ff892a;
  }
  &.file {
    background: rgba(255, 206, 70, 0.81);
  }
`;

const MoreBtn = styled.button`
  /* float: right; */
  color: #fff;

  top: 18px;
  right: -18px;
  position: absolute;

  width: 18px;
  height: 18px;

  background: #888 url(${({ icon }) => icon}) no-repeat;
  background-size: 10px;
  background-position: center;

  &.down {
    border-radius: 4px 0px 0px 4px;
    transform: rotate(180deg);
  }

  &.up {
    border-radius: 0px 4px 4px 0px;
    transform: rotate(0deg);
  }
`;
