export default class KnowledgeUtil {
  static checkType(item) {
    if (item === 20) {
      return "Folder";
    } else if (item === 0) {
      return "URL";
    } else if (item === 1) {
      return "File";
    } else if (item === 2) {
      return "DB";
    }
    return "";
  }

  static crawlInfo(botData) {
    if (!botData.crawlPage || !botData.crawlSize) return;
    const pageStr = botData.crawlPage > 1 ? `${botData.crawlPage} page /` : "";
    return `${pageStr} ${botData.crawlSize.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} byte`;
  }

  static itemToGridItem(item, index = 0) {
    const type = KnowledgeUtil.checkType(item.dataType);
    return {
      id: index + 1,
      dataName: item.dataName,
      fileName: item.dataName,
      type,
      size: item.dataType !== 20 ? KnowledgeUtil.crawlInfo(item ?? {}) : "",
      botUid: item.botUid,
      dataUid: item.dataUid,
      dataType: item.dataType,
      pdataUid: item.pdataUid,
      dataStatus: item.dataStatus,
      fileCount: item.fileCount,
      fileSize: item.fileSize,
      crawlSize: item.crawlSize,
      isRowSelectable: true,
      activatedFileCount: item.activatedFileCount,
      currentPath: item.currentPath,
      division: item.division,
      isClicked: item.dataType !== 20 ? true : item?.isClicked ?? false,
      extractedData: item.extractedData,
    };
  }
  static listToRows(knowledgeList) {
    return knowledgeList.map((item, index) => KnowledgeUtil.itemToGridItem(item, index));
  }

  static filterItems(defaultData, newData) {
    const updateList = defaultData.filter((item) => {
      return newData.findIndex((newItem) => newItem.id === item.id) !== -1 && item;
    });

    return updateList;
  }
}
