import camera_icon from "assets/images/icons/icon_camera.svg";
import user_icon from "assets/images/icons/user_icon.svg";
import CloseButton from "components/atomic/CloseButton";
import InputBox from "components/atomic/InputBox";
import NormalButton from "components/atomic/NormalButton2";
import TextArea from "components/atomic/TextArea";
import ProfilePop from "components/templates/popup/ProfilePop";
import { useEffect } from "react";
import { useState } from "react";
import styled, { keyframes } from "styled-components";

export default function BotInfoDetailMain({
  mode,
  botInfo,
  closePopup,
  callBackFunc, //신규면 createBot, 수정이면 modifyBot
}) {
  const IMAGE_PATH = process.env.REACT_APP_RESOURCE_HOST + "/images/bot/";
  const [showProfilePop, setShowProfilePop] = useState(false);

  const [botUid, setBotUid] = useState(botInfo.botUid);
  const [botName, setBotName] = useState(botInfo.botName);
  const [dataName, setDataName] = useState(botInfo.dataName);
  const [excludeUrl, setExcludeUrl] = useState(
    botInfo.excludeUrl.replace(/,/gi, "\n"),
  );
  const [greetingMessage, setGreetingMessage] = useState(
    botInfo.greetingMessage,
  );
  const [botRole, setBotRole] = useState(botInfo.botRole);

  const [botImagePath, setBotImagePath] = useState(botInfo.botImagePath);

  //
  const [previewImage, setPreviewImage] = useState("");

  const prevCallbackFunc = () => {
    let botInfoParams = {
      botUid,
      botName,
      dataName,
      excludeUrl: excludeUrl.trim().replace(/\n/gi, ","),
      greetingMessage,
      botRole,
      botImagePath,
    };

    callBackFunc && callBackFunc(botInfoParams);
  };

  useEffect(() => {
    setShowProfilePop(false);
  }, [botImagePath]);

  return (
    <Container>
      <Header>
        챗봇 {mode === "C" ? "생성" : "설정 수정"}
        <CloseButton callBack={closePopup} />
      </Header>
      <Body>
        <Photo>
          <Profile>
            <Thumb>
              <img
                src={
                  previewImage
                    ? previewImage
                    : botImagePath
                      ? IMAGE_PATH + botImagePath
                      : user_icon
                }
                alt=""
              />
            </Thumb>
            {showProfilePop && (
              <ProfilePop
                setShowProfilePop={setShowProfilePop}
                setPreviewImage={setPreviewImage}
                setBotImagePath={setBotImagePath}
              />
            )}
            <Modify
              imrSrc={camera_icon}
              onClick={(e) => setShowProfilePop(!showProfilePop)}
            ></Modify>
          </Profile>
          <InputGroup type="name">
            <Title className="ri-arrow-drop-right-line">챗봇 이름</Title>
            <InputBox
              inputId="botName"
              value={botName}
              setValue={setBotName}
              placeholderText="챗봇이름"
            />
          </InputGroup>
        </Photo>

        <InputGroup>
          <Title className="ri-arrow-drop-right-line">생성 URL</Title>
          <InputBox
            inputId="dataName"
            value={dataName}
            setValue={setDataName}
            placeholderText="봇 생성 URL"
            disabled={mode === "M" ? "disabled" : ""}
          />
        </InputGroup>

        <InputGroup>
          <Title className="ri-arrow-drop-right-line">제외 URL 패턴</Title>
          <TextArea
            textClass={"createBot"}
            id="excludeUrl"
            value={excludeUrl}
            setValue={setExcludeUrl}
            placeholderText="ex)&#13;&#10;https://MESwiser.ai/member&#13;&#10;https://MESwiser.ai/detail/*"
          />
        </InputGroup>
        <InputGroup>
          <Title className="ri-arrow-drop-right-line">환영 인사</Title>
          <InputBox
            inputId="welcomeMsg"
            value={greetingMessage}
            setValue={setGreetingMessage}
            placeholderText="챗봇 환영인사"
          />
        </InputGroup>
        <InputGroup>
          <Title className="ri-arrow-drop-right-line">챗봇 역할</Title>
          {/* SelectBox로 변경 해야 함 */}
          <InputBox
            type="text"
            inputId="botRole"
            value={botRole}
            setValue={setBotRole}
            placeholderText="챗봇 역할"
          />
        </InputGroup>

        <ButtonGroup>
          <NormalButton
            disabled={false}
            buttonId="cancel"
            buttonTitle="취소"
            buttonClass="cDel mid"
            callBackFunc={closePopup}
          />
          <NormalButton
            disabled={false}
            buttonTitle="등록"
            buttonClass="cOrange mid"
            callBackFunc={prevCallbackFunc}
          />
        </ButtonGroup>
      </Body>
    </Container>
  );
}

/* 기존 애니메이션 */
const openModal = keyframes`
0% {
-webkit-transform: translateY(10%);
transform: translateY(10%);
opacity: 0;
}

100% {
-webkit-transform: translate(0);
transform: translate(0);
opacity: 1;
}
`;

const Container = styled.div`
  /* width: 500px;
  height: 800px; */
  width: 550px;
  height: 700px;

  animation: ${openModal} 0.2s ease-in-out;
  animation-fill-mode: both;

  background: #fff;
  border-radius: 15px;
`;

const Header = styled.div`
  border-radius: 15px 15px 0px 0px;
  display: flex !important;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 7px;
  padding-left: 20px;
  border-bottom: solid 1px #dcdcdc;
  background: #fff;
  font-weight: bold;
`;

const Body = styled.div`
  padding: 20px;
  max-height: none !important;
`;

const InputGroup = styled.div`
  margin: 20px 10px;
  position: relative;

  input {
    width: 100% !important;
    height: 45px !important;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding-left: 13px;
    position: relative;
    box-sizing: border-box;
    font-size: 14px;
  }

  ${(props) =>
    props.type === "name" &&
    `
    width: 100%;
    float: left;
    text-align: left;
    margin-left: 40px;
  `}

  button {
    position: absolute;
    right: 0;
    top: -0.1vh;
    z-index: 2;
    width: 100px;
    height: 45px;
    font-size: 0.7rem;
    color: #1a78c9;
    color: var(--text-linkColor);
    text-align: center;
    background: transparent;
    border: none;
  }

  strong {
    position: absolute;
    right: 0;
    top: 1.5vh;
    z-index: 2;
    width: 100px;
    height: 45px;
    font-size: 0.7rem;
    color: #1a78c9;
    color: var(--text-linkColor);
    text-align: center;
    background: transparent;
    border: none;
  }
`;

const Title = styled.p`
  margin: 10px 0px;
`;

const ButtonGroup = styled.div`
  /* margin-top: 20px;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-bottom: -17px; */
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 20px 10px;

  button {
    font-size: 16px !important;
    font-weight: 500 !important;
    width: 100px;
    height: 35px;
    padding: 0;
    margin: 0 5px;
    cursor: pointer;
    &.cancel {
      /* background-color: #dcdcdc; */
      color: #ec5656;
      border: 1px solid #ec5656;
    }
    &.confirm {
      /* border: 1px solid #dcdcdc !important; */
      color: #ff892a;
      border: 1px solid #ff892a;
    }
    &:hover {
      opacity: 0.8;
    }
  }
`;
const Photo = styled.div`
  display: flex;
  width: 100%;
  margin-right: 40px;
  flex-shrink: 0;
  text-align: center;
`;

const Profile = styled.div`
  display: inline-block;
  width: 123px;
  height: 123px;
  position: relative;
  margin-bottom: 20px;
`;

const Thumb = styled.span`
  display: inline-block;
  width: 123px;
  height: 123px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Modify = styled.button`
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 2px;
  right: 2px;
  z-index: 2;
  border-radius: 50%;
  border: none;
  background: url(${({ imrSrc }) => imrSrc}) no-repeat;
  background-size: 15px 12px;
  background-position: 50%;
  background-color: #e9e9e9;
`;
