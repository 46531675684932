import styled, { css } from "styled-components";

export default function InputBox({
  disabled,
  inputId,
  value,
  setValue,
  onChangeFunc,
  placeholderText,
  type,
  inputclass,
  name,
  inputStyle,
  onKeyDown
}) {
  return (
    <Input
      className={inputclass}
      id={inputId ? inputId : "input_box"}
      disabled={disabled}
      type={type ? type : "text"}
      value={value}
      placeholder={placeholderText}
      style={type === "file" ? { display: "none" } : (inputStyle ?? {})}
      name={name}
      onChange={(e) => {
        e.preventDefault();
        if (onChangeFunc) {
          onChangeFunc(e);
        } else {
          setValue(e.target.value);
        }
      }}
      onKeyDown={(e) => onKeyDown && onKeyDown(e)}
    />
  );
}

const Input = styled.input`
  padding: 15px 15px;
  ${({ type }) => {
    if (type === "file") {
      return css`
        padding: 12px 5px;
      `;
    }
  }}
  border: none;
  height: 100%;
  font-size: 20px;

  flex: 1 1 auto;

  &.knowledge {
    color: #2a8cff;
    font-weight: 500;

    &::placeholder {
      color: #2c2c2c;
    }
    background: #e5f1ff;
  }

  &.signUp {
    background: #fff;
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: #000;
    transition: background-color 5000s ease-in-out 0s;
  }

  ${(props) =>
    props.disabled &&
    `
      opacity: 0.5;
      cursor: not-allowed;
    `}

  ${(props) =>
    props.id.includes("manage") &&
    `
      height: 40px;
      width: 100%;
      border: 1px solid #dcdcdc;
      font-size: 14px;
      padding-left: 10px;
    `}
    
  &::placeholder {
    color: #b0b0b0;
    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: 400;
  }

  &.botTable {
    &::placeholder {
      font-size: 14px;
    }
  }

  @media (max-width: 1024px) {
    font-size: 16px;
    &::placeholder {
      font-size: 15px;
    }
  }

  @media (max-width: 480px) {
    font-size: 14px !important;
    /* padding: 5px 10px !important; */
    &::placeholder {
      color: #b0b0b0;
      font-family: Noto Sans KR;
      font-size: 12px !important;
      font-weight: 400;
    }
  }
`;
