import styled, { keyframes } from "styled-components";
import iconCloseRound from "../../../assets/images/icon_closeRound.svg";

const FadeInUp = keyframes`
  0% {
    top: 35px;
    opacity: 0;
  }
  100% {
    top: 0%;
    opacity: 1;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 60px;

  background: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;

  img.botImg {
    width: 34px;
    height: 34px;
    margin-left: 17px;
    margin-right: 10px;
    border-radius: 7px;
  }
  .viewTitle,
  .chatName {
    width: 100%;

    padding: 14px;

    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #1a1a1a;

    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ri-close-line,
  .ri-arrow-left-s-line {
    font-size: 20px;
    margin: 0 5px;
  }

  button {
    width: 25px;
    height: 25px;
    margin-left: auto;
    margin-right: 15px;
    &.normalbutton {
      width: unset;
      height: unset;
    }
  }

  @media (min-width: 481px) {
    &.wide {
      height: 85px;
      img.botImg {
        width: 40px;
        height: 40px;
      }
      .viewTitle,
      .chatName {
        font-size: 20px;
        font-weight: 700;
        line-height: 29px;
      }
    }
  }

  &.widget {
    .viewTitle,
    .chatName {
      font-size: 14px !important;
      font-weight: 600 !important;
      line-height: 20px !important;
    }
    height: 60px;
  }
`;

export const HeaderContainerComplex = styled(HeaderContainer)`
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #dcdcdc;
  &.mobile {
    height: 35px;
    border-bottom: 0;
    .chatName {
      font-weight: 500;
      font-size: 13px !important;
      padding: 9px;
    }
  }
  div.complexHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 860px;
  }

  /* mobile 설정 */
  .normalbutton.rear {
    padding: 0;
    margin: 0 0 0 10px;
    /* color: #ff892a; */
  }
  .viewTitle.mobile {
    padding: 10px;
  }
`;

export const WholeChatWrapper = styled.div`
  & div.widget,
  & div.own {
    & div.chatInput {
      button {
        font-size: 14px;
      }
    }
    textarea {
      /* height: 20px !important; */

      font-size: 14px !important;
      line-height: normal !important;
      &::placeholder {
        font-size: 14px !important;
        line-height: normal !important;
      }
    }
  }

  &.widget {
    box-shadow: 0px 5px 17px 0px rgba(0, 0, 0, 0.26);
    border-radius: 25px;
    margin-right: 15px;
    animation: ${FadeInUp} 0.2s ease-in-out;
  }

  & div.chatObj {
    & button.response {
      height: fit-content;
      font-size: 14px;
      padding: 8px;
    }
  }
`;

export const PopWrapper = styled.div`
  display: ${({ isShow }) => (isShow ? "flex" : "none")};
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
`;

export const Container = styled.div`
  width: calc(50vw - 180px);
  min-height: fit-content;

  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 480px) {
    width: 90vw;
  }

  & p.title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  & .box {
    margin-bottom: 35px;

    &:nth-child(2) {
      flex-grow: 1;
      margin-bottom: 30px;
    }
  }

  & .btnCt {
    margin-top: auto;
    justify-content: center;

    & button {
      margin: 0 10px;
      padding: 5px 15px;
      flex: 1 0 40%;
    }
  }

  & > div {
    margin: 5px 0;
    width: 100%;

    input,
    textarea,
    button {
      width: 100%;
    }

    input,
    textarea {
      padding: 5px;
      border: 1px solid #b3b3b3;
      border-radius: 5px;
      font-size: 16px;

      @media (max-width: 480px) {
        font-size: 15px;
      }
    }

    textarea {
      height: calc(45vh - 110px);
      resize: none;
    }
  }
`;

export const AwsOptInfoBox = styled.div`
  position: absolute;
  top: -18px;
  left: 0px;
  color: #777;
`;

export const AwsOptKeyword = styled.p`
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  color: #ff892a;
  &.keyword {
    border-right: 1px solid #dcdcdc;
    margin-right: 5px;
    padding-right: 5px;
  }
`;

export const MenuBtn = styled.button`
  margin-right: 10px;

  @media (min-width: 481px) {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(${({ icon }) => icon}) no-repeat;
    background-size: 50px;
    background-position: center;
    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: 480px) {
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(${({ icon }) => icon}) no-repeat;
    background-size: 40px;
    background-position: center;
  }
`;

export const FadeIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

export const UpDown = keyframes`
0% {
  top: -30%;
  opacity: 0;
}
100% {
  top: 0px;
  opacity: 1;

}
`;

export const DropMenuBg = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);

  animation: ${FadeIn} 0.15s ease-in-out;

  &.knowVeiw {
    position: absolute;
  }

  border-radius: 25px;
  @media (max-width: 480px) {
    border-radius: 0;
  }
  @media (min-width: 481px) {
    &.wide {
      border-radius: 0;
    }
  }
`;

export const DropMenu = styled.div`
  display: none;
  z-index: 2;

  &.active {
    display: block;
    animation: ${UpDown} 0.3s ease-in-out;
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  z-index: 1;
  box-shadow: 0px 0.5px 0.5px 0px rgba(0, 0, 0, 0.3);
  background: #fff;
  border-radius: 0 0 36px 36px;

  overflow: hidden;

  & p {
    width: 100%;
    height: 45px;

    padding: 11px 20px;
    color: #5a5a5a;

    font-size: 14px;
    font-weight: 350;
    line-height: 20px;

    @media (min-width: 481px) {
      font-weight: 500;
    }
    cursor: pointer;
    & i {
      margin-right: 10px;
    }
    &:hover {
      color: #ff892a;
      font-weight: 500;
    }
  }
  & div.header {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      padding: 14px;
      width: auto;
      line-height: 20px;
      font-size: 13px;
      font-weight: 500;
      /* font-size: 1.2rem; */
      color: #00000060;
      &:hover {
        font-weight: 400;
        pointer: default !important;
      }
    }
    button {
      width: 25px;
      height: 25px;
      margin-right: 8px;
      background: url(${iconCloseRound}) no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  @media (min-width: 481px) {
    border-radius: 0px 0px 30px 30px;

    &.own,
    &.widget {
      border-radius: 25px 25px 30px 30px;
    }

    width: 100%;
    max-width: 860px;

    left: 50%;
    transform: translateX(-50%);

    & p {
      margin: 2px 0;
      background: #fff;
      font-size: 16px;
    }
  }
`;

const ChatMenuUp = keyframes`
  0% {
    bottom: -30%;
    opacity: 0;
  }
  100% {
    bottom: 58px;
    opacity: 1;

  }
`;

export const ChatMenuCt = styled.div`
  border: 1px solid transparent;
  width: calc(100% - 30px);

  border-radius: 10px 10px 0 0;

  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  color: #fff;
  transition: 0.3s;

  animation: ${ChatMenuUp} 0.2s ease-in-out;

  bottom: 60px;

  @media (min-width: 481px) {
    max-width: calc(860px - 30px);
    left: 50%;
    bottom: 74px;
    transform: translate(-50%);

    &.widget,
    &.own {
      max-width: calc(100% - 30px);
      bottom: 60px;
    }
  }

  @media (max-width: 480px) {
    &.widget,
    &.own {
      width: calc(100% - 30px);
    }
  }
`;

export const ChatMenuBtnCt = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: #fff;
`;

export const ChatMenuBtn = styled.button`
  flex: 1 0 30%;
  height: 80px;
  font-size: 15px;
  color: #fff;
  border: 0.5px solid #fff;

  background: linear-gradient(0deg, #2a8cff 0%, #2a8cff 100%);

  cursor: pointer;

  &:hover {
    font-weight: 500;
    opacity: 0.9;
  }

  & .icon {
    display: inline-block;

    @media (max-width: 480px) {
      width: 25px;
      height: 25px;
    }
    &.own,
    &.widget {
      width: 25px;
      height: 25px;
    }

    width: 35px;
    height: 35px;

    filter: invert(100%) sepia(9%) saturate(4791%) hue-rotate(220deg)
      brightness(111%) contrast(96%);

    background: url(${({ icon }) => icon}) no-repeat;
    /* background-image: url(${(props) => props.icon}); */
    background-size: contain;
    background-position: center;
  }

  &.own,
  &.widget {
    font-size: 13px;
  }

  @media (max-width: 480px) {
    font-size: 13px;
    letter-spacing: -1px;
  }
`;
