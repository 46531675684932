import { ErrorCatch } from "api/ErrorCatch";
import botApi from "api/botApi.js";
import { useContext, useEffect, useState } from "react";
import { AlertPop2 } from "components/templates/popup/AlertPop.js";
import styled from "styled-components";
import { isMobileContext } from "utils/providers/BrowserEnvContextProvider.js";
import { chatContext } from "utils/providers/ChatContextProvider.js";
import { signAppContext } from "utils/providers/SignContextProvider.js";
import ChatBotSelect from "../../../utils/ChatBotSelect.js";
import { DASH_HOME, FlexColumnBox } from "./dashboardStyle.js";
import ChatEmpty from "components/pages/dashboard/AsstEmpty.js";
import LoadingComponent from "utils/LoadingComponent";
import CombineSearchBox from "components/templates/dashboard/uses/CombineSearchBox.js";
import PlainChatBox from "components/templates/dashboard/uses/PlainChatBox.js";
import CombineChatBox from "components/templates/dashboard/uses/CombineChatBox.js";
// 하이라이트 및 개행처리 컴포넌트
import PlainSearchBox from "components/templates/dashboard/uses/PlainSearchBox.js";
// import PlainSearchBox from "components/templates/dashboard/uses/PlainSearchBox2.js";

export default function AsstUses() {
  //공통사용
  const { handleSignOutApp } = useContext(signAppContext);
  const { chatBotData } = useContext(chatContext); // 본인의 챗봇
  const [extBotList, setExtBotList] = useState(null); // 남이 사용하라고 준 챗봇 목록.
  const [optData, setOptData] = useState(null); // 남이 사용하라고 준 목록중 선택 목록.
  const [pickChatBotData, setPickChatBotData] = useState(null); // 활용에서 사용할 챗봇
  const { isMobile } = useContext(isMobileContext);
  const [popState, setPopState] = useState(false);
  const [popMessage, setPopMessage] = useState("");
  const [tabState, setTabState] = useState("search");

  // 검색형 사용.
  const [keyword, setKeyword] = useState("");

  const tabChangeFunc = (changeValue) => {
    setKeyword(""); // 검색어 초기화
    setTabState(changeValue);
  };

  // 나에게 사용하라고 연결한 봇이 있는가 추가 체크 리스트에 담을 예정.
  const getExternalUsesBot = async () => {
    try {
      const { status, data } = await botApi.getExternalUsesBot();
      console.log('status, data', status, data);
      if (status === 200) {
        const resultData = JSON.parse(data.userExtBotList);
        if (resultData && resultData.length > 0) {
          setExtBotList(resultData);
        } else {
          setExtBotList([]);
        }
      } else {
        setExtBotList([]);
      }
    } catch (error) {
      setExtBotList([]);
      ErrorCatch(error, handleSignOutApp);
    }
  };

  useEffect(() => {
    if (optData) {
      setPickChatBotData(optData);
    } else if (chatBotData) {
      setPickChatBotData(chatBotData);
    }
  }, [optData, chatBotData]);

  // 나에게 사용 가능한 봇이 있는지 확인한다.
  useEffect(() => {
    getExternalUsesBot();
  }, []);

  if (!extBotList) {
    return (
      <>
        <ResultSection>
          <p className="empty">로딩중입니다...</p>
        </ResultSection>
        <LoadingComponent />
      </>
    );
  }

  if (!chatBotData && extBotList.length === 0) {
    return (
      <DASH_HOME.Container>
        <ChatEmpty>아직 생성 된 챗봇이 없습니다.</ChatEmpty>
      </DASH_HOME.Container>
    );
  }

  return (
    <DASH_HOME.Container>
      <DASH_HOME.ContentCol style={{ height: "auto", alignItems: "start" }}>
        <SelectCt>
          <FlexColumnBox
            style={
              !isMobile
                ? {
                    marginBottom: "20px",
                    width: "70%",
                    maxWidth: "1200px",
                  }
                : {
                    marginBottom: "20px",
                    width: "100%",
                  }
            }
          >
            <ChatBotSelect
              usesOptions={extBotList}
              setSelectedOption={setOptData}
            />
          </FlexColumnBox>
        </SelectCt>
        {chatBotData?.dataType === 10 || optData?.dataType === 10 ? (
          <ChatEmpty
            MessageText={
              <span>
                아직 활용 가능한 지식이 없습니다.
                <br />
                <br /> 지식관리를 이용해주세요
              </span>
            }
          />
        ) : (
          <DashBoardCt>
            <TabBtnCt>
              <button
                className={tabState === "search" ? "active" : ""}
                onClick={() => {
                  tabChangeFunc("search");
                }}
              >
                검색형
              </button>
              <button
                className={tabState === "commu" ? "active" : ""}
                onClick={() => {
                  tabChangeFunc("commu");
                }}
              >
                대화형
              </button>
              <button
                className={tabState === "mixSearch" ? "active" : ""}
                onClick={() => {
                  tabChangeFunc("mixSearch");
                }}
              >
                혼합검색형
              </button>
              <button
                className={tabState === "mixCommu" ? "active" : ""}
                onClick={() => {
                  tabChangeFunc("mixCommu");
                }}
              >
                혼합대화형
              </button>
            </TabBtnCt>

            {/* tabState로 content 조건부 처리 */}
            {tabState === "search" ? (
              <TabContentCt className="tab01">
                <PlainSearchBox
                  keyword={keyword}
                  setKeyword={setKeyword}
                  chatBotData={pickChatBotData}
                  setPopState={setPopState}
                  setPopMessage={setPopMessage}
                />
              </TabContentCt>
            ) : tabState === "commu" ? (
              // BaseChat 컴포넌트, 기존에 api를 통해서 받아오던 bot 정보 삭제해서 ui만 보여주는 상태
              <TabContentCt className="tab02">
                <PlainChatBox
                  chatBotData={pickChatBotData}
                  setPopState={setPopState}
                  setPopMessage={setPopMessage}
                  handleSignOutApp={handleSignOutApp}
                />
              </TabContentCt>
            ) : tabState === "mixSearch" ? (
              // 혼합 검색형
              <TabContentCt className="tab03">
                <CombineSearchBox
                  keyword={keyword}
                  setKeyword={setKeyword}
                  chatBotData={pickChatBotData}
                  setPopState={setPopState}
                  setPopMessage={setPopMessage}
                />
              </TabContentCt>
            ) : (
              // BaseChat 컴포넌트, 기존에 api를 통해서 받아오던 bot 정보 삭제해서 ui만 보여주는 상태
              tabState === "mixCommu" && (
                <TabContentCt className="tab04">
                  <CombineChatBox
                    chatBotData={pickChatBotData}
                    setPopState={setPopState}
                    setPopMessage={setPopMessage}
                    handleSignOutApp={handleSignOutApp}
                  />
                </TabContentCt>
              )
            )}
          </DashBoardCt>
        )}

        {popState && (
          <AlertPop2
            text={popMessage}
            popState={popState}
            setPopState={setPopState}
          />
        )}
      </DASH_HOME.ContentCol>
    </DASH_HOME.Container>
  );
}

const SelectCt = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  /* 700임시 */
  @media (max-width: 700px) {
    width: 100%;
    margin: 10px auto;
  }
`;

const DashBoardCt = styled.div`
  width: 70%;
  max-width: 1200px;
  /* @media (max-width: 480px) { */
  @media (max-width: 780px) {
    width: 100%;
  }
  /* padding: 0px 10px; */
`;

const TabBtnCt = styled.div`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;

  @media (max-width: 480px) {
    margin-bottom: 20px;
    display: flex;
    & button {
      flex: 1 0 auto;
      font-size: 14px !important;
      padding-right: 5px;
      margin-right: 5px;
    }
  }

  & button {
    font-size: 16px;
    border-right: 1px solid #dcdcdc;
    margin-right: 10px;
    padding-right: 10px;
    color: #000;

    &.active {
      color: #ff892a;
      font-weight: 500;
      text-decoration: underline;
      text-underline-offset: 5px;
    }

    &:last-child {
      border-right: none;
    }
    &:hover {
      font-weight: 500;
      opacity: 0.8;
      color: #ff892a;
    }
  }
`;

const TabContentCt = styled.div`
  /* border: 1px solid red; */

  &.tab01 {
    /* border: 1px dashed red; */
  }
  &.tab02 {
    /* border: 1px dashed blue; */
  }
  &.tab03 {
    /* border: 1px dashed green; */
  }
  &.tab04 {
    /* border: 1px dashed purple; */
  }
`;

const ResultSection = styled.section`
  width: 100%;
  height: 100%;
  margin-top: 200px;

  & p.empty {
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    color: #888;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vw;
    width: 100%;

    @media (max-width: 480px) {
      font-size: 20px;
    }
  }
`;
