import botApi from "api/botApi";
import { useContext, useState } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { signAppContext } from "utils/providers/SignContextProvider";

import BotInfoDetailMain from "components/organisms/BotInfoDetailMain";
import { useCallback, useEffect } from "react";
import { ErrorCatch } from "api/ErrorCatch";

import { AlertPop2 } from "./AlertPop";

export default function BotInfoDetailPop({
  closePopup,
  mode,
  modBotUid,
  setMyBotList,
}) {
  const [showDetailView, setShowDetailView] = useState(false);
  const history = useHistory();

  const [popState, setPopState] = useState(false);
  const [popMessage, setPopMessage] = useState("");

  const [botInfo, setBotInfo] = useState({
    botUid: "",
    botName: "",
    dataName: "",
    excludeUrl: "",
    greetingMessage: "",
    botRole: "",
    botImagePath: "",
  });

  const { handleSignOutApp } = useContext(signAppContext);

  const createBotInfo = async (botInfoParams) => {
    try {
      const { status, data } = await botApi.botInfoCreate(botInfoParams);
      if (status === 200) {
        setPopState((prev) => !prev);
        setPopMessage(
          "생성 중에 있습니다. \n 챗봇 생성까지 잠시 기다려주세요."
        );
        // alert("생성 중에 있습니다. \n 챗봇 생성까지 잠시 기다려주세요.");
        setMyBotList((prev) => [...prev, JSON.parse(data.createBot)]);
        closePopup(status);
      }
    } catch (error) {
      console.log(error);
      ErrorCatch(error, handleSignOutApp);
    }
  };

  const modifyBotInfo = async (botInfoParams) => {
    try {
      const { status, data } = await botApi.botInfoModify(botInfoParams);

      if (status === 200) {
        const botInfo = JSON.parse(data.botInfo);

        setPopState((prev) => !prev);
        setPopMessage("수정 되었습니다.");
        // alert("수정 되었습니다.");

        setMyBotList((prev) =>
          prev.map((item) => {
            if (item.botUid === botInfo.botUid) {
              return Object.assign({}, item, botInfo);
            }
            return item;
          })
        );

        closePopup();
      }
    } catch (error) {
      console.log(error);
      ErrorCatch(error, handleSignOutApp);
    }
  };

  const getBotInfoByBotUid = useCallback(async () => {
    try {
      const { status, data } = await botApi.getBotInfoByBotUid(modBotUid);

      if (status === 200) {
        const resultData = JSON.parse(data.botInfo);
        if (!resultData) {
          setPopState((prev) => !prev);
          setPopMessage("불러오는 중 오류가 발생했습니다.");
          // alert("불러오는 중 오류가 발생했습니다.");
          console.log("getBotInfoByBotUid ", resultData);
          return;
        }

        const {
          botUid,
          botName,
          dataName,
          excludeUrl,
          greetingMessage,
          botRole,
          botImagePath,
        } = resultData;

        setBotInfo({
          botUid: botUid ? botUid : "",
          botName: botName ? botName : "",
          dataName: dataName ? dataName : "",
          excludeUrl: excludeUrl ? excludeUrl : "",
          greetingMessage: greetingMessage ? greetingMessage : "",
          botRole: botRole ? botRole : "",
          botImagePath: botImagePath ? botImagePath : "",
        });
        setShowDetailView(true);
      }
    } catch (error) {
      ErrorCatch(error, handleSignOutApp);
    }
  }, []);

  useEffect(() => {
    mode === "M" ? getBotInfoByBotUid() : setShowDetailView(true);
  }, [getBotInfoByBotUid]);

  return (
    <>
      {showDetailView ? (
        createPortal(
          <Wrapper>
            <BotInfoDetailMain
              mode={mode}
              botInfo={botInfo}
              closePopup={closePopup}
              callBackFunc={mode === "C" ? createBotInfo : modifyBotInfo}
            />
          </Wrapper>,
          document.getElementById("modal")
        )
      ) : (
        <></>
      )}
      {popState && (
        <AlertPop2
          popState={popState}
          setPopState={setPopState}
          text={popMessage}
        />
      )}
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
`;
