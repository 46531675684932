import user_icon from "assets/images/icons/user_icon.svg";
import iconLoading from "assets/images/progressDot.svg";
import BotInfoDetailPop from "components/templates/popup/BotInfoDetailPop.js";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { SubscribeContext } from "utils/providers/SubscribeContextProvider";
import "../../../assets/styles/style.css";
import NormalButton from "../../atomic/NormalButton2.js";
import ChatViewDetailPop from "../../templates/popup/ChatViewDetailPop.js";
import "./dashboard.css";
import { DASH_HOME, FlexBox } from "./dashboardStyle.js";

/* TODO
*  - 테이블 제거
*  - 기존 챗봇 설정 수정 모달창을 자연스럽게 옮기기
*  - 제외 URL 패턴 ⇒ 컴마(,) 단위로 구분
    - 버튼 제거
    - 아래의 제외 URL 패턴 문구를 위로
    - write your own prompt 버튼 제거
* */

const popMenuReducer = (state, action) => {
  switch (action.type) {
    case "OPEN_POP":
      return {
        ...state,
        menu: action.menu,
        popMode: action.popMode,
        botUid: action.botUid,
      };
    case "CLOSE_POP":
      return initPopMenu;
    default:
      return false;
  }
};

const initPopMenu = {
  menu: "", //목록에 action 클릭 시 나오는 메뉴 정보 | VIEW - 챗봇보기, INFO - 설정변경 ...
  popMode: "", //popup의 모드 Ex) C - create, M - modify 등등 각 메뉴에 맞게 사용
  botUid: "",
};

export default function DashboardHome({ myBotList, setMyBotList }) {
  const [popMenuState, dispatchPopMenu] = useReducer(
    popMenuReducer,
    initPopMenu,
  );

  const botState = {
    "00": {
      icon: "",
      text: "",
    },
    "01": {
      icon: "ri-brain-line",
      text: "생성",
    },
    "02": {
      icon: "ri-error-warning-line",
      text: "실패",
    },
  };

  const { subscribeMessage } = useContext(SubscribeContext); //봇 index 생성 플래그

  const IMAGE_PATH = process.env.REACT_APP_RESOURCE_HOST + "/images/bot/";
  const [featMenu, setFeatMenu] = useState({ open: false, botUid: "" });

  const [loadingStatus, setLoadingStatus] = useState(false);
  const tbodyRef = useRef();

  const closeBotInfoPop = (status) => {
    if (
      popMenuState.menu === "INFO" &&
      popMenuState.popMode === "C" &&
      status === 200
    ) {
      tbodyRef &&
        tbodyRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }

    dispatchPopMenu({ type: "CLOSE_POP" });
  };

  const initalBotInfoPop = () => {
    dispatchPopMenu({ type: "OPEN_POP", menu: "INFO", popMode: "C" });
  };

  useEffect(() => {
    console.log(">>> subscribe Effect");
    if (!loadingStatus) return;

    const botInfo = subscribeMessage;
    if (botInfo) {
      setMyBotList((prev) =>
        prev.map((item) => {
          if (item.botUid === botInfo.botUid)
            return Object.assign({}, item, botInfo);
          return item;
        }),
      );
      setLoadingStatus(false);
    }
  }, [subscribeMessage]);

  useEffect(() => {
    //초기 접속 시에 status 가 00인 경우 체크
    setLoadingStatus(
      myBotList.find((item) => item.status === "00") ? true : false,
    );
  }, [myBotList]);

  return (
    <DASH_HOME.Container className={"dashboard--home--container"}>
      <DASH_HOME.Header className={"line--bottom"}>
        <FlexBox className="top">
          <h2 className={"dashboard--home--title"}>챗봇 설정</h2>
          <NormalButton
            disabled={loadingStatus ? "disabled" : ""}
            buttonId={""}
            buttonTitle={"챗봇 생성"}
            buttonClass={"mid cOrange round"}
            style={{ marginBottom: "10px" }}
            callBackFunc={initalBotInfoPop}
          />
        </FlexBox>
      </DASH_HOME.Header>

      <DASH_HOME.Content>
        <table className={"dashboard--table"}>
          <colgroup>
            <col width="40%" />
            <col width="10%" />
            <col width="20%" />
            <col width="20%" />
            <col width="10%" />
          </colgroup>

          <thead>
            <tr>
              <th className="botNameTh">봇 이름</th>
              <th>
                상태 <i className="ri-information-line"></i>
                <div className="InfoText"></div>
              </th>
              <th>
                크롤링 된 페이지 수<i className="ri-question-line"></i>
                <div className="InfoText"></div>
              </th>
              <th>남은 질문 갯수 </th>
              <th>ACTION</th>
            </tr>
          </thead>
        </table>
        <DASH_HOME.Tbody>
          <table className="dashboard--table purpose--tbody">
            <colgroup>
              <col width="40%" />
              <col width="10%" />
              <col width="20%" />
              <col width="20%" />
              <col width="10%" />
            </colgroup>
            <tbody ref={tbodyRef}>
              {myBotList.length > 0 ? (
                myBotList.map((bot, index) => (
                  <tr key={`key_${index}`}>
                    <td className="botDataTd">
                      <div>
                        <DASH_HOME.BotImg
                          src={
                            bot.botImagePath
                              ? IMAGE_PATH + bot.botImagePath
                              : user_icon
                          }
                          alt=""
                        />
                        <p
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              "../../templates/popup/ChatViewDetailPop",
                              "_blank",
                              "noopener, noreferrer",
                            );
                          }}
                        >
                          {bot.botName}
                        </p>
                      </div>
                    </td>
                    <td className="">
                      {bot.status === "01" ? (
                        <>
                          <i
                            style={{ color: "#48BB78" }}
                            className={botState[bot.status].icon}
                          />
                          <span style={{ color: "#48BB78" }}>
                            {botState[bot.status].text}
                          </span>
                        </>
                      ) : bot.status === "02" ? (
                        <>
                          <i
                            style={{ color: "#E53E3E" }}
                            className={botState[bot.status].icon}
                          />
                          <span style={{ color: "#E53E3E" }}>
                            {botState[bot.status].text}
                          </span>
                        </>
                      ) : bot.status === "00" ? (
                        <>
                          <LoadingCt>
                            <LoadingProgress img={iconLoading} />
                          </LoadingCt>
                        </>
                      ) : bot.status === "99" ? (
                        "문의"
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="">{bot.crawlPage ? bot.crawlPage : 0}</td>
                    <td className="">{bot.totalCount - bot.useCount}</td>
                    <td className="featMenuTd">
                      <span
                        className="featMenu"
                        onClick={(e) => {
                          setFeatMenu({ open: true, botUid: bot.botUid });
                        }}
                      ></span>
                      {featMenu.botUid === bot.botUid && featMenu.open && (
                        <FeatMenu
                          dispatchPopMenu={dispatchPopMenu}
                          setFeatMenu={setFeatMenu}
                          botUid={bot.botUid}
                        />
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}> 봇을 생성해 보세요!</td>
                </tr>
              )}
            </tbody>
          </table>
        </DASH_HOME.Tbody>
      </DASH_HOME.Content>

      <DASH_HOME.Footer className={"line--top"}>
        <NormalButton
          style={{ margin: "3px" }}
          buttonId=""
          buttonTitle={"삭제"}
          buttonClass={"mid cDel round"}
        />
        <NormalButton
          style={{ margin: "3px" }}
          buttonId="saveBtn"
          buttonTitle={"저장"}
          buttonClass={"mid cGreen round"}
        />
      </DASH_HOME.Footer>
      {popMenuState.menu === "INFO" && (
        <BotInfoDetailPop
          closePopup={closeBotInfoPop}
          mode={popMenuState.popMode}
          modBotUid={popMenuState.botUid}
          setMyBotList={setMyBotList}
        />
      )}
      {popMenuState.menu === "VIEW" && (
        <ChatViewDetailPop
          closePopup={closeBotInfoPop}
          mode={popMenuState.popMode}
          modBotUid={popMenuState.botUid}
        />
      )}
    </DASH_HOME.Container>
  );
}

export const FeatMenu = ({ dispatchPopMenu, setFeatMenu, botUid }) => {
  const featRef = useRef();

  useEffect(() => {
    const onClick = (e) => {
      if (featRef.current && !featRef.current.contains(e.target))
        setFeatMenu(false);
    };
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [featRef]);

  return (
    <Wrapper ref={featRef}>
      <Menu
        onClick={(e) => {
          e.preventDefault();
          dispatchPopMenu({
            type: "OPEN_POP",
            menu: "VIEW",
            popMode: "V",
            botUid: botUid,
          });
        }}
      >
        챗봇보기
      </Menu>
      <Menu
        onClick={(e) => {
          e.preventDefault();
          dispatchPopMenu({
            type: "OPEN_POP",
            menu: "INFO",
            popMode: "M",
            botUid: botUid,
          });
        }}
      >
        설정 변경
      </Menu>
      <Menu>웹사이트 코드 삽입방법</Menu>
      <Menu>챗봇삭제</Menu>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 85%;
  left: 10%;
  display: grid;
  width: fit-content;
  height: auto;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  z-index: 100;
`;

const Menu = styled.button`
  width: 100%;
  display: inline-block;
  text-align: left;
  padding: 10px;
  font-size: 12px;
  letter-spacing: -0.5px;

  &:hover {
    background-color: #dcdcdc;
    font-weight: bold;
  }
`;

const Progress = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingCt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: auto;
  height: 30px;
`;

const LoadingProgress = styled.div`
  width: 100%;
  height: 100%;
  background: url(${({ img }) => img}) no-repeat;
  background-position: center;
  background-size: contain;
  filter: invert(1);
  animation: ${Progress} 2.5s infinite linear;
`;
