import { memo, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { PaginationContainer } from "utils/Pagination.js";
import BaseChat from "components/pages/chat/BaseChat.js";
import { refineToClientMessages } from "../../../../utils/chatUtil.js";
import botApi from "api/botApi.js";
import { ErrorCatch } from "api/ErrorCatch";
import NormalButton from "../../../atomic/NormalButton2.js";

export default function HistPlainChatBox(props) {
  const { chatBotData, setPopState, setPopMessage, handleSignOutApp } =
    props ?? {};
  // 대화사용
  const [openChatRoom, setOpenChatRoom] = useState(null);
  const [messages, setMessages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedChatRoom, setSelectedChatRoom] = useState(null);
  const PagingBtn = memo((props) => {
    const { text, ...otherProps } = props ?? {};
    return <PagingBtnComponent {...otherProps}>{text}</PagingBtnComponent>;
  });

  const getChatHistoryMessage = async (chatRoomId, botUid) => {
    async function getChatHistoryMessage(chatRoomId, botUid) {
      try {
        const { status, data } = await botApi.getChatHistoryMessage(
          chatRoomId,
          botUid,
        );
        if (status === 200) {
          const chatMessages = data.chatMessages
            ? JSON.parse(data.chatMessages)
            : "";
          if (chatMessages) {
            console.log("getChatHistoryMessage: ", chatMessages);
          }
          if (!Array.isArray(chatMessages)) {
            chatMessages = [chatMessages]; // 배열로 변환
          }
          const refineMessages = refineToClientMessages(chatMessages);
          setMessages(refineMessages);
        } else {
          console.log("getChatHistoryMessage Not Loaded...");
        }
      } catch (error) {
        ErrorCatch(error, handleSignOutApp);
      }
    }

    await getChatHistoryMessage(chatRoomId, botUid);
  };

  const funcSelectChatInfo = (data) => {
    getChatHistoryMessage(data.chatRoomId, chatBotData.botUid);
    setSelectedChatRoom(data);
  };

  const asyncFuncArgs = useMemo(() => {
    const chatType = 0;
    if (chatBotData?.botUid) {
      return [chatBotData?.botUid, chatType];
    } else {
      return [];
    }
  }, [chatBotData?.botUid]);

  useEffect(() => {
    if (!chatBotData) return;
    setSelectedChatRoom(null);
  }, [chatBotData]);

  return (
    <>
      {selectedChatRoom ? (
        <div style={{ width: "100%" }}>
          <div>
            {/* baseChat 자리 */}
            <ExChat>
              <BaseChat
                popupInfo={{ isPopup: false, setOpenChatRoom, openChatRoom }}
                showQuestionBox={false}
                messages={messages}
                setMessages={setMessages}
                chatBotData={chatBotData}
                chatRoomId={selectedChatRoom.chatRoomId}
                handleSignOutApp={handleSignOutApp}
                isHistoryBack={false}
                isHistory={true}
                isHeader={false}
                botClass="HISTORY"
                setPopMessage={setPopMessage}
                setPopState={setPopState}
              />
            </ExChat>
            <ChatDetailInfoFooter>
              <NormalButton
                style={{ margin: "0 auto" }}
                buttonTitle={"목록으로 "}
                buttonClass="mid cDel round"
                callBackFunc={(e) => {
                  e.preventDefault();
                  setSelectedChatRoom(false);
                }}
              ></NormalButton>
            </ChatDetailInfoFooter>
          </div>
        </div>
      ) : (
        <HistoryTableCt>
          {chatBotData?.botUid && (
            <PaginationContainer
              opt={{
                isAsync: true,
                asyncFuncName: "getChatRoomList",
                asyncFuncArgs: asyncFuncArgs,
                addArrayInClient: [],
              }}
              pageLength={5}
              pageCount={3}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              botUid={chatBotData.botUid}
              dataNullMessage={<>대화 기록이 없습니다</>}
              DataWrapComponent={BotHistoryList}
              DataItemComponent={(props) => (
                <CustomBotHistory
                  funcSelectChatInfo={funcSelectChatInfo}
                  {...props}
                />
              )}
              ItemComponent={PagingBtnComponent}
              itemActiveClassName={"current"}
              PrevComponent={(props) => (
                <PagingBtn className={"prev"} text={"이전"} {...props} />
              )}
              NextComponent={(props) => (
                <PagingBtn className={"next"} text={"다음"} {...props} />
              )}
              PaginationNumWrap={PagingCt}
            />
          )}
        </HistoryTableCt>
      )}
    </>
  );
}

const ExChat = styled.div`
  /* border: 1px solid #dcdcdc; */
  width: 100%;
  height: 600px;

  /* border-radius: 10px; */
  .chat--bot2.chat--cover.HISTORY {
    margin: 0;
  }
`;

const CustomBotHistory = (props) => {
  const { data, keyName, funcSelectChatInfo, ...otherProps } = props;
  return (
    <>
      <InfoHeader>
        <p className="title content">대화 기록</p>
        <p className="title date">작성일</p>
      </InfoHeader>

      <BotHistory
        key={keyName}
        onClick={(e) => {
          e.preventDefault();
          funcSelectChatInfo(data);
        }}
      >
        <InfoIp className="item content">{`${data.ip}`}</InfoIp>
        <InfoCreateAt className="item date">{`${data.createAt}`}</InfoCreateAt>
      </BotHistory>
    </>
  );
};

const InfoHeader = styled.div`
  width: 100%;
  display: none;

  p {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
    width: 120px;
    text-align: center;

    /* border: 1px solid red; */
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  & p.title.content {
    width: 400px;
    margin-right: 50px;
    @media (max-width: 480px) {
      width: 180px;
    }
  }

  & p.title.date {
    width: 200px;
  }

  &:first-child {
    background: #f2f3f8;
    border-bottom: 1px solid #ccc;
    display: flex;
    padding: 10px 15px;
    display: flex;
    justify-content: space-around;
    width: 100%;

    /* @media (max-width: 480px) {
      justify-content: unset;
      padding: 15px 30px;
      & p:last-child {
        margin-left: 35%;
      }
    } */
  }
`;
const InfoIp = styled.p`
  color: #7a7a7a;
  font-size: 16px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.16px;

  text-align: left;
  width: 400px;
  margin-right: 50px;

  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 480px) {
    width: 300px;
    margin-right: 0;
  }
`;
const InfoCreateAt = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #7a7a7a;
  font-size: 16px;
  font-weight: 350;
  line-height: normal;
  letter-spacing: -0.16px;

  width: 200px;
  text-align: center;
`;

const BotHistory = styled.div`
  //margin: 10px 0;
  padding: 15px;

  display: flex;
  justify-content: space-around;
  width: 100%;

  &:hover {
    background: #f2f3f8;
    border-radius: 0 !important;
    cursor: pointer;
  }

  & .item.content,
  .item.date {
    /* border: 1px solid red; */
  }

  &.active {
    width: 350px;
    padding: 0;
    justify-content: start;

    p {
      margin-right: 10px;
    }
  }
`;
const HistoryTableCt = styled.div`
  width: 100%;
  height: fit-content;
  margin: 9.5px 3px;
  display: flex;
  flex-direction: column;
  background: #fff;

  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);

  @media (max-width: 480px) {
    min-height: auto;
  }
`;

const BotHistoryList = styled.div`
  width: 100%;
  max-height: 330px;
  overflow: hidden;
  /* 모바일/ 웹이서 하단이 잘려보여서 15px로 통일  */
  /* border-radius: 15px 15px 0 0; */
  /* border-radius: 15px; */

  /* 23.07.25.codelua pagination 으로 던진 스타일이 이쪽에서 처리되도록 된 굉장히 짜증나는 코드 레거시임! */
  & p.empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #ffffff;
    color: tomato;
    line-height: 1.8;
  }
`;

const PagingBtnComponent = styled.button`
  border: 1px solid #ff892a;
  border-radius: 5px;
  margin: 10px 5px;
  padding: 5px 8px;

  color: #888;
  border: 1px solid #888;

  width: 45px;
  height: 35px;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &.current {
    border: 1px solid #ff892a;
    background-color: #ff892a;
    color: #fff;
  }

  &.next {
  }

  &.prev {
  }
`;

const PagingCt = styled.div`
  width: 100%;
  text-align: center;

  margin: 30px 0;
`;

const ChatDetailInfoFooter = styled.div`
  width: 100%;
  margin: 9.5px 3px;
  display: flex;
  align-items: center;

  @media (max-width: 480px) {
    font-size: 12px;
    flex-direction: column;
  }
`;
