import icon_close from "assets/images/icon_close.svg";
import styled from "styled-components";

export default function CloseButton({ callBack, styles }) {
  return (
    <XCloseBtn
      icon={icon_close}
      onClick={(e) => {
        callBack && callBack();
      }}
      style={styles}
    ></XCloseBtn>
  );
}

const XCloseBtn = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;

  background: url(${({ icon }) => icon}) no-repeat;
  background-size: cover;
  background-position: center;
`;
