export default class ObjectUtil {
  static strToObj(objStr) {
    if (typeof objStr !== "string") return objStr;

    try {
      return JSON.parse(objStr);
    } catch (err) {
      console.error("err", err);

      return null;
    }
  }

  static strToArray(objStr) {
    const refineData = ObjectUtil.strToObj(objStr);
    if (!Array.isArray(refineData)) return [];
    return refineData;
  }
}
